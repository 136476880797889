import { useEffect, useState } from "react";

const SIDEBAR_KEY = "sidebarExpanded";

export default function useSidebarState(defaultExpanded = true) {
  const [isExpanded, setIsExpanded] = useState(() => {
    const storedValue = localStorage.getItem(SIDEBAR_KEY);
    return storedValue !== null ? storedValue === "true" : defaultExpanded;
  });

  useEffect(() => {
    localStorage.setItem(SIDEBAR_KEY, String(isExpanded));
  }, [isExpanded]);

  const toggleSidebar = () => setIsExpanded((prev) => !prev);

  return { isExpanded, toggleSidebar };
}
