import { Box } from "@chakra-ui/react";
import { BlueBorderCard } from "components/Card/BlueBorderCard";

interface TableWrapperProps {
  useCardWrapper?: boolean;
  children: React.ReactNode;
  containerProps?: object;
}

export default function TableWrapper({ useCardWrapper = true, children, containerProps }: TableWrapperProps) {
  return useCardWrapper ? (
    <BlueBorderCard maxW="100%" overflow="scroll" width="100%" {...containerProps}>
      {children}
    </BlueBorderCard>
  ) : (
    <Box {...containerProps}>{children}</Box>
  );
}
