/* eslint-disable max-len */
import { Icon, IconProps, useColorModeValue } from "@chakra-ui/react";

function StarOutline({ ...props }: IconProps) {
  const color = useColorModeValue("notBlack.50", "white");
  return (
    <Icon
      viewBox="0 0 12 12"
      fill="none"
      color={color}
      {...props}
    >
      <path
        d="M9.82418 4.52L7.40418 4.31L6.45918 2.085C6.28918 1.68 5.70918 1.68 5.53918 2.085L4.59418 4.315L2.17918 4.52C1.73918 4.555 1.55918 5.105 1.89418 5.395L3.72918 6.985L3.17918 9.345C3.07918 9.775 3.54418 10.115 3.92418 9.885L5.99918 8.635L8.07418 9.89C8.45418 10.12 8.91918 9.78 8.81918 9.35L8.26918 6.985L10.1042 5.395C10.4392 5.105 10.2642 4.555 9.82418 4.52ZM5.99918 7.7L4.11918 8.835L4.61918 6.695L2.95918 5.255L5.14918 5.065L5.99918 3.05L6.85418 5.07L9.04418 5.26L7.38418 6.7L7.88418 8.84L5.99918 7.7Z"
        fill="currentColor"
      />
    </Icon>
  );
}
export default StarOutline;
