/* eslint-disable react/jsx-no-useless-fragment */
import { Tooltip } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

interface TooltipWrapperProps extends PropsWithChildren {
  condition: boolean;
  tooltipText: string;
}

function TooltipWrapper({ condition, tooltipText, children }: TooltipWrapperProps) {
  return condition ? (
    <Tooltip label={tooltipText}>
      <span>{children}</span>
    </Tooltip>
  ) : (
    <>
      { children }
    </>
  );
}

export default TooltipWrapper;
