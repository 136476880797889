import FieldByType from "features/PendingNewHire/Form/FieldByType";
import { UseFormReturn } from "react-hook-form";
import { camelCase } from "lodash";
import { CompanyUpdateFormValues, FIELD_CONFIG } from "./formDefinition";

export default function DynamicCompanyField({
  form,
  fieldName,
  customLabel,
  ...rest
}: {
  form: UseFormReturn<any>;
  fieldName: keyof CompanyUpdateFormValues,
  customLabel?: string;
}) {
  if (!fieldName) {
    return null;
  }
  const camelFieldName = camelCase(fieldName) as keyof CompanyUpdateFormValues;
  const field = FIELD_CONFIG[camelFieldName];
  const label = customLabel || field?.label;
  if (!field) {
    console.error(`Field ${camelFieldName} not found`);
    return null;
  }
  return (
    <FieldByType form={form} key={camelFieldName} {...field} label={label} {...rest} />
  );
}
