import { HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";
import StarOutline from "components/Icon/Iconify/StartOutline";
import { assignedActionTargetText } from "helpers/workflowActionableConverters";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { EmailRecipient } from "models/automation";
import RecipientTag from "components/Forms/PathActions/Email/RecipientTag";
import { emailTagId } from "features/AssignedAction/helpers";
import { Flex } from "@chakra-ui/react";
import NameTag from "components/Tag/NameTag";
import { longAttributeStyles } from "../styles";

export default function RecipientAttribute({ action }: { action: AssignedAction }) {
  return (
    <HorizontalGreyBlackField icon={StarOutline} label="To" labelContainerProps={longAttributeStyles.labelContainerProps}>
      <NameTag name={assignedActionTargetText(action)} />
    </HorizontalGreyBlackField>
  );
}

export function EmailRecipientAttribute({ action }: { action: AssignedAction }) {
  const recipients = [<NameTag key={assignedActionTargetText(action)} name={assignedActionTargetText(action)} />];
  action?.additionalRecipients?.forEach((recipient: EmailRecipient) => {
    recipients.push(<RecipientTag key={emailTagId(recipient)} recipient={recipient} disabled />);
  });
  return (
    <HorizontalGreyBlackField icon={StarOutline} label="To" labelContainerProps={longAttributeStyles.labelContainerProps}>
      {recipients.length > 1 ? (
        <Flex gap="1">
          {recipients}
        </Flex>
      ) : (
        recipients
      )}
    </HorizontalGreyBlackField>
  );
}
