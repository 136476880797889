import {
  Box, Flex, HStack, Link, SystemStyleObject, Text,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { SHARED_COMPANY_ROUTES, SHARED_ROUTES } from "definitions/constants/routeConstants";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { RandomEmptyRecentNewHiresState } from "components/EmptyComponents/RandomEmptyState";
import { newHireJourneyPathById } from "helpers/url";
import StartDateTag from "components/Tag/StartDateTag";
import { DashboardNewHire } from "pages/PeopleTeam/DashboardPage/models";
import { NumberOfIssuesByNewHireTag } from "features/Issues/Components/NumberOfIssuesTag";
import ControlledDataTable, { ControlledDataTableProps } from "./ControlledDataTable";

const columnHelper = createColumnHelper<DashboardNewHire>();

const columns = [
  columnHelper.accessor((row) => row, {
    id: "name",
    header: "New Hire",
    minSize: 250,
    size: 250,
    cell: (row) => (
      <Link
        as={RouterLink}
        _hover={{ textDecoration: "none" }}
        to={{
          pathname: generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, { id: row.getValue().newHire.id }),
        }}
        width="fit-content"
      >
        <HStack spacing="3">
          <Flex direction="column">
            <Text fontWeight="medium">{row.getValue().newHire.name || "Unknown"}</Text>
            <Text color="fg.muted">{row.getValue().newHire.title || ""}</Text>
          </Flex>
        </HStack>
      </Link>
    ),
  }),
  columnHelper.accessor((row) => row, {
    id: "startDate",
    cell: (row) => <StartDateTag startDate={row.getValue().newHire.startDate} />,
    header: "Start Date",
  }),
  columnHelper.accessor((row) => row, {
    id: "currentUserOpenTasksCount",
    header: "My Outstanding Tasks",
    cell: (row) => (
      <Box textAlign="center">
        <Link as={RouterLink} to={`${generatePath(SHARED_ROUTES.tasks.index)}?newHireId=${row.getValue().newHire.id}`}>
          {row.getValue().openTasksCount.currentUser}
        </Link>
      </Box>
    ),
    meta: { headerHStackProps: { justifyContent: "center" } },
  }),
  columnHelper.accessor((row) => row, {
    id: "othersOpenTasksCount",
    header: "Team Outstanding Tasks",
    cell: (row) => (
      <Box textAlign="center">
        <Link as={RouterLink} to={`${newHireJourneyPathById(row.getValue()?.newHire.id, "tasks")}`}>
          {row.getValue().openTasksCount.others}
        </Link>
      </Box>
    ),
    meta: { headerHStackProps: { justifyContent: "center" } },
  }),
  columnHelper.accessor((row) => row, {
    id: "issuesCount",
    header: "Issues",
    cell: (row) => (
      <Box textAlign="center">
        <NumberOfIssuesByNewHireTag newHireId={row.getValue().newHire.id} />
      </Box>
    ),
    meta: { headerHStackProps: { justifyContent: "center" } },
  }),
  columnHelper.accessor((row) => row, {
    id: "actionsProcessed",
    header: "Actions",
    cell: (row) => (
      <Box textAlign="left">
        <Link as={RouterLink} to={`${newHireJourneyPathById(row.getValue().newHire.id, "activityFeed")}`}>
          {`${row.getValue().actionsSent} Sent`}
        </Link>
      </Box>
    ),
  }),
];

interface PeopleTeamUserDashboardTableProps {
  data?: DashboardNewHire[];
  isLoading?: boolean;
  sx?: SystemStyleObject;
}

export default function PeopleTeamUserDashboardTable({ data = [], isLoading = false, sx = undefined }: PeopleTeamUserDashboardTableProps) {
  const tableProps: ControlledDataTableProps<DashboardNewHire> = {
    isLoading,
    data: data || [],
    columns,
    initialPagination: { pageSize: 20, pageIndex: 0 },
    defaultSort: [{ id: "startDate", desc: false }],
    textWrap: true,
    emptyStateComponent: <RandomEmptyRecentNewHiresState />,
    variant: "simple",
  };

  return (
    <ControlledDataTable
      containerProps={{
        borderRadius: "8px",
      }}
      sx={{
        mt: "5",
        width: { base: "100%", xl: "973px" },
        ...sx,
      }}
      {...tableProps}
    />
  );
}
