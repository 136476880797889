import { Issue } from "models/issue";
import { useLocation, useNavigate } from "react-router-dom";
import { assignedActionPathById, issuePathById, newHireJourneyPathById } from "helpers/url";
import { PrimaryTableButton } from "components/Button/TableButton";
import { ButtonProps } from "@chakra-ui/react";
import { useIssuesByNewHireIdQuery } from "features/Issues/hooks";

interface ResolveIssueButtonProps extends ButtonProps {
  issue?: Issue;
}

export function ResolveIssueButton({ issue, ...rest }: ResolveIssueButtonProps) {
  const navigate = useNavigate();

  if (!issue) {
    return null;
  }

  return (
    <PrimaryTableButton
      onClick={() => navigate(issuePathById(issue?.id))}
      {...rest}
    >
      Resolve
    </PrimaryTableButton>
  );
}

interface ResolveActionButtonProps extends ButtonProps {
  actionId: string;
}

export function ResolveActionButton({ actionId, ...rest }: ResolveActionButtonProps) {
  const navigate = useNavigate();
  const location = useLocation();

  if (!actionId || location.pathname.includes(assignedActionPathById(actionId))) {
    return null;
  }

  return (
    <PrimaryTableButton
      onClick={() => navigate(assignedActionPathById(actionId))}
      {...rest}
    >
      Resolve
    </PrimaryTableButton>
  );
}

interface ResolveNewHireIssuesButtonProps extends ButtonProps {
  newHireId: string;
}

export function ResolveNewHireIssuesButton({ newHireId, ...rest }: ResolveNewHireIssuesButtonProps) {
  const { data: issues } = useIssuesByNewHireIdQuery(newHireId);
  const navigate = useNavigate();

  if (!newHireId) {
    return null;
  }

  return (
    <PrimaryTableButton
      onClick={() => navigate(issues?.length ? issuePathById(issues[0]?.id) : newHireJourneyPathById(newHireId))}
      {...rest}
    >
      Resolve
    </PrimaryTableButton>
  );
}
