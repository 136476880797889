import { Heading, VStack } from "@chakra-ui/react";
import { ErroredActionsByNewHireTable } from "features/Issues/Table/ErroredActionsTable";
import { NewHireJourneyId } from "models/newHire";

interface PathsTabsProps {
  newHireId: NewHireJourneyId;
}

export default function ErroredActionsTab({ newHireId }: PathsTabsProps) {
  return (
    <VStack align="flex-start">
      <Heading as="h6" size="xxs">
        Errored Actions
      </Heading>
      {newHireId && <ErroredActionsByNewHireTable newHireId={newHireId} />}
    </VStack>
  );
}
