import { CreateToastFnReturn, useToast } from "@chakra-ui/react";
import { QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { API_ROUTES } from "definitions/constants/routeConstants";
import useDeferredApiCall from "hooks/useDeferredApiCall";
import { AvailableIntegrationType, Integration } from "models/integration";
import { NewIncomingWebhook } from "models/integration/incomingWebhook";
import { MessageServiceChannel } from "models/integration/messageService";
import { User } from "models/user";
import { Schema } from "pages/PeopleTeam/CompanyIntegrationsPage/NewCredentialsCard";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";

export const integrationKeys = {
  all: ["integrations"] as const,
  lists: () => [...integrationKeys.all, "list"] as const,
  available: () => [...integrationKeys.all, "available"] as const,
  details: () => [...integrationKeys.all, "detail"] as const,
  detail: (id: string) => [...integrationKeys.details(), id] as const,
};

export const useIntegrationDetailQuery = (id: string, options = {}) => useQuery<Integration>({
  queryKey: integrationKeys.detail(id),
  queryFn: async () => apiClient.get(generatePath(API_ROUTES.integrations.show, { id })),
  ...options,
});

export const useIntegrationListQuery = (options = {}) => useQuery({
  queryKey: integrationKeys.lists(),
  queryFn: async () => apiClient.get<Integration[]>(generatePath(API_ROUTES.integrations.index)),
  ...options,
});

export const useAvailableIntegrationListQuery = (options = {}) => useQuery({
  queryKey: integrationKeys.available(),
  queryFn: async () => apiClient.get<AvailableIntegrationType[]>(generatePath(API_ROUTES.integrations.available.index)),
  ...options,
});

export function useToggleActiveIntegration(integrationId: string) {
  return useDeferredApiCall<{ success: true }>(
    generatePath(API_ROUTES.integrations.show, { id: integrationId }),
    {
      method: "PUT",
      onSuccess: (_, queryClient, toast) => {
        queryClient.invalidateQueries({ queryKey: integrationKeys.lists() });
        toast({ title: "Integration updated" });
      },
      onFailure: (_, _1: QueryClient, toast: CreateToastFnReturn) => {
        toast({ title: "There was an error, please try again", status: "error" });
      },
    },
  );
}

export function useDeleteIntegration(integrationId: string, onSuccess?: () => void) {
  return useDeferredApiCall<{ success: true }>(
    generatePath(API_ROUTES.integrations.show, { id: integrationId }),
    {
      method: "DELETE",
      onSuccess: (_, queryClient, toast) => {
        queryClient.invalidateQueries({ queryKey: integrationKeys.lists() });
        queryClient.invalidateQueries({ queryKey: integrationKeys.available() });
        toast({ title: "Integration removed" });
        onSuccess?.();
      },
      onFailure: (_, _1: QueryClient, toast: CreateToastFnReturn) => {
        toast({ title: "There was an error, please try again", status: "error" });
      },
    },
  );
}

export function useCreateIntegrationWithSecret(onSuccess?: (response: NewIncomingWebhook, queryClient: QueryClient, toast: CreateToastFnReturn) => void) {
  const queryClient = useQueryClient();
  const toast = useToast({
    status: "success",
  });
  return useMutation({
    mutationFn: (data: Schema) => apiClient.post<{ success: boolean }>(generatePath(API_ROUTES.integrations.index), { incoming_webhook: data }),
    onSuccess: async (response: any) => {
      toast({ title: "Secret key added successfully" });
      queryClient.invalidateQueries({ queryKey: integrationKeys.lists() });
      queryClient.invalidateQueries({ queryKey: integrationKeys.available() });
      onSuccess?.(response, queryClient, toast);
    },
    onError: (mutateError) => {
      console.log("mutateError");
      console.log(mutateError);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    },
  });
}

export function useAddIntegration(onSuccess?: (response: NewIncomingWebhook, queryClient: QueryClient, toast: CreateToastFnReturn) => void) {
  return useDeferredApiCall<NewIncomingWebhook>(API_ROUTES.integrations.index, {
    method: "POST",
    onSuccess: (response, queryClient, toast) => {
      queryClient.invalidateQueries({ queryKey: integrationKeys.lists() });
      queryClient.invalidateQueries({ queryKey: integrationKeys.available() });
      onSuccess?.(response, queryClient, toast);
    },
    onFailure: (response, _2: QueryClient, toast: CreateToastFnReturn) => {
      console.log("webhookCreateError", response);
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });
}

export function useSyncChannels() {
  return useDeferredApiCall<MessageServiceChannel[]>(API_ROUTES.messageServices.channels.sync, {
    method: "POST",
    onSuccess: (_, queryClient, toast) => {
      queryClient.invalidateQueries({ queryKey: integrationKeys.lists() });
      toast({ title: "Channels synced" });
    },
    onFailure: (_, _2: QueryClient, toast: CreateToastFnReturn) => {
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });
}

interface SyncUsersOptions {
  onSuccess?: () => void;
}

export function useSyncUsers(options: SyncUsersOptions = {}) {
  const { onSuccess } = options;
  return useDeferredApiCall<User[]>(API_ROUTES.messageServices.users.sync, {
    method: "POST",
    onSuccess: (_, queryClient, toast) => {
      onSuccess?.();
      queryClient.invalidateQueries({ queryKey: integrationKeys.lists() });
      toast({ title: "Users synced" });
    },
    onFailure: (_, _2: QueryClient, toast: CreateToastFnReturn) => {
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });
}
