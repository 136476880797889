import { Flex } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import ActionRichTextAreaField from "components/FormElements/Fields/ActionRichTextAreaField";
import {
  forwardRef, useCallback, useEffect, useImperativeHandle,
} from "react";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { yupResolver } from "@hookform/resolvers/yup";
import { dirtyValues } from "helpers/form";
import { useQueryClient } from "@tanstack/react-query";
import { GreyBlackLabel, HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";
import { assignedActionTargetText } from "helpers/workflowActionableConverters";
import { newHireJourneyKeys } from "features/NewHireJourney/hooks";
import StarOutline from "components/Icon/Iconify/StartOutline";
import CalendarMonth from "components/Icon/Iconify/CalendarMonth";
import Clock from "components/Icon/Iconify/Clock";
import { ListIssuesByAction } from "features/Issues/Components/ListIssuesInfo";
import NameTag from "components/Tag/NameTag";
import { Schema, schema } from "./schema";
import { BODY_FIELD } from "./fields";
import useSubmitUpdateForm from "../Shared/useSubmitUpdateForm";
import { triggerValues } from "../Shared/TriggerDateTime";
import TriggerDate from "../Shared/TriggerDate";
import TriggerTimeModalButton from "../Shared/TriggerTimeModalButton";
import { TriggerExplanation } from "../Shared/DisplayInfo";

function isChatAction(action: AssignedAction): action is AssignedAction & { actionType: "email" } {
  return action.actionType === "chat";
}

const values = (action: AssignedAction): Schema => {
  if (!isChatAction(action)) {
    throw new Error("Action is not of type chat");
  }

  return {
    actionType: "chat",
    content: {
      body: action?.content?.bodyTrix ?? action?.content?.body,
    },
    name: action?.name,
    ...triggerValues(action),
    trigger: action?.trigger,
  };
};

interface UpdateChatFormProps {
  assignedAction: AssignedAction;
  onSuccess: () => void;
  setIsSubmitLoading: (isLoading: boolean) => void;
  setIsFormDirty: (isLoading: boolean) => void;
}

const UpdateChatForm = forwardRef(
  ({ assignedAction, onSuccess, setIsSubmitLoading, setIsFormDirty }: UpdateChatFormProps, ref) => {
    const queryClient = useQueryClient();
    const form = useForm<Schema>({
      mode: "onBlur",
      resolver: yupResolver<Schema>(schema, { stripUnknown: true }),
      defaultValues: values(assignedAction),
    });
    const {
      control,
      formState: { errors, dirtyFields, isDirty },
      handleSubmit,
      reset,
    } = form;
    const { mutate: onSubmitForm, isPending: onSubmitLoading } = useSubmitUpdateForm({
      assignedAction,
      onSubmitSuccess: onSuccess,
    });

    useEffect(() => {
      setIsFormDirty(isDirty);
    }, [isDirty, setIsFormDirty]);

    useEffect(() => {
      setIsSubmitLoading(onSubmitLoading);
    }, [onSubmitLoading, setIsSubmitLoading]);

    const handleOnSubmit = useCallback(
      (onSuccessCallback: () => void) => {
        handleSubmit((data) => {
          // handleSubmit built in - if the form is invalid, this function will not be called.
          // Because of this, if we pull "updateData" and its an empty object (no dirty fields),
          // we do not need to call the onSubmit function.
          const updateData = dirtyValues(data, dirtyFields);
          if (Object.keys(updateData).length === 0) {
            onSuccess?.();
            onSuccessCallback?.();
            return;
          }
          updateData.actionType = data.actionType;
          onSubmitForm(updateData, {
            onSuccess: () => {
              queryClient.invalidateQueries({ queryKey: newHireJourneyKeys.detail(assignedAction?.scheduledWorkflow?.onboardingJourneyId) });
              reset({}, { keepValues: true });
              onSuccess?.();
              onSuccessCallback?.();
            },
            onError: (error) => {
              console.log("error", error);
            },
          });
        })();
      },
      [handleSubmit, dirtyFields, onSubmitForm, onSuccess, queryClient, assignedAction?.scheduledWorkflow?.onboardingJourneyId, reset],
    );

    // Expose form methods to parent via ref
    useImperativeHandle(ref, () => ({
      handleSubmit: (onSuccessCallback: () => void) => handleOnSubmit(onSuccessCallback),
    }));

    return (
      <>
        <ListIssuesByAction actionId={assignedAction?.id} align="flex-start" />
        <Flex mt="2" mb="6" gap="4" direction="column" align="start" w="100%">
          <HorizontalGreyBlackField icon={StarOutline} label="To" labelContainerProps={{ width: "75px" }}>
            <NameTag name={assignedActionTargetText(assignedAction)} />
          </HorizontalGreyBlackField>
          {assignedAction?.trigger?.type === "date_time" ? (
            <>
              <TriggerDate
                form={form}
                formFieldProps={{ sideLayout: true, labelProps: { display: "flex", alignItems: "flex-end", minWidth: "75px" } }}
                label={<GreyBlackLabel icon={CalendarMonth} label="Send Date" />}
                includeIcon={false}
                triggerButtonProps={{
                  padding: "0",
                  height: "32px !important",
                  paddingTop: "0 !important",
                  fontSize: "14px",
                }}
                containerProps={{ height: "32px" }}
              />
              <HorizontalGreyBlackField
                icon={Clock}
                label="Sending At"
                labelContainerProps={{ minWidth: "75px" }}
              >
                <TriggerTimeModalButton form={form} assignedActionId={assignedAction?.id || ""} />
              </HorizontalGreyBlackField>
            </>
          ) : (
            <TriggerExplanation assignedAction={assignedAction} />
          )}
        </Flex>
        <ActionRichTextAreaField
          actionType="chat"
          targetType={assignedAction?.workflowAction?.actionable?.targetType}
          {...BODY_FIELD}
          errors={errors}
          control={control}
        />
      </>
    );
  },
);

export default UpdateChatForm;
