import { Flex, Skeleton, Stack } from "@chakra-ui/react";
import FlowerAvatar from "components/CaminoAvatar";
import { Heading18 } from "components/Heading";
import { Text14 } from "components/Text";
import { NewHireJourney } from "models/newHire";
import { Link as RouterLink } from "react-router-dom";
import { newHireJourneyPathById } from "helpers/url";
import { StartDateAttribute } from "./NewHireAttribute";

interface NewHireNameAndTitleProps {
  newHire: NewHireJourney | undefined;
  isNewHirePage?: boolean;
}

export default function NewHireNameAndTitle({ newHire, isNewHirePage = false, ...rest }: NewHireNameAndTitleProps) {
  return (
    <Flex gap="4" {...rest}>
      <FlowerAvatar
        size="md"
        name={newHire?.user?.fullName}
        src={newHire?.user?.avatarUrl}
      />
      <Stack gap="1">
        {!newHire ? <Skeleton width="100px" height="24px" /> : null}
        {isNewHirePage ? (
          <Heading18>
            {newHire?.user?.fullName}
          </Heading18>
        ) : (
          <Heading18
            as={RouterLink}
            size="xs"
            fontSize="xl"
            to={newHireJourneyPathById(newHire?.id)}
            _hover={{ textDecoration: "underline" }}
          >
            {newHire?.user?.fullName}
          </Heading18>
        )}
        {newHire ? (
          <Text14 color="notBlack.50">{newHire?.user?.title}</Text14>
        ) : (
          <Skeleton width="100px" height="12px" />
        )}
        <StartDateAttribute mt="1.5" newHire={newHire} />
      </Stack>
    </Flex>
  );
}
