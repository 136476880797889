import * as Yup from "yup";
import { get, set } from "lodash";
import { AutoFormBasicField, AutoFormField, AutoFormSection } from "models/autoForm";
import { dateStrToLocalDate } from "utils/dateFormatter";

export function buildDataTransformer(sections: AutoFormSection[]) {
  const fields = sections.flatMap((section) => section.fields);
  return (data: any) => {
    if (!data) {
      return data;
    }
    const transformedData: any = {};

    fields.forEach((field) => {
      const value = data[field.name];
      if (value !== undefined) {
        if (field.writer) {
          set(transformedData, field.writer, value);
        } else {
          transformedData[field.name] = value;
        }
      }

      if (field.hiddenFields) {
        field.hiddenFields.forEach((hiddenField) => {
          const hiddenValue = data[hiddenField.name];
          if (hiddenValue !== undefined) {
            if (hiddenField.writer) {
              set(transformedData, hiddenField.writer, hiddenValue);
            } else {
              transformedData[hiddenField.name] = hiddenValue;
            }
          }
        });
      }
    });
    return transformedData;
  };
}

function buildFormSectionSchema(section: AutoFormSection) {
  const schema = Yup.object().shape(
    section.fields.reduce((acc, field) => {
      let fieldSchema: Yup.StringSchema | Yup.DateSchema | Yup.NumberSchema = Yup.string();
      if (field.type === "date") fieldSchema = Yup.date();
      if (field.type === "email") fieldSchema = Yup.string().email(`${field.label} must be a valid email`);
      if (field.required) fieldSchema = fieldSchema.required(`${field.label} is required`);
      acc[field.name] = fieldSchema;

      if (field.hiddenFields) {
        field.hiddenFields.forEach((hiddenField) => {
          acc[hiddenField.name] = Yup.string();
        });
      }

      return acc;
    }, {}),
  );
  return schema;
}

export function buildFormSchema(sections: AutoFormSection[]) {
  const combinedSchema = sections.reduce((acc, section) => {
    const sectionSchema = buildFormSectionSchema(section);
    return acc.concat(sectionSchema);
  }, Yup.object().shape({}));
  return combinedSchema;
}

function getFieldValue<T>(data: T, field: AutoFormBasicField | AutoFormField) {
  const value = get(data, field.accessor || field.name);
  if (!value) {
    return;
  }
  switch (field?.type) {
    case "date":
      return dateStrToLocalDate(value);
    default:
      return value;
  }
}

export function sectionDefaultValues<T>(section: AutoFormSection, data: T) {
  const defaultValues: Record<string, any> = {};
  section.fields.forEach((field) => {
    const value = getFieldValue<T>(data, field);
    if (value) {
      defaultValues[field.name] = value;
    }

    if (field.hiddenFields) {
      field.hiddenFields.forEach((hiddenField) => {
        const additionalValue = getFieldValue<T>(data, hiddenField);
        if (additionalValue !== undefined) {
          defaultValues[hiddenField.name] = additionalValue;
        }
      });
    }
  });
  return defaultValues;
}

export default function autoFormDefaultValues<T>(sections: AutoFormSection[], data: T) {
  const defaultValues: Record<string, any> = {};
  sections.forEach((section) => {
    const sectionDefaults = sectionDefaultValues(section, data);
    Object.assign(defaultValues, sectionDefaults);
  });
  return defaultValues;
}
