import { Issue } from "models/issue";
import { issuePathById } from "helpers/url";
import { useNavigate } from "react-router-dom";
import OutlineTag from "components/Tag/OutlineTag";
import { TagProps } from "@chakra-ui/react";
import resolveIssueType, { expandedIssueTypeResolver } from "../Resolutions/resolveIssueType";
import { useIssueContextQuery } from "../hooks";

function IssueTagBase({ children, ...rest }: TagProps) {
  return (
    <OutlineTag
      color="tagRed.text"
      borderColor="tagRed.bg"
      bg="tagRed.bg"
      border="none"
      cursor="pointer"
      height="20px"
      minHeight="18px"
      fontSize="xs"
      {...rest}
    >
      {children}
    </OutlineTag>
  );
}

export function IssueTag({ issue, ...rest }: { issue: Issue } & TagProps) {
  return (
    <IssueTagBase {...rest}>
      {resolveIssueType(issue)}
    </IssueTagBase>
  );
}

export function ExpandedIssueTag({ issue, ...rest }: { issue: Issue } & TagProps) {
  const issueTypeResolver = expandedIssueTypeResolver(issue);

  const issueContextQueryResult = useIssueContextQuery(issue, {
    enabled: !!issueTypeResolver,
  });

  let issueText;

  if (issueTypeResolver && issueContextQueryResult.isFetched) {
    issueText = issueTypeResolver(issue, issueContextQueryResult);
  } else {
    issueText = resolveIssueType(issue);
  }

  return (
    <IssueTagBase {...rest}>
      {issueText}
    </IssueTagBase>
  );
}

export function RedirectIssueTag({ issue }: { issue: Issue }) {
  const navigate = useNavigate();
  return (
    <IssueTag issue={issue} onClick={() => navigate(issuePathById(issue?.id))} />
  );
}
