/* eslint-disable max-len */
import { Icon, IconProps } from "@chakra-ui/react";

function BaselineCheckOutline({ color = "#77D1A1", ...props }: IconProps) {
  return (
    <Icon
      viewBox="0 0 12 12"
      fill="none"
      color={color}
      {...props}
    >
      <path
        d="M6 1C3.24 1 1 3.24 1 6s2.24 5 5 5 5-2.24 5-5S8.76 1 6 1m-1 7.5l-2.5-2.5.705-.705L5 7.085l3.795-3.795L9.5 4z"
        fill="currentColor"
      />
    </Icon>
  );
}
export default BaselineCheckOutline;
