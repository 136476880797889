import { Badge, BadgeProps } from "@chakra-ui/react";

export default function RoundBadge({ ...rest }: BadgeProps) {
  return (
    <Badge
      w="12px"
      h="12px"
      borderRadius="full"
      p={0}
      {...rest}
    />
  );
}
