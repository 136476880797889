import { Issue, IssueType, TIMING_ISSUE_TYPES } from "models/issue";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { timingIssueQueryArgs } from "../helpers";
import { unassignedRoleQueryArgs } from "./definitions/UnassignedRole";
import { missingMessageServiceQueryArgs } from "./definitions/MissingMessageService";
import { missingMessageServiceUserQueryArgs } from "./definitions/MissingMessageServiceUser";
import { missingEmailQueryArgs } from "./definitions/MissingEmail";
import { missingAttributeQueryArgs } from "./definitions/MissingAttribute";
import { missingChannelQueryArgs } from "./definitions/MissingChannel";

type IssueQueryArgs = (issue: Issue) => any;
type ActionQueryArgs = (action: AssignedAction) => any;

export const ISSUE_VALUE_QUERY_ARGS_MAP: Record<IssueType, IssueQueryArgs | ActionQueryArgs> = {
  missing_channel: missingChannelQueryArgs,
  missing_attribute: missingAttributeQueryArgs,
  missing_email: missingEmailQueryArgs,
  missing_message_service: missingMessageServiceQueryArgs,
  missing_message_service_user: missingMessageServiceUserQueryArgs,
  reference_event_overlap: timingIssueQueryArgs,
  trigger_at_in_past: timingIssueQueryArgs,
  unassigned_role: unassignedRoleQueryArgs,
};

export default function resolveIssueValueQueryArgs(issue: Issue, action?: AssignedAction) {
  if (!issue) {
    return undefined;
  }
  const issueValueQueryArgs = ISSUE_VALUE_QUERY_ARGS_MAP[issue.issueType];
  if (!issueValueQueryArgs) {
    throw new Error(`Unknown issue type: ${issue?.issueType} for issue ${issue?.id}`);
  }
  if (TIMING_ISSUE_TYPES.includes(issue.issueType)) {
    if (!action) {
      throw new Error(`Missing action for timing issue: ${issue?.issueType} for issue ${issue?.id}`);
    }
    return (issueValueQueryArgs as ActionQueryArgs)(action);
  }
  return (issueValueQueryArgs as IssueQueryArgs)(issue);
}
