import { useNavigate } from "react-router-dom";

export default function useGoBack(backupPath: string) {
  const navigate = useNavigate();
  return () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(backupPath);
    }
  };
}
