import { EmailIcon } from "@chakra-ui/icons";
import { Tag, TagCloseButton, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { useUserDetailQuery } from "features/User/hooks";
import { titleize } from "helpers/string";
import { EmailRecipient } from "models/automation";
import { OnboardingJourneyNetworkRelation } from "models/record_target";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";

export default function RecipientTag({ recipient, remove = () => {}, disabled = false }: { recipient: EmailRecipient; remove?: () => void, disabled?: boolean }) {
  const appendEmailType = (value: string, emailType: string) => {
    const abbreviation = emailType?.slice(0, 1);
    if (!abbreviation) return value;
    return `${value} (${abbreviation})`;
  };
  let { id, targetType } = { id: "", targetType: "" };
  if (recipient.customEmail) {
    // No-op
  } else {
    id = recipient.recipient.targetId;
    targetType = recipient.recipient.targetType;
  }

  const { data: userData } = useUserDetailQuery(id || "", {
    enabled: targetType === "User",
  });

  const { data: relationData } = useQuery<OnboardingJourneyNetworkRelation>({
    queryKey: ["OnboardingJourneyNetworkRelation", id],
    queryFn: () => apiClient.get<OnboardingJourneyNetworkRelation>(
      generatePath(API_ROUTES.onboardingJourneyNetworkRelations.show, { id }),
    ),
    enabled: targetType === "OnboardingJourneyNetworkRelation",
  });
  let values = { value: "Loading", icon: EmailIcon };
  if (recipient.customEmail) {
    values = { value: recipient.customEmail, icon: EmailIcon };
  } else if (targetType === "User") {
    if (userData) values = { value: appendEmailType(userData.fullName, recipient.emailType), icon: EmailIcon };
  } else if (targetType === "OnboardingJourneyNetworkRelation") {
    if (relationData) values = { value: appendEmailType(titleize(relationData.name), recipient.emailType), icon: EmailIcon };
  }

  return (
    <Tag size="lg" height="28px" minHeight="26px" borderRadius="2xl">
      <TagLeftIcon boxSize="12px" as={values.icon} />
      <TagLabel
        fontWeight="normal"
        width="max-content"
        fontSize="sm"
      >
        {values.value}
      </TagLabel>
      {!disabled && <TagCloseButton onClick={remove} />}
    </Tag>
  );
}
