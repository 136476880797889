import StarryNightForest from "assets/starry-night-forest.jpg";
import Empty from "./Empty";

interface UnfoundResourceProps {
  resourceName?: string;
}

export default function UnfoundResource({ resourceName = undefined }: UnfoundResourceProps) {
  const returnText = "Hmm... Seems like this path leads to nowhere....";
  let body;
  if (resourceName) {
    body = `Could not find the ${resourceName} you were looking for..`;
  }
  return <Empty heading={returnText} body={body} image={{ src: StarryNightForest, alt: "Path to nowhere..." }} />;
}
