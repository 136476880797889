import {
  Card,
  CardBody,
  CardHeader,
  Icon,
  HStack,
  Heading,
  Text,
  Skeleton,
  Button,
  StackProps,
  Flex,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import ButtonWithAlert from "components/Button/ButtonWithConfirm";
import { Action, EmailRecipient, PathActionable } from "models/automation";
import { IconType } from "react-icons";
import { PropsWithChildren } from "react";
import { ActionTargetText, ActionTriggerAndTimingText } from "features/Action/DisplayInfo";
import UpdateActionButtonWithModal from "components/ModalForm/PathActionForm/UpdateActionButtonWithModal";
import { automationTriggerText } from "helpers/workflowActionableConverters";
import { isAssignedAction } from "helpers/action";
import { DynamicActionType } from "components/ActionType";
import RecipientTag from "components/Forms/PathActions/Email/RecipientTag";
import ActionableContent from "./ActionableContent";

interface ActionCardProps {
  action: Action
  isLoading: boolean;
  onTestSend: () => void;
  onDelete: () => void;
}

interface ActionAttributeCardProps extends PropsWithChildren, StackProps {
  icon?: IconType;
  text?: string | JSX.Element;
}

export function ActionAttributeCard({ icon, text, children, ...rest }: ActionAttributeCardProps) {
  return (
    <HStack
      fontSize="lg"
      border="1px"
      borderColor="gray.500"
      paddingX="15px"
      paddingY="5px"
      borderRadius="5px"
      {...rest}
    >
      {icon && <Icon as={icon} color="gray.500" />}
      {text && <Text>{text}</Text>}
      {children}
    </HStack>
  );
}

ActionAttributeCard.defaultProps = {
  icon: null,
  text: null,
};

function RecipientsContainer({ label, recipients }: { label: string; recipients: EmailRecipient[] }) {
  return (
    <>
      <Heading as="h3" size="xxs" fontWeight="bold">
        {label}
      </Heading>
      <Flex
        border="1px solid"
        borderColor="fg.subtle"
        gap={2}
        borderRadius="lg"
        p={2}
        minH="40px"
        minWidth="60%"
        maxWidth="100%"
        flexWrap="wrap"
        alignItems="center"
      >
        {recipients.map((recipient, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <RecipientTag key={index} recipient={recipient} />
        ))}
        <Spacer />
      </Flex>
    </>
  );
}

function AdditionalRecipients({ actionable }: { actionable: PathActionable }) {
  if (actionable.actionType !== "email") return null;
  return (
    <>
      {actionable.additionalRecipients && actionable.additionalRecipients.length > 0 && (
        <RecipientsContainer label="Additional Recipients" recipients={actionable.additionalRecipients} />
      )}
      {actionable.ccRecipients && actionable.ccRecipients.length > 0 && (
        <RecipientsContainer label="Cc" recipients={actionable.ccRecipients} />
      )}
      {actionable.bccRecipients && actionable.bccRecipients.length > 0 && (
        <RecipientsContainer label="Bcc" recipients={actionable.bccRecipients} />
      )}
    </>
  );
}

function ActionInformation({ action }: { action: Action }) {
  return (
    <VStack mb={4} spacing="4" align="start">
      <HStack spacing="4" ml="4">
        <DynamicActionType actionType={action.actionType} size="sm" textProps={{ fontWeight: "normal", color: "var(--chakra-colors-body-text)" }} />
        <ActionTargetText fontSize="lg" action={action} />
        <ActionTriggerAndTimingText fontSize="lg" action={action} />
        {action?.actionable.actionType === "task" && action.actionable.dueDateTrigger && (
          <Text fontSize="lg">
            {"📅 Due "}
            {automationTriggerText(action.actionable.dueDateTrigger)}
          </Text>
        )}
      </HStack>
      {action?.actionable && <AdditionalRecipients actionable={action?.actionable} />}
    </VStack>
  );
}

interface WorkflowActionButtonsProps {
  onDelete: () => void;
  action: Action;
}

function WorkflowActionButtons({ action, onDelete }: WorkflowActionButtonsProps) {
  return (
    <HStack my={8} justifyContent="center">
      <UpdateActionButtonWithModal action={action} />
      <ButtonWithAlert
        buttonText="Delete Action"
        header="Delete Action"
        body="Are you sure you want to delete this Action? It can't be undone."
        handleConfirm={onDelete}
        confirmButtonText="Delete"
      />
    </HStack>
  );
}

export function ActionCard({ action, onDelete, onTestSend, isLoading }: ActionCardProps) {
  const isScheduledAction = isAssignedAction(action);
  const actionable = isScheduledAction && action ? action.workflowAction.actionable : (action as Action)?.actionable;

  return (
    <Card>
      <Skeleton isLoaded={!isLoading}>
        <CardHeader mt="4" pb={0} display="flex" justifyContent="space-between" flexDirection="row">
          <Flex w="80%" gap="4">
            <Heading size="xs">{actionable?.name}</Heading>
          </Flex>
          <Button
            mr="12"
            isDisabled={!["email", "chat", "task"].includes((action as Action)?.actionable?.actionType)}
            onClick={onTestSend}
            colorScheme="gray"
            variant="outline"
          >
            Test Send
          </Button>
        </CardHeader>
        <CardBody>
          <ActionInformation action={action} />
          <ActionableContent content={actionable} />
        </CardBody>
        <WorkflowActionButtons action={action as Action} onDelete={onDelete} />
      </Skeleton>
    </Card>
  );
}
