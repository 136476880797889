import { isInPast } from "helpers/time";
import { AssignedAction } from "models/automation/scheduledWorkflow";

export default function isActionTimingValid(action: AssignedAction) {
  if (!action) return false;

  const { status, trigger } = action;
  if (["processed", "skipped", "removed"].includes(status)) {
    return true;
  }

  const datetime = trigger?.data?.datetime;
  return datetime && !isInPast(datetime);
}
