import { Text, TextProps } from "@chakra-ui/react";

export function Text12({ children, ...rest }: TextProps) {
  return (
    <Text
      fontSize="12px"
      fontWeight="400"
      lineHeight="14.4px"
      {...rest}
    >
      {children}
    </Text>
  );
}

export function Text14({ children, ...rest }: TextProps) {
  return (
    <Text
      fontSize="14px"
      fontWeight="400"
      lineHeight="16.8px"
      {...rest}
    >
      {children}
    </Text>
  );
}
