import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Button, Card, CardBody, CardProps, Flex, Heading, Link, Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { CopyableField } from "components/DataDisplay/Copyable";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import { PARTNER_INSTRUCTION_URLS } from "definitions/constants";
import { useCreateIntegrationWithSecret } from "features/Integrations/hooks";
import { webhookUrl } from "helpers/integrations";
import { titleize } from "helpers/string";
import { NewIncomingWebhook } from "models/integration/incomingWebhook";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

type NewCredentialsProps = {
  webhookCredentials: NewIncomingWebhook;
};

function Instructions({ webhookCredentials }: NewCredentialsProps) {
  return (
    <Text color="fg.muted">
      {`For instructions on setting up the integration,
      please visit `}
      <Link href={PARTNER_INSTRUCTION_URLS[webhookCredentials.partner]} isExternal>
        {"the setup instructions. "}
        <ExternalLinkIcon mb="4px" mx="2px" />
      </Link>
    </Text>
  );
}

function SecretGivenDisplay({ webhookCredentials }: NewCredentialsProps) {
  const { partner, secret } = webhookCredentials;

  if (!PARTNER_INSTRUCTION_URLS[partner]) {
    console.error(`Instruction URLs constant missing a URL for partner ${partner}`);
  }

  const partnerName = titleize(partner);

  return (
    <>
      <Heading size="xs" color="fg.muted">{`${partnerName} Key`}</Heading>
      <Text color="fg.muted" fontWeight="700">
        This secret is only available upon creation.
      </Text>
      <Text color="fg.muted" fontWeight="700">
        After you close this webpage, you will not be able to access it!
      </Text>
      <Instructions webhookCredentials={webhookCredentials} />
      <CopyableField includeFieldName fieldName="Webhook Url" fieldValue={webhookUrl(webhookCredentials)} />
      <CopyableField includeFieldName fieldName="Secret" fieldValue={secret} />
    </>
  );
}

const schema = yup.object({
  secret: yup.string().trim().label("Secret").required(),
});

export type Schema = yup.InferType<typeof schema>;

function CredentialsInputDisplay({ webhookCredentials }: NewCredentialsProps) {
  const { id, partner, event } = webhookCredentials;

  if (!PARTNER_INSTRUCTION_URLS[partner]) {
    console.error(`Instruction URLs constant missing a URL for partner ${partner}`);
  }

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setFocus,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: { id, partner, event },
  });

  const { mutate, isSuccess } = useCreateIntegrationWithSecret();

  useEffect(() => {
    setFocus("secret");
  }, [reset, setFocus]);

  const partnerName = titleize(partner);

  return (
    <>
      <Heading size="xs" color="fg.muted">{`${partnerName} Credentials`}</Heading>
      <Text color="fg.muted" fontWeight="700">
        Please input the secret before you close this webpage - Otherwise, you will need to recreate the credentials!
      </Text>
      <Instructions webhookCredentials={webhookCredentials} />
      <CopyableField includeFieldName fieldName="Webhook Url" fieldValue={webhookUrl(webhookCredentials)} />
      {isSuccess ? (
        <Text color="fg.muted" fontWeight="700">
          Secret successfully added!
        </Text>
      ) : (
        <Flex direction="row" gap="4" align="center" justify="center">
          <TextInputField
            required
            label="Secret"
            placeholder={`Input ${partnerName} secret...`}
            name="secret"
            errors={errors}
            register={register}
          />
          <Button onClick={handleSubmit(mutate)}>Add Secret</Button>
        </Flex>
      )}
    </>
  );
}

export default function NewCredentialsCard({ webhookCredentials, ...rest }: NewCredentialsProps & CardProps) {
  const { partner } = webhookCredentials;

  if (!PARTNER_INSTRUCTION_URLS[partner]) {
    console.error(`Instruction URLs constant missing a URL for partner ${partner}`);
  }

  return (
    <Card maxWidth="800px" mb="4" {...rest}>
      <CardBody>
        <Flex direction="column" gap={4}>
          {partner === "greenhouse" ? (
            <SecretGivenDisplay webhookCredentials={webhookCredentials} />
          ) : (
            <CredentialsInputDisplay webhookCredentials={webhookCredentials} />
          )}
        </Flex>
      </CardBody>
    </Card>
  );
}
