import {
  API_ROUTES,
  PEOPLE_TEAM_ROUTES,
  SHARED_COMPANY_ROUTES,
  SHARED_ROUTES,
} from "definitions/constants/routeConstants";
import { Action } from "models/automation";
import { AssignedAction, AssignedPath } from "models/automation/scheduledWorkflow";
import { Task } from "models";
import { NewHireJourney } from "models/newHire";
import { User } from "models/user";
import { TaskId } from "models/task";
import { generatePath as routerGeneratePath } from "react-router-dom";
import { truncateEnd } from "./string";
import { createQueryString } from "./queryString";

export function generatePath({
  path,
  params = undefined,
  query = undefined,
}: { path: string, params?: {}, query?: string }) {
  return routerGeneratePath(
    query?.length ? `${path}?${query.replace(/^[\?]./, "")}` : path,
    params
  )
}

export function removeLastPath(pathname: string) {
  return pathname.split("/").slice(0, -1).join("/");
}

export function removeUrlScheme(url: string) {
  return url.replace(/(^\w+:|^)\/\//, "").replace(/^www\./, "");
}

export function truncateUrl(url: string, maxLength: number = 32, ending = "...") {
  return truncateEnd(removeUrlScheme(url), maxLength, ending);
}

export function userPathById(userId: string | undefined) {
  return generatePath({ path: SHARED_ROUTES.showUser, params: { id: userId || "" } });
}

export function userPath(user: User) {
  return userPathById(user?.id);
}

export function newHireJourneysPath() {
  return generatePath({ path: SHARED_COMPANY_ROUTES.newHireJourneys.index });
}

export function newHireJourneyPathById(newHireJourneyId: string | undefined, tabName?: string) {
  let path = SHARED_COMPANY_ROUTES.newHireJourneys.show;
  switch (tabName) {
    case "tasks":
      path = SHARED_COMPANY_ROUTES.newHireJourneys.tabs.tasks.base;
      break;
    case "schedule":
      path = SHARED_COMPANY_ROUTES.newHireJourneys.tabs.schedule.base;
      break;
    case "fields":
      path = SHARED_COMPANY_ROUTES.newHireJourneys.tabs.fields.base;
      break;
    case "paths":
      path = SHARED_COMPANY_ROUTES.newHireJourneys.tabs.paths.base;
      break;
    case "activityFeed":
      path = SHARED_COMPANY_ROUTES.newHireJourneys.tabs.activityFeed.base;
      break;
    default:
      break;
  }
  return generatePath({ path, params: { id: newHireJourneyId || "" } });
}

export function newHireJourneyPath(newHireJourney: NewHireJourney) {
  return newHireJourneyPathById(newHireJourney?.id);
}

export function pendingNewHirePathById(newHireId: string) {
  return generatePath({ path: SHARED_COMPANY_ROUTES.newHireJourneys.pending.show, params: { id: newHireId || "" } });
}

export function pendingNewHirePath(newHire: NewHireJourney) {
  return pendingNewHirePathById(newHire?.id);
}

export function pendingNewHireJourneysPath() {
  return generatePath({ path: SHARED_COMPANY_ROUTES.newHireJourneys.pending.index });
}

export function assignedPathPathById(assignedPathId: string) {
  return generatePath({ path: PEOPLE_TEAM_ROUTES.assignedPaths.show, params: { id: assignedPathId || "" } });
}

export function assignedPathPath(assignedPath: AssignedPath) {
  return assignedPathPathById(assignedPath?.id);
}
export function assignedActionPathById(assignedActionId: string) {
  if (!assignedActionId) return "";
  return generatePath({ path: PEOPLE_TEAM_ROUTES.assignedActions.show, params: { id: assignedActionId } })
}

export function assignedActionPath(assignedAction: AssignedAction) {
  return assignedActionPathById(assignedAction?.id);
}

export function actionPathById(actionId: string) {
  return generatePath({ path: PEOPLE_TEAM_ROUTES.actions.show, params: { id: actionId || "" } });
}

export function actionPath(action: Action) {
  return actionPathById(action?.id);
}

export function taskPathById(taskId: TaskId) {
  return generatePath({ path: SHARED_ROUTES.tasks.show, params: { id: taskId || "" } });
}

export function taskPath(task: Task) {
  return taskPathById(task?.id);
}

export function issuesPath(newHireId?: string) {
  const query = createQueryString({ additionalQueries: { newHireId } });
  return generatePath({ path: PEOPLE_TEAM_ROUTES.issues.base, query });
}

export function issuePathById(issueId: string) {
  return generatePath({ path: PEOPLE_TEAM_ROUTES.issues.show, params: { id: issueId || "" } });
}

export function apiIssuePathById(issueId: string) {
  return generatePath({ path: API_ROUTES.issues.show, params: { id: issueId || "" } });
}

export function apiUserPathById(userId: string) {
  return generatePath({ path: API_ROUTES.users.show, params: { id: userId || "" } });
}

export function apiNewHireJourneyPathById(newHireJourneyId: string) {
  return generatePath({ path: API_ROUTES.newHireJourneys.show, params: { id: newHireJourneyId || "" } });
}

export function apiPendingNewHireJourneysPath() {
  return API_ROUTES.newHireJourneys.pending.base;
}

export function apiNewHireJourneyPath(newHireJourney: NewHireJourney) {
  return apiNewHireJourneyPathById(newHireJourney?.id);
}

export function apiActionPathById(actionId: string) {
  if (!actionId) return "";
  return generatePath({ path: API_ROUTES.actions.show, params: { id: actionId } });
}

export function apiAssignedActionPathById(assignedActionId: string) {
  if (!assignedActionId) return "";
  return generatePath({ path: API_ROUTES.assignedActions.show, params: { id: assignedActionId } });
}

export function apiTaskPathById(taskId: string) {
  return generatePath({ path: API_ROUTES.tasks.show, params: { id: taskId || "" } });
}

export function apiStagePathById(stageId: string) {
  return generatePath({ path: API_ROUTES.stages.show, params: { id: stageId || "" } });
}

export function apiTimezoneByPlaceId(googlePlaceId: string) {
  return generatePath({ path: API_ROUTES.autocomplete.timezones.show, params: { id: googlePlaceId || "" } });
}

export function apiAssignedActionBatchPathByIds(ids: string[]) {
  const query = createQueryString({ additionalQueries: { ids } });
  return generatePath({ path: API_ROUTES.assignedActions.batch.base, query });
}

export function apiTaskBatchPathByIds(ids: string[]) {
  const query = createQueryString({ additionalQueries: { ids } });
  return generatePath({ path: API_ROUTES.tasks.batch.base, query });
}

export function apiTaskListPath(queryString?: string) {
  return generatePath({ path: API_ROUTES.tasks.base, query: queryString })
}

export function apiNewHireListPath(queryString?: string) {
  return generatePath({ path: API_ROUTES.newHireJourneys.base, query: queryString });
}

export function apiNewHireWithActiveIssuesListPath(queryString?: string) {
  return generatePath({ path: API_ROUTES.newHireJourneys.withActiveIssues.base, query: queryString });
}

export function apiCurrentCompanyPath() {
  return generatePath({ path: API_ROUTES.companies.show, params: { id: "current" } });
}

export function apiCombinedActionPathByNewHireId(id: string) {
  return generatePath({ path: API_ROUTES.tasks.batch.base, params: { id: id || "" } });
}

export function apiIssuesPathByActionId(actionId: string) {
  return generatePath({ path: API_ROUTES.assignedActions.newIssues.base, params: { id: actionId || "" } });
}

export function apiAssignedPathsPathByNewHireId(newHireId: string) {
  return generatePath({ path: API_ROUTES.newHireJourneys.paths, params: { id: newHireId || "" } });
}

export function apiAssignedPathPathById(assignedPathId: string) {
  return generatePath({ path: API_ROUTES.assignedPaths.show, params: { id: assignedPathId || "" } });
}

export function apiPathPathById(pathId: string) {
  return generatePath({ path: API_ROUTES.paths.show, params: { id: pathId || "" } });
}
