import { Button, ButtonGroup, Flex } from "@chakra-ui/react";
import { PrimaryButton, SecondaryButton } from "components/Button";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import CancelButton from "components/Button/CancelButton";
import ProcessedTask from "components/Forms/AssignedActions/Task/ProcessedTask";
import UpdateTaskForm from "components/Forms/AssignedActions/Task/UpdateTaskForm";
import { useForceSendAssignedAction } from "features/AssignedAction/hooks";
import { findTaskNotifier } from "features/Task/helpers";
import { Task } from "models";
import { CombinedAction } from "models/automation/combinedAction";
import { useMemo, useRef, useState } from "react";

const CONFIRM_SEND_NOW = {
  header: "Confirm you want to send this task now!",
  body: "This cannot be undone! Be sure you want to send this task now.",
  confirmButtonText: "Send Now",
  buttonText: "Send Now",
};

interface TaskDrawerFormProps {
  onClose: () => void;
  combinedAction: CombinedAction;
}

export default function TaskDrawerForm({ onClose, combinedAction }: TaskDrawerFormProps) {
  const formRef = useRef<any>(null); // Create a ref to access form methods
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);

  const initialNotifier = useMemo(() => findTaskNotifier(combinedAction as Task), [combinedAction]);

  const handleSubmit = (onSuccessCallback: () => void) => {
    formRef.current?.handleSubmit(onSuccessCallback); // Call form submit from the ref
  };

  const { mutate: sendNow, isReady } = useForceSendAssignedAction({
    action: initialNotifier,
  });

  if (!combinedAction) {
    return null;
  }

  return (
    <Flex direction="column">
      {combinedAction && (
        ["skipped", "processed"].includes(findTaskNotifier(combinedAction)?.status) ? (
          <ProcessedTask task={combinedAction} />
        ) : (
          <>
            <UpdateTaskForm
              ref={formRef}
              task={combinedAction}
              onSuccess={onClose}
              setIsSubmitLoading={setIsSubmitLoading}
              setIsFormDirty={setIsFormDirty}
            />
            <ButtonGroup display="flex" justifyContent="flex-end">
              <CancelButton onClick={() => onClose()}>
                Cancel
              </CancelButton>
              <ButtonWithConfirm
                intent="warning"
                isDisabled={!isReady}
                {...CONFIRM_SEND_NOW}
                handleConfirm={() => handleSubmit(() => sendNow())}
              >
                <SecondaryButton>
                  Send Now
                </SecondaryButton>
              </ButtonWithConfirm>
              <PrimaryButton
                onClick={() => handleSubmit(() => {})}
                isLoading={isSubmitLoading}
              >
                Update Task
              </PrimaryButton>
            </ButtonGroup>
          </>
        )
      )}
    </Flex>
  );
}
