/* eslint-disable import/prefer-default-export */
import { isTask } from "features/Task/helpers";
import { assignedActionPathById, taskPathById } from "helpers/url";
import { CombinedAction, CombinedActionId } from "models/automation/combinedAction";
import { AssignedAction } from "models/automation/scheduledWorkflow";

const COMBINED_ID_PREFIXES = ["task", "email", "chat"] as const;

type ActionType = "task" | "task_reminder" | "chat" | "email";

export function isCombinedActionId(combinedActionId: CombinedActionId) {
  if (!combinedActionId) return false;
  const [prefix] = combinedActionId.split("_");
  return COMBINED_ID_PREFIXES.includes(prefix as any);
}

export function toCombinedActionId(action: CombinedAction): CombinedActionId {
  if (!action) {
    return "" as CombinedActionId;
  }
  if (isTask(action)) {
    return `task_${action.id}`;
  } else if (action.actionType === "task_reminder") {
    return `task_reminder_${action.id}`;
  } else if (action.actionType === "chat") {
    return `chat_${action.id}`;
  } else if (action.actionType === "email") {
    return `email_${action.id}`;
  }
  throw new Error(`Unknown action type ${action.actionType}`);
}

export function fromCombinedActionId(actionId: CombinedActionId): { actionType: ActionType; id: string } | null {
  if (!actionId) {
    return null;
  }
  const parts = actionId.split("_");
  const id = parts.pop() as string;
  const actionType = parts.join("_") as ActionType;
  return { actionType, id };
}

export function combinedActionPathById(combinedActionId: CombinedActionId) {
  if (!combinedActionId) return "";
  const { actionType = undefined, id: resourceId = "" } = fromCombinedActionId(combinedActionId) || {};
  if (actionType === "task") {
    return taskPathById(resourceId);
  }
  return assignedActionPathById(resourceId);
}

export function toCombinedActionIdFromAssignedAction(action: AssignedAction): CombinedActionId | "" {
  if (!action) {
    return "";
  }
  if (action.actionType == "task_notifier") {
    return `task_${action.taskId}`;
  } else if (["task_reminder", "chat", "email"].includes(action.actionType)) {
    return `${action.actionType}_${action.id}`;
  }
  throw new Error(`Unknown action type ${action.actionType}`);
}
