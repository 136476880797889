import { create } from "zustand";
import { BreadcrumbInfo, BreadCrumbNotCurrentPage } from "components/Breadcrumbs";
import { useEffect } from "react";

type NavbarStore = {
  breadcrumbs: Array<BreadcrumbInfo | BreadCrumbNotCurrentPage>;
  setBreadcrumbs: (breadcrumbs: Array<BreadcrumbInfo | BreadCrumbNotCurrentPage>) => void;
  clearBreadcrumbs: () => void;
  backLocationPath: string | null;
  setBackLocationPath: (backLocation: string | null) => void;
  clearBackLocationPath: () => void;
  backLocationName: string | null;
  setBackLocationName: (pageTitle: string | null) => void;
  clearBackLocationName: () => void;
  pageTitle: string | null;
  setPageTitle: (pageTitle: string | null) => void;
  clearPageTitle: () => void;
  clearNavbar: () => void;
};

const useNavbarStore = create<NavbarStore>((set) => ({
  breadcrumbs: [],
  setBreadcrumbs: (breadcrumbs: Array<BreadcrumbInfo | BreadCrumbNotCurrentPage>) => set({ breadcrumbs }),
  clearBreadcrumbs: () => set({ breadcrumbs: [] }),
  backLocationPath: null,
  setBackLocationPath: (backLocationPath: string | null) => set({ backLocationPath }),
  clearBackLocationPath: () => set({ backLocationPath: null }),
  backLocationName: null,
  setBackLocationName: (backLocationName: string | null) => set({ backLocationName }),
  clearBackLocationName: () => set({ backLocationName: null }),
  pageTitle: null,
  setPageTitle: (pageTitle: string | null) => {
    document.title = pageTitle || "";
    set({ pageTitle });
  },
  clearPageTitle: () => set({ pageTitle: null }),
  clearNavbar: () => set({ breadcrumbs: [], pageTitle: null, backLocationPath: null, backLocationName: null }),
}));

export default useNavbarStore;

function useNavbar() {
  const [
    setBreadcrumbs,
    setPageTitle,
    setBackLocationPath,
    setBackLocationName,
    clearNavbar,
  ] = useNavbarStore((state) => [
    state.setBreadcrumbs,
    state.setPageTitle,
    state.setBackLocationPath,
    state.setBackLocationName,
    state.clearNavbar,
  ]);

  useEffect(
    () => () => {
      clearNavbar();
    },
    [clearNavbar],
  );

  return { setBreadcrumbs, setPageTitle, setBackLocationPath, setBackLocationName };
}

export { useNavbar };
