/* eslint-disable max-len */
import { Icon, IconProps, useColorModeValue } from "@chakra-ui/react";

function OutlinePersonPin({ ...props }: IconProps) {
  const color = useColorModeValue("notBlack.50", "white");
  return (
    <Icon
      viewBox="0 0 12 12"
      fill="none"
      color={color}
      {...props}
    >
      <path
        d="M9.5 1H2.5C2.23478 1 1.98043 1.10536 1.79289 1.29289C1.60536 1.48043 1.5 1.73478 1.5 2V9C1.5 9.26522 1.60536 9.51957 1.79289 9.70711C1.98043 9.89464 2.23478 10 2.5 10H4.5L6 11.5L7.5 10H9.5C10.05 10 10.5 9.55 10.5 9V2C10.5 1.45 10.05 1 9.5 1ZM9.5 9H7.085L6.79 9.295L6 10.085L5.205 9.29L4.915 9H2.5V2H9.5V9ZM6 5.5C6.825 5.5 7.5 4.825 7.5 4C7.5 3.175 6.825 2.5 6 2.5C5.175 2.5 4.5 3.175 4.5 4C4.5 4.825 5.175 5.5 6 5.5ZM6 3.5C6.275 3.5 6.5 3.725 6.5 4C6.5 4.275 6.275 4.5 6 4.5C5.725 4.5 5.5 4.275 5.5 4C5.5 3.725 5.725 3.5 6 3.5ZM9 7.79C9 6.54 7.015 6 6 6C4.985 6 3 6.54 3 7.79V8.5H9V7.79ZM4.24 7.5C4.61 7.245 5.355 7 6 7C6.645 7 7.39 7.245 7.76 7.5H4.24Z"
        fill="currentColor"
      />
    </Icon>
  );
}
export default OutlinePersonPin;
