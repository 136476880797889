import { Issue, IssueContextQueryResult } from "models/issue";
import { ResolutionInfo } from "models/resolutions";
import { TimingIssueModalInputFields } from "./TimingResolution";

export function referenceEventOverlapIssueType(issue: Issue) {
  return "Scheduling Timing Issue";
}

export function referenceEventOverlapResolution(_1: Issue, _2: IssueContextQueryResult): ResolutionInfo {
  return ({
    issueType: "reference_event_overlap",
    tagText: "Scheduling Timing Issue",
    title: "Scheduling Timing Issue (Reference Event Overlap)",
    // eslint-disable-next-line max-len
    additionalContext: "This scheduling timing issue is a 'Reference Event Overlap'. This happens when an action like 'immediate + 2 weeks' is scheduled, but the start date in 1 week, causing a potential issue. Please review the acitons and reschedule, skip or send them.",
    inputComponent: TimingIssueModalInputFields,
  });
}
