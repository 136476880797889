import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Menu, MenuButton, MenuList, MenuItem, Button, Portal,
} from "@chakra-ui/react";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { useForceSendAssignedAction, useDeleteAssignedAction, useSkipAssignedAction, useTestSendAssignedAction } from "features/AssignedAction/hooks";
import AssignedActionButtonWithModal from "./ModalForm/AssignedActionForm/AssignedActionButtonWithModal";
import ButtonWithConfirm from "./Button/ButtonWithConfirm";

interface ScheduledActionRowButtonProps {
  action: AssignedAction;
}

const CONFIRM_REMOVE = {
  header: "CONFIRM you want to remove this action",
  body: "This cannot be undone! Be sure you want to remove this action now.",
  confirmButtonText: "Confirm Remove",
};

const CONFIRM_SEND_NOW = {
  header: "CONFIRM sending the action now!",
  body: "This cannot be undone! Be sure you want to send this action now.",
  confirmButtonText: "Confirm Send Now",
};

export default function ScheduledActionRowButton({
  action,
}: ScheduledActionRowButtonProps) {
  const testSendIsDisabled = !["email", "chat", "task"].includes(action?.workflowAction?.actionable?.actionType);
  const { mutate: removeAssignedAction } = useDeleteAssignedAction({ action });
  const { mutate: forceSendAssignedAction } = useForceSendAssignedAction({ action });
  const { mutate: skipAssignedAction } = useSkipAssignedAction({ action });
  const { mutate: testSend } = useTestSendAssignedAction({ action });

  return (
    <Menu>
      <MenuButton
        size="xs"
        isDisabled={action?.status === "processed"}
        as={Button}
        colorScheme="gray"
        rightIcon={<ChevronDownIcon />}
      >
        More
      </MenuButton>
      <Portal>
        <MenuList>
          {/* MenuItems are not rendered unless Menu is open */}
          <AssignedActionButtonWithModal assignedAction={action}>
            <MenuItem>Edit</MenuItem>
          </AssignedActionButtonWithModal>
          <MenuItem onClick={() => skipAssignedAction()}>{action?.status === "skipped" ? "Unskip" : "Skip"}</MenuItem>
          <ButtonWithConfirm {...CONFIRM_REMOVE} handleConfirm={() => removeAssignedAction()}>
            <MenuItem>Remove</MenuItem>
          </ButtonWithConfirm>
          <MenuItem isDisabled={testSendIsDisabled} onClick={() => testSend()}>
            Test Send
          </MenuItem>
          <ButtonWithConfirm {...CONFIRM_SEND_NOW} intent="confirmation" handleConfirm={forceSendAssignedAction}>
            <MenuItem>Send Now</MenuItem>
          </ButtonWithConfirm>
        </MenuList>
      </Portal>
    </Menu>
  );
}
