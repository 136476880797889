import { Box, CardProps, Flex, Text } from "@chakra-ui/react";
import { SHARED_COMPANY_ROUTES } from "definitions/constants/routeConstants";
import DashboardCard from "./DashboardCard";

interface PendingNewHiresCardProps extends CardProps {
  pendingNewHiresCount: number;
}

export default function PendingNewHiresCard({ pendingNewHiresCount, ...rest }: PendingNewHiresCardProps) {
  const needsModifier = pendingNewHiresCount > 1 ? "" : "s";
  return (
    <DashboardCard
      title="Pending New Hires"
      emoji="🚀"
      buttonText="Review"
      destination={SHARED_COMPANY_ROUTES.newHireJourneys.pending.index}
      {...rest}
    >
      <Flex direction="column" justifyContent="center" alignItems="start" h="100%">
        <Box as="p">
          <Text as="span" fontSize="lg" fontWeight="bold">
            {pendingNewHiresCount}
          </Text>
          <Text as="span" fontSize="lg">
            {` need${needsModifier} onboarding to start!`}
          </Text>
        </Box>
      </Flex>
    </DashboardCard>
  );
}
