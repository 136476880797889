import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  Flex,
  Skeleton,
  Stack,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import NewHireJourneyEditButtonWithModal from "components/ModalForm/NewHireJourneyEditForm/NewHireJourneyEditButtonWithModal";
import { SHARED_COMPANY_ROUTES, SHARED_ROUTES } from "definitions/constants/routeConstants";
import { generatePath, useLocation, Link as ReactRouterLink } from "react-router-dom";
import { NewHireJourney } from "models/newHire";
import { ExtendedNewHireJourney } from "models";
import LinkButton from "components/Button/LinkButton";
import { pendingNewHirePath } from "helpers/url";
import { titleize } from "helpers/string";
import { useCurrentCompanyQuery } from "features/Company/hooks";
import { PropsWithChildren } from "react";
import { TiDocumentAdd } from "react-icons/ti";
import { ListIssuesByNewHire } from "features/Issues/Components/ListIssuesInfo";
import { GrayTableButton } from "components/Button/TableButton";
import { PersonalEmailAttribute, WorkEmailAttribute, LocationAttribute, LinkedInAttribute, TimezoneAttribute } from "./NewHireAttribute";
import NewHireSupportTeam from "./NewHireSupportTeam";
import NewHireNameAndTitle from "./NewHireNameAndTitle";

interface NewHireTitleBarProps extends PropsWithChildren {
  newHireJourney: ExtendedNewHireJourney | NewHireJourney | undefined;
}

export default function NewHireTitleBar({ newHireJourney, children, ...restProps }: NewHireTitleBarProps) {
  const { pathname } = useLocation();
  const { data: company } = useCurrentCompanyQuery();
  const isNewHirePage = pathname.includes(generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, { id: newHireJourney?.id || "" }));
  return (
    <Skeleton isLoaded={!!newHireJourney}>
      <Card width="100%" {...restProps}>
        <CardBody mx="4">
          <Stack direction="row" justify="space-between" align="flex-start">
            <Stack gap={2}>
              <NewHireNameAndTitle newHire={newHireJourney} isNewHirePage />
              <Stack gap="1" ml="16">
                <Flex align="center" gap="4">
                  <WorkEmailAttribute newHire={newHireJourney} />
                  <PersonalEmailAttribute newHire={newHireJourney} />
                  {company?.messageService && (
                    <Tag size="sm" variant="outline" colorScheme="gray" boxShadow="inset 0 0 0px .5px" color="helperText.DEFAULT">
                      <TagLabel>
                        <Flex align="center" gap="1">
                          <Badge
                            boxShadow="none"
                            variant="solid"
                            colorScheme={newHireJourney?.user?.messageServiceId ? "green" : "red"}
                            borderRadius="8px"
                            size="xs"
                            minWidth="8px"
                            minHeight="8px"
                          />
                          {newHireJourney?.user?.messageServiceId ? `${titleize(company?.messageService?.name)} Account Synced` : `No ${titleize(company?.messageService?.name)} Account Found`}
                        </Flex>
                      </TagLabel>
                    </Tag>
                  )}
                </Flex>
                <Flex align="center" gap="4">
                  <LocationAttribute newHire={newHireJourney} />
                  <TimezoneAttribute newHire={newHireJourney} />
                  <LinkedInAttribute newHire={newHireJourney} />
                </Flex>
              </Stack>
            </Stack>
            <Box>
              {newHireJourney && isNewHirePage && (
                <Flex gap="3" align="baseline">
                  {company?.hasOnboardingPlans && (
                    newHireJourney?.onboardingPlanUrl ? (
                      <LinkButton
                        size="sm"
                        variant="link"
                        to={newHireJourney.onboardingPlanUrl}
                        linkProps={{ target: "_blank" }}
                      >
                        Onboarding Plan
                      </LinkButton>
                    ) : (
                      <NewHireJourneyEditButtonWithModal alignSelf="center" newHireJourney={newHireJourney}>
                        <Button
                          size="sm"
                          variant="link"
                          colorScheme="gray"
                          color="notBlack.50"
                          leftIcon={<TiDocumentAdd color="notBlack.50" />}
                        >
                          Add Onboarding Plan
                        </Button>
                      </NewHireJourneyEditButtonWithModal>
                    )
                  )}
                  <ReactRouterLink to={generatePath(SHARED_ROUTES.showUser, { id: newHireJourney?.user?.id || "" })}>
                    <GrayTableButton cursor="pointer">
                      Employee Info
                    </GrayTableButton>
                  </ReactRouterLink>
                  <NewHireJourneyEditButtonWithModal alignSelf="center" newHireJourney={newHireJourney}>
                    <GrayTableButton cursor="pointer" aria-label="edit new hire's information">
                      Edit
                    </GrayTableButton>
                  </NewHireJourneyEditButtonWithModal>
                </Flex>
              )}
            </Box>
          </Stack>
          <ListIssuesByNewHire mt="4" newHireId={newHireJourney?.id} />
          <Divider mt="4" />
          <NewHireSupportTeam my="4" newHire={newHireJourney} />
          {newHireJourney?.isPending && (
            <LinkButton to={pendingNewHirePath(newHireJourney)} variant="alternative" size="sm">
              Kick Off Onboarding
            </LinkButton>
          )}
          {children}
        </CardBody>
      </Card>
    </Skeleton>
  );
}
