import { TagProps } from "@chakra-ui/react";
import { BasicUser, User } from "models/user";
import NameTag, { SkeletonTag } from "../NameTag";

export interface UserTagProps extends TagProps {
  isLoading?: boolean;
  user: BasicUser | User | undefined;
  imageUrl?: string | undefined;
  role?: string | undefined;
  tagLabelProps?: object;
  size?: "sm" | "md";
}


function getDisplayText({ user, role }: Pick<UserTagProps, 'user' | 'role'>) {
  if (!user) {
    return role ? `Missing ${role}` : "Missing User";
  }
  if (user?.deactivatedAt) {
    return "Deactivated User";
  }

  return role ? `${user.fullName} (${role})` : user.fullName;
}

export default function UserTag({ isLoading, user, role, size, ...props }: UserTagProps) {
  if (!isLoading) {
    return <SkeletonTag size={size} />;
  }
  return (
    <NameTag name={getDisplayText({ user, role })} size={size} {...props} />
  );
}
