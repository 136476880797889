/* eslint-disable no-nested-ternary */
import {
  Box,
} from "@chakra-ui/react";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { useCallback } from "react";
import SkippedStatus from "components/Status/SkippedStatus";
import SentStatus from "components/Status/SentStatus";
import { PrimarySendNowButton } from "features/Issues/Components/Button/SendNowButton";
import { GraySkipButton } from "features/Issues/Components/Button/SkipButton";
import { CombinedActionId } from "models/automation/combinedAction";
import ExpandRowButton from "components/Button/ExpandRowButton";
import { toCombinedActionIdFromAssignedAction } from "features/CombinedAction/helpers";
import { useNonTimingIssueCountByActionId } from "features/Issues/Resolutions/useActionIssueValue";
import { useQueryClient } from "@tanstack/react-query";
import { updateIssueActionList } from "features/Issues/hooks";
import { newHireJourneyKeys } from "features/NewHireJourney/hooks";

export default function ActionRowResolutionButtons({
  action,
  issueId,
  createExpandClickHandlerByCombinedId,
}: {
  action: AssignedAction,
  issueId?: string,
  createExpandClickHandlerByCombinedId: (combinedId: CombinedActionId) => (e: React.MouseEvent<HTMLElement>) => void,
}) {
  const { count: issueCount } = useNonTimingIssueCountByActionId(action?.id);
  const queryClient = useQueryClient();

  const onSuccess = useCallback((resp: any) => {
    if (issueId) {
      updateIssueActionList({ queryClient, issueId, action: resp });
      queryClient.invalidateQueries({ queryKey: newHireJourneyKeys.withActiveIssuesList() });
    }
  }, [issueId, queryClient]);

  if (!action) {
    return null;
  }
  const combinedId = toCombinedActionIdFromAssignedAction(action);
  return action?.status === "skipped" ? (
    <Box display="flex" alignItems="center" gap="2">
      {combinedId && <ExpandRowButton onOpen={createExpandClickHandlerByCombinedId(combinedId)}>Details</ExpandRowButton>}
      <SkippedStatus actionType={action.actionType} />
      <GraySkipButton onSuccess={onSuccess} action={action as AssignedAction} />
    </Box>
  ) : action?.status === "processed" ? (
    <Box display="flex" alignItems="center" gap="2">
      {combinedId && <ExpandRowButton onOpen={createExpandClickHandlerByCombinedId(combinedId)}>Details</ExpandRowButton>}
      <SentStatus actionType={action.actionType} />
    </Box>
  ) : (
    <Box display="flex" alignItems="center" gap="2">
      {combinedId && <ExpandRowButton onOpen={createExpandClickHandlerByCombinedId(combinedId)}>Details</ExpandRowButton>}
      <PrimarySendNowButton isDisabled={issueCount !== undefined && issueCount !== 0} onSuccess={onSuccess} minWidth="96px" action={action} />
      <GraySkipButton onSuccess={onSuccess} action={action} />
    </Box>
  );
}
