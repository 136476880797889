import { FieldDef, Issue, IssueContextQueryResult } from "models/issue";
import { useEffect, useMemo, useState } from "react";
import { OnlyIssue, ResolutionInfo } from "models/resolutions";
import { useIssueContextQuery } from "features/Issues/hooks";
import { titleize } from "helpers/string";
import { attributeValue } from "features/Issues/helpers";
import GreenStatusButton from "components/Status/GreenStatusButton";
import { FiCheckCircle } from "react-icons/fi";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { companyKeys, useCurrentCompanyQuery } from "features/Company/hooks";
import { USER_ROLE_NAMES } from "features/Issues/constants";
import { newHireJourneyQueryArgs, useNewHireJourneyDetailQuery } from "features/NewHireJourney/hooks";
import { userKeys, useUserDetailQuery } from "features/User/hooks";
import { apiUserPathById } from "helpers/url";
import { Company } from "models/company";
import { NewHireJourney } from "models/newHire";
import { User } from "models/user";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";
import { Flex } from "@chakra-ui/react";
import UserFormFields from "features/Issues/Components/UserFormFields";
import { UserUpdateFormValues } from "components/Forms/UserUpdateForm";
import CompanyFormFields from "features/Issues/Components/CompanyFormFields";
import { CompanyUpdateFormValues } from "features/Company/DynamicForm/formDefinition";
import { IssueModalInputFields as MissingSlackUserIssueModalInputFields } from "features/Issues/Resolutions/definitions/MissingMessageServiceUser";

export function missingAttributeIssueType(issue: Issue) {
  if (issue?.context?.attribute === "slack_mention") {
    return `Missing ${titleize(issue.context.roleName)} Slack`;
  }
  return `Missing "${titleize(issue.context.attribute)}"`;
}

export function expandedMissingAttributeIssueType(issue: Issue, issueContext: IssueContextQueryResult) {
  if (issue?.context?.attribute === "slack_mention" && issueContext?.user?.fullName) {
    return `Missing ${issueContext?.user?.fullName}'s Slack`;
  }
  return missingAttributeIssueType(issue);
}

export function missingAttributeValue(issueContext: IssueContextQueryResult) {
  return attributeValue({
    company: issueContext?.company,
    newHire: issueContext?.newHire,
    user: issueContext?.user,
    roleName: issueContext?.issue?.context?.roleName,
    attribute: issueContext?.issue?.context?.attribute === "slack_mention" ? "message_service_id" : issueContext?.issue?.context?.attribute,
  });
}

export function useMissingAttributeValue({ issue }: OnlyIssue) {
  const issueContextQueryResult = useIssueContextQuery(issue);
  return missingAttributeValue(issueContextQueryResult);
}

function UserInputFields({ issue }: { issue: Issue }) {
  const value = useMissingAttributeValue({ issue });
  const { data: newHire } = useNewHireJourneyDetailQuery(issue?.newHireJourneyId, {
    enabled: !!issue?.newHireJourneyId,
    staleTime: 0,
  });
  const userId = issue?.context?.userId ?? newHire?.userId;

  const { data: user } = useUserDetailQuery(userId as string, {
    enabled: !!userId,
    staleTime: 0,
  });
  const fields: FieldDef<UserUpdateFormValues>[] = useMemo(() => (
    issue?.context?.attribute ? [{ name: issue?.context?.attribute as keyof UserUpdateFormValues, label: `${user?.fullName ? `${user?.fullName} ` : ""}${titleize(issue?.context?.attribute)}` }] : []
  ), [issue?.context?.attribute, user?.fullName]);
  return (
    <Flex direction="column" gap="1">
      {user && <UserFormFields user={user} fieldsDefs={fields} issue={issue} />}
      {value && <GreenStatusButton icon={FiCheckCircle}>{`${titleize(issue?.context?.attribute)} Added`}</GreenStatusButton>}
    </Flex>
  );
}

function CompanyInputFields({ issue }: { issue: Issue }) {
  const value = useMissingAttributeValue({ issue });
  const { data: company } = useCurrentCompanyQuery({
    staleTime: 0,
  });
  const fields: FieldDef<CompanyUpdateFormValues>[] = useMemo(() => (
    issue?.context?.attribute ? [{ name: issue?.context?.attribute as keyof CompanyUpdateFormValues, label: `Company ${titleize(issue?.context?.attribute)}` }] : []
  ), [issue?.context?.attribute]);
  return (
    <Flex direction="column" gap="1">
      {company && <CompanyFormFields company={company} fieldsDefs={fields} issue={issue} />}
      {value && <GreenStatusButton icon={FiCheckCircle}>{`${titleize(issue?.context?.attribute)} Added`}</GreenStatusButton>}
    </Flex>
  );
}

export function IssueModalInputFields({ issue }: { issue: Issue }) {
  if (issue?.context?.attribute === "slack_mention") {
    return (
      <MissingSlackUserIssueModalInputFields issue={{ ...issue, issueType: "missing_message_service_user" }} />
    );
  } else if (issue?.context?.roleName === "company") {
    return <CompanyInputFields issue={issue} />;
  } else if (issue?.id) {
    return <UserInputFields issue={issue} />;
  }
  return null;
}

export function missingAttributeResolution(issue: Issue, _: IssueContextQueryResult): ResolutionInfo {
  let title = `No value for ${titleize(issue.context.roleName)} "${titleize(issue.context.attribute)}"`;
  // eslint-disable-next-line max-len
  let additionalContext = `At least 1 action is referencing the ${titleize(issue.context.roleName)} "${titleize(issue.context.attribute)}", and it has no value. Please add this value so the actions can be sent.`;
  if (issue.context.attribute === "slack_mention") {
    title = `Ensure the ${titleize(issue.context.roleName)} has a valid Slack account.`;
    // eslint-disable-next-line max-len
    additionalContext = `At least 1 action is trying to @mentions the ${titleize(issue.context.roleName)} in slack, but Camino cannot find the account. Ensure the Camino Work Email matches the Slack Email.`;
  }

  return {
    issueType: "missing_attribute",
    tagText: missingAttributeIssueType(issue),
    title,
    additionalContext,
    inputComponent: IssueModalInputFields,
  };
}

export function missingAttributeQueryArgs(issue: Issue) {
  const { roleName, attribute, userId } = issue?.context || {};
  const { newHireJourneyId } = issue;
  if (!!userId && USER_ROLE_NAMES.includes(roleName)) {
    return {
      queryKey: userKeys.detail(userId),
      staleTime: 1000 * 60 * 3, // 3 minutes
      queryFn: async () => apiClient.get<User>(apiUserPathById(userId)),
      select: (user: User) => attributeValue({ user, roleName, attribute }),
    };
  }
  if (!!newHireJourneyId && roleName === "new_hire_journey") {
    return newHireJourneyQueryArgs(newHireJourneyId, {
      select: (newHire: NewHireJourney) => attributeValue({ newHire, roleName, attribute }),
    });
  }
  if (roleName === "company") {
    return {
      queryKey: companyKeys.current(),
      queryFn: async () => apiClient.get<Company>(generatePath(API_ROUTES.companies.show, { id: "current" })),
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 3, // 3 minutes
      select: (company: Company) => attributeValue({ company, roleName, attribute }),
    };
  }
  console.error(`Unable to uery issue attribute value for roleName: ${roleName}, issue: ${issue?.id}`);
  return {};
}
