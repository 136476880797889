import { useToast } from "@chakra-ui/react";
import { QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fromCombinedActionId, toCombinedActionId } from "features/CombinedAction/helpers";
import { combinedActionKeys } from "features/CombinedAction/hooks";
import { newHireJourneyKeys } from "features/NewHireJourney/hooks";
import { apiTaskBatchPathByIds, apiTaskListPath, apiTaskPathById } from "helpers/url";
import { ExtendedNewHireJourney, Task } from "models";
import { CombinedAction, CombinedActionId } from "models/automation/combinedAction";
import { BaseTask } from "models/task";
import apiClient from "services/ApiClient";

export function selectCombinedTaskIds(ids: string[]): string[] {
  return ids.reduce((acc, currentId) => {
    const { actionType = undefined, id = "" } = fromCombinedActionId(currentId as CombinedActionId) || {};
    if (actionType === "task" || !actionType) {
      acc.push(id);
    }
    return acc;
  }, [] as string[]);
}

export const taskKeys = {
  all: ["tasks"] as const,
  me: () => [...taskKeys.all, "me"] as const,
  myNewHireTasks: () => [...taskKeys.me(), "newHire"] as const,
  lists: () => [...taskKeys.all, "list"] as const,
  list: (filters: string) => [...taskKeys.lists(), { filters }] as const,
  details: () => [...taskKeys.all, "detail"] as const,
  detail: (id: string) => [...taskKeys.details(), id] as const,
  batch: (ids: string[]) => [...taskKeys.all, "batch", ids.sort().toString] as const,
  count: () => [...taskKeys.all, "count"] as const,
};

export function setTaskDetailQueryCache(queryClient: QueryClient, res: Task) {
  const queryKey = taskKeys.detail(res.id);
  queryClient.setQueryData(queryKey, (oldData: Task[]) => {
    if (oldData) {
      return {
        ...oldData,
        ...res,
      } as Task;
    }
    return res;
  });
}

export const useTaskDetailQuery = (id: string, options = {}) => useQuery<Task>({
  queryKey: taskKeys.detail(id),
  queryFn: async () => apiClient.get(apiTaskPathById(id)),
  staleTime: 1000 * 60 * 3, // 3 minutes
  ...options,
});

export const useTaskListQuery = (queryString: string, options = {}) => useQuery({
  queryKey: queryString ? taskKeys.list(queryString) : taskKeys.lists(),
  queryFn: async () => apiClient.get(apiTaskListPath(queryString)),
  staleTime: 1000 * 60 * 3, // 3 minutes
  ...options,
});

const useTaskBatchArguments = (combinedIds: string[]) => {
  const queryClient = useQueryClient();
  const taskIds = selectCombinedTaskIds(combinedIds);
  return {
    queryKey: taskKeys.batch(taskIds),
    queryFn: async () => {
      const tasks = await apiClient.get(apiTaskBatchPathByIds(taskIds));
      tasks.forEach((task: Task) => {
        queryClient.setQueryData(combinedActionKeys.detail(toCombinedActionId(task)), task);
      });
      return tasks;
    },
    staleTime: 1000 * 60 * 3, // 3 minutes
  };
};

export const useTaskBatchQuery = (combinedIds: CombinedActionId[], options = {}) => useQuery({
  ...useTaskBatchArguments(combinedIds),
  ...options,
});

export const usePreloadTaskBatchQuery = (combinedIds: CombinedActionId[], options = {}) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery({ ...useTaskBatchArguments(combinedIds), ...options });
};

interface TaskUpdateProps {
  task: Task | undefined;
  onSuccess?: (response: any) => void;
  onError?: (error: unknown) => void;
}

export function useUpdateTask({ task, onSuccess, onError }: TaskUpdateProps) {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation({
    mutationFn: (data: Partial<Task>) => apiClient.put<Task>(apiTaskPathById(task?.id || ""), data),
    onSuccess: (res) => {
      onSuccess?.(res);
      setTaskDetailQueryCache(queryClient, res);
      queryClient.setQueryData(newHireJourneyKeys.detail(res.newHireJourneyId), (oldData: ExtendedNewHireJourney | undefined) => {
        if (!oldData) {
          return null;
        }
        return {
          ...oldData,
          tasks: (oldData.tasks || []).map((oldTask: BaseTask) => (oldTask.id === res.id ? res : oldTask)),
        };
      });
      queryClient.setQueryData(combinedActionKeys.detail(toCombinedActionId(task)), (oldData: CombinedAction | undefined) => {
        if (!oldData) {
          return res;
        }
        return {
          ...oldData,
          ...res,
        };
      });
    },
    onError: (e) => {
      console.log(e);
      onError?.(e);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    },
  });
}

export function useToggleTaskCompletion({ task, onSuccess, onError }: TaskUpdateProps) {
  const taskIncompleteStatus = task?.notifiedAt ? "notified" : "assigned";
  const payload = { status: task?.status === "completed" ? taskIncompleteStatus : "completed" };
  const { mutate: toggleTaskCompletion, isPending } = useUpdateTask({ task, onSuccess, onError });
  return { mutate: () => toggleTaskCompletion(payload as Partial<Task>), isPending };
}

export function useRemoveTask({ task, onSuccess, onError }: TaskUpdateProps) {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation({
    mutationFn: () => apiClient.delete<Task>(apiTaskPathById(task?.id || "")),
    onSuccess: (res) => {
      onSuccess?.(res);
      queryClient.invalidateQueries({ queryKey: taskKeys.detail(res.id) });
      queryClient.invalidateQueries({ queryKey: newHireJourneyKeys.detail(task?.newHireJourneyId || "") });
      queryClient.invalidateQueries({ queryKey: combinedActionKeys.detail(toCombinedActionId(task) || "") });
      toast({
        title: "Task removed!",
      });
      return res;
    },
    onError: (mutateError) => {
      onError?.(mutateError);
      console.log("Task Remove Error", mutateError);
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });
}
