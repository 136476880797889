import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { invalidateIssueQueries } from "features/Issues/hooks";
import { FieldDef, Issue } from "models/issue";
import { Company } from "models/company";
import useCompanyAutoSaveForm from "features/Company/DynamicForm/useCompanyAutoSaveForm";
import { CompanyUpdateFormValues } from "features/Company/DynamicForm/formDefinition";
import DynamicCompanyField from "features/Company/DynamicForm/DynamicCompanyField";

interface CompanyFormFieldsProps {
  company: Company;
  fieldsDefs: Array<FieldDef<CompanyUpdateFormValues>>;
  issue: Issue;
}

export default function CompanyFormFields({ company, fieldsDefs, issue }: CompanyFormFieldsProps) {
  const queryClient = useQueryClient();
  const onSuccess = useCallback(() => {
    invalidateIssueQueries(queryClient, issue);
  }, [issue, queryClient]);
  const fields = useMemo(() => fieldsDefs.map((fieldDef) => fieldDef.name), [fieldsDefs]);
  const { form } = useCompanyAutoSaveForm({
    company,
    fields,
    onSuccess,
  });
  return (
    <>
      {fieldsDefs.map((fieldDef) => (
        <DynamicCompanyField
          key={fieldDef.name}
          customLabel={fieldDef.label}
          fieldName={fieldDef.name}
          form={form}
          formFieldProps={{
            overrideHelperSpace: true,
            backgroundColor: "tableUnderlineBorder.opacity16",
            borderTopRadius: "5px",
          }}
        />
      ))}
    </>
  );
}
