import { useForm } from "react-hook-form";
import ActionRichTextAreaField from "components/FormElements/Fields/ActionRichTextAreaField";
import { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { dirtyValues } from "helpers/form";
import { useQueryClient } from "@tanstack/react-query";
import { TASK_TYPES } from "definitions/constants";
import { Task } from "models";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import TargetableTypeField from "components/FormElements/Fields/TargetableTypeField";
import { findTaskNotifier, isTask } from "features/Task/helpers";
import { GreyBlackLabel, HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";
import { taskTargetText } from "helpers/workflowActionableConverters";
import { Flex } from "@chakra-ui/react";
import { newHireJourneyKeys } from "features/NewHireJourney/hooks";
import StarOutline from "components/Icon/Iconify/StartOutline";
import Clock from "components/Icon/Iconify/Clock";
import CalendarMonth from "components/Icon/Iconify/CalendarMonth";
import { titleize } from "helpers/string";
import OutlineSubtitles from "components/Icon/Iconify/OutlineSubtitles";
import { ListIssuesByAction } from "features/Issues/Components/ListIssuesInfo";
import NameTag from "components/Tag/NameTag";
import { Schema, schema } from "./schema";
import { DESCRIPTION_FIELD, MESSAGE_SUGGESTION_FIELD, TITLE_FIELD } from "./fields";
import useSubmitUpdateTaskForm from "./useSubmitUpdateTaskForm";
import { triggerValues } from "../Shared/TriggerDateTime";
import { TriggerExplanation } from "../Shared/DisplayInfo";
import TriggerDate from "../Shared/TriggerDate";
import TriggerTimeModalButton from "../Shared/TriggerTimeModalButton";

const values = (task: Task): Schema => {
  if (!isTask(task)) {
    throw new Error("Action is not of type task");
  }

  return {
    taskType: task?.taskType,
    title: task?.title,
    description: task?.description,
    messageTarget: task?.messageTarget
      ? {
        targetType: task?.messageTarget?.targetType,
        targetId: task?.messageTarget?.target?.id,
      }
      : null,
    messageBody: task?.messageBody?.bodyTrix ?? task?.messageBody?.body,
    ...triggerValues(findTaskNotifier(task)),
    trigger: findTaskNotifier(task)?.trigger,
  };
};

interface UpdateTaskFormProps {
  task: Task;
  onSuccess: () => void;
  setIsSubmitLoading: (isLoading: boolean) => void;
  setIsFormDirty: (isLoading: boolean) => void;
}

const UpdateTaskForm = forwardRef(
  ({ task, onSuccess, setIsSubmitLoading, setIsFormDirty }: UpdateTaskFormProps, ref) => {
    const queryClient = useQueryClient();
    const taskNotifier = findTaskNotifier(task);
    const form = useForm<Schema>({
      mode: "onBlur",
      resolver: yupResolver<Schema>(schema, { stripUnknown: true }),
      defaultValues: values(task),
    });
    const {
      control,
      formState: { errors, dirtyFields, isDirty },
      handleSubmit,
      register,
      reset,
    } = form;
    const { onSubmit: onSubmitForm, isLoading: onSubmitLoading } = useSubmitUpdateTaskForm({
      task,
      onSuccess,
    });

    useEffect(() => {
      setIsFormDirty(isDirty);
    }, [isDirty, setIsFormDirty]);

    useEffect(() => {
      setIsSubmitLoading(onSubmitLoading);
    }, [onSubmitLoading, setIsSubmitLoading]);

    const handleOnSubmit = useCallback(
      (onSuccessCallback: () => void) => {
        handleSubmit((data) => {
          // handleSubmit built in - if the form is invalid, this function will not be called.
          // Because of this, if we pull "updateData" and its an empty object (no dirty fields),
          // we do not need to call the onSubmit function.
          const updateData = dirtyValues(data, dirtyFields);
          const { timezone, timeOfDay, triggerDate, ...rest } = updateData;
          const { trigger, ...updatedTaskData } = rest;
          const submitData = {};
          if (timezone) {
            submitData.notifier = { timezone };
          }
          if (Object.keys(updatedTaskData).length > 0) {
            submitData.task = updatedTaskData;
          }
          if (trigger) {
            submitData.trigger = trigger;
          }
          if (updateData.messageTarget?.targetId && !updateData.messageTarget?.messageTarget) {
            updateData.messageTarget.targetType = data?.messageTarget?.targetType;
          }
          if (Object.keys(updateData).length === 0 && !timezone && !trigger) {
            onSuccess?.();
            onSuccessCallback?.();
            return;
          }
          onSubmitForm(submitData, {
            onSuccess: () => {
              queryClient.invalidateQueries({ queryKey: newHireJourneyKeys.detail(task?.newHireJourneyId) });
              reset({}, { keepValues: true });
              onSuccessCallback?.();
              onSuccess?.();
            },
            onError: (error) => {
              console.error("error", error);
            },
          });
        })();
      },
      [handleSubmit, dirtyFields, onSubmitForm, onSuccess, queryClient, task?.newHireJourneyId, reset],
    );

    // Expose form methods to parent via ref
    useImperativeHandle(ref, () => ({
      handleSubmit: (onSuccessCallback: () => void) => handleOnSubmit(onSuccessCallback),
    }));

    return (
      <Flex mt="2" mb="6" gap="4" direction="column" align="start" w="100%">
        <ListIssuesByAction actionId={taskNotifier?.id} align="flex-start" />
        <HorizontalGreyBlackField icon={StarOutline} label="To" labelContainerProps={{ width: "75px" }}>
          <NameTag name={taskTargetText(task)} />
        </HorizontalGreyBlackField>
        {taskNotifier?.trigger?.type === "date_time" ? (
          <>
            <TriggerDate
              form={form}
              formFieldProps={{ sideLayout: true, labelProps: { display: "flex", alignItems: "flex-end", minWidth: "75px" } }}
              label={<GreyBlackLabel icon={CalendarMonth} label="Send Date" />}
              includeIcon={false}
              triggerButtonProps={{
                padding: "0",
                height: "32px !important",
                paddingTop: "0 !important",
                fontSize: "14px",
              }}
              containerProps={{ height: "32px" }}
            />
            <HorizontalGreyBlackField
              icon={Clock}
              label="Sending At"
              labelContainerProps={{ minWidth: "75px" }}
            >
              <TriggerTimeModalButton form={form} assignedActionId={taskNotifier?.id || ""} />
            </HorizontalGreyBlackField>
          </>
        ) : (
          <TriggerExplanation assignedAction={taskNotifier} />
        )}
        <TextInputField
          {...TITLE_FIELD}
          errors={errors}
          register={register}
          formFieldProps={{
            sideLayout: true,
            labelProps: { display: "flex", alignItems: "flex-end", minWidth: "75px" },
            overrideHelperSpace: true,
          }}
          label={<GreyBlackLabel icon={OutlineSubtitles} label="Title" />}
          height="32px !important"
          paddingTop="0 !important"
        />
        <HorizontalGreyBlackField label="Task Type">
          {titleize(task?.taskType)}
        </HorizontalGreyBlackField>
        {[TASK_TYPES.CUSTOM, TASK_TYPES.SEND_MESSAGE_PROMPT].includes(task.taskType) && (
          <ActionRichTextAreaField
            actionType="task"
            targetType={task.taskNotifier?.workflowAction?.actionable?.targetType}
            {...DESCRIPTION_FIELD}
            errors={errors}
            control={control}
          />
        )}
        {TASK_TYPES.SEND_MESSAGE_PROMPT === task.taskType && (
          <>
            <TargetableTypeField<Schema>
              errors={errors}
              control={control}
              label="Message Recipient"
              fieldName="messageTarget"
              targetTitle="Message Target"
            />
            <ActionRichTextAreaField
              actionType="task"
              targetType={task.taskNotifier?.workflowAction?.actionable?.targetType}
              {...MESSAGE_SUGGESTION_FIELD}
              errors={errors}
              control={control}
            />
          </>
        )}
      </Flex>
    );
  },
);

export default UpdateTaskForm;
