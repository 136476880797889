import { Button, ButtonGroup, Flex } from "@chakra-ui/react";
import { SecondaryButton, PrimaryButton } from "components/Button";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import CancelButton from "components/Button/CancelButton";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import ProcessedChat from "components/Forms/AssignedActions/Chat/ProcessedChat";
import UpdateReminderForm from "components/Forms/AssignedActions/Reminder/UpdateReminderForm";
import { useForceSendAssignedAction } from "features/AssignedAction/hooks";
import { CombinedAction } from "models/automation/combinedAction";
import { useRef, useState } from "react";

const CONFIRM_SEND_NOW = {
  header: "Confirm you want to send this reminder now!",
  body: "This cannot be undone! Be sure you want to send this reminder now.",
  confirmButtonText: "Send Now",
  buttonText: "Send Now",
};

interface ReminderDrawerFormProps {
  onClose: () => void;
  combinedAction: CombinedAction;
}

export default function ReminderDrawerForm({ onClose, combinedAction }: ReminderDrawerFormProps) {
  console.log("ReminderDrawerForm", combinedAction);
  const formRef = useRef<any>(null); // Create a ref to access form methods
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);

  const handleSubmit = (onSuccessCallback: () => void) => {
    formRef.current?.handleSubmit(onSuccessCallback); // Call form submit from the ref
  };

  const { mutate: sendNow, isReady } = useForceSendAssignedAction({ action: combinedAction });

  return (
    <Flex direction="column">
      {combinedAction && (
        ["processed", "skipped"].includes(combinedAction.status) ? (
          <ProcessedChat assignedAction={combinedAction} />
        ) : (
          <>
            <UpdateReminderForm
              ref={formRef}
              assignedAction={combinedAction}
              onSuccess={onClose}
              setIsSubmitLoading={setIsSubmitLoading}
              setIsFormDirty={setIsFormDirty}
              variant="flushedFloating"
            />
            <ButtonGroup display="flex" justifyContent="flex-end">
              <CancelButton onClick={() => onClose()}>
                Cancel
              </CancelButton>
              <ButtonWithConfirm
                intent="warning"
                isDisabled={!isReady}
                {...CONFIRM_SEND_NOW}
                handleConfirm={() => handleSubmit(() => sendNow())}
              >
                <SecondaryButton>
                  Send Now
                </SecondaryButton>
              </ButtonWithConfirm>
              <PrimaryButton
                onClick={() => handleSubmit(() => {})}
                isLoading={isSubmitLoading}
              >
                Update Reminder
              </PrimaryButton>
            </ButtonGroup>
          </>
        )
      )}
    </Flex>
  );
}
