import AppPage from "layouts/AppPage";
import { useEffect } from "react";
import { useNavbar } from "hooks/useNavbar";
import NewHireJourneyCardTable from "features/NewHireJourney/NewHireJourneyCardTable";

export default function NewHireJourneysPage() {
  const { setPageTitle } = useNavbar();

  useEffect(() => {
    setPageTitle("New Hires");
    return () => {
      setPageTitle(null);
    };
  }, [setPageTitle]);

  return (
    <AppPage>
      <NewHireJourneyCardTable />
    </AppPage>
  );
}
