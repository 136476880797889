/* eslint-disable import/prefer-default-export */
import { Heading, HeadingProps, useColorModeValue } from "@chakra-ui/react";

export function CaminoHeading({ children, ...rest }: HeadingProps) {
  return (
    <Heading size="xs" fontSize="xl" fontWeight="bold" {...rest}>
      {children}
    </Heading>
  );
}

export function DashboardHeading({ children, ...rest }: HeadingProps) {
  return (
    <Heading size="2xs" fontSize="lg" fontWeight="semibold" {...rest}>
      {children}
    </Heading>
  );
}

export function BlueHeading({ children, ...rest }: HeadingProps) {
  return (
    <Heading color="linkBlue.500" size="2xs" fontSize="lg" fontWeight="semibold" {...rest}>
      {children}
    </Heading>
  );
}

export function Heading24({children, ...rest }: HeadingProps) {
  const headerColor = useColorModeValue("notBlack.600", "var(--chakra-colors-whiteAlpha-900)");
  return (
    <Heading color={headerColor} fontSize="22px" fontWeight="600" {...rest}>
      {children}
    </Heading>
  );
}

export function Heading18({ children, ...rest }: HeadingProps) {
  const headerColor = useColorModeValue("notBlack.DEFAULT", "var(--chakra-colors-whiteAlpha-900)");
  return (
    <Heading color={headerColor} fontSize="18px" lineHeight="21.6px" fontWeight="700" {...rest}>
      {children}
    </Heading>
  );
}

export function Heading16({ children, ...rest }: HeadingProps) {
  const headerColor = useColorModeValue("notBlack.DEFAULT", "var(--chakra-colors-whiteAlpha-900)");
  return (
    <Heading color={headerColor} fontSize="16px" lineHeight="19.2px" fontWeight="700" {...rest}>
      {children}
    </Heading>
  );
}
