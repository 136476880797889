/* eslint-disable import/prefer-default-export */

import { Button, ButtonProps, Skeleton } from "@chakra-ui/react";
import OauthButton from "components/OauthButton";
import SendMessageButtonWrapper from "components/SendMessageButtonWrapper";
import useCaminoStore from "hooks/useCaminoStore";
import { Task } from "models";

interface CustomizeMessagePromptButtonProps extends ButtonProps {
  task: Task;
  onSuccess: () => void;
}

export function CustomizeMessagePromptButton({ task, onSuccess, ...rest }: CustomizeMessagePromptButtonProps) {
  const [user] = useCaminoStore((state) => [state.currentUser]);
  if (!task) {
    return (
      <Skeleton>
        <Button />
      </Skeleton>
    );
  }
  const messageBody = task?.messageBody;
  const targetType = task?.messageTarget?.targetType;
  const targetId = task?.messageTarget?.target?.id;
  const returnObject = user?.sendMessageAuthorized ? (
    <SendMessageButtonWrapper
      targetId={targetId}
      targetType={targetType}
      defaultMessage={messageBody?.bodyTrix}
      onSuccess={onSuccess}
    >
      <Button width="fit-content" {...rest}>
        Customize & Send Message
      </Button>
    </SendMessageButtonWrapper>
  ) : (
    <OauthButton>
      <Button width="fit-content" {...rest}>
        {/* This is the same as above as we want the display to be the same, only the modal */}
        {/* will be different */}
        Customize & Send Message
      </Button>
    </OauthButton>
  );
  return returnObject;
}
