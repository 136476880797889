import { ButtonProps } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { assignedActionKeys, useSkipAssignedAction } from "features/AssignedAction/hooks";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import React from "react";
import { GrayTableButton } from "components/Button/TableButton";
import BrandOutlineButton from "components/Button/BrandOutlineButton";
import { issueKeys } from "features/Issues/hooks";

const CONFIRM_SKIP_ACTION = {
  header: "Confirm Skip",
  body: "This action will not be sent.",
  confirmButtonText: "Confirm Skip",
};

const CONFIRM_UNSKIP_ACTION = {
  header: "Confirm Unskip",
  body: "This action will be scheduled to be sent.",
  confirmButtonText: "Confirm Unskip",
};

interface SkipButtonProps extends ButtonProps {
  action: AssignedAction;
  onSuccess?: (resp: any) => void;
}

function SkipButtonWrapper({ action, onSuccess, children }: SkipButtonProps) {
  const queryClient = useQueryClient();

  const { mutate: performRequest, isPending } = useSkipAssignedAction({
    action,
    onSuccess: (resp: any) => {
      queryClient.invalidateQueries({ queryKey: issueKeys.lists() });
      queryClient.invalidateQueries({ queryKey: issueKeys.details() });
      queryClient.invalidateQueries({ queryKey: assignedActionKeys.detail(action.id) });
      onSuccess?.(resp);
    },
  });
  if (!action) {
    return null;
  }

  return (
    <ButtonWithConfirm
      handleConfirm={() => performRequest()}
      intent="confirmation"
      {...(action.status === "skipped" ? CONFIRM_UNSKIP_ACTION : CONFIRM_SKIP_ACTION)}
    >
      {React.cloneElement(
        children as JSX.Element,
        { ...(isPending ? { isDisabled: true } : {}) },
      )}
    </ButtonWithConfirm>
  );
}

export function GraySkipButton({ action, onSuccess }: SkipButtonProps) {
  return (
    <SkipButtonWrapper action={action} onSuccess={onSuccess}>
      <GrayTableButton>{action?.status === "skipped" ? "Unskip" : "Skip"}</GrayTableButton>
    </SkipButtonWrapper>
  );
}

export function BrandSkipButton({ action, onSuccess }: SkipButtonProps) {
  return (
    <SkipButtonWrapper action={action} onSuccess={onSuccess}>
      <BrandOutlineButton>{action?.status === "skipped" ? "Unskip" : "Skip"}</BrandOutlineButton>
    </SkipButtonWrapper>
  );
}
