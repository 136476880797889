import { Flex, Text } from "@chakra-ui/react";
import BaselineCheckOutline from "components/Icon/Iconify/BaselineCheckOutline";
import BaselineErrorOutline from "components/Icon/Iconify/BaselineErrorOutilne";

interface ExistsProps {
  labelYes: string;
  labelNo: string;
  condition: boolean;
}

export function ExistsRight({ labelYes, labelNo, condition }: ExistsProps) {
  return (
    <Flex align="center" gap="2">
      <Text fontSize="sm" fontWeight="medium" color="chakra-body-text">
        {condition ? labelYes : labelNo}
      </Text>
      {condition ? (
        <BaselineCheckOutline color="successGreen.DEFAULT" aria-label="Yes" />
      ) : (
        <BaselineErrorOutline color="caminoNewErrorRed.DEFAULT" aria-label="Check No" />
      )}
    </Flex>
  );
}

export function ExistsLeft({ labelYes, labelNo, condition }: ExistsProps) {
  return (
    <Flex align="center" gap="1">
      {condition ? (
        <BaselineCheckOutline color="successGreen.DEFAULT" aria-label="Yes" />
      ) : (
        <BaselineErrorOutline color="caminoNewErrorRed.DEFAULT" aria-label="Check No" />
      )}
      <Text fontSize="sm" fontWeight="medium" color="chakra-body-text">
        {condition ? labelYes : labelNo}
      </Text>
    </Flex>
  );
}