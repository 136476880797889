import { MenuItem, useToast } from "@chakra-ui/react";
import Modal from "components/Modal";
import { useQueryClient } from "@tanstack/react-query";
import Alert from "components/Alert/Alert";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import DuplicatePathActionForm from "components/Forms/PathActions/DuplicatePathActionForm";
import { VThreeDotMenu } from "components/Menu/ThreeDotMenu";
import { API_ROUTES, PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import useCaminoStore from "hooks/useCaminoStore";
import { useModal } from "hooks/useModal";
import { AlertState } from "models/alert";
import { Action } from "models/automation";
import { PropsWithChildren, useCallback, useState } from "react";
import { generatePath, Link as RouterLink } from "react-router-dom";
import apiClient from "services/ApiClient";
import { pathKeys } from "features/Path/hooks";

interface ActionMenuButtonProps extends PropsWithChildren {
  action: Action;
}

const CONFIRM_REMOVE = {
  header: "Remove Action",
  body: "Are you sure you want to remove this action?",
  confirmButtonText: "Yes, remove",
};

const ALERT_SUCCESS_TITLE = "Message Sent!";
const ALERT_SUCCESS_BODY = (destination: string) => `A test was sent to ${destination}`;
const ALERT_FAILURE_TITLE = "Error";
const ALERT_FAILURE_BODY = (destination: string) => `There was an error trying to send to ${destination}`;
const ALERT_BUTTON_TITLE = "Close";

export default function ActionMenuButton({ action }: ActionMenuButtonProps) {
  const [alertState, setAlertState] = useState<AlertState>({ isOpen: false, isFailure: false });
  const [user] = useCaminoStore((state) => [state.currentUser]);
  const queryClient = useQueryClient();
  const toast = useToast({
    status: "success",
  });

  const modal = useModal({ id: "duplicate", header: { fontSize: "30", title: "Duplicate Action" }, size: "lg" });

  const handleTestSend = useCallback(
    async (id: string) => {
      try {
        const resp = await apiClient.post<{ success: boolean }>(generatePath(API_ROUTES.actions.testSend, { id }), {});
        console.log(resp);
        setAlertState({ ...{ isOpen: true, isFailure: false } });
      } catch (error) {
        setAlertState({ ...{ isOpen: true, isFailure: true } });
      }
    },
    [setAlertState],
  );

  const handleDestroy = useCallback(
    async (id: string, pathId: string) => {
      try {
        const resp = await apiClient.delete<{ success: boolean }>(generatePath(API_ROUTES.actions.show, { id }));
        console.log(resp);
        toast({ title: "Action Successfully Deleted" });
        queryClient.invalidateQueries({ queryKey: pathKeys.detail(pathId) });
      } catch (error) {
        console.log(error);
        toast({
          title: "Error",
          description: "There was an error, please try again.",
          status: "error",
        });
      }
    },
    [queryClient, toast],
  );

  const onClose = useCallback(() => {
    setAlertState({ isOpen: false, isFailure: false });
  }, [setAlertState]);

  if (!action) {
    return null;
  }

  return (
    <>
      <VThreeDotMenu inPortal>
        {["email", "chat", "task"].includes(action?.actionable?.actionType) ? (
          <MenuItem onClick={() => handleTestSend(action.id)}>Test Send</MenuItem>
        ) : null}
        <MenuItem
          as={RouterLink}
          to={`${generatePath(PEOPLE_TEAM_ROUTES.actions.show, { id: action.id })}?eid=${action.id}`}
        >
          Edit
        </MenuItem>
        <MenuItem onClick={modal.onOpen}>Duplicate</MenuItem>
        <ButtonWithConfirm
          {...CONFIRM_REMOVE}
          intent="warning"
          handleConfirm={() => handleDestroy(action.id, action.workflowId)}
        >
          <MenuItem>Remove</MenuItem>
        </ButtonWithConfirm>
      </VThreeDotMenu>
      <Alert
        title={alertState.isFailure ? ALERT_FAILURE_TITLE : ALERT_SUCCESS_TITLE}
        body={
          alertState.isFailure
            ? ALERT_FAILURE_BODY(user?.fullName as string)
            : ALERT_SUCCESS_BODY(user?.fullName as string)
        }
        buttonTitle={ALERT_BUTTON_TITLE}
        isOpen={alertState.isOpen}
        onClose={onClose}
      />
      <Modal modal={modal}>
        <DuplicatePathActionForm action={action} onClose={modal.onClose} />
      </Modal>
    </>
  );
}
