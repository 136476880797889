import { Flex } from "@chakra-ui/react";
import { HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { TriggerExplanation } from "components/Forms/AssignedActions/Shared/DisplayInfo";
import { titleize } from "helpers/string";
import { longAttributeStyles } from "../styles";
import { EmailRecipientAttribute } from "../Attribute/RecipientAttribute";
import SendAtAttribute from "../Attribute/SendAtAttribute";
import SendDateAttribute from "../Attribute/SendDateAttribute";
import TemplateActionAttribute from "../Attribute/FromTemplateAttribute";

export default function TaskOverview({ action }: { action: AssignedAction }) {
  return (
    <Flex mt="2" mb="6" gap="4" direction="column" align="start" w="100%">
      <EmailRecipientAttribute action={action} />
      <HorizontalGreyBlackField label="Task Type" labelContainerProps={longAttributeStyles.labelContainerProps}>
        {titleize(action?.task?.taskType)}
      </HorizontalGreyBlackField>
      {action?.trigger?.type === "date_time" ? (
        <>
          <SendDateAttribute action={action} />
          <SendAtAttribute action={action} />
        </>
      ) : (
        <TriggerExplanation assignedAction={action} />
      )}
      <TemplateActionAttribute action={action} />
    </Flex>
  );
}
