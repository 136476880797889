import { BaseAssignedAction } from "models/automation";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import moment from "moment";

const READABLE_SCHEDULED_ACTION_STATUS_CONVERTER = {
  pending: "Not Sent",
  error: "Error",
  processed: "Sent",
  skipped: "Skipped",
  removed: "Removed",
};

export function scheduledActionStatusConverter(status: keyof typeof READABLE_SCHEDULED_ACTION_STATUS_CONVERTER) {
  return READABLE_SCHEDULED_ACTION_STATUS_CONVERTER[status];
}

const READABLE_SCHEDULED_ACTION_STATUS_TO_COLOR_CONVERTER = {
  pending: "brand",
  processed: "green",
  error: "red",
  skipped: "gray",
  removed: "gray",
};

function scheduledActionStatusToColor(status: keyof typeof READABLE_SCHEDULED_ACTION_STATUS_CONVERTER) {
  return READABLE_SCHEDULED_ACTION_STATUS_TO_COLOR_CONVERTER[status];
}

export function isElevated(assignedAction: AssignedAction) {
  return (
    assignedAction.status === "pending"
    && assignedAction.trigger.type === "date_time"
    && new Date(assignedAction.trigger.data.datetime) <= moment().add(1, "week").toDate()
  );
}

export function scheduledActionToStatusColor(assignedAction: AssignedAction) {
  return scheduledActionStatusToColor(assignedAction.status);
}

export function countCompletedScheduledActions(scheduledWorkflowActions: BaseAssignedAction[]) {
  return scheduledWorkflowActions.filter((action) => action.status === "processed").length;
}

export function countValidScheduledActions(scheduledWorkflowActions: BaseAssignedAction[]) {
  return scheduledWorkflowActions.filter((action) => !["skipped", "removed"].includes(action.status)).length;
}
