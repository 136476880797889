import {
  InputGroup, InputRightElement, Input, Icon,
  InputGroupProps,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";

interface SearchBarProps extends InputGroupProps {
  name: string;
  placeholder?: string;
  onInput: (data: any) => void;
  variant?: string;
  searchIconColor?: string;
  inputProps?: object;
}

export default function SearchBar({
  name,
  placeholder = "Search",
  onInput,
  variant = "outline",
  searchIconColor = "searchBarGrey.DEFAULT",
  inputProps = {},
  ...rest
}: SearchBarProps) {
  const [searchInput, setSearchInput] = useState("");
  const searchBarColor = useColorModeValue("searchBarGrey.DEFAULT", "searchBarGrey.DEFAULT");

  useEffect(() => {
    onInput(searchInput);
  }, [searchInput, onInput]);

  return (
    <InputGroup
      borderRadius="4px"
      color={searchBarColor}
      maxW="md"
      display="inline-flex"
      {...rest}
    >
      <InputRightElement height="100%">
        <Icon as={FiSearch} color={searchIconColor} fontSize="lg" />
      </InputRightElement>
      <Input
        value={searchInput}
        color={searchBarColor}
        borderColor={searchBarColor}
        onChange={(e) => setSearchInput(e.target.value)}
        placeholder={placeholder}
        variant={variant}
        {...inputProps}
      />
    </InputGroup>
  );
}
