import { Button, ButtonProps } from "@chakra-ui/react";

export default function BrandOutlineButton({ children, ...rest }: ButtonProps) {
  return (
    <Button
      variant="outline"
      colorScheme="brand"
      color="brand.DEFAULT"
      borderColor="var(--chakra-colors-brand-opacity50)"
      border="1.25px solid"
      py={0}
      h="32px"
      {...rest}
    >
      {children}
    </Button>
  );
}
