import { Text, useColorModeValue } from "@chakra-ui/react";
import { SegmentedControlButton, SegmentedControlGroup } from "components/FormElements/UnderlineSegmentedControlGroup";
import CaminoBuddy from "components/Icon/CaminoBuddy";
import CaminoManager from "components/Icon/CaminoManager";
import CaminoNewHire from "components/Icon/CaminoNewHire";
import CaminoPeopleTeam from "components/Icon/CaminoPeopleTeamIcon";
import { RELATION_TARET_TYPE } from "definitions/constants";
import { PathRelationTarget } from "models/automation";
import { Dispatch, SetStateAction } from "react";

const SelectableRelationTargetTypeOptions = [
  { label: "All", value: "" },
  { label: "New Hire", value: RELATION_TARET_TYPE.NEW_HIRE, icon: CaminoNewHire },
  { label: "Manager", value: RELATION_TARET_TYPE.MANAGER, icon: CaminoManager },
  { label: "People Team", value: RELATION_TARET_TYPE.PEOPLE_TEAM, icon: CaminoPeopleTeam },
  { label: "Buddy", value: RELATION_TARET_TYPE.BUDDY, icon: CaminoBuddy },
];

interface SelectableRelationTargetTypeProps {
  setRelationTargetType: Dispatch<SetStateAction<PathRelationTarget | "">>;
  relationTargetType: PathRelationTarget | "";
}

export default function SelectableRelationTargetType({
  relationTargetType,
  setRelationTargetType,
}: SelectableRelationTargetTypeProps) {
  const fontColor = useColorModeValue("gray.400", "white");
  return (
    <SegmentedControlGroup
      defaultValue={relationTargetType}
      spacing="2"
      onChange={(value: any) => setRelationTargetType(value)}
    >
      {SelectableRelationTargetTypeOptions.map(({ value, icon, label }) => (
        <SegmentedControlButton key={value} value={value} icon={icon}>
          <Text color={fontColor} size="sm">{label}</Text>
        </SegmentedControlButton>
      ))}
    </SegmentedControlGroup>
  );
}
