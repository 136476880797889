/* eslint-disable quote-props */
/* eslint-disable import/prefer-default-export */

import { camelCase, startCase } from "lodash";

const ERROR_MAP = {
  "invalid_auth": "Slack authorization error. Make sure the Camino App is authorized to send a message to this user or channel.",
  "send_message_to_user delegated to message_service_user, but message_service_user is nil": "Cannot find Slack user to send this action to.",
  "undefined method 'external_id' for nil": "Cannot find Slack user to send this action to.",
  "Target has not been assigned": "Unable to send, action recipient is uknown.",
  default: "There was an error, please try again.",
};

export function formatMessyErrorMessage(error: string) {
  if (ERROR_MAP[error as keyof typeof ERROR_MAP]) {
    return ERROR_MAP[error as keyof typeof ERROR_MAP];
  }
  if (error.includes("has not been added")) {
    return "Action content error - Could not resolve a value for a personalization token";
  }
  return "There was an error, please try again.";
}

export function parseMessyErrorMessages(errors: string[]): string {
  if (!errors || errors.length === 0) {
    return "There was an error, please try again.";
  }
  return errors.map((error) => formatMessyErrorMessage(error)).join(", ");
}

export function parseErrors(errorResponse: { errors: Record<string, string[]> }, defaultMessage?: string): string {
  if (!errorResponse.errors || typeof errorResponse.errors !== "object") {
    return defaultMessage || "There was an error, please try again.";
  }

  return Object.entries(errorResponse.errors)
    .map(([field, messages]) => `"${startCase(camelCase(field))}" ${messages.join(", ")}. `)
    .join();
}
