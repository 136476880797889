import { Box, Flex, FlexProps, HeadingProps } from "@chakra-ui/react";
import { Heading16 } from "components/Heading";
import ActionTypeTag from "components/Tag/ActionTypeTag";
import { AssignedAction } from "models/automation/scheduledWorkflow";

interface ActionTypeAndNameHeaderProps extends FlexProps {
  action: AssignedAction;
  headingProps?: HeadingProps;
}

export default function ActionNameAndTypeHeader({ action, headingProps = {}, ...rest }: ActionTypeAndNameHeaderProps) {
  return (
    <Flex align="center" gap="4" {...rest}>
      <Heading16 {...headingProps}>{action?.name}</Heading16>
      <Box>
        <ActionTypeTag actionType={action?.actionType} />
      </Box>
    </Flex>
  );
}
