import { Button } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import ModalFormWrapper from "components/ModalForm/ModalFormWrapper";
import { AddIcon } from "@chakra-ui/icons";
import NewHireForm from ".";

export default function NewHireButtonWithModal({ children }: PropsWithChildren) {
  return (
    <ModalFormWrapper
      modalHeader="Add New Hire"
      button={(children as JSX.Element) || <Button size="sm" leftIcon={<AddIcon boxSize={4} />}>Add New Hire</Button>}
      size="md"
    >
      <NewHireForm />
    </ModalFormWrapper>
  );
}
