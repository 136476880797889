import * as yup from "yup";
import { get } from "lodash";
import { User } from "models/user";
import { validDate } from "utils/dateFormatter";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { AutoFormField } from "models/autoForm";

export interface UserUpdateFormValues {
  firstName: string;
  lastName: string;
  title: string;
  personalEmail?: string;
  workEmail?: string;
  location?: string;
  timezone?: string;
  peopleTeamContactId?: string;
  managerId?: string;
  buddyId?: string;
  startDate?: Date;
  linkedinUrl?: string;
  onboardingPlanUrl?: string;
  teamWikiUrl?: string;
  teamGoalsUrl?: string;
  shippingTrackingNumber?: string;
  shippingTrackingUrl?: string;
  shippingCarrier?: string;
  internalOnboardingChecklistUrl?: string;
  hrisOnboardingUrl?: string;
  department: string;
  pronouns: string;
  preferredName: string;
  employmentType: string;
  phoneNumber: string;
  workMode: string;
}

export const userUpdateSchema = yup.object({
  firstName: yup.string().label("First Name").notRequired(),
  lastName: yup.string().label("Last Name").notRequired(),
  title: yup.string().label("Title").notRequired(),
  managerId: yup.string().label("Manager").notRequired(),
  buddyId: yup.string().label("Buddy").notRequired(),
  peopleTeamContactId: yup.string().label("People Team Contact").notRequired(),
  personalEmail: yup.string().label("Personal Email").email().lowercase()
    .notRequired(),
  workEmail: yup.string().label("Work Email").email().lowercase()
    .notRequired(),
  location: yup.string().label("Location").notRequired(),
  timezone: yup.string().label("Timezone").notRequired(),
  startDate: yup.date().label("Start Date").notRequired(),
  linkedinUrl: yup.string().label("Linkedin Url").notRequired(),
  onboardingPlanUrl: yup.string().label("Onboarding Plan Url").notRequired(),
  teamWikiUrl: yup.string().label("Team Wiki Url").notRequired(),
  teamGoalsUrl: yup.string().label("Team Goals Url").notRequired(),
  department: yup.string().label("Department").notRequired(),
  pronouns: yup.string().label("Pronouns").notRequired(),
  preferredName: yup.string().label("Preferred Name").notRequired(),
  employmentType: yup.string().label("Employment Type").notRequired(),
  phoneNumber: yup.string().label("Phone Number").notRequired(),
  workMode: yup.string().label("Work Mode").notRequired(),
  shippingTrackingNumber: yup.string().label("Shipping Tracking Number").notRequired(),
  shippingTrackingUrl: yup.string().label("Shipping Tracking Url").notRequired(),
  shippingCarrier: yup.string().label("Shipping Carrier").notRequired(),
  internalOnboardingChecklistUrl: yup
    .string()
    .label("Internal Onboarding Checklist Url")
    .notRequired(),
  hrisOnboardingUrl: yup.string().label("HRIS Onboarding Url").notRequired(),
});

export const values = (user: User | undefined) => ({
  firstName: user?.firstName || null,
  lastName: user?.lastName || null,
  title: user?.title || null,
  personalEmail: user?.personalEmail || null,
  workEmail: user?.workEmail || null,
  linkedinUrl: user?.linkedinUrl || null,
  location: user?.location || null,
  timezone: user?.timezone || null,
  startDate: validDate(user?.onboardingJourney?.startDate) || null,
  managerId: user?.manager?.id || null,
  peopleTeamContactId: user?.onboardingJourney?.peopleTeamContact?.id || null,
  buddyId: user?.onboardingJourney?.buddy?.id || null,
  onboardingPlanUrl: user?.onboardingJourney?.onboardingPlanUrl || null,
  teamWikiUrl: user?.onboardingJourney?.teamWikiUrl || null,
  teamGoalsUrl: user?.onboardingJourney?.teamGoalsUrl || null,
  department: user?.department || null,
  pronouns: user?.pronouns || null,
  preferredName: user?.preferredName || null,
  employmentType: user?.employmentType || null,
  phoneNumber: user?.phoneNumber || null,
  workMode: user?.workMode || null,
  shippingTrackingNumber: get(user?.onboardingJourney, "shippingTrackingNumber", null),
  shippingTrackingUrl: get(user?.onboardingJourney, "shippingTrackingUrl", null),
  shippingCarrier: get(user?.onboardingJourney, "shippingCarrier", null),
  internalOnboardingChecklistUrl: get(
    user?.onboardingJourney,
    "internalOnboardingChecklistUrl",
    null,
  ),
  hrisOnboardingUrl: get(user?.onboardingJourney, "hrisOnboardingUrl", null),
});

export const FIELD_CONFIG: Record<keyof UserUpdateFormValues, AutoFormField> = {
  firstName: {
    type: "text",
    name: "firstName",
    label: "First Name",
  },
  lastName: {
    type: "text",
    label: "Last Name",
    name: "lastName",
  },
  title: {
    type: "text",
    label: "Job Title",
    name: "title",
  },
  startDate: {
    type: "date",
    label: "Start Date",
    name: "startDate",
    textTransform: "lowercase",
  },
  personalEmail: {
    label: "Personal Email",
    name: "personalEmail",
    type: "email",
    textTransform: "lowercase",
  },
  workEmail: {
    label: "Work Email",
    name: "workEmail",
    type: "email",
    textTransform: "lowercase",
  },
  location: {
    label: "Location",
    name: "location",
    type: "locationSelect",
  },
  timezone: {
    label: "Timezone",
    name: "timezone",
    type: "timezoneSelect",
  },
  managerId: {
    label: "Manager",
    name: "managerId",
    type: "userSelect",
  },
  buddyId: {
    label: "Buddy",
    name: "buddyId",
    type: "userSelect",
  },
  peopleTeamContactId: {
    label: "People Team Contact",
    name: "peopleTeamContactId",
    type: "userSelect",
  },
  linkedinUrl: {
    label: "Linkedin Url",
    name: "linkedinUrl",
    type: "text",
  },
  onboardingPlanUrl: {
    label: "Onboarding Plan Url",
    name: "onboardingPlanUrl",
    type: "text",
  },
  teamWikiUrl: {
    label: "Team Wiki Url",
    name: "teamWikiUrl",
    type: "text",
  },
  teamGoalsUrl: {
    label: "Team Goals Url",
    name: "teamGoalsUrl",
    type: "text",
  },
  shippingTrackingNumber: {
    label: "Shipping Tracking Number",
    name: "shippingTrackingNumber",
    type: "text",
  },
  shippingTrackingUrl: {
    label: "Shipping Tracking Url",
    name: "shippingTrackingUrl",
    type: "text",
  },
  shippingCarrier: {
    label: "Shipping Carrier",
    name: "shippingCarrier",
    type: "text",
  },
  internalOnboardingChecklistUrl: {
    label: "Internal Onboarding Checklist Url",
    name: "internalOnboardingChecklistUrl",
    type: "text",
  },
  hrisOnboardingUrl: {
    label: "HRIS Onboarding Url",
    name: "hrisOnboardingUrl",
    type: "text",
  },
  department: {
    label: "Department",
    name: "department",
    type: "createableSingleSelect",
    optionsUrl: API_ROUTES.departments.base,
  },
  pronouns: {
    label: "Pronouns",
    name: "pronouns",
    type: "text",
  },
  preferredName: {
    label: "Preferred Name",
    name: "preferredName",
    type: "text",
  },
  employmentType: {
    label: "Employment Type",
    name: "employmentType",
    type: "singleSelect",
    options: [
      { value: "fullTime", label: "Full Time" },
      { value: "partTime", label: "Part Time" },
      { value: "contractor", label: "Contractor" },
    ],
  },
  phoneNumber: {
    label: "Phone Number",
    name: "phoneNumber",
    type: "text",
  },
  workMode: {
    label: "Work Mode",
    name: "workMode",
    type: "singleSelect",
    options: [
      { value: "remote", label: "Remote" },
      { value: "onsite", label: "Onsite" },
      { value: "hybrid", label: "Hybrid" },
    ],
  },
};
