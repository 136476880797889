import { DATE_PICKER_FORMAT_MOMENT } from "definitions/constants";
import moment from "moment";

const DEFAULT_TIME_FORMAT = "h:mm a";
const DEFAULT_TIME_FORMAT_WITH_TIMEZONE = `${DEFAULT_TIME_FORMAT} z`;

export function display12hrTimeOfDay(timeValue: string | undefined) {
  return timeValue ? moment(timeValue, "HH:mm").format(DEFAULT_TIME_FORMAT) : "";
}

export function convertTime(timeString: string) {
  // Converts 24-hour time (e.g., "09:00", "14:00") to 12-hour format with AM/PM
  return moment(timeString, "HH:mm").format("h:mm a");
}

export function getLocalTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function taskDateDisplay(value: Date | undefined) {
  // Will display date/time like: "Tuesday, Jan 3"
  return value ? moment(value).format("dddd, MMM D") : "";
}

export function tableTaskCompletedDateDisplay(value: Date | undefined) {
  // Will display date/time like: "12:17 am on Tues, Jan 3"
  return value ? moment(value).format("hh:mm A [on] ddd, MMM D") : "";
}

export function dateToLongString(value: Date | undefined) {
  // Will display date/time like: "Tuesday, January 3rd"
  return value ? moment(value).format("dddd, MMMM Do") : "";
}

export function dateToAbrevString(value: Date | undefined) {
  // Will display date/time like: "Tues, Jan 3rd"
  return value ? moment(value).format("ddd, MMM Do") : "";
}

export function dateToFormalLongString(value: Date | undefined) {
  // Will display date/time like: "Tuesday, January 3rd, 2024"
  return value ? moment(value).format("dddd, MMMM Do, YYYY") : "";
}

export function dateToFormalLongStringWithTimeAndZone(value: Date | undefined) {
  // Will display date/time like: "Tuesday, January 3rd, 2024 at 12:47 am UTC"
  return value ? moment(value).tz(getLocalTimezone()).format(`dddd, MMMM Do [at] ${DEFAULT_TIME_FORMAT_WITH_TIMEZONE}`) : "";
}

export function display12hrTimeOfDayWithTZ(timeValue: string | undefined) {
  return timeValue ? moment(timeValue, "HH:mm").tz(getLocalTimezone()).format(DEFAULT_TIME_FORMAT_WITH_TIMEZONE) : "";
}

export function dateTo12hrTimeOfDayWithTZInZone(dateTimeValue: string | undefined, timezone: string) {
  return dateTimeValue ? moment(dateTimeValue).tz(timezone).format(DEFAULT_TIME_FORMAT_WITH_TIMEZONE) : "";
}

export function dateToAbbrevMonthYear(value: Date | undefined) {
  // Will display date/time like: "Jan 3rd, 2024"
  return value ? moment(value).format("MMM Do, YYYY") : "";
}
export function dateToAbrevStringWithYear(value: Date | undefined) {
  // Will display date/time like: "Tuesday, January 3rd, 2024"
  return value ? moment(value).format("ddd, MMM Do, YYYY") : "";
}

export function dateToLongStringWithTime(value: Date | undefined) {
  // Will display date/time like: "Tuesday, January 3rd at 12:47 am"
  return value ? moment(value).format(`dddd, MMMM Do [at] ${DEFAULT_TIME_FORMAT}`) : "";
}

export function basicDayMonth(value: Date | undefined) {
  // Will display date/time like:  January 1
  return value ? moment(value).format("MMMM D") : "";
}

export function basicDate(value: Date | undefined) {
  // Will display date/time like:  01/03/2023
  return value ? moment(value).format("L") : "";
}

export function basicShortDate(value: Date | undefined) {
  // Will display date/time like:  01/03/23
  return value ? moment(value).format("MM/DD/YY") : "";
}

export function timeAtSpecificDayMonth(value: Date | undefined) {
  // Will display date/time like: "Tues, 9/22 at 12:47 pm or Tomorrow at 12:47 pm"
  if (!value) {
    return "";
  }
  if (moment(value).isBetween(moment().subtract(1, "day").startOf("day"), moment().add(1, "day").endOf("day"))) {
    return moment(value).calendar().replace("AM", "am").replace("PM", "pm");
  }
  return value ? moment(value).format(`ddd, MMM D [at] ${DEFAULT_TIME_FORMAT}`) : "";
}

export function timeAtDayMonth(value: Date | undefined) {
  // Will display date/time like: "9/22 at 12:47 pm or Yesterday at 12:47 pm"
  if (!value) {
    return "";
  }
  if (moment(value).isBetween(moment().subtract(1, "day").startOf("day"), moment().add(1, "day").endOf("day"))) {
    return moment(value).calendar().replace("AM", "am").replace("PM", "pm");
  }
  return value ? moment(value).format(`MMM D [at] ${DEFAULT_TIME_FORMAT}`) : "";
}

export function timeOnUSDate(value: Date | undefined) {
  // Will display date/time like: "12:47 PM on 9/22/2023"
  return value ? moment(value).format("hh:mm A [on] l") : "";
}

export function lastUpdated(value: Date | undefined) {
  return value ? moment(value).fromNow() : "";
}

export function backendTimeFormat(value: Date | undefined) {
  return value ? moment(value).toISOString(true) : "";
}

export function setTimeFromOriginalDateTime(newDate: Date, originalDate: Date | string | undefined): Date {
  if (!originalDate) return newDate;
  const referenceDateObj = typeof originalDate === "string" ? new Date(originalDate) : originalDate;
  newDate.setHours(referenceDateObj.getHours());
  newDate.setMinutes(referenceDateObj.getMinutes());
  newDate.setSeconds(referenceDateObj.getSeconds());
  return newDate;
}

function timeFormat({ includeTimezone }: { includeTimezone: boolean }): string {
  return `${DEFAULT_TIME_FORMAT}${includeTimezone ? " z" : ""}`;
}

export function relativeDateTime(
  value: Date | undefined,
  options: { includeTimezone: boolean } = { includeTimezone: false },
): string {
  // Will display date/time like:
  // "Fri, Sep 23 at 12:47 pm"
  // "Last Friday at 12:47 pm"
  // "This Past Monday at 12:47 pm"
  // "Yesterday at 12:47 pm"
  // "Today at 12:47 pm"
  // "Tomorrow at 12:47 pm"
  // "This Friday at 12:47 pm"
  // "Next Monday at 12:47 pm"
  // "Fri, Sep 23 at 12:47 pm"
  if (!value) {
    return "";
  }

  const now = moment();
  let targetDate = moment(value);
  const daysDiff = targetDate.diff(now, "days");
  const format = timeFormat(options);
  if (options?.includeTimezone) {
    targetDate = targetDate.tz(getLocalTimezone());
  }

  // More than 1 week ago
  if (targetDate.isBefore(now.clone().subtract(1, "week").startOf("day"))) {
    return targetDate.format(`ddd MMM D [at] ${format}`);
  }

  // Within past week
  if (targetDate.isBetween(now.clone().subtract(1, "week").startOf("day"), now.startOf("day"))) {
    return targetDate.calendar(null, {
      sameDay: `[Today at] ${format}`,
      nextDay: `[Tomorrow at] ${format}`,
      nextWeek: `[This] dddd [at] ${format}`,
      lastDay: `[Yesterday at] ${format}`,
      lastWeek: `[Last] dddd [at] ${format}`,
      sameElse: `ddd MMM D [at] ${format}`,
    });
  }

  // Yesterday
  if (
    targetDate.isBetween(now.clone().subtract(1, "day").startOf("day"), now.clone().subtract(1, "day").endOf("day"))
  ) {
    return targetDate.format(`[Yesterday at] ${format}`);
  }

  // Today
  if (targetDate.isBetween(now.startOf("day"), now.endOf("day"))) {
    return targetDate.format(`[Today at] ${format}`);
  }

  // Tomorrow
  if (targetDate.isBetween(now.clone().add(1, "day").startOf("day"), now.clone().add(1, "day").endOf("day"))) {
    return targetDate.format(`[Tomorrow at] ${format}`);
  }

  // This week (from today until the end of this week)
  if (targetDate.isBetween(now, now.clone().endOf("week"))) {
    return targetDate.calendar(null, {
      sameDay: `[Today at] ${format}`,
      nextDay: `[Tomorrow at] ${format}`,
      nextWeek: `[This] dddd [at] ${format}`,
      lastDay: `[Yesterday at] ${format}`,
      lastWeek: `[Last] dddd [at] ${format}`,
      sameElse: `ddd MMM D [at] ${format}`,
    });
  }

  // Within the next week (but not this week)
  if (daysDiff >= 1 && daysDiff <= 7) {
    return targetDate.calendar(null, {
      sameDay: `[Today at] ${format}`,
      nextDay: `[Tomorrow at] ${format}`,
      nextWeek: `[Next] dddd [at] ${format}`,
      lastDay: `[Yesterday at] ${format}`,
      lastWeek: `[Last] dddd [at] ${format}`,
      sameElse: `ddd MMM D [at] ${format}`,
    });
  }

  // More than 1 week in the future
  return targetDate.format(`ddd MMM D [at] ${format}`);
}

export function relativeDateTimeWithTimezone(value: Date | undefined): string {
  return relativeDateTime(value, { includeTimezone: true });
}

export function relativeDate(value: Date | undefined): string {
  // Will display date/time like:
  // "Fri, Sep 23, 2024"
  // "Last Friday"
  // "This Past Monday"
  // "Yesterday"
  // "Today"
  // "Tomorrow"
  // "This Friday"
  // "Next Monday"
  // "Fri, Sep 23, 2024"
  if (!value) {
    return "";
  }

  const now = moment();
  const targetDate = moment(value);
  const daysDiff = targetDate.diff(now, "days");

  // More than 1 week ago
  if (targetDate.isBefore(now.clone().subtract(1, "week").startOf("day"))) {
    return targetDate.format("ddd MMM D, YYYY");
  }

  // Within past week
  if (targetDate.isBetween(now.clone().subtract(1, "week").startOf("day"), now.startOf("day"))) {
    return targetDate.calendar(null, {
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "[This] dddd",
      lastDay: "[Yesterday]",
      lastWeek: "[Last] dddd",
      sameElse: "ddd MMM D, YYYY",
    });
  }

  // Yesterday
  if (
    targetDate.isBetween(now.clone().subtract(1, "day").startOf("day"), now.clone().subtract(1, "day").endOf("day"))
  ) {
    return targetDate.format("[Yesterday]");
  }

  // Today
  if (targetDate.isBetween(now.startOf("day"), now.endOf("day"))) {
    return targetDate.format("[Today]");
  }

  // Tomorrow
  if (targetDate.isBetween(now.clone().add(1, "day").startOf("day"), now.clone().add(1, "day").endOf("day"))) {
    return targetDate.format("[Tomorrow]");
  }

  // This week (from today until the end of this week)
  if (targetDate.isBetween(now, now.clone().endOf("week"))) {
    return targetDate.calendar(null, {
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "[This] dddd",
      lastDay: "[Yesterday]",
      lastWeek: "[Last] dddd",
      sameElse: "ddd MMM D, YYYY",
    });
  }

  // Within the next week (but not this week)
  if (daysDiff >= 1 && daysDiff <= 7) {
    return targetDate.calendar(null, {
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "[Next] dddd",
      lastDay: "[Yesterday]",
      lastWeek: "[Last] dddd",
      sameElse: "ddd MMM D, YYYY",
    });
  }

  // More than 1 week in the future
  return targetDate.format("ddd MMM D, YYYY");
}

export function isInPast(value: Date | undefined): boolean {
  if (!value || !moment(value).isValid()) {
    return false;
  }
  return moment(value).isBefore(moment());
}

export function convertToLocalTimeString(utcDatetime: string | Date, timezone: string) {
  return moment.utc(utcDatetime)
    .tz(timezone)
    .format("HH:mm");
}

export function convertToLocalDateString(utcDatetime: string | Date, timezone: string) {
  return moment.utc(utcDatetime).tz(timezone).format(DATE_PICKER_FORMAT_MOMENT);
}

export function convertToLocalDate(utcDatetime: string | Date, timezone: string) {
  const localDateStr = convertToLocalDateString(utcDatetime, timezone);
  return new Date(`${localDateStr}T00:00:00`);
}

export function convertDateTo12pm(date: Date) {
  return moment(date).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toDate();
}

export function buildISODate(date: string | Date, time: string, timezone: string) {
  let dateString = date;
  if (date instanceof Date) {
    dateString = moment(date).format(DATE_PICKER_FORMAT_MOMENT);
  }
  const combinedDateTime = `${dateString}T${time}`;
  const zonedDatetime = moment.tz(combinedDateTime, timezone);
  return zonedDatetime.toISOString();
}
