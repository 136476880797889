import { createColumnHelper } from "@tanstack/react-table";
import GenericTable, { GenericTableProps } from "components/Table/GenericTable";
import { FilterMeta, ResponseModel } from "services/ApiClient/responseModel";
import apiClient from "services/ApiClient";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { RandomEmptyIssueState } from "components/EmptyComponents/RandomEmptyState";
import { basicDate, basicShortDate } from "helpers/time";
import ActionTypeTag from "components/Tag/ActionTypeTag";
import { InternalLink } from "components/Link";
import { combinedActionDateTime } from "helpers/assignedAction";
import { assignedActionPathById } from "helpers/url";
import { BaseAssignedAction } from "models/automation";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { assignedActionKeys } from "features/AssignedAction/hooks";
import { Box, Flex } from "@chakra-ui/react";
import { TABLE_BORDER_CONTAINER_PROPS, TABLE_STYLE_OVERRIDES } from "definitions/constants/styling";
import SearchBar from "components/SearchBar";
import { useMemo, useState } from "react";
import { BlockedOrReadyTag } from "features/Issues/Components/NumberOfIssuesTag";
import { CombinedActionId } from "models/automation/combinedAction";
import useAssignedActionSideDrawer from "components/AssignedActionSideDrawer/hooks";
import AssignedActionSideDrawer from "components/AssignedActionSideDrawer";
import { NewHireJourneyId } from "models/newHire";
import ActionRowResolutionButtons from "features/Issues/Components/ActionRowResolutionButtons";
import { useNewHiresWithIssuesListQuery } from "features/Issues/hooks";
import useSearchParam from "hooks/useSearchParam";
import NewHireJourneySelect from "components/Form/Select/NewHireJourneySingleSelect";
import { Text12 } from "components/Text";
import { DisplayRecipients } from "../IndividualIssueActionTable/Components";

const columnHelper = createColumnHelper<BaseAssignedAction | AssignedAction>();

const createColumnDefs = (
  createExpandClickHandlerByCombinedId: (combinedId: CombinedActionId) => (e: React.MouseEvent<HTMLElement>) => void,
) => [
    columnHelper.accessor((row) => row, {
      id: "actionType",
      header: "Type",
      cell: (row) => (
        <ActionTypeTag actionType={row.getValue()?.actionType} />
      ),
      minSize: 24,
      maxSize: 24,
      size: 24,
      meta: {
        sx: {
          paddingLeft: 2,
          paddingRight: 0,
        },
      },
    }),
    columnHelper.accessor((row) => row, {
      id: "triggerAt",
      header: "Scheduled",
      cell: (row) => <Text12>{basicShortDate(combinedActionDateTime(row.getValue()))}</Text12>,
      minSize: 79,
      maxSize: 79,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
      id: "name",
      header: "Name",
      cell: (row) => (
        <Box width="fit-content">
          <InternalLink
            to={assignedActionPathById(row.getValue()?.id)}
            textDecoration={row.getValue()?.status === "skipped" ? "line-through" : "none"}
            width="fit-content"
          >
            <Text12 wordBreak="break-word">
              {row.getValue()?.name}
            </Text12>
          </InternalLink>
        </Box>
      ),
      maxSize: 200,
      minSize: 200,
      size: 200,
      enableSorting: false,
      meta: {
        sx: {
          paddingLeft: 0,
        },
      },
    }),
    columnHelper.accessor((row) => row, {
      id: "target",
      header: "To",
      cell: (row) => <DisplayRecipients action={row.getValue()} />,
      minSize: 28,
      maxSize: 28,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
      id: "issuesCount",
      header: "",
      cell: (row) => (
        <Flex width="fit-content" justify="flex-start">
          <BlockedOrReadyTag issuesCount={row.getValue()?.issues?.length} action={row.getValue()} />
        </Flex>
      ),
      minSize: 99,
      maxSize: 99,
      meta: { headerHStackProps: { justifyContent: "center" } },
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
      id: "moreOptions",
      header: "",
      enableSorting: false,
      cell: (row) => (
        <ActionRowResolutionButtons
          createExpandClickHandlerByCombinedId={createExpandClickHandlerByCombinedId}
          action={row.getValue()}
        />
      ),
    }),
  ];

export function ErroredActionsByNewHireTable({ newHireId }: { newHireId: NewHireJourneyId }) {
  const [searchText, setSearchText] = useState("");
  const { isOpen, onClose, selectedActionId, createExpandClickHandlerByCombinedId } = useAssignedActionSideDrawer();

  const filters = useMemo(() => {
    const pageFilters: FilterMeta[] = [];
    if (newHireId) {
      pageFilters.push({
        fieldName: "scheduled_workflows.onboarding_journey_id",
        value: newHireId,
        operator: "=",
      });
    }
    return pageFilters;
  }, [newHireId]);

  const additionalQueries = useMemo(() => {
    const queries: Record<string, string> = {};
    if (searchText && searchText.length > 2) {
      queries.q = searchText;
    }
    return queries;
  }, [searchText]);
  const tableProps: GenericTableProps<AssignedAction | BaseAssignedAction> = {
    fetchData: (query, signal) => apiClient
      .performRequest<ResponseModel<AssignedAction[]>>(`${API_ROUTES.assignedActions.issues.newPast}${query}`, { signal })
      .then((response) => response.payload),
    queryKey: (queryString: string) => (queryString ? assignedActionKeys.actionsWithIssue(queryString) : assignedActionKeys.actionsWithIssues()),
    additionalQueries,
    columns: createColumnDefs(createExpandClickHandlerByCombinedId),
    meta: {
      pagination: { perPage: 20, initialPage: 1 },
      filters,
    },
    textWrap: true,
    emptyStateComponent: <RandomEmptyIssueState />,
    tableLayout: "fixed",
  };

  return (
    <>
      <Flex justify="space-between">
        <SearchBar
          minWidth="500"
          name="actionsSearch"
          onInput={setSearchText}
          my="4"
        />
      </Flex>
      <GenericTable
        sx={TABLE_STYLE_OVERRIDES}
        containerProps={{ sx: TABLE_BORDER_CONTAINER_PROPS }}
        {...tableProps}
      />
      <AssignedActionSideDrawer isOpen={isOpen} onClose={onClose} resourceId={selectedActionId} />
    </>
  );
}

export function ErroredActionsWithIssuesTable() {
  const [searchText, setSearchText] = useState("");
  const { isOpen, onClose, selectedActionId, createExpandClickHandlerByCombinedId } = useAssignedActionSideDrawer();
  const { value: selectedNewHireId, setSearchParam: setSelectedNewHireId } = useSearchParam("newHireId");

  const { data: initialNewHireJourneys } = useNewHiresWithIssuesListQuery();

  const filters = useMemo(() => {
    const pageFilters: FilterMeta[] = [];
    if (selectedNewHireId) {
      pageFilters.push({
        fieldName: "scheduled_workflows.onboarding_journey_id",
        value: selectedNewHireId,
        operator: "=",
      });
    }
    return pageFilters;
  }, [selectedNewHireId]);

  const additionalQueries = useMemo(() => {
    const queries: Record<string, string> = {};
    if (searchText && searchText.length > 2) {
      queries.q = searchText;
    }
    return queries;
  }, [searchText]);
  const tableProps: GenericTableProps<AssignedAction> = {
    fetchData: (query, signal) => apiClient
      .performRequest<ResponseModel<AssignedAction[]>>(`${API_ROUTES.assignedActions.issues.newPast}${query}`, signal)
      .then((response) => response.payload),
    queryKey: (queryString: string) => (queryString ? assignedActionKeys.actionsWithIssue(queryString) : assignedActionKeys.actionsWithIssues()),
    additionalQueries,
    columns: createColumnDefs(createExpandClickHandlerByCombinedId),
    meta: {
      pagination: { perPage: 20, initialPage: 1 },
      filters,
    },
    textWrap: true,
    emptyStateComponent: <RandomEmptyIssueState />,
    tableLayout: "fixed",
  };

  return (
    <>
      <Flex justify="space-between">
        <SearchBar
          minWidth="500"
          name="actionsSearch"
          onInput={setSearchText}
          my="4"
        />
        <Flex minWidth="250px" maxWidth="250px">
          <NewHireJourneySelect
            newHireJourneys={initialNewHireJourneys}
            selectedNewHireJourneyId={selectedNewHireId}
            onChange={(e) => setSelectedNewHireId(e as string)}
            placeholder="Filter by New Hire"
            isClearable
            useBasicStyles
          />
        </Flex>
      </Flex>
      <GenericTable
        sx={TABLE_STYLE_OVERRIDES}
        containerProps={{ sx: TABLE_BORDER_CONTAINER_PROPS }}
        {...tableProps}
      />
      <AssignedActionSideDrawer isOpen={isOpen} onClose={onClose} resourceId={selectedActionId} />
    </>
  );
}
