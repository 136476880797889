import {
  Box, Center, Flex, Spinner, Stack, useColorModeValue,
} from "@chakra-ui/react";
import SearchBar from "components/SearchBar";
import { useState } from "react";
import { NewHireJourney } from "models/newHire";
import { ExtendedNewHireJourney } from "models";
import TasksTable from "components/Table/TasksTable";
import SelectableTasksByStatus, { TaskStatusType } from "components/Selectable/SelectableTasksByStatus";
import SelectSupportTeam from "components/Select/SelectSupportTeam";

interface TasksTabProps {
  newHireJourney: ExtendedNewHireJourney;
}

export default function TasksTab({ newHireJourney }: TasksTabProps) {
  const [searchText, setSearchText] = useState("");
  const [taskStatusType, setTaskStatusType] = useState<TaskStatusType>("open");
  const [filterByUserId, setFilterByUserId] = useState<string>();
  const searchBorder = useColorModeValue("border.dark", "border.light");

  return (
    <Flex direction="column" alignItems="flex-start">
      <Box mt="4px">
        <Flex align="self-start" w="100%" mb="4">
          <Flex direction="column" w="100%">
            <Stack
              direction={{ base: "column", md: "row" }}
              w="100%"
              justify={{ base: "flex-start", xl: "space-between" }}
              alignItems={{ base: "flex-start", md: "center" }}
              wrap="wrap"
              spacing="4"
              shouldWrapChildren
            >
              <SearchBar
                border={`1px solid var(--stroke-vibes, ${searchBorder})`}
                minWidth="250px"
                maxWidth="300px"
                name="tasksSearch"
                onInput={setSearchText}
                height="48px"
                mt="4"
                inputProps={{ height: "48px" }}
              />
              <Flex mt="4" align="center" h="100%">
                <SelectSupportTeam newHireJourney={newHireJourney} setFilterByUserId={setFilterByUserId} height="48px" />
              </Flex>
              <Box mt="4" ml="0">
                <SelectableTasksByStatus
                  setTaskStatusType={setTaskStatusType}
                  taskStatusType={taskStatusType}
                />
              </Box>
            </Stack>
          </Flex>
        </Flex>
        {!newHireJourney ? (
          <Center>
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
          </Center>
        ) : (
          <TasksTable
            includeCommunications
            newHireJourney={newHireJourney as NewHireJourney}
            filterByUserId={filterByUserId}
            searchText={searchText}
            statusFilter={taskStatusType}
          />
        )}
      </Box>
    </Flex>
  );
}
