import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import {
  Card,
  CardBody,
  Center,
  Spinner,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { useNavbar } from "hooks/useNavbar";
import { useNewHireJourneyDetailQuery } from "features/NewHireJourney/hooks";
import PendingNewHireAlertBanner from "components/AlertBanner/PendingNewHireAlertBanner";
import Schedule from "features/NewHireJourney/Schedule";
import TasksTab from "./Tabs/TasksTab";
import NewHireJourneyTitleBar from "../../../../features/NewHireJourney/Components/NewHiresTitleBar";
import FieldsTab from "./Tabs/FieldsTab";
import ErroredActionsTab from "./Tabs/ErroredActionsTab";
import ActivityFeedTab from "./Tabs/ActivityFeedTab";

function NewHirePage() {
  const { id } = useParams<{ id: string }>();
  const { setBackLocationPath, setBackLocationName, setPageTitle } = useNavbar();
  const location = useLocation();
  const navigate = useNavigate();

  const { data, isLoading } = useNewHireJourneyDetailQuery(id || "");

  useEffect(() => {
    if (data?.user?.fullName && !isLoading) {
      setPageTitle(`New Hire ${data?.user?.fullName ? data.user.fullName : ""}`);
    }
    // setBackLocationPath(newHireJourneysPath());
    // setBackLocationName("New Hires");
    return () => {
      setPageTitle(null);
      setBackLocationName(null);
      setBackLocationPath(null);
    };
  }, [data?.user?.fullName, isLoading, setBackLocationName, setBackLocationPath, setPageTitle]);

  const memoizedTabs = useMemo(
    () => [
      { path: "schedule", label: "Schedule", content: <Schedule newHireJourney={data} isLoading={isLoading} /> },
      { path: "tasks", label: "Tasks", content: <TasksTab newHireJourney={data} /> },
      { path: "fields", label: "Fields", content: <FieldsTab newHireJourney={data} /> },
      { path: "failed-actions", label: "Unsent Actions", content: <ErroredActionsTab newHireId={id} /> },
      { path: "activity-feed", label: "Activity", content: <ActivityFeedTab newHireJourney={data} /> },
    ],
    [data, id, isLoading],
  );

  // Extract the current tab from the URL
  const activeTabPath = location.pathname.split("/").pop();
  const resolvedActiveTabPath = activeTabPath === "list" ? "schedule" : activeTabPath;

  // Find the index of the active tab
  const activeTabIndex = memoizedTabs.findIndex((tab) => tab.path === resolvedActiveTabPath);

  useEffect(() => {
    if (activeTabIndex === -1 && memoizedTabs.length > 0) {
      navigate(`/new-hires/${id}/${memoizedTabs[0].path}`, { replace: true });
    }
  }, [activeTabIndex, navigate, id, memoizedTabs]);

  // Handle tab change to navigate to the correct route
  const handleTabChange = (index: number) => {
    navigate(`/new-hires/${id}/${memoizedTabs[index].path}`);
  };

  return (
    <AppPage>
      {data?.isPending && (
        <PendingNewHireAlertBanner newHireId={id as string} />
      )}
      <NewHireJourneyTitleBar newHireJourney={data} />
      <Card mt="6">
        <CardBody>
          <Tabs index={activeTabIndex} onChange={handleTabChange} size="lg" variant="underline" isLazy>
            <TabList>
              {memoizedTabs.map(({ path, label }) => (
                <Tab key={path} color="fg.muted">{label}</Tab>
              ))}
            </TabList>
            <TabIndicator />
            <TabPanels>
              {memoizedTabs.map(({ path, content }) => (
                <TabPanel key={path}>
                  {data ? content : (
                    <Center>
                      <Spinner thickness="4px" size="xl" />
                    </Center>
                  )}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </CardBody>
      </Card>
    </AppPage>
  );
}

export default React.memo(NewHirePage);
