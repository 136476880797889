/* eslint-disable no-nested-ternary */
import { Issue } from "models/issue";
import { useAssignedActionDetailQuery } from "features/AssignedAction/hooks";
import { isInPast } from "helpers/time";
import { OnlyAction, OnlyIssue } from "models/resolutions";
import { memo, useCallback } from "react";
import IndividualIssueActionTable from "features/Issues/Table/IndividualIssueActionTable";
import { timingIssueAllActionsSentOrSkipped } from "features/Issues/helpers";
import { invalidateIssueQueries, updateIssueActionList, useIssueContextQuery } from "features/Issues/hooks";
import { useNonTimingIssueCountByActionId } from "features/Issues/Resolutions/useActionIssueValue";
import { useQueryClient } from "@tanstack/react-query";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { Box } from "@chakra-ui/react";
import SentStatus from "components/Status/SentStatus";
import SkippedStatus from "components/Status/SkippedStatus";
import { PrimarySendNowButton } from "features/Issues/Components/Button/SendNowButton";
import { GraySkipButton } from "features/Issues/Components/Button/SkipButton";
import UpdateTriggerAtForm from "features/AssignedAction/Components/UpdateTriggerAtForm";

const MemoizedIndividualIssueActionTable = memo(IndividualIssueActionTable);

export function useTimingResolutionValue({ issue }: OnlyIssue) {
  const issueContextQueryResult = useIssueContextQuery(issue, {
    enabled: !!issue?.id,
    includeActions: true,
  });
  return timingIssueAllActionsSentOrSkipped(issueContextQueryResult);
}

export function useTimingActionValue({ action }: OnlyAction) {
  const { data: assignedAction } = useAssignedActionDetailQuery(action?.id, { enabled: !!action?.id });
  let value = false;
  if (["processed", "skipped", "removed"].includes(assignedAction?.status || "")) {
    value = true;
  } else if (assignedAction?.trigger.data?.datetime && !isInPast(assignedAction?.trigger.data?.datetime)) {
    value = true;
  }
  return value;
}

export function TimingIssueModalInputFields({ issue }: { issue: Issue }) {
  return <MemoizedIndividualIssueActionTable issue={issue} />;
}

export function IndividualActionTimingIssueModalInputFields({ issue, action }: { issue: Issue, action: AssignedAction }) {
  const { count: issueCount } = useNonTimingIssueCountByActionId(action?.id);
  const queryClient = useQueryClient();

  const onSuccess = useCallback((resp: any) => {
    if (issue?.id) {
      updateIssueActionList({ queryClient, issueId: issue?.id, action: resp });
      invalidateIssueQueries(queryClient, issue);
    }
  // only need to change this if the issue is a different issue, which it should never be
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issue?.id, queryClient]);

  if (!action) {
    return null;
  }
  return action?.status === "skipped" ? (
    <Box display="flex" alignItems="center" gap="2">
      <SkippedStatus actionType={action.actionType} />
      <GraySkipButton onSuccess={onSuccess} action={action as AssignedAction} />
    </Box>
  ) : action?.status === "processed" ? (
    <Box display="flex" alignItems="center" gap="2">
      <SentStatus actionType={action.actionType} />
    </Box>
  ) : (
    <Box display="flex" alignItems="center" gap="2">
      <UpdateTriggerAtForm action={action} label="Send date" usePortal={false} />
      <PrimarySendNowButton isDisabled={issueCount !== undefined && issueCount !== 0} onSuccess={onSuccess} minWidth="96px" action={action} />
      <GraySkipButton onSuccess={onSuccess} action={action} />
    </Box>
  );
}
