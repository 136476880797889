import { GrayTableButton } from "components/Button/TableButton";
import { CombinedActionId } from "models/automation/combinedAction";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { combinedActionPathById, isCombinedActionId } from "./helpers";

interface ViewCombinedActionButtonProps extends PropsWithChildren {
  combinedActionId: CombinedActionId | undefined;
}

export default function ViewCombinedActionButton({ combinedActionId, children }: ViewCombinedActionButtonProps) {
  const navigate = useNavigate();
  if (!combinedActionId) return "";
  if (!isCombinedActionId(combinedActionId)) {
    throw new Error(`Invalid combined action id ${combinedActionId} - missing prefix`);
  }
  return (
    <GrayTableButton onClick={() => navigate(combinedActionPathById(combinedActionId))}>
      {children}
    </GrayTableButton>
  );
}
