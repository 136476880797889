/* eslint-disable import/prefer-default-export */

import { EmailRecipient } from "models/automation";
import { AssignedAction } from "models/automation/scheduledWorkflow";

export function emailTagId(recipient: EmailRecipient) {
  const id = recipient?.customEmail ? recipient.customEmail : `${recipient?.recipient?.emailType}-${recipient?.recipient?.targetId}`
  return id;
}

export function isProcessedOrSkipped(action: AssignedAction | undefined) {
  return ["processed", "skipped"].includes(action?.status || "");
}
