/* eslint-disable import/prefer-default-export */

import {
  Text, TextProps, Link, useColorModeValue,
} from "@chakra-ui/react";
import ReceiverLinkOrTBD from "components/Table/CellDisplayHelpers";
import { EMOJI_ICONS } from "definitions/constants/icons";
import { PEOPLE_TEAM_ROUTES, SHARED_ROUTES } from "definitions/constants/routeConstants";
import { ActionTriggerAndTimingText, ActionTriggerText } from "features/Action/DisplayInfo";
import { display12hrTimeOfDay, getLocalTimezone, relativeDateTimeWithTimezone } from "helpers/time";
import { actionTargetText, actionableTypeConverter, emailTypeConverter } from "helpers/workflowActionableConverters";
import { AssignedAction } from "models/automation/scheduledWorkflow";
// needed to import moment.tz to get moment().tz to work
import "moment-timezone";
import moment from "moment";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { DEFAULT_TIME_OF_DAY } from "definitions/constants/trigger";
import { HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";

interface AssignedActionDisplayProps extends TextProps {
  assignedAction: AssignedAction;
}

interface AssignedActionDisplayWithLinkProps extends AssignedActionDisplayProps {
  linkProps?: object;
}

export function AssignedActionTypeText({ assignedAction, ...rest }: AssignedActionDisplayProps) {
  const emailType = assignedAction?.actionType === "email" ? ` (${emailTypeConverter(assignedAction?.emailType)})` : "";
  const readableActionType = actionableTypeConverter(assignedAction);
  const emoji = EMOJI_ICONS[assignedAction?.actionType];
  const additionalText = assignedAction?.actionType === "task_notifier" || assignedAction?.actionType === "task_reminder" ? (
    <>
      {" "}
      for Task
      {" "}
      <Link as={RouterLink} to={{ pathname: generatePath(SHARED_ROUTES.tasks.show, { id: assignedAction.taskId }) }}>
        {assignedAction?.name}
      </Link>
    </>
  ) : (
    ""
  );
  return (
    <Text fontSize="md" textAlign="center" color="chakra-body-text" {...rest}>
      {`${emoji} `}
      <Text as="span" fontWeight="medium">
        {readableActionType}
      </Text>
      {emailType}
      {additionalText}
    </Text>
  );
}

export function AssignedActionOriginalAction({
  assignedAction,
  linkProps,
  ...rest
}: AssignedActionDisplayWithLinkProps) {
  return (
    <Text fontSize="md" textAlign="left" color="chakra-body-text" {...rest}>
      {`Created from Template `}
      <Text as="span" fontWeight="medium">
        <Link
          as={RouterLink}
          to={{
            pathname: generatePath(PEOPLE_TEAM_ROUTES.actions.show, { id: assignedAction?.workflowAction?.id || "" }),
          }}
          {...linkProps}
        >
          {assignedAction.workflowAction.actionable.name}
        </Link>
      </Text>
    </Text>
  );
}

AssignedActionOriginalAction.defaultProps = {
  linkProps: {},
};

export function AssignedActionTargetText({
  assignedAction,
  linkProps,
  ...rest
}: AssignedActionDisplayProps & { linkProps?: object }) {
  const valueColor = useColorModeValue("valueBlack.DEFAULT", "var(--chakra-colors-whiteAlpha-900)");
  const additionalText = actionTargetText(assignedAction.workflowAction);
  return (
    <HorizontalGreyBlackField label="To" {...rest}>
      <Text color={valueColor} fontSize="sm" fontWeight="normal" lineHeight="1">
        <ReceiverLinkOrTBD receiver={assignedAction?.targetable} {...linkProps} />
        {additionalText && ` (${additionalText})`}
      </Text>
    </HorizontalGreyBlackField>
  );
}

AssignedActionTargetText.defaultProps = {
  linkProps: {},
};

function scheduledAtOriginalTimezone(assignedAction: AssignedAction) {
  if (!assignedAction || assignedAction?.trigger?.type !== "date_time" || !assignedAction?.timezone) {
    return "";
  }
  if (assignedAction?.timezone === getLocalTimezone()) {
    return ` (${moment.tz(assignedAction?.timezone).format("z")})`;
  }
  return ` (${moment(assignedAction?.trigger?.data?.datetime).tz(assignedAction?.timezone).format("h:mm a z")})`;
}

function attemptedAtOriginalTimezone(assignedAction: AssignedAction) {
  if (!assignedAction || !assignedAction?.attemptedAt || !assignedAction?.timezone) {
    return "";
  }
  if (assignedAction?.timezone === getLocalTimezone()) {
    return ` (${moment.tz(assignedAction?.timezone).format("z")})`;
  }
  return ` (${moment(assignedAction?.attemptedAt).tz(assignedAction?.timezone).format("h:mm a z")})`;
}

export function AssignedActionScheduledAt({
  assignedAction,
  ...rest
}: {
  assignedAction: AssignedAction;
}) {
  const { trigger } = assignedAction;

  if (assignedAction.status === "skipped") {
    return null;
  }

  if (
    assignedAction?.workflowAction?.actionable?.trigger?.data?.timezoneType === "target_timezone"
    && !assignedAction.targetable
  ) {
    const timeOfDay = assignedAction?.workflowAction?.actionable?.trigger?.data?.timeOfDay || DEFAULT_TIME_OF_DAY;
    const triggerText = `${display12hrTimeOfDay(timeOfDay)} (Recipient's Timezone)`;
    return (
      <HorizontalGreyBlackField label="Scheduled" {...rest}>
        {triggerText}
      </HorizontalGreyBlackField>
    );
  }

  const attemptedAtWithOriginalTimezone = attemptedAtOriginalTimezone(assignedAction);
  const scheduledAtWithOriginalTimezone = scheduledAtOriginalTimezone(assignedAction);
  if (assignedAction.status === "processed") {
    return (
      <HorizontalGreyBlackField label="Sent At">
        {`${relativeDateTimeWithTimezone(assignedAction?.attemptedAt)}${attemptedAtWithOriginalTimezone}`}
      </HorizontalGreyBlackField>
    );
  }
  if (["error"].includes(assignedAction?.status)) {
    const scheduledText = assignedAction?.attemptedAt
      ? `${EMOJI_ICONS.failed} ${relativeDateTimeWithTimezone(assignedAction?.attemptedAt)}`
      : `${relativeDateTimeWithTimezone(assignedAction?.trigger?.data?.datetime)}`;
    // const backgroundColor = assignedAction?.attemptedAt ? {} : scheduledAtBackgroundColor(assignedAction);
    const helperText = assignedAction?.attemptedAt ? "Attempted At" : "Scheduled At";
    const originalTimezone = assignedAction?.attemptedAt
      ? attemptedAtWithOriginalTimezone
      : scheduledAtWithOriginalTimezone;

    return (
      <HorizontalGreyBlackField label={helperText}>
        {`${scheduledText}${originalTimezone}`}
      </HorizontalGreyBlackField>
    );
  }
  const attempted = !!assignedAction?.attemptedAt;

  switch (trigger.type) {
    case "dependency":
      return (
        <HorizontalGreyBlackField label={attempted ? "Attempted At" : "Scheduling TBD"}>
          {`${relativeDateTimeWithTimezone(assignedAction?.attemptedAt) || "tbd"}${attemptedAtWithOriginalTimezone}`}
        </HorizontalGreyBlackField>
      );
    case "date_time":
      return (
        <HorizontalGreyBlackField label="Scheduled At">
          {`${relativeDateTimeWithTimezone(assignedAction?.trigger?.data?.datetime) || ""}${scheduledAtWithOriginalTimezone}`}
        </HorizontalGreyBlackField>
      );
    default:
      return null;
  }
}

export function assignedActionSentOrSkippedAt(assignedAction: AssignedAction) {
  const attemptedAtWithOriginalTimezone = attemptedAtOriginalTimezone(assignedAction);
  const scheduledAtWithOriginalTimezone = scheduledAtOriginalTimezone(assignedAction);

  if (assignedAction?.status === "processed") {
    return `${relativeDateTimeWithTimezone(assignedAction?.attemptedAt)}${attemptedAtWithOriginalTimezone}`;
  }

  if (assignedAction?.status === "skipped") {
    const scheduledText = assignedAction?.attemptedAt
      ? `${relativeDateTimeWithTimezone(assignedAction?.attemptedAt)}`
      : `${relativeDateTimeWithTimezone(assignedAction?.trigger?.data?.datetime)}`;
    const originalTimezone = assignedAction?.attemptedAt
      ? attemptedAtWithOriginalTimezone
      : scheduledAtWithOriginalTimezone;

    return `${scheduledText}${originalTimezone}`;
  }

  return null;
}

export function AssignedActionTriggerText({ assignedAction, ...rest }: { assignedAction: AssignedAction }) {
  if (assignedAction?.trigger?.data?.customized !== true) {
    return ActionTriggerText({ action: assignedAction?.workflowAction, ...rest });
  }
  return (
    <HorizontalGreyBlackField label="Trigger Text" {...rest}>
      Custom Date & Time
    </HorizontalGreyBlackField>
  );
}

export function AssignedActionTriggerAndTimingText({ assignedAction, ...rest }: { assignedAction: AssignedAction }) {
  if (assignedAction?.trigger?.data?.customized !== true) {
    return ActionTriggerAndTimingText({ action: assignedAction?.workflowAction, ...rest });
  }
  return (
    <Text fontSize="md" textAlign="center" color="chakra-body-text" {...rest}>
      {`${EMOJI_ICONS.triggerAt} Custom Date & Time`}
    </Text>
  );
}
