import { IssueType, Issue, IssueContextQueryResult } from "models/issue";
import { ResolutionInfo } from "models/resolutions";
import { missingAttributeResolution } from "./definitions/MissingAttribute";
import { missingEmailResolution } from "./definitions/MissingEmail";
import { missingMessageServiceResolution } from "./definitions/MissingMessageService";
import { missingMessageServiceUserResolution } from "./definitions/MissingMessageServiceUser";
import { referenceEventOverlapResolution } from "./definitions/ReferenceEventOverlap";
import { triggerAtInPastResolution } from "./definitions/TriggerAtInPast";
import { unassignedRoleResolution } from "./definitions/UnassignedRole";
import { missingChannelResolution } from "./definitions/MissingChannel";

export const ISSUE_RESOLUTION_MAP: Record<IssueType, (issue: Issue, issueContext: IssueContextQueryResult) => ResolutionInfo> = {
  missing_channel: missingChannelResolution,
  missing_attribute: missingAttributeResolution,
  missing_email: missingEmailResolution,
  missing_message_service: missingMessageServiceResolution,
  missing_message_service_user: missingMessageServiceUserResolution,
  reference_event_overlap: referenceEventOverlapResolution,
  trigger_at_in_past: triggerAtInPastResolution,
  unassigned_role: unassignedRoleResolution,
};

export function issueResolution(issue: Issue, issueContext: IssueContextQueryResult): ResolutionInfo | Record<string, never> {
  const localIssueResolution = ISSUE_RESOLUTION_MAP[issue.issueType];
  if (issueContext.isLoading) {
    return {};
  }
  return localIssueResolution(issue, issueContext);
}
