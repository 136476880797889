import { defineStyle } from "@chakra-ui/react";

export const primary = defineStyle({
  bg: "brand.500",
  color: "white",
  _hover: {
    bg: "brand.300",
  },
  focus: {
    bg: "brand.900",
    color: "white",
  },
  _disabled: {
    bg: "tagGrey.text",
    color: "white",
    _hover: {
      bg: "tagGrey.text",
      color: "white",
    },
  },
  _dark: {
    bg: "indigo.200",
    color: "notBlack.500",
    _hover: {
      bg: "indigo.300",
      color: "darkModeHoverColor.DEFAULT",
    },
    _focus: {
      bg: "indigo.400",
      color: "darkModeHoverColor.DEFAULT",
    },
    _disabled: {
      bg: "disabledGray.DEFAULT",
      color: "notBlack.500",
      _hover: {
        bg: "disabledGray.DEFAULT",
        color: "notBlack.500",
      },
    },
  },
});

export const secondary = defineStyle({
  color: "brand.400",
  _hover: {
    color: "brand.700",
    bg: "brand.50",
  },
  focus: {
    color: "brand.900",
    bg: "brand.100",
  },
  _disabled: {
    color: "tagGrey.text",
    borderColor: "tagGrey.text",
    bg: "transparent",
    _hover: {
      color: "tagGrey.text",
      borderColor: "tagGrey.text",
      bg: "transparent",
    },
  },
  _dark: {
    color: "indigo.200",
    _hover: {
      bg: "indigo.300",
      color: "indigo.100",
    },
    _focus: {
      bg: "brand.300",
      color: "indigo.100",
    },
    _disabled: {
      color: "disabledGray.DEFAULT",
      borderColor: "tagGrey.text",
      bg: "transparent",
      _hover: {
        color: "disabledGray.DEFAULT",
        borderColor: "tagGrey.text",
        bg: "transparent",
      },
    },
  },
});
