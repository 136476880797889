import { assignedActionPathById, issuePathById, newHireJourneyPathById } from "helpers/url";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { NewHireJourneyId } from "models/newHire";
import { BrandTag, GrayTag, RedTag } from "components/Tag/ColorOutineTag";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { useNonTimingIssueCountByActionId } from "../Resolutions/useActionIssueValue";
import { useIssuesByNewHireIdQuery } from "../hooks";

interface NumberOfIssuesByNewHireTagProps {
  newHireId: NewHireJourneyId;
}

export function NumberOfIssuesByNewHireTag({ newHireId }: NumberOfIssuesByNewHireTagProps) {
  const { data: issues } = useIssuesByNewHireIdQuery(newHireId);
  const navigate = useNavigate();

  if (!newHireId || !issues) {
    return null;
  }
  const singular = issues?.length === 1 ? "" : "s";
  return !issues?.length ? (
    <GrayTag>No Isues</GrayTag>
  ) : (
    <RedTag
      cursor="pointer"
      onClick={() => navigate(issues?.length ? issuePathById(issues[0]?.id) : newHireJourneyPathById(newHireId))}
    >
      {`${issues?.length} issue${singular}`}
    </RedTag>
  );
}

interface BlockedOrReadyTagProps {
  action: AssignedAction | undefined;
}

export function BlockedOrReadyTag({ action }: BlockedOrReadyTagProps) {
  const { count, isLoading } = useNonTimingIssueCountByActionId(action?.id);
  if (["processed", "skipped"].includes(action?.status)) {
    return null;
  }
  if (action?.id === undefined || count === undefined || !!isLoading) return <GrayTag>Loading...</GrayTag>;
  return count === 0 ? (
    <BrandTag>Ready</BrandTag>
  ) : (
    <RouterLink to={assignedActionPathById(action?.id)}>
      <RedTag>{`${count} Issue${count === 1 ? "" : "s"}`}</RedTag>
    </RouterLink>
  );
}
