import {
  Box, BoxProps, Container, Flex, Spinner, useColorModeValue,
} from "@chakra-ui/react";
import { SIDEBAR_WIDTH } from "definitions/constants/styling";

import Navbar from "layouts/Navbar";
import Sidebar from "layouts/Sidebar/Sidebar";
import { Suspense } from "react";

interface AppPageProps extends BoxProps { }

export default function AppPage({ children = undefined, ...rest }: AppPageProps) {
  const bgColor = useColorModeValue("creamBg.opacity10", "gray.500");
  return (
    // FIXME: Add some kind of generic skeleton loader instead of Spinner
    <Suspense fallback={<Spinner />}>
      <Box minH="100vh" maxW="100vw" position="relative">
        <Flex height="100vh" id="app-container">
          <Box
            w={`var(--chakra-sizes-${SIDEBAR_WIDTH})`}
            bg="gray.900"
            color="white"
            fontSize="sm"
            height="100vh"
            overflowY="scroll"
          >
            <Sidebar />
          </Box>
          <Container
            height="100vh"
            overflowY="scroll"
            // max width is 100% minus the size of the sidebar
            maxWidth={`calc(100% - var(--chakra-sizes-${SIDEBAR_WIDTH}))`}
            bg={bgColor}
            flex="1"
            display="flex"
            flexDirection="column"
            px="0"
          >
            <Navbar />
            <Box px="6" height="100%" color={useColorModeValue("gray.200", "gray.700")} maxWidth="full" {...rest}>
              {children}
            </Box>
          </Container>
        </Flex>
      </Box>
    </Suspense>
  );
}
