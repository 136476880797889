import { LinkProps, Link as RouterLink } from "react-router-dom";

interface LinkifyProps extends Omit<LinkProps, "to"> {
  linkTo?: string;
}

export default function Linkify({ children, linkTo, ...rest }: LinkifyProps) {
  return linkTo ? <RouterLink to={linkTo} {...rest}>{children}</RouterLink> : children;
}

Linkify.defaultProps = {
  linkTo: undefined,
};
