import SelectableScheduleType, { ScheduleType } from "components/Selectable/SelectableScheduleType";
import { ExtendedNewHireJourney } from "models";
import { useCallback } from "react";
import { Button, Flex, Stack } from "@chakra-ui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { newHireJourneyPathById } from "helpers/url";
import StartStopAutomationsButton from "components/NewHireJourney/StartStopAutomationsButton";
import AddNewPathModal from "components/Path/AddNewPathModal";
import { AssignedPath } from "models/automation/scheduledWorkflow";
import { FiPlus } from "react-icons/fi";
import ActionsByList from "./ActionsByList";
import ActionsByStage from "./ActionsByStage";

interface ScheduleProps {
  newHireJourney: ExtendedNewHireJourney;
  isLoading: boolean;
}

export default function Schedule({ newHireJourney, isLoading }: ScheduleProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { id: newHireId } = useParams<{ id: string }>();
  const lastPathSegment = location.pathname.split("/").pop();
  const resolvedLastPathSegment = lastPathSegment === "schedule" ? "stages" : lastPathSegment;

  const newHireJourneySchedulePath = newHireJourneyPathById(newHireId, "schedule");

  const setScheduleType = useCallback((value: ScheduleType) => (
    value === "stages" ? navigate(newHireJourneySchedulePath) : navigate(`${newHireJourneySchedulePath}/list`)
  ), [navigate, newHireJourneySchedulePath]);

  return (
    <Stack>
      <Flex w="100%" justify="space-between">
        <SelectableScheduleType scheduleType={resolvedLastPathSegment} setScheduleType={setScheduleType} />
        <Flex gap="1">
          <StartStopAutomationsButton borderRadius="md" size="sm" newHireJourney={newHireJourney} />
          <AddNewPathModal
            newHireJourneyId={newHireJourney?.id}
            currentWorkflowIds={(newHireJourney?.assignedPaths || []).map(
              (assignedPath: AssignedPath) => assignedPath.workflow.id,
            )}
          >
            <Button variant="camino-secondary" leftIcon={<FiPlus />} size="sm">
              Add Path(s)
            </Button>
          </AddNewPathModal>
        </Flex>
      </Flex>
      {lastPathSegment === "list" ? (
        <ActionsByList newHireJourney={newHireJourney} />
      ) : (
        <ActionsByStage
          newHireJourney={newHireJourney}
          isLoading={isLoading}
          showImmediateDate
        />
      )}
    </Stack>
  );
}
