import { Button, ButtonProps, useTheme } from "@chakra-ui/react";
import { primary, secondary } from "theme/button";

export function PrimaryButton({ children, ...rest }: ButtonProps) {
  return (
    <Button
      variant="solid"
      sx={primary}
      {...rest}
    >
      {children}
    </Button>
  );
}

export function SecondaryButton({ children, ...rest }: ButtonProps) {
  return (
    <Button
      variant="outline"
      sx={secondary}
      {...rest}
    >
      {children}
    </Button>
  );
}
