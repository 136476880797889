import {
  Card,
  CardBody,
  CardHeader,
  Flex,
} from "@chakra-ui/react";
import { ASSIGNED_ACTION_STATUS_TYPES } from "definitions/constants";
import AssignedActionMenuButton from "features/AssignedAction/AssignedActionMenuButton";
import { ActionStatusTag } from "components/Tag/Status/ActionStatusTag";
import ActionableContent from "components/ActionCard/ActionableContent";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { ListIssuesByAction } from "features/Issues/Components/ListIssuesInfo";
import ActionNameAndTypeHeader from "../ActionNameAndTypeHeader";
import EmailOverview from "./EmailOverview";

export default function EmailPageInfo({ action }: { action: AssignedAction }) {
  return (
    <Card px="8">
      <CardHeader mt="4" pb={0} display="flex" justifyContent="space-between" flexDirection="row">
        <Flex align="center" gap="4" w="100%">
          <ActionNameAndTypeHeader action={action} />
          <ActionStatusTag action={action} mt="3px" />
        </Flex>
        {action?.status !== ASSIGNED_ACTION_STATUS_TYPES.PROCESSED ? (
          <Flex>
            <AssignedActionMenuButton assignedAction={action} editRedirect={false} />
          </Flex>
        ) : null}
      </CardHeader>
      <CardBody>
        <EmailOverview action={action} />
        <ListIssuesByAction actionId={action?.id} />
        <ActionableContent content={action} />
      </CardBody>
    </Card>
  );
}
