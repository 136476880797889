import { Flex, Text } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";

interface ExpandRowButtonProps extends PropsWithChildren {
  onOpen: (e: React.MouseEvent<HTMLElement>) => void;
}

export default function ExpandRowButton({ onOpen, children }: ExpandRowButtonProps) {
  return (
    <Flex
      sx={{
        cursor: "pointer",
        color: "helperText.DEFAULT",
        transition: "color 250ms",
        marginRight: "2",
        minWidth: "52px",
      }}
      _hover={{
        color: "brand.500",
      }}
      align="center"
      onClick={onOpen}
    >
      <MdOutlineKeyboardArrowLeft />
      <Text>{children}</Text>
    </Flex>
  );
}
