import {
  Box, Button, Input, InputGroup, InputLeftElement, InputRightElement, useTheme,
} from "@chakra-ui/react";
import { TextInputFieldProps } from "components/Form/typings";
import FormField from "../FormField";

export default function TextInputField({
  name,
  label,
  register,
  placeholder,
  helperText,
  errors,
  required,
  type,
  textTransform,
  leftIcon,
  rightButton,
  defaultValue: _,
  variant,
  formFieldProps = {},
  inputGroupProps = {},
  ...props
}: TextInputFieldProps & { inputGroupProps?: object, rightButton?: { width: string | number; text: string; onClick: () => void } }) {
  const theme = useTheme();
  // Accessing default variant from the theme
  const formVariant = variant || theme.components.Form.defaultProps.variant;

  return (
    <FormField
      name={name}
      label={label}
      errors={errors}
      helperText={helperText}
      required={required}
      variant={formVariant}
      {...formFieldProps}
    >
      <InputGroup {...inputGroupProps}>
        {leftIcon && (
          <InputLeftElement>
            <Box className="left-box-icon" as={leftIcon} />
          </InputLeftElement>
        )}
        <Input
          textTransform={textTransform}
          type={type}
          placeholder={["floating", "flushedFloating"].includes(formVariant) ? " " : placeholder}
          variant={formVariant}
          {...register(name)}
          {...props}
        />
        {rightButton && (
          <InputRightElement width={rightButton.width}>
            <Button colorScheme="gray" h="1.75rem" size="sm" onClick={rightButton.onClick}>
              {rightButton.text}
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
    </FormField>
  );
}

TextInputField.defaultProps = {
  rightButton: null,
  inputGroupProps: {},
};
