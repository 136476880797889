import { useClipboard, useToast } from "@chakra-ui/react";
import { useCallback } from "react";

export default function useCopyToClipboardWIthToast(text: string | undefined) {
  const { onCopy: initialOnCopy, hasCopied, setValue } = useClipboard(text || "");
  const toast = useToast();

  const onCopy = useCallback(
    () => {
      initialOnCopy();
      toast({ title: `${text} Copied to clipboard!` });
    },
    [initialOnCopy, text, toast],
  );
  return { onCopy, hasCopied, setValue };
}
