import { Button, Stack } from "@chakra-ui/react";
import ModalFormWrapper from "components/ModalForm/ModalFormWrapper";
import { UseFormReturn } from "react-hook-form";
import { PropsWithChildren } from "react";
import { convertTime } from "helpers/time";
import TriggerDateTime from "./TriggerDateTime";
import { SharedSchema } from "./schema";

interface FormWrapperProps extends PropsWithChildren {
  onClose: () => void;
}

function FormWrapper({ children, onClose }: FormWrapperProps) {
  return (
    <Stack>
      {children}
      <Button onClick={onClose}>Continue</Button>
    </Stack>
  );
}

interface TriggerTimeModalButtonProps {
  form: UseFormReturn<SharedSchema>;
  assignedActionId: string;
}

export default function TriggerTimeModalButton({ assignedActionId, form }: TriggerTimeModalButtonProps) {
  const { getValues } = form;
  const [timeOfDay, timezone] = getValues(["timeOfDay", "timezone"]);
  if (!timeOfDay || !timezone) {
    return null;
  }
  return (
    <ModalFormWrapper
      modalHeader="Sending At"
      button={(
        <Button
          variant="flushedFloating"
          height="32px !important"
          paddingTop="0 !important"
          paddingLeft="0 !important"
          color="valueBlack.DEFAULT"
        >
          {`${convertTime(timeOfDay)} (${timezone})`}
        </Button>
      )}
    >
      <FormWrapper>
        <TriggerDateTime
          form={form}
          assignedActionId={assignedActionId}
        />
      </FormWrapper>
    </ModalFormWrapper>
  );
}
