import {
  Card,
  CardBody,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  TabIndicator,
  useColorModeValue,
} from "@chakra-ui/react";
import useNavbar from "hooks/useNavbar";
import FullPage from "layouts/FullPage";
import { useEffect, useMemo } from "react";
import { ErroredActionsWithIssuesTable } from "features/Issues/Table/ErroredActionsTable";
import { useLocation, useNavigate } from "react-router-dom";
import IssuesByNewHireTable from "features/Issues/Table/IssuesByNewHireTable";

export default function InvertedIssuesPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { setBackLocationPath, setBackLocationName, setPageTitle } = useNavbar();
  const tabColor = useColorModeValue("brand.500", "brand.200");

  useEffect(() => {
    setPageTitle("Issues");
    // setBackLocationName("Home");
    // setBackLocationPath("/");

    return () => {
      setPageTitle(null);
      setBackLocationName(null);
      setBackLocationPath(null);
    };
  }, [setBackLocationName, setBackLocationPath, setPageTitle]);

  const memoizedTabs = useMemo(
    () => [
      { name: "issues", path: "issues", label: "Issues", content: <IssuesByNewHireTable /> },
      { name: "unsent", path: "issues/unsent", label: "Unsent Actions", content: <ErroredActionsWithIssuesTable /> },
    ],
    [],
  );

  // Extract the current tab from the URL
  const activeTabPath = location.pathname.split("/").pop();

  // Find the index of the active tab
  const activeTabIndex = memoizedTabs.findIndex((tab) => tab.name === activeTabPath);

  // Handle tab change to navigate to the correct route
  const handleTabChange = (index: number) => {
    navigate(`/people-team/${memoizedTabs[index].path}`);
  };

  return (
    <FullPage>
      <Card w="100%" mb="4">
        <CardBody>
          <Tabs index={activeTabIndex} onChange={handleTabChange} position="relative" variant="unstyled" isLazy>
            <TabList>
              {memoizedTabs.map(({ path, label }) => (
                <Tab key={path} color={tabColor}>{label}</Tab>
              ))}
            </TabList>
            <TabIndicator mt="-1.5px" bg={tabColor} height="2px" borderRadius="1px" />
            <TabPanels>
              {memoizedTabs.map(({ path, content }) => (
                <TabPanel key={path}>
                  {content}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </CardBody>
      </Card>
    </FullPage>
  );
}
