import { createColumnHelper } from "@tanstack/react-table";
import { Issue } from "models/issue";
import GenericTable, { GenericTableProps } from "components/Table/GenericTable";
import { ResponseModel } from "services/ApiClient/responseModel";
import apiClient from "services/ApiClient";
import { RandomEmptyIssueState } from "components/EmptyComponents/RandomEmptyState";
import { Flex } from "@chakra-ui/react";
import SearchBar from "components/SearchBar";
import { useMemo, useState } from "react";
import { TABLE_BORDER_CONTAINER_PROPS, TABLE_STYLE_OVERRIDES } from "definitions/constants/styling";
import { ResolveNewHireIssuesButton } from "features/Issues/Components/Button/ResolveButton";
import { TableColumnListIssuesByNewHire } from "features/Issues/Components/ListIssuesInfo";
import { NewHireJourney } from "models/newHire";
import { newHireJourneyKeys } from "features/NewHireJourney/hooks";
import { apiNewHireWithActiveIssuesListPath } from "helpers/url";
import { NewHireTag } from "components/Tag/UserTag/RoleTag";
import { StartDateGrayTag } from "components/Tag/StartDateTag";

const columnHelper = createColumnHelper<NewHireJourney>();

const columnDefs = [
  columnHelper.accessor((row) => row, {
    id: "onboardingJourneyId",
    header: "New Hire",
    cell: (info) => <NewHireTag newHire={info?.getValue()} />,
    maxSize: 24,
    minSize: 24,
    size: 24,
  }),
  columnHelper.accessor((row) => row, {
    id: "startDate",
    cell: (row) => <StartDateGrayTag startDate={row.getValue()?.startDate} />,
    header: "Start Date",
    maxSize: 16,
    minSize: 16,
    size: 16,
  }),
  columnHelper.accessor((row) => row, {
    id: "issues",
    header: "Issues",
    cell: (row) => <TableColumnListIssuesByNewHire newHireId={row.getValue()?.id} />,
    enableSorting: false,
    maxSize: 64,
    minSize: 64,
    size: 64,
  }),
  columnHelper.accessor((row) => row, {
    id: "resolution",
    header: "",
    cell: (row) => <ResolveNewHireIssuesButton newHireId={row.getValue()?.id} />,
    enableSorting: false,
    maxSize: 20,
    minSize: 20,
    size: 20,
  }),
];

interface IssuesByNewHireTableProps {
  tableDefaultSort?: { sortBy: string; direction: "asc" | "desc" };
}

export default function IssuesByNewHireTable({
  tableDefaultSort = { sortBy: "startDate", direction: "desc" },
}: IssuesByNewHireTableProps) {
  const [searchText, setSearchText] = useState("");

  const additionalQueries = useMemo(() => {
    const queries: Record<string, string> = {};
    if (searchText && searchText.length > 2) {
      queries.q = searchText;
    }
    return queries;
  }, [searchText]);

  const tableProps: GenericTableProps<NewHireJourney> = {
    fetchData: (queryString, signal) => apiClient.performRequest<ResponseModel<NewHireJourney[]>>(apiNewHireWithActiveIssuesListPath(queryString), { signal })
      .then((response) => response.payload),
    queryKey: newHireJourneyKeys.withActiveIssuesList(),
    additionalQueries,
    columns: columnDefs,
    meta: {
      pagination: { perPage: 20, initialPage: 1 },
      sort: [tableDefaultSort],
    },
    textWrap: true,
    emptyStateComponent: <RandomEmptyIssueState />,
    variant: "simple",
  };

  return (
    <>
      <Flex justify="flex-start" align="center">
        <SearchBar
          minWidth="500"
          name="actionsSearch"
          onInput={setSearchText}
          my="4"
        />
      </Flex>
      <GenericTable
        sx={TABLE_STYLE_OVERRIDES}
        containerProps={{ sx: TABLE_BORDER_CONTAINER_PROPS }}
        {...tableProps}
      />
    </>
  );
}
