import { Button, ButtonProps } from "@chakra-ui/react";

export function GrayTableButton({ children, ...rest }: ButtonProps) {
  return (
    <Button
      colorScheme="gray"
      color="brand.DEFAULT"
      bg="resolveGrey.DEFAULT"
      borderColor="resolveGrey.DEFAULT"
      height="7"
      _hover={{
        bg: "resolveGrey.HOVER",
        borderColor: "resolveGrey.HOVER",
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}

export function PrimaryTableButton({ children, ...rest }: ButtonProps) {
  // const bgColor = useColorModeValue("brand.DEFAULT", "brand.300");
  // const hoverColor = useColorModeValue("resolveBlue.HOVER", "resolveBlue.DARK_HOVER");
  return (
    <Button
      colorScheme="brand"
      height="7"
      _hover={{
        bg: "brand.300",
        borderColor: "brand.300",
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}
