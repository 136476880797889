import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useQueryClient } from "@tanstack/react-query";
import { GrayTableButton } from "components/Button/TableButton";
import { PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import { useCurrentCompanyQuery } from "features/Company/hooks";
import { useSyncChannels, useSyncUsers } from "features/Integrations/hooks";
import { userKeys } from "features/User/hooks";
import { Issue } from "models/issue";
import React, { PropsWithChildren } from "react";

export function RedirectToConnectSlackButtonWrapper({ children }: PropsWithChildren) {
  return React.cloneElement(
    children as JSX.Element,
    {
      onClick: () => window.open(PEOPLE_TEAM_ROUTES.company.integrations.index, "_blank", "rel=noopener noreferrer"),
      rightIcon: <ExternalLinkIcon />,
    },
  );
}

export function RedirectToConnectSlackGrayTableButton({ children }: PropsWithChildren) {
  return (
    <RedirectToConnectSlackButtonWrapper>
      <GrayTableButton>
        Go To Integrations
      </GrayTableButton>
    </RedirectToConnectSlackButtonWrapper>
  );
}

export function RedirectToSearchSlackUsersButtonWrapper({ children }: PropsWithChildren) {
  const { data: company } = useCurrentCompanyQuery();
  if (!company?.messageService?.serviceCompanyId) {
    return null;
  }
  const redirectUrl = `https://app.slack.com/client/${company?.messageService?.serviceCompanyId}/people`;
  return React.cloneElement(
    children as JSX.Element,
    {
      onClick: () => window.open(redirectUrl, "_blank", "rel=noopener noreferrer"),
      rightIcon: <ExternalLinkIcon />,
    },
  );
}

export function RedirectToSearchUsersButton() {
  return (
    <RedirectToSearchSlackUsersButtonWrapper>
      <GrayTableButton width="fit-content">
        Search Slack
      </GrayTableButton>
    </RedirectToSearchSlackUsersButtonWrapper>
  );
}

interface ResyncSlackUsersResolutionButtonProps {
  issue: Issue;
  onSuccess?: () => void;
}

export function ResyncSlackUsersResolutionButton({ issue, onSuccess }: ResyncSlackUsersResolutionButtonProps) {
  const queryClient = useQueryClient();
  const {
    performRequest: syncSlackUsers,
    state: { loading: isLoading },
  } = useSyncUsers({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userKeys.detail(issue?.context?.userId) });
      onSuccess?.();
    },
  });
  return (
    <GrayTableButton
      onClick={() => syncSlackUsers({ issue })}
      isLoading={isLoading}
      loadingText="Syncing Users"
    >
      Resync Slack Users
    </GrayTableButton>
  );
}

export function ResyncSlackChannelsResolutionButton() {
  const {
    performRequest: syncSlackChannels,
    state: { loading: isLoading },
  } = useSyncChannels();
  return (
    <GrayTableButton
      onClick={() => syncSlackChannels()}
      isLoading={isLoading}
    >
      Resync Slack Channels
    </GrayTableButton>
  );
}
