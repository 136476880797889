import { ColumnDef, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useQuery } from "@tanstack/react-query";
import { MetaQuery, ResponseModel } from "services/ApiClient/responseModel";
import { TableProps } from "@chakra-ui/react";
import useQueryString from "./useQueryString";

export interface UseTableQueryProps<T> extends TableProps {
  fetchData: (query: string, signal?: AbortSignal) => Promise<ResponseModel<T[]>>;
  queryKey: string[];
  columns: ColumnDef<T, any>[];
  meta?: MetaQuery;
  additionalQueries?: Record<string, string | undefined | string[]>;
}

export default function useTableQuery<T>({
  fetchData,
  queryKey,
  columns,
  meta,
  additionalQueries,
}: UseTableQueryProps<T>) {
  const {
    queryString, pagination, setPagination, sortBy, setSortBy,
  } = useQueryString(meta, additionalQueries);

  const { data, isLoading, error } = useQuery({
    queryKey: [...queryKey, queryString],
    queryFn: async ({ signal }) => fetchData(queryString, signal),
    placeholderData: (previousData: any) => previousData,
    refetchOnWindowFocus: false,
  });

  const pageCount = data?.meta?.pagination?.totalPages ?? 1;

  const table = useReactTable({
    data: data?.data || [],
    columns,
    pageCount,
    state: {
      pagination,
      sorting: sortBy,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSortBy,
    manualPagination: true,
    manualSorting: true,
    enableSortingRemoval: true,
    enableMultiSort: true,
  });

  return { table, data, isLoading, error };
}
