/* eslint-disable import/prefer-default-export */

const brandStyles = {
  bgColor: "brand.50",
  color: "brand.800",
};

const greenStyles = {
  bgColor: "caminoGreen.50",
  color: "tagGreen.text",
};

const errorStyles = {
  bgColor: "statusRed.bg",
  color: "statusRed.text",
};

const grayStyles = {
  bgColor: "tagGrey.bg",
  color: "tagGrey.text",
};

const blueStyles = {
  bgColor: "dateBlue.background",
  color: "dateBlue.500",
};

const ACTION_STATUS_STYLE_MAP = {
  pending: brandStyles,
  processed: greenStyles,
  error: errorStyles,
  skipped: grayStyles,
  removed: grayStyles,
  immediate: blueStyles,
};

export function toActionStatusStyles(status: keyof typeof ACTION_STATUS_STYLE_MAP): typeof ACTION_STATUS_STYLE_MAP[keyof typeof ACTION_STATUS_STYLE_MAP] {
  if (!(status in ACTION_STATUS_STYLE_MAP)) {
    throw new Error(`Invalid status: ${status}. Expected one of: ${Object.keys(ACTION_STATUS_STYLE_MAP).join(", ")}`);
  }
  return ACTION_STATUS_STYLE_MAP[status as keyof typeof ACTION_STATUS_STYLE_MAP];
}

const ISSUE_STATUS_STYLE_MAP = {
  resolved: greenStyles,
  error: errorStyles,
};

export function toIssueStatusStyles(status: keyof typeof ISSUE_STATUS_STYLE_MAP): typeof ISSUE_STATUS_STYLE_MAP[keyof typeof ISSUE_STATUS_STYLE_MAP] {
  if (!(status in ISSUE_STATUS_STYLE_MAP)) {
    throw new Error(`Invalid status: ${status}. Expected one of: ${Object.keys(ISSUE_STATUS_STYLE_MAP).join(", ")}`);
  }
  return ISSUE_STATUS_STYLE_MAP[status as keyof typeof ISSUE_STATUS_STYLE_MAP];
}
