import { Flex, FlexProps } from "@chakra-ui/react";
import { ExtendedNewHireJourney } from "models";
import { NewHireJourney } from "models/newHire";
import { BuddyInfo, ManagerInfo, PeopleTeamContactInfo } from "./NewHireInfo";

export default function NewHireSupportTeam({ newHire, ...rest }: { newHire: NewHireJourney | ExtendedNewHireJourney | undefined } & FlexProps) {
  return (
    <Flex display="inline-flex" gap="4" {...rest}>
      <ManagerInfo newHire={newHire} />
      <BuddyInfo newHire={newHire} />
      <PeopleTeamContactInfo newHire={newHire} />
    </Flex>
  );
}
