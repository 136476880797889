import { Issue, IssueContextQueryResult, IssueType } from "models/issue";
import { missingAttributeValue } from "./definitions/MissingAttribute";
import { missingEmailValue } from "./definitions/MissingEmail";
import { missingMessageServiceValue } from "./definitions/MissingMessageService";
import { missingMessageServiceUserValue } from "./definitions/MissingMessageServiceUser";
import { unassignedRoleValue } from "./definitions/UnassignedRole";
import { timingIssueAllActionsSentOrSkipped } from "../helpers";
import { useIssueContextQuery } from "../hooks";
import { missingChannelValue } from "./definitions/MissingChannel";

export const ISSUE_VALUE_MAP: Record<IssueType, (input: IssueContextQueryResult) => any> = {
  missing_channel: missingChannelValue,
  missing_attribute: missingAttributeValue,
  missing_email: missingEmailValue,
  missing_message_service: missingMessageServiceValue,
  missing_message_service_user: missingMessageServiceUserValue,
  unassigned_role: unassignedRoleValue,
  reference_event_overlap: timingIssueAllActionsSentOrSkipped,
  trigger_at_in_past: timingIssueAllActionsSentOrSkipped,
};

export function useIssueValue(issue: Issue): { data: any, isLoading: boolean } {
  const issueContextResult = useIssueContextQuery(issue, { includeActions: true });
  if (issueContextResult?.issue) {
    const resolveIssueValue = ISSUE_VALUE_MAP[issueContextResult?.issue?.issueType] || (() => undefined);
    return { data: resolveIssueValue(issueContextResult as IssueContextQueryResult), isLoading: issueContextResult?.isLoading };
  }
  return { data: undefined, isLoading: issueContextResult?.isLoading };
}
