import { Issue, IssueContextQueryResult } from "models/issue";
import { Flex } from "@chakra-ui/react";
import { OnlyIssue, ResolutionInfo } from "models/resolutions";
import { currentCompanyQueryArgs, useCurrentCompanyQuery } from "features/Company/hooks";
import GreenStatusButton from "components/Status/GreenStatusButton";
import { FiCheckCircle } from "react-icons/fi";
import { RedirectToConnectSlackGrayTableButton } from "features/Issues/Components/Button/SlackResolutionButtons";
import { useMissingAttributeValue } from "./MissingAttribute";

export function missingMessageServiceIssueType(issue: Issue) {
  return "Slack Not Connected";
}

function IssueModalInputFields({ issue }: { issue: Issue }) {
  const value = useMissingAttributeValue({ issue });
  return (
    <Flex direction="column" gap="1">
      <RedirectToConnectSlackGrayTableButton />
      {value && <GreenStatusButton icon={FiCheckCircle}>Slack Connected</GreenStatusButton>}
    </Flex>
  );
}

export function useMissingMessageServiceValue({ issue }: OnlyIssue) {
  const { data: company } = useCurrentCompanyQuery();
  return company?.messageService?.serviceCompanyId;
}

export function missingMessageServiceValue(issueContext: IssueContextQueryResult) {
  return issueContext?.company?.messageService?.serviceCompanyId;
}

export function missingMessageServiceResolution(_1: Issue, _2: IssueContextQueryResult): ResolutionInfo {
  return ({
    issueType: "missing_message_service",
    tagText: "Slack not connected",
    title: "Slack is not connected to Camino",
    additionalContext: `Slack is not connected to Camino, and an action requires this. Please go to the integrations sidebar and connect Slack.`,
    inputComponent: IssueModalInputFields,
  });
}

export function missingMessageServiceQueryArgs(_: Issue) {
  return currentCompanyQueryArgs();
}

export function missingMessageServiceContextQueries(_: Issue) {
  return [currentCompanyQueryArgs()];
}
