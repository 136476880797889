import { Tag } from "@chakra-ui/react";
import moment, { Moment } from "moment";

function getColorScheme(today: Moment, startDate: Moment) {
  const nextWeek = moment().add(7, "days");

  if (startDate.isSameOrBefore(today, "day")) {
    return "dateGreen";
  } else if (startDate.isSameOrBefore(nextWeek, "day")) {
    return "dateYellow";
  } else if (startDate.isAfter(nextWeek, "day")) {
    return "dateBlue";
  }
  return "gray";
}

function relativeStartDateLabel(today: Moment, startDate: Moment) {
  const daysDiff = startDate.diff(today, "days");

  if (daysDiff === 0) {
    return "Today";
  } else if (daysDiff === 1) {
    return "Tomorrow";
  } else if (daysDiff > 1 && daysDiff <= 7) {
    const dayName = startDate.format("dddd");
    return `Next ${dayName}`;
  } else if (daysDiff > 7 && daysDiff <= 14) {
    const dayName = startDate.format("dddd");
    return `${dayName}, the ${startDate.format("Do")}`;
  } else if (daysDiff > 14) {
    const weeksDiff = startDate.diff(today, "weeks");
    return `In ${weeksDiff} week${weeksDiff > 1 ? "s" : ""}`;
  } else if (daysDiff === -1) {
    return "Yesterday";
  } else if (daysDiff < -1 && daysDiff >= -7) {
    return `${Math.abs(daysDiff)} days ago`;
  } else if (daysDiff < -7 && daysDiff >= -14) {
    return "Last week";
  } else if (daysDiff < -14) {
    const weeksDiff = Math.abs(startDate.diff(today, "weeks"));
    return `${weeksDiff} week${weeksDiff > 1 ? "s" : ""} ago`;
  }
  return "";
}

export default function StartDateTag({ startDate }: { startDate?: string | Date }) {
  if (!startDate) return null;

  const today = moment().startOf("day");
  const parsedStartDate = typeof startDate === 'string'
    ? moment(startDate, "YYYY-MM-DD").startOf("day")
    : moment(startDate).startOf("day");
  const colorScheme = getColorScheme(today, parsedStartDate);
  return (
    <Tag
      variant="outline"
      color={`${colorScheme}.DEFAULT`}
      border={`1px solid var(--chakra-colors-${colorScheme}-DEFAULT)`}
      bg={`${colorScheme}.background`}
      borderRadius="md"
      boxShadow="none"
    >
      {relativeStartDateLabel(today, parsedStartDate)}
    </Tag>
  );
}

export function StartDateGrayTag({ startDate }: { startDate?: string | Date }) {
  if (!startDate) return null;

  const today = moment().startOf("day");
  const parsedStartDate = typeof startDate === 'string'
    ? moment(startDate, "YYYY-MM-DD").startOf("day")
    : moment(startDate).startOf("day");
  return (
    <Tag
      colorScheme="gray"
      borderRadius="md"
      size="sm"
    >
      {relativeStartDateLabel(today, parsedStartDate)}
    </Tag>
  );
}
