import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link, Tooltip, Text, TextProps, LinkProps } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

interface ExternalLinkProps extends TextProps {
  to: string;
  text: string;
  linkProps?: Record<string, unknown>;
}

export default function ExternalLink({ to, text, linkProps = {}, ...rest }: ExternalLinkProps) {
  return (
    <Tooltip label={to} placement="top">
      <Text {...rest}>
        <Link href={to} isExternal {...linkProps}>
          {text}
          {" "}
          <ExternalLinkIcon mb="4px" ml="4px" />
        </Link>
      </Text>
    </Tooltip>
  );
}

interface InternalLinkProps extends LinkProps {
  to: string;
  newTab?: boolean;
};

export function InternalLink({ to, newTab = false, children, ...rest }: InternalLinkProps) {
  return (
    <Link
      as={RouterLink}
      to={to}
      isExternal={newTab}
      {...rest}
    >
      {children}
    </Link>
  );
}
