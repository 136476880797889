import { Flex } from "@chakra-ui/react";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { TriggerExplanation } from "components/Forms/AssignedActions/Shared/DisplayInfo";
import SendDateAttribute from "../Attribute/SendDateAttribute";
import SendAtAttribute from "../Attribute/SendAtAttribute";
import RecipientAttribute from "../Attribute/RecipientAttribute";
import TemplateActionAttribute from "../Attribute/FromTemplateAttribute";

export default function ChatOverview({ action }: { action: AssignedAction }) {
  return (
    <Flex mt="2" mb="6" gap="4" direction="column" align="start" w="100%">
      <RecipientAttribute action={action} />
      {action?.trigger?.type === "date_time" ? (
        <>
          <SendDateAttribute action={action} />
          <SendAtAttribute action={action} />
        </>
      ) : (
        <TriggerExplanation assignedAction={action} />
      )}
      <TemplateActionAttribute action={action} />
    </Flex>
  );
}
