import * as yup from "yup";
import { Company } from "models/company";
import { AutoFormField } from "models/autoForm";

export interface CompanyUpdateFormValues {
  newHireInfoPacketUrl?: string;
  benefitsInfoUrl?: string;
  hrisUrl?: string;
  companyHandbookUrl?: string;
  companyWikiUrl?: string;
  companyGoalsUrl?: string;
  acronymRepositoryUrl?: string;
  buddyExpectationsUrl?: string;
  buddySelectionUrl?: string;
  onboardingPlanTemplatesUrl?: string;
}

export const companyUpdateSchema = yup.object({
  newHireInfoPacketUrl: yup.string().label("New Hire Info Packet Url").notRequired(),
  benefitsInfoUrl: yup.string().label("Benefits Info Url").notRequired(),
  hrisUrl: yup.string().label("HRIS Url").notRequired(),
  companyHandbookUrl: yup.string().label("Company Handbook Url").notRequired(),
  companyWikiUrl: yup.string().label("Company Wiki Url").notRequired(),
  companyGoalsUrl: yup.string().label("Company Goals Url").notRequired(),
  acronymRepositoryUrl: yup.string().label("Acronym Repository Url").notRequired(),
  buddyExpectationsUrl: yup.string().label("Buddy Expectations Url").notRequired(),
  buddySelectionUrl: yup.string().label("Buddy Selection Url").notRequired(),
  onboardingPlanTemplatesUrl: yup.string().label("Onboarding Plan Templates Url").notRequired(),
});

export const values = (company: Company | undefined) => ({
  newHireInfoPacketUrl: company?.newHireInfoPacketUrl || null,
  benefitsInfoUrl: company?.benefitsInfoUrl || null,
  hrisUrl: company?.hrisUrl || null,
  companyHandbookUrl: company?.companyHandbookUrl || null,
  companyWikiUrl: company?.companyWikiUrl || null,
  companyGoalsUrl: company?.companyGoalsUrl || null,
  acronymRepositoryUrl: company?.acronymRepositoryUrl || null,
  buddyExpectationsUrl: company?.buddyExpectationsUrl || null,
  buddySelectionUrl: company?.buddySelectionUrl || null,
  onboardingPlanTemplatesUrl: company?.onboardingPlanTemplatesUrl || null,
});

export const FIELD_CONFIG: Record<keyof CompanyUpdateFormValues, AutoFormField> = {
  newHireInfoPacketUrl: {
    type: "text",
    label: "New Hire Info Packet Url",
    name: "newHireInfoPacketUrl",
  },
  benefitsInfoUrl: {
    type: "text",
    label: "Benefits Info Url",
    name: "benefitsInfoUrl",
  },
  hrisUrl: {
    type: "text",
    label: "HRIS Url",
    name: "hrisUrl",
  },
  companyHandbookUrl: {
    type: "text",
    label: "Company Handbook Url",
    name: "companyHandbookUrl",
  },
  companyWikiUrl: {
    type: "text",
    label: "Company Wiki Url",
    name: "companyWikiUrl",
  },
  companyGoalsUrl: {
    type: "text",
    label: "Company Goals Url",
    name: "companyGoalsUrl",
  },
  acronymRepositoryUrl: {
    type: "text",
    label: "Acronym Repository Url",
    name: "acronymRepositoryUrl",
  },
  buddyExpectationsUrl: {
    type: "text",
    label: "Buddy Expectations Url",
    name: "buddyExpectationsUrl",
  },
  buddySelectionUrl: {
    type: "text",
    label: "Buddy Selection Url",
    name: "buddySelectionUrl",
  },
  onboardingPlanTemplatesUrl: {
    type: "text",
    label: "Onboarding Plan Templates Url",
    name: "onboardingPlanTemplatesUrl",
  },
};
