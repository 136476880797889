import { Badge, BadgeProps } from "@chakra-ui/react";
import { upperCase } from "lodash";
import { AssignedActionType } from "models/automation";

interface ActionTypeTagProps extends BadgeProps {
  actionType: AssignedActionType | "task",
}

export default function ActionTypeTag({ actionType, ...rest }: ActionTypeTagProps) {
  let displayActionType = upperCase(actionType);
  let displayColor = "linkBlue.DEFAULT";
  switch (actionType) {
    case "task_notifier":
      displayActionType = "TASK";
      displayColor = "caminoOrange.DEFAULT";
      break;
    case "task":
      displayActionType = "TASK";
      displayColor = "caminoOrange.DEFAULT";
      break;
    case "task_reminder":
      displayActionType = "REMINDER";
      displayColor = "caminoLilac.DEFAULT";
      break;
    case "chat":
      displayActionType = "MESSAGE";
      displayColor = "caminoLilac.DEFAULT";
      break;
    case "meeting":
      displayActionType = "MEETING";
      displayColor = "caminoYellow.DEFAULT";
      break;
    case "email":
      displayColor = "caminoPurple.DEFAULT";
      break;
    default:
      break;
  }
  return (
    <Badge
      variant="solid"
      bg={displayColor}
      borderRadius="2px"
      fontWeight="bold"
      paddingInline="1.5"
      size="xs"
      {...rest}
    >
      {displayActionType}
    </Badge>
  );
}
