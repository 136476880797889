/* eslint-disable import/prefer-default-export */
import { Flex } from "@chakra-ui/react";
import { ExistsLeft } from "components/DataDisplay/Exists";
import { HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";
import SlackIcon from "components/Icon/Iconify/SlackIcon";
import { useUserDetailQuery } from "../hooks";

export function CheckSlackConnection({ userId }: { userId: string }) {
  const { data: user } = useUserDetailQuery(userId, {
    enabled: !!userId,
    staleTime: 0,
  });
  return (
    <Flex
      direction="column"
      gap="2"
      borderColor="tableBorder.DEFAULT"
      borderWidth="1px"
      borderRadius="md"
      p="2"
      width="fit-content"
    >
      <HorizontalGreyBlackField
        label={`${user?.fullName}'s Slack`}
        icon={SlackIcon}
      >
        <ExistsLeft
          labelYes="Account Found & Synced"
          labelNo="No Account Found"
          condition={!!user?.messageServiceId}
        />
      </HorizontalGreyBlackField>
    </Flex>
  );
}
