import { useTheme } from "@chakra-ui/react";
import { SelectFieldProps } from "components/Form/typings";
import { TIME_OPTIONS } from "definitions/constants";
import SingleSelectField from "./SingleSelectField";

export default function HourSelectField({
  name,
  label,
  placeholder,
  isClearable,
  control,
  errors,
  required,
  disabled,
  variant,
  formFieldProps = {},
  ...rest
}: SelectFieldProps) {
  const theme = useTheme();
  // Accessing default variant from the theme
  const formVariant = variant || theme.components.Form.defaultProps.variant;

  return (
    <SingleSelectField
      options={TIME_OPTIONS}
      name={name}
      label={label}
      placeholder={placeholder}
      isClearable={isClearable}
      control={control}
      errors={errors}
      variant={formVariant}
      required={required}
      disabled={disabled}
      formFieldProps={formFieldProps}
      {...rest}
    />
  );
}
