import { startCase, camelCase } from "lodash";
import { FieldDef, Issue, IssueContextQueryResult } from "models/issue";
import { titleize } from "helpers/string";
import { newHireJourneyQueryArgs, useNewHireJourneyDetailQuery } from "features/NewHireJourney/hooks";
import { displayRoleName, toRoleFieldName } from "features/Issues/helpers";
import { NewHireJourney } from "models/newHire";
import { OnlyIssue, ResolutionInfo, TransformedData } from "models/resolutions";
import GreenStatusButton from "components/Status/GreenStatusButton";
import { FiCheckCircle } from "react-icons/fi";
import { useIssueContextQuery } from "features/Issues/hooks";
import { useMemo } from "react";
import { UserUpdateFormValues } from "components/Forms/UserUpdateForm";
import { Flex } from "@chakra-ui/react";
import UserFormFields from "features/Issues/Components/UserFormFields";

export function unassignedRoleIssueType(issue: Issue) {
  return `Unassigned ${startCase(camelCase(issue?.context?.roleName || "Role"))}`;
}

export function unassignedRoleValue(issueContext: IssueContextQueryResult) {
  const roleFieldName = toRoleFieldName(issueContext?.issue?.context?.roleName) as keyof NewHireJourney;
  return issueContext?.newHire ? issueContext?.newHire[roleFieldName] as string : null;
}

export function useUnassignedRoleValue({ issue }: OnlyIssue): string | null {
  const issueContextQueryResult = useIssueContextQuery(issue);
  return unassignedRoleValue(issueContextQueryResult);
}

export function IssueModalInputFields({ issue }: { issue: Issue }) {
  const value = useUnassignedRoleValue({ issue });
  const { data: sharedResponse } = useNewHireJourneyDetailQuery(issue?.newHireJourneyId, {
    enabled: !!issue?.newHireJourneyId,
    staleTime: 0,
    select: (originalData: NewHireJourney) => ({
      newHire: originalData,
      // we need to do this as there is no userId in the issueContext and the newHire.user does not have the
      // user.onboardingJourney, which is needed for the user form fields, so we reverse it here
      user: { ...originalData.user, onboardingJourney: originalData },
    }),
  }) as unknown as TransformedData;
  const { user } = sharedResponse || {};
  const roleFieldName = toRoleFieldName(issue?.context?.roleName) as keyof UserUpdateFormValues;
  const fields: FieldDef<UserUpdateFormValues>[] = useMemo(() => (
    roleFieldName ? [{ name: roleFieldName, label: `Assign ${issue?.context?.roleName ? titleize(issue?.context?.roleName) : "Role"}` }] : []
  ), [roleFieldName, issue?.context?.roleName]);
  return (
    <Flex direction="column" gap="1">
      {user && <UserFormFields user={user} fieldsDefs={fields} issue={issue} />}
      {value && <GreenStatusButton icon={FiCheckCircle}>{`${displayRoleName(issue)} Assigned`}</GreenStatusButton>}
    </Flex>
  );
}

export function unassignedRoleResolution(issue: Issue, issueContext: IssueContextQueryResult): ResolutionInfo {
  const { newHire } = issueContext || {};
  const roleName = displayRoleName(issue);
  return ({
    issueType: "unassigned_role",
    tagText: unassignedRoleIssueType(issue),
    title: newHire ? `No '${roleName}' assigned for ${newHire?.user?.fullName}` : "",
    additionalContext: `Some actions either mention the ${roleName}, or will be sent to the ${roleName}, but no one has been assigned to it. Please assign a ${roleName}.`,
    inputComponent: IssueModalInputFields,
  });
}

export function unassignedRoleQueryArgs(issue: Issue) {
  const roleFieldName = toRoleFieldName(issue.context?.roleName) as keyof NewHireJourney;
  return newHireJourneyQueryArgs(issue.newHireJourneyId, {
    select: (newHire: NewHireJourney) => (newHire ? newHire[roleFieldName] as string : null),
  });
}

export function unassignedRoleContextQueries(_: Issue) {
  return [];
}
