import { useTheme } from "@chakra-ui/react";
import DatePicker from "components/Form/DatePicker";
import { DatePickerFieldProps } from "components/Form/typings";
import FormField from "./FormField";

export default function DatePickerField({
  name,
  label,
  helperText,
  control,
  errors,
  defaultValue,
  disabled,
  required,
  isClearable,
  minDate,
  defaultIsOpen,
  usePortal,
  preserveTime,
  variant,
  dateFormat,
  onDateChange,
  formFieldProps = {},
  includeIcon,
  children,
  ...rest
}: DatePickerFieldProps) {
  const theme = useTheme();
  const formVariant = variant || theme.components.Form.defaultProps.variant;

  return (
    <FormField
      name={name}
      label={label}
      errors={errors}
      helperText={helperText}
      required={required}
      variant={formVariant}
      className="chakra-datepicker-wrapper"
      {...formFieldProps}
    >
      <DatePicker
        name={name}
        control={control}
        rules={required ? { required: true } : {}}
        disabled={disabled}
        defaultValue={defaultValue}
        isClearable={isClearable}
        minDate={minDate}
        defaultIsOpen={defaultIsOpen}
        usePortal={usePortal}
        preserveTime={preserveTime}
        dateFormat={dateFormat}
        onDateChange={onDateChange}
        variant={formVariant}
        includeIcon={includeIcon}
        {...rest}
      >
        {children}
      </DatePicker>
    </FormField>
  );
}
