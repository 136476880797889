import { useColorModeValue } from "@chakra-ui/react";
import Linkify from "components/DataDisplay/Linkify";
import { newHireJourneyPathById, userPathById } from "helpers/url";
import { NewHireJourney } from "models/newHire";
import UserTag, { UserTagProps } from ".";
import { BasicUser, User } from "models/user";

interface RoleTagProps extends Omit<UserTagProps, "user" | "name" | "imageUrl" | "role"> {
  newHire: NewHireJourney;
  includeLink?: boolean;
}

export function NewHireTag({ newHire, includeLink = true, ...rest }: RoleTagProps) {
  const bgColor = useColorModeValue(
    "var(--chakra-colors-newHireGreen-bg)",
    "var(--chakra-colors-newHireGreen-text)",
  );
  const color = useColorModeValue("newHireGreen.text", "newHireGreen.bg");
  const url = newHire?.user?.id && includeLink ? newHireJourneyPathById(newHire?.id) : undefined;
  return (
    <RoleTag user={newHire?.user} role="New Hire" isLoading={!!newHire} color={color} bgColor={bgColor} url={url} {...rest} />
  );
}

export function ManagerTag({ newHire, includeLink = true, ...rest }: RoleTagProps) {
  const bgColor = useColorModeValue(
    "var(--chakra-colors-indigo-100)",
    "var(--chakra-colors-indigo-800)",
  );
  const color = useColorModeValue("indigo.800", "indigo.100");
  const url = newHire?.manager?.id && includeLink ? userPathById(newHire?.manager?.id) : undefined;
  return (
    <RoleTag user={newHire?.manager} role="Manager" isLoading={!!newHire} color={color} bgColor={bgColor} url={url} {...rest} />
  );
}

export function PeopleTeamContactTag({ newHire, includeLink = true, ...rest }: RoleTagProps) {
  const bgColor = useColorModeValue(
    "peopleTeamBlue.light",
    "peopleTeamBlue.dark",
  );
  const color = useColorModeValue("peopleTeamBlue.dark", "peopleTeamBlue.light");
  const url = newHire?.peopleTeamContactId && includeLink ? userPathById(newHire?.peopleTeamContactId) : undefined;
  return (
    <RoleTag user={newHire?.peopleTeamContact} role="People Team" isLoading={!!newHire} color={color} bgColor={bgColor} url={url} {...rest} />
  );
}

export function BuddyTag({ newHire, includeLink = true, ...rest }: RoleTagProps) {
  const bgColor = useColorModeValue(
    "caminoOrange.50",
    "caminoOrange.600",
  );
  const color = useColorModeValue("caminoOrange.600", "caminoOrange.50");
  const url = newHire?.buddyId && includeLink ? userPathById(newHire?.buddyId) : undefined;
  return (
    <RoleTag user={newHire?.buddy} role="Buddy" isLoading={!!newHire} color={color} bgColor={bgColor} url={url} {...rest} />
  );
}

interface InternalRoleTagProps extends Omit<UserTagProps, "user" | "name" | "imageUrl" | "role"> {
  user: BasicUser | User | undefined;
  role: string | undefined;
  url?: string;
}

function RoleTag({ user, role, isLoading, color, bgColor, url, ...rest }: InternalRoleTagProps) {
  return (
    <Linkify linkTo={url} style={{ width: "100%" }}>
      <UserTag isLoading={isLoading} user={user} role={role} tagLabelProps={{ color }} bgColor={bgColor} size="sm" {...rest} />
    </Linkify>
  );
}
