import { Avatar, AvatarProps } from "@chakra-ui/react";
import Flower from "components/Icon/Brand/Flower";

export default function FlowerAvatar({ name, src, ...props }: AvatarProps) {
  return (
    <Avatar
      name={src ? name : undefined}
      src={src}
      bg="caminoLilac.DEFAULT"
      icon={<Flower mt="2px" fontSize="1.6rem" />}
      {...props}
    />
  );
}
