/* eslint-disable no-unsafe-optional-chaining */
import { Flex, FlexProps, Skeleton, Wrap, WrapItem } from "@chakra-ui/react";
import { HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";
import IssueExclamation from "components/Icon/Iconify/IssueExclamation";
import resolveIssueType from "features/Issues/Resolutions/resolveIssueType";
import { AssignedActionId } from "models/automation";
import { Issue } from "models/issue";
import { useMemo } from "react";
import { NewHireJourneyId } from "models/newHire";
import { useIssuesByActionIdQuery, useIssuesByNewHireIdQuery } from "../hooks";
import IssueModalTag, { ActionIssueModalTag } from "./IssueModal";
import { dedupeMissingSlackUsers } from "../helpers";
import { useAssignedActionDetailQuery } from "features/AssignedAction/hooks";

function IssueModalTagSkeleton() {
  return (
    <Skeleton height="20px" width="80px" />
  );
}

interface ListIssuesByActionProps extends FlexProps {
  actionId: AssignedActionId;
  fieldProps?: object;
}

export function ListIssuesByAction({ actionId, fieldProps = {}, ...rest }: ListIssuesByActionProps) {
  const { data: action } = useAssignedActionDetailQuery(actionId, { enabled: !!actionId });
  const { data: issues } = useIssuesByActionIdQuery(actionId, { enabled: !!actionId });
  console.log("actionId", actionId);
  console.log("action", action);
  console.log("issues", issues);
  const numberOfIssues = issues?.length || 0;
  const issueByLength = useMemo(() => dedupeMissingSlackUsers(issues || []).sort((a, b) => (resolveIssueType(b)?.length || 0) - (resolveIssueType(a)?.length || 0)), [issues]);
  if (!issues) return null;
  return (
    <Flex gap="2" {...rest}>
      <HorizontalGreyBlackField
        icon={IssueExclamation}
        label={`${numberOfIssues} Issue${numberOfIssues > 1 ? "s" : ""}`}
        labelContainerProps={{ minWidth: "75px" }}
        align="flex-start"
        {...fieldProps}
      >
        <Wrap>
          {issueByLength.map((issue: Issue) => (
            <WrapItem key={issue.id}>
              {action ? (
                <ActionIssueModalTag issue={issue} action={action} />
              ) : (
                <IssueModalTagSkeleton />
              )}
            </WrapItem>
          ))}
        </Wrap>
      </HorizontalGreyBlackField>
    </Flex>
  );
}

export function ListIssues({ issues = [] }: { issues?: Issue[] }) {
  const issueByLength = useMemo(() => (issues || []).sort((a, b) => (resolveIssueType(b)?.length || 0) - (resolveIssueType(a)?.length || 0)), [issues]);
  if (!issues?.length) return null;
  return (
    <Wrap>
      {issueByLength.map((issue: Issue) => (
        <WrapItem key={issue.id} mt="3px">
          <IssueModalTag issue={issue} />
        </WrapItem>
      ))}
    </Wrap>
  );
}

interface ListIssuesByNewHireProps extends FlexProps {
  newHireId: NewHireJourneyId;
  fieldProps?: object;
}

export function ListIssuesByNewHire({ newHireId, fieldProps = {}, ...rest }: ListIssuesByNewHireProps) {
  const { data: issues } = useIssuesByNewHireIdQuery(newHireId);

  const numberOfIssues = issues?.length || 0;
  if (numberOfIssues === 0) {
    return null;
  }
  return (
    <Flex gap="2" {...rest}>
      <HorizontalGreyBlackField
        icon={IssueExclamation}
        label={`${numberOfIssues} Issue${numberOfIssues > 1 ? "s" : ""}`}
        labelContainerProps={{ minWidth: "75px", mt: "3px" }}
        align="flex-start"
        {...fieldProps}
      >
        <ListIssues issues={dedupeMissingSlackUsers(issues)} />
      </HorizontalGreyBlackField>
    </Flex>
  );
}

export function TableColumnListIssuesByNewHire({ newHireId, fieldProps = {}, ...rest }: ListIssuesByNewHireProps) {
  const { data: issues } = useIssuesByNewHireIdQuery(newHireId);
  return (
    <ListIssues issues={dedupeMissingSlackUsers(issues)} />
  );
}
