import UserUpdateForm, { UserUpdateFormValues } from "components/Forms/UserUpdateForm";
import BaseDialogFormHeader from "components/Forms/FormHeader";
import { useUserDetailQuery } from "features/User/hooks";
import { userPathById } from "helpers/url";

interface DynamicUserFormProps {
  userId: string;
  title: string;
  cardLink?: string;
  fields: Array<keyof UserUpdateFormValues>;
  onSuccess: () => void;
  onClose: () => void;
}

export default function DynamicUserForm({
  userId, fields, title, onSuccess, onClose,
}: DynamicUserFormProps) {
  const { data: user, isLoading } = useUserDetailQuery(userId);
  const cardLink = userPathById(userId);
  return (
    <BaseDialogFormHeader
      linkTo={cardLink as string}
      label={title}
      value={user?.fullName as string}
      imageUrl={user?.avatarUrl}
      isLoaded={!isLoading}
    >
      <UserUpdateForm userId={userId} fields={fields} onSuccess={onSuccess} onClose={onClose} />
    </BaseDialogFormHeader>
  );
}

DynamicUserForm.defaultProps = {
  cardLink: null,
};
