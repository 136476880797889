/* eslint-disable no-nested-ternary */
import {
  Box,
  LightMode,
  SystemStyleObject,
  Table as ChakraTable,
  TableProps,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Table } from "@tanstack/react-table";
import TableWrapper from "./TableWrapper"; // Import the wrapper component
import SmallArrowPagination from "./SmallArrowPagination";
import { SkeletonRows } from "./SkeletonRow";
import TableCell, { TableHeader } from "./TableComponents";

export const defaultBaseRowTableStyles: SystemStyleObject = {
  width: "100%",
  "td:first-of-type": {
    paddingLeft: "30px",
  },
  th: {
    borderColor: "transparent",
    textAlign: "center",
  },
  thead: {
    position: "relative",
    _after: {
      content: "''",
      position: "absolute",
      bottom: "0",
      height: "2px",
      backgroundColor: "tableUnderlineBorder.DEFAULT",
      width: "calc(100% - 68px)",
      left: "34px",
      right: "34px",
    },
  },
  "th:first-of-type": {
    paddingLeft: "30px",
  },
  minWidth: { base: "400px", xl: "973px" },
};

export const defaultThStyles: SystemStyleObject = {
  background: "transparent",
  paddingTop: 8,
};

interface BaseTableProps<T> extends TableProps {
  table: Table<T>;
  sx?: SystemStyleObject;
  textWrap?: boolean;
  isLoading?: boolean;
  pageSizeOptions?: number[];
  emptyStateComponent?: React.ReactNode;
  containerProps?: object;
  thProps?: object;
  theadProps?: object;
  useCardWrapper?: boolean;
}

export default function BaseRowTable({
  table,
  sx = {},
  textWrap = false,
  isLoading = false,
  pageSizeOptions = undefined,
  emptyStateComponent = null,
  containerProps = {},
  thProps = {},
  theadProps = {},
  useCardWrapper = true,
  ...props
}: BaseTableProps<any>) {
  // eslint-disable-next-line no-underscore-dangle
  const columnNumber = table._getColumnDefs().length;

  const tableContent = (
    <ChakraTable overflow="scroll" borderRadius="5px" variant="simple" {...props}>
      <Thead {...theadProps}>
        <LightMode>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader
                  key={header?.column?.id}
                  header={header}
                  sx={defaultThStyles}
                  {...thProps}
                />
              ))}
            </Tr>
          ))}
        </LightMode>
      </Thead>
      <Tbody {...(isLoading ? { pt: "4" } : {})}>
        {isLoading ? (
          <SkeletonRows columns={columnNumber} rows={10} speed={1.75} />
        ) : !table.getRowModel().rows?.length && emptyStateComponent ? (
          <Tr>
            <Td colSpan={columnNumber} textAlign="center">
              {emptyStateComponent}
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map((row) => (
            <Tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell?.id} cell={cell} textWrap={textWrap || false} />
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </ChakraTable>
  );

  return (
    <Box sx={{ ...defaultBaseRowTableStyles, ...sx }} width="100%">
      <SmallArrowPagination pageSizeOptions={pageSizeOptions} table={table} />
      <TableWrapper useCardWrapper={useCardWrapper} containerProps={containerProps}>
        {tableContent}
      </TableWrapper>
    </Box>
  );
}
