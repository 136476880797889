import { HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { dateToFormalLongStringWithTimeAndZone } from "helpers/time";
import CalendarMonth from "components/Icon/Iconify/CalendarMonth";
import { longAttributeStyles } from "../styles";

export default function SendDateAttribute({ action }: { action: AssignedAction }) {
  return (
    <HorizontalGreyBlackField
      icon={CalendarMonth}
      label="Scheduled"
      labelContainerProps={longAttributeStyles.labelContainerProps}
    >
      {dateToFormalLongStringWithTimeAndZone(action?.trigger?.data?.datetime)}
    </HorizontalGreyBlackField>
  );
}
