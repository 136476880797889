import { Tag, TagProps } from "@chakra-ui/react";

export default function OutlineTag({ children, color, borderColor, bg, ...rest }: TagProps) {
  return (
    <Tag
      variant="outline"
      borderRadius="md"
      boxShadow="none"
      color={color}
      border={`1px solid ${borderColor}`}
      bg={bg}
      {...rest}
    >
      {children}
    </Tag>
  );
}
