import { NetworkingError } from "services/ApiClient/networkingError";
import UnfoundResource from "./UnfoundResource";
import UnknownError from "./UnknownError";

interface QueryErrorProps {
  queryError: any;
  id: string;
  resourceName: string;
}

export default function QueryError({ queryError, id, resourceName }: QueryErrorProps) {
  return (queryError as NetworkingError)?.status === 404 ? (
    <UnfoundResource resourceName={resourceName} />
  ) : (
    <UnknownError resourceId={id} resourceName={resourceName} />
  );
}
