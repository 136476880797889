import { FieldDef, Issue, IssueContextQueryResult } from "models/issue";
import { userQueryArgs, useUserDetailQuery } from "features/User/hooks";
import { useMemo } from "react";
import { User } from "models/user";
import { titleize } from "helpers/string";
import { useIssueContextQuery } from "features/Issues/hooks";
import { OnlyIssue, ResolutionInfo } from "models/resolutions";
import GreenStatusButton from "components/Status/GreenStatusButton";
import { FiCheckCircle } from "react-icons/fi";
import { attributeValue, toEmailFieldName } from "features/Issues/helpers";
import { get } from "lodash";
import { Flex } from "@chakra-ui/react";
import UserFormFields from "features/Issues/Components/UserFormFields";
import { UserUpdateFormValues } from "components/Forms/UserUpdateForm";

export function missingEmailIssueType(issue: Issue) {
  return `Missing "${issue?.context?.emailType ? `${titleize(issue?.context?.emailType)} ` : ""}Email"`;
}

export function expandedMissingEmailIssueType(issue: Issue, issueContext: IssueContextQueryResult) {
  if (issueContext?.user?.fullName) {
    return `Missing ${issueContext?.user?.fullName}'s ${issue?.context?.emailType ? `${titleize(issue?.context?.emailType)} ` : ""}Email`;
  }
  return missingEmailIssueType(issue);
}

export function missingEmailValue(issueContext: IssueContextQueryResult) {
  const attribute = toEmailFieldName(issueContext?.issue?.context?.emailType);
  return get(issueContext?.user, attribute);
}

export function useMissingEmailValue({ issue }: OnlyIssue) {
  const issueContextQueryResult = useIssueContextQuery(issue);
  return missingEmailValue(issueContextQueryResult);
}

export function IssueModalInputFields({ issue }: { issue: Issue }) {
  const value = useMissingEmailValue({ issue });
  const emailFieldName = toEmailFieldName(issue?.context?.emailType) as keyof UserUpdateFormValues;
  const { data: user } = useUserDetailQuery(issue?.context?.userId, {
    enabled: !!issue?.context?.userId,
    staleTime: 0,
  });
  const fields: FieldDef<UserUpdateFormValues>[] = useMemo(() => (
    emailFieldName ? [{ name: emailFieldName, label: `${user?.fullName ? `${user?.fullName} ` : ""}${titleize(issue?.context?.emailType)} Email` }] : []
  ), [emailFieldName, issue?.context?.emailType, user?.fullName]);
  return (
    <Flex direction="column" gap="2">
      {user && <UserFormFields user={user} fieldsDefs={fields} issue={issue} />}
      {value && <GreenStatusButton icon={FiCheckCircle}>{`${issue?.context?.emailType ? `${titleize(issue?.context?.emailType)} ` : ""}Email Added`}</GreenStatusButton>}
    </Flex>
  );
}

export function missingEmailResolution(issue: Issue, issueContext: IssueContextQueryResult): ResolutionInfo {
  const { user } = issueContext || {};

  return ({
    issueType: "missing_email",
    tagText: `Missing ${user?.fullName || "user"}'s ${titleize(issue?.context?.emailType)} Email`,
    title: `Missing ${user?.fullName || "user"}'s ${titleize(issue?.context?.emailType)} Email`,
    // eslint-disable-next-line max-len
    additionalContext: `Cannot find ${user?.fullName}'s ${titleize(issue?.context?.emailType)} Email to deliver the email. Please add or update their ${titleize(issue?.context?.emailType)} Email.`,
    inputComponent: IssueModalInputFields,
  });
}

export function missingEmailQueryArgs(issue: Issue) {
  const { emailType, userId } = issue?.context || {};
  const attribute = toEmailFieldName(emailType);
  return {
    ...userQueryArgs(userId),
    // No rolename is provided for missing email, so we pass recipient bc it
    // defaults to looking for the userId user's info (and the userId is the person whose email is missing)
    select: (user: User) => attributeValue({ user, roleName: "recipient", attribute }),
  };
}

export function missingEmailContextQueries(issue: Issue) {
  const { userId } = issue?.context || {};
  return [userQueryArgs(userId)];
}
