import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { ActionIssueResolutionContent, IssueResolutionContent } from "features/Issues/Components/IssueCard";
import { Issue } from "models/issue";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { PropsWithChildren, useRef } from "react";
import { ExpandedIssueTag } from "./IssueTag";
import { isTimingIssue } from "../helpers";

interface IssueModalProps extends PropsWithChildren {
  issue: Issue;
  size?: string
}

function IssueModalBase({ issue, size, children }: IssueModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ref = useRef(null);

  return (
    <>
      {issue && <ExpandedIssueTag issue={issue} onClick={() => onOpen()} />}

      <Modal autoFocus={false} onClose={onClose} size={size} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent ref={ref}>
          <ModalCloseButton
            fontSize="sm"
            // having issues with the modal close button size not working, this is a workaround
            sx={{ "--close-button-size": "var(--chakra-sizes-6)" }}
          />
          <ModalBody mt="6">
            <Center>
              <Box mt="4" w="100%">
                {children}
              </Box>
            </Center>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default function IssueModalTag({ issue }: { issue: Issue }) {
  const size = isTimingIssue(issue) ? "6xl" : "lg";

  return (
    <IssueModalBase issue={issue} size={size}>
      <IssueResolutionContent issue={issue} includeNext={false} skeletonProps={{ width: "100%" }} />
    </IssueModalBase>
  );
}

export function ActionIssueModalTag({ issue, action }: { issue: Issue, action: AssignedAction }) {
  return (
    <IssueModalBase issue={issue} size="lg">
      <ActionIssueResolutionContent issue={issue} action={action} skeletonProps={{ width: "100%" }} />
    </IssueModalBase>
  );
}
