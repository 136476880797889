/* eslint-disable max-len */
import { Icon, IconProps } from "@chakra-ui/react";

function Template(props: IconProps) {
  return (
    <Icon
      viewBox="0 0 12 12"
      fill="none"
      {...props}
    >
      <path
        d="M 2 2.5 a 0.5 0.5 90 0 1 0.5 -0.5 h 7 a 0.5 0.5 90 0 1 0.5 0.5 v 1 a 0.5 0.5 90 0 1 -0.5 0.5 H 2.5 a 0.5 0.5 90 0 1 -0.5 -0.5 z m 0 4 a 0.5 0.5 90 0 1 0.5 -0.5 h 2 a 0.5 0.5 90 0 1 0.5 0.5 v 3 a 0.5 0.5 90 0 1 -0.5 0.5 H 2.5 a 0.5 0.5 90 0 1 -0.5 -0.5 z m 5 -0.5 h 3 m -3 2 h 3 m -3 2 h 3"
        fill="none"
        stroke="#959393"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
export default Template;
