import { Props, Select } from "chakra-react-select";
import { NewHireJourney } from "models/newHire";
import { useTheme } from "@chakra-ui/react";
import { OptionType } from ".";

export type NewHireJourneySingleSelectProps = Props & {
  selectedNewHireJourneyId: string | null;
  onChange: (arg0: string | undefined) => void;
  newHireJourneys: NewHireJourney[];
};

interface NewHireJourneyOption {
  value: string;
  label: string;
  rawValue: NewHireJourney;
}

export function newHireJourneyToNewHireJourneyOption(newHireJourney: NewHireJourney): NewHireJourneyOption {
  return {
    value: newHireJourney.id,
    label: newHireJourney?.user?.fullName,
    rawValue: newHireJourney,
  };
}

export default function NewHireJourneySingleSelect({
  selectedNewHireJourneyId,
  newHireJourneys,
  name,
  id,
  placeholder,
  isClearable,
  useBasicStyles,
  onChange,
  variant,
  ...props
}: NewHireJourneySingleSelectProps) {
  const theme = useTheme();
  const options = (newHireJourneys || []).map((newHireJourney) => newHireJourneyToNewHireJourneyOption(newHireJourney));
  const formVariant = variant || theme.components.Form.defaultProps.variant;
  return (
    <Select
      useBasicStyles={useBasicStyles}
      isClearable={isClearable}
      menuPortalTarget={document.body}
      options={options}
      name={name}
      placeholder={placeholder}
      onChange={(val) => onChange((val as NewHireJourneyOption)?.value)}
      value={options.find((option) => (option as OptionType).value === selectedNewHireJourneyId)}
      classNamePrefix="chakra-react-select"
      className="chakra-react-select-container-single-select"
      colorScheme="brand"
      variant={formVariant}
      chakraStyles={{
        option: (provided) => ({
          ...provided,
          color: "fg.muted",
        }),
        valueContainer: (provided) => ({
          ...provided,
          className: "single-select",
        }),
        control: (provided) => ({
          ...provided,
          cursor: "pointer",
        }),
        container: (provided) => ({
          ...provided,
          width: "100%",
        }),
      }}
      styles={{ menuPortal: (provided) => ({ ...provided, zIndex: 1400 }) }}
      {...props}
    />
  );
}
