import { VStack } from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { AutoFormFieldType } from "models/autoForm";
import FieldByType from "./FieldByType";

interface FormSectionProps {
  section: any;
  form: UseFormReturn<any>;
}

function FormSection({ section, form, ...rest }: FormSectionProps) {
  return (
    <VStack spacing={3} {...rest}>
      {section.fields.map(
        (field: {
          name: string;
          label: string;
          type: AutoFormFieldType;
          accessor?: string;
          required?: boolean;
          hiddenFields?: { name: string; accessor: string }[] | undefined;
        }) => (
          <FieldByType
            key={field.name}
            form={form}
            required={field.required || false}
            formFieldProps={{
              overrideHelperSpace: true,
              backgroundColor: "tableUnderlineBorder.opacity16",
              borderTopRadius: "5px",
            }}
            {...field}
          />
        ),
      )}
    </VStack>
  );
}

export default FormSection;
