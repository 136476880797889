import { useTheme } from "@chakra-ui/react";
import { GenericAsyncSelect, GenericAsyncSelectProps } from "components/Form/Select/GenericAsyncSelect";
import { SelectFieldProps } from "components/Form/typings";
import FormField from "../FormField";

export default function GenericAsyncSelectField<T>({
  name,
  label,
  helperText,
  errors,
  required,
  placeholder,
  variant,
  formFieldProps = {},
  ...props
}: SelectFieldProps & GenericAsyncSelectProps<T>) {
  const theme = useTheme();
  // Accessing default variant from the theme
  const formVariant = variant || theme.components.Form.defaultProps.variant;
  const placeholderValue = ["floating", "flushedFloating"].includes(formVariant) ? " " : placeholder;
  return (
    <FormField
      name={name}
      label={label}
      errors={errors}
      helperText={helperText}
      required={required}
      variant={formVariant}
      {...formFieldProps}
    >
      <GenericAsyncSelect
        name={name}
        // passing in the explicit variant prop and not 'floating'
        // as floating variant is handled in the GenericAsyncSelect component
        variant={variant}
        placeholder={placeholderValue}
        {...props}
      />
    </FormField>
  );
}
