import { useTheme } from "@chakra-ui/react";
import { CreateableSingleSelectFieldProps } from "components/Form/typings";
import CreateableSingleSelect from "components/Form/Select/CreateableSingleSelect";
import FormField from "../FormField";

export default function CreateableSingleSelectField({
  name,
  label,
  placeholder,
  control,
  options,
  errors,
  required,
  variant,
  resourceUrl,
  formFieldProps = {},
}: CreateableSingleSelectFieldProps) {
  const theme = useTheme();
  // Accessing default variant from the theme
  const formVariant = variant || theme.components.Form.defaultProps.variant;
  return (
    <FormField
      name={name}
      label={label}
      errors={errors}
      required={required}
      variant={formVariant}
      {...formFieldProps}
    >
      <CreateableSingleSelect
        name={name}
        control={control}
        placeholder={["floating", "flushedFloating"].includes(formVariant) ? " " : placeholder}
        options={options}
        resourceUrl={resourceUrl}
      />
    </FormField>
  );
}
