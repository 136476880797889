import { AssignedActionType } from "models/automation";
import { toAssignedActionType } from "helpers/assignedAction";
import { FiCheckCircle } from "react-icons/fi";
import GreenStatusButton from "./GreenStatusButton";

export default function SentStatus({ actionType }: { actionType: AssignedActionType }) {
  return (
    <GreenStatusButton icon={FiCheckCircle}>{`${toAssignedActionType(actionType)} Sent`}</GreenStatusButton>
  );
}
