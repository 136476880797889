import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import useUserAutoSaveForm from "features/User/DynamicForm/useUserAutoSaveForm";
import DynamicUserField from "features/User/DynamicForm/DynamicUserField";
import { invalidateIssueQueries } from "features/Issues/hooks";
import { User } from "models/user";
import { FieldDef, Issue } from "models/issue";
import { UserUpdateFormValues } from "components/Forms/UserUpdateForm";

interface UserFormFieldsProps {
  user: User;
  fieldsDefs: Array<FieldDef<UserUpdateFormValues>>;
  issue: Issue;
}

export default function UserFormFields({ user, fieldsDefs, issue }: UserFormFieldsProps) {
  const queryClient = useQueryClient();
  const onSuccess = useCallback(() => {
    invalidateIssueQueries(queryClient, issue);
  }, [issue, queryClient]);
  const fields = useMemo(() => fieldsDefs.map((fieldDef) => fieldDef.name), [fieldsDefs]);
  const { form } = useUserAutoSaveForm({
    user,
    fields,
    onSuccess,
  });
  return (
    <>
      {fieldsDefs.map((fieldDef) => (
        <DynamicUserField
          key={fieldDef.name}
          customLabel={fieldDef.label}
          fieldName={fieldDef.name}
          form={form}
          formFieldProps={{
            overrideHelperSpace: true,
            backgroundColor: "tableUnderlineBorder.opacity16",
            borderTopRadius: "5px",
          }}
        />
      ))}
    </>
  );
}
