import { useToast } from "@chakra-ui/react";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { useUpdateAssignedAction } from "features/AssignedAction/hooks";

interface UseSubmitAssignedActionUpdate {
  assignedAction: AssignedAction;
  onSubmitSuccess?: () => void;
}

export default function useSubmitUpdateForm({ assignedAction, onSubmitSuccess }: UseSubmitAssignedActionUpdate) {
  const toast = useToast({
    status: "success",
    duration: 2000,
    isClosable: true,
  });

  /* HandleOnSubmit */
  return useUpdateAssignedAction({
    action: assignedAction,
    onSuccess: () => {
      toast({
        title: "Updated!",
      });
      onSubmitSuccess?.();
    },
  });
}
