import { useSearchParams } from "react-router-dom";

export default function useSearchParam(key: string, defaultValue?: string) {
  const [searchParams, setSearchParams] = useSearchParams();
  const value = searchParams.get(key) || defaultValue || null;

  const setSearchParam = (newValue: string) => {
    if (newValue) {
      searchParams.set(key, newValue);
    } else {
      searchParams.delete(key);
    }
    setSearchParams(searchParams);
  };

  return {
    value,
    setSearchParam,
  };
}
