/* eslint-disable import/prefer-default-export */
import { titleize } from "helpers/string";
import { camelCase, get } from "lodash";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { Issue, IssueContextQueryResult, TIMING_ISSUE_TYPES } from "models/issue";
import { NewHireJourney } from "models/newHire";
import { ComponentWithAs, IconProps } from "@chakra-ui/react";
import CaminoBuddyIcon from "components/Icon/CaminoBuddy";
import CaminoManagerIcon from "components/Icon/CaminoManager";
import CaminoNewHireIcon from "components/Icon/CaminoNewHire";
import CaminoPeopleTeamIcon from "components/Icon/CaminoPeopleTeamIcon";
import { Company } from "models/company";
import { PersonalizationTokenRoleName } from "models/personalizationToken";
import { User } from "models/user";
import { IconType } from "react-icons";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { assignedActionDetailQueryArgs } from "features/AssignedAction/hooks";
import isActionTimingValid from "features/AssignedAction/helpers/isActionTimingValid";
import { isInPast } from "helpers/time";

const ROLE_NAME_FIELDS = ["buddyId", "peopleTeamContactId", "managerId"];

export function toRoleFieldName(roleName: string | undefined) {
  if (!roleName) {
    return undefined;
  }
  const roleNameField = `${camelCase(roleName)}Id`;
  if (!ROLE_NAME_FIELDS.includes(roleNameField)) {
    throw new Error(`Invalid role name: ${roleName}`);
  }
  return roleNameField;
}

const EMAIL_TYPE_FIELDS = ["workEmail", "personalEmail"];

export function toEmailFieldName(emailType: string | undefined) {
  if (!emailType) {
    return "";
  }
  const emailTypeField = `${emailType}Email`;
  if (!EMAIL_TYPE_FIELDS.includes(emailTypeField)) {
    throw new Error(`Invalid email type field name: ${emailTypeField}`);
  }
  return emailTypeField;
}

export function isTimingIssue(issue: Issue) {
  return issue && TIMING_ISSUE_TYPES.includes(issue.issueType);
}

export function hasNonTimingIssues(action: AssignedAction | undefined) {
  return (action?.issues || []).some((issue: Issue) => (
    !TIMING_ISSUE_TYPES.includes(issue.issueType)
  ));
}

export function excludeTimingIssues(issues: Issue[] | undefined) {
  return (issues || []).filter((issue: Issue) => (
    !TIMING_ISSUE_TYPES.includes(issue.issueType)
  ));
}

export function actionNonTimingIssues(action: AssignedAction | undefined) {
  return excludeTimingIssues(action?.issues || []);
}

export function actionsWithNonTimingIssues(actions: AssignedAction[]) {
  return actions.filter((action) => hasNonTimingIssues(action));
}

export function reorderTimingIssues(issues: Issue[]): Issue[] {
  const { timingIssues, nonTimingIssues } = issues.reduce<{
    timingIssues: Issue[];
    nonTimingIssues: Issue[];
  }>(
    (acc, issue) => {
      if (TIMING_ISSUE_TYPES.includes(issue.issueType)) {
        acc.timingIssues.push(issue);
      } else {
        acc.nonTimingIssues.push(issue);
      }
      return acc;
    },
    { timingIssues: [], nonTimingIssues: [] },
  );

  return [...nonTimingIssues, ...timingIssues];
}

export function isRoleSet(roleName: string | undefined, newHire: NewHireJourney | undefined) {
  if (!roleName || !newHire) {
    return false;
  }
  const roleFieldName = toRoleFieldName(roleName) as keyof NewHireJourney;
  return !!newHire[roleFieldName];
}

export function displayRoleName(issue: Issue) {
  return issue?.context?.roleName ? titleize(issue?.context?.roleName) : "Role";
}

interface AttributeValueArgs {
  company?: Company;
  newHire?: NewHireJourney;
  user?: User;
  roleName: PersonalizationTokenRoleName;
  attribute: string;
}

export function attributeValue({ company, newHire, user, roleName, attribute }: AttributeValueArgs): string | undefined {
  const camelCaseAttribute = camelCase(attribute);

  if (!roleName) {
    return undefined;
  }
  switch (roleName) {
    case "recipient":
    case "assignee":
    case "new_hire":
      return get(user, camelCaseAttribute);
    case "manager":
      return get(user, camelCaseAttribute);
    case "buddy":
      return get(user, camelCaseAttribute);
    case "people_team_contact":
      return get(user, camelCaseAttribute);
    case "new_hire_journey":
      return get(newHire, camelCaseAttribute);
    case "company":
      return get(company, camelCaseAttribute);
    default:
      console.error(`Cannot find attribute ${camelCaseAttribute} value - Invalid roleName ${roleName}: userId ${user?.id}, newHireJourneyId ${newHire?.id}, companyId ${company?.id}`);
      return undefined;
  }
}

interface AttributeIconArgs {
  company?: Company;
  newHire?: NewHireJourney;
  user?: User;
  roleName: PersonalizationTokenRoleName;
}

type returnIconType = IconType | ((props: IconProps) => JSX.Element) | ComponentWithAs<"svg", IconProps> | undefined

export function attributeIcon({ company, newHire, user, roleName }: AttributeIconArgs): returnIconType {
  if (!roleName) {
    return { icon: undefined, value: undefined };
  }
  switch (roleName) {
    case "recipient":
    case "assignee":
    case "new_hire":
      return CaminoNewHireIcon;
    case "manager":
      return CaminoManagerIcon;
    case "buddy":
      return CaminoBuddyIcon;
    case "people_team_contact":
      return CaminoPeopleTeamIcon;
    case "new_hire_journey":
      return CaminoNewHireIcon;
    case "company":
      return HiOutlineOfficeBuilding;
    default:
      console.error(`Cannot find attribute Icon - Invalid roleName ${roleName}: userId ${user?.id}, newHireJourneyId ${newHire?.id}, companyId ${company?.id}`);
      return undefined;
  }
}

type AttributeValueReturn = {
  icon: returnIconType;
  value: string | undefined;
};

export function attributeValueAndIcon({ company, newHire, user, roleName, attribute }: AttributeValueArgs): AttributeValueReturn {
  return ({
    icon: attributeIcon({ company, newHire, user, roleName }),
    value: attributeValue({ company, newHire, user, roleName, attribute }),
  });
}

export function timingIssueQueryArgs(action: AssignedAction) {
  return ({
    ...assignedActionDetailQueryArgs(action?.id),
    select: (returnAction: AssignedAction) => isActionTimingValid(returnAction),
  });
}

export function hasUnsentActions(actions: AssignedAction[]) {
  return (
    actions?.some((action) => ["pending", "error"].includes(action?.status))
  );
}

export function timingIssueAllActionsSentOrSkipped(issueContext: IssueContextQueryResult) {
  return !hasUnsentActions(issueContext?.actions || []);
}

export function timingIssueActionCompleted(action: AssignedAction) {
  if (["processed", "skipped", "removed"].includes(action?.status || "")) {
    return true;
  } else if (action?.trigger?.data?.datetime && !isInPast(action?.trigger?.data?.datetime)) {
    return true;
  // } else if (action?.trigger?.type === "dependency") {
  //   return true;
  }
  return false;
}

export function dedupeMissingSlackUsers(issues: Issue[] | undefined) {
  const userIssueMap = new Map<string, { hasMessageIssue: boolean; hasMissingSlackMention: boolean }>();

  // Collect issue types per user_id
  (issues || []).forEach((issue) => {
    const userId = issue?.context?.userId;
    if (!userId) return;

    const existing = userIssueMap.get(userId) || { hasMessageIssue: false, hasMissingSlackMention: false };

    if (issue.issueType === "missing_message_service_user") {
      existing.hasMessageIssue = true;
    }

    if (issue.issueType === "missing_attribute" && issue?.context?.attribute === "slack_mention") {
      existing.hasMissingSlackMention = true;
    }

    userIssueMap.set(userId, existing);
  });

  // Filter based on collected data
  return (issues || []).filter((issue) => {
    const userId = issue?.context?.userId;
    if (!userId) return true;

    const { hasMessageIssue, hasMissingSlackMention } = userIssueMap.get(userId) || {};

    // Keep only `missing_message_service_user` when both exist
    if (hasMessageIssue && hasMissingSlackMention) {
      return issue.issueType === "missing_message_service_user";
    }

    // Otherwise, keep all issues as they are
    return true;
  });
}
