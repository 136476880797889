import {
  Box,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spacer,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { generatePath, useParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import apiClient from "services/ApiClient";
import { API_ROUTES, PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import { ExtendedTemplateAction, TemplateAction } from "models/automation/templateWorkflow";
import { QUERY_KEYS } from "definitions/constants";
import {
  actionableTypeConverter,
  actionableTypeToIcon,
  automationTriggerText,
  emailTypeConverter,
  relationTargetConverter,
  fromPathTrigger,
  toPathTrigger,
} from "helpers/workflowActionableConverters";
import { GoClockFill, GoPersonFill } from "react-icons/go";
import ActionableContent from "components/ActionCard/ActionableContent";
import { ActionAttributeCard } from "components/ActionCard";
import { PathContent, PathRelationTarget, PathTrigger } from "models/automation";
import useCaminoStore from "hooks/useCaminoStore";
import Form from "components/ModalForm/PathActionForm/Form";
import React, { useCallback, useEffect } from "react";
import { Schema } from "components/ModalForm/PathActionForm/schema";
import { EditIcon } from "@chakra-ui/icons";
import { useNavbar } from "hooks/useNavbar";
import { getTemplateName } from "helpers/path";

const values = (
  actionable: PathContent & { trigger: PathTrigger; relationTarget: PathRelationTarget; name: string },
) => {
  if (!actionable) return {};

  return {
    ...actionable,
    content: {
      ...actionable.content,
      body: actionable.content.bodyTrix ?? actionable.content.body,
      description: actionable.content.descriptionTrix ?? actionable.content.description,
    },
    trigger: fromPathTrigger(actionable.trigger),
    reminders: actionable.reminders?.map((reminder) => ({
      ...reminder,
      trigger: fromPathTrigger(reminder.trigger),
    })),
  };
};

function EditModalButton({ data: action }: { data: TemplateAction }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const toast = useToast();
  const queryClient = useQueryClient();
  const defaultValues = values(action);

  const handleUpdate = useCallback(
    async (data: Partial<Schema>) => {
      const {
        actionType, emailType, taskType, relationTarget, name, content, trigger, reminders,
      } = data;

      apiClient
        .put(generatePath(API_ROUTES.templatePathActions.show, { id: action.id }), {
          actionType,
          relationTarget,
          name,
          emailType,
          taskType,
          content: { ...content },
          trigger: trigger ? toPathTrigger(trigger) : undefined,
          reminders: reminders?.map((reminder) => ({ ...reminder, trigger: toPathTrigger(reminder.trigger) })),
        })
        .then(() => {
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.templatePathAction, action.id] });
          toast({ title: "Action updated", status: "success" });
          onClose();
        })
        .catch(() => {
          toast({ title: "Error updating action", status: "error" });
        });
    },
    [action.id, queryClient, toast, onClose],
  );

  return (
    <>
      <IconButton aria-label="edit" size="sm" icon={<EditIcon />} onClick={onOpen} />

      <Modal initialFocusRef={initialRef} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={30}>Edit</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Form onSubmit={handleUpdate} isUpdate defaultValues={defaultValues} proceedText="Save" onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function TemplateActionCard({ data }: { data: TemplateAction }) {
  const currentCompanyId = useCaminoStore((state) => state.currentCompany?.id);

  const {
    name, companyId, content, actionType, trigger, relationTarget,
  } = data;

  const emailType = actionType === "email" ? ` (${emailTypeConverter(data.emailType)})` : "";

  return (
    <Card>
      <CardHeader pb={0}>
        <Heading size="sm">{name}</Heading>
      </CardHeader>
      <CardBody>
        <HStack my={4} spacing="4">
          <ActionAttributeCard
            icon={actionableTypeToIcon({ actionType, taskType: data?.taskType })}
            text={`${actionableTypeConverter({ actionType, taskType: data?.taskType })}${emailType}`}
          />
          <ActionAttributeCard icon={GoPersonFill} text={relationTargetConverter(relationTarget)} />
          <ActionAttributeCard icon={GoClockFill} text={automationTriggerText(trigger)} />
          <Spacer />
          {currentCompanyId === companyId && <EditModalButton data={data} />}
        </HStack>
        <ActionableContent
          content={{
            content,
            actionType,
            taskType: actionType === "task" ? data.taskType : undefined,
            reminders: actionType === "task" ? data.reminders : undefined,
          }}
        />
      </CardBody>
    </Card>
  );
}

export default function TemplatePathActionPage() {
  const { id } = useParams<{ id: string }>();
  const { setBreadcrumbs, setPageTitle } = useNavbar();

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.templatePathAction, id],
    queryFn: async ({ signal }) => apiClient.get<ExtendedTemplateAction>(generatePath(API_ROUTES.templatePathActions.show, { id }), signal),
  });

  useEffect(() => {
    if (data && !isLoading) {
      setBreadcrumbs([
        {
          name: "Template Paths",
          toUrl: generatePath(PEOPLE_TEAM_ROUTES.paths.index),
          isCurrentPage: false,
        },
        {
          name: getTemplateName(data?.templateWorkflow),
          toUrl: generatePath(PEOPLE_TEAM_ROUTES.templatePaths.show, { id: data?.templateWorkflow?.id || "" }),
          isCurrentPage: false,
        },
        {
          name: data?.name,
          isCurrentPage: true,
        },
      ]);
      setPageTitle(`Template Action: ${data?.name}`);
    }
  }, [data, isLoading, setBreadcrumbs, setPageTitle]);

  return (
    <AppPage>
      <Box>
        <Skeleton isLoaded={!isLoading}>{data && <TemplateActionCard data={data} />}</Skeleton>
      </Box>
    </AppPage>
  );
}
