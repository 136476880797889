import { Image } from "@chakra-ui/react";
import MindfulSVG from "assets/undraw_meditation.svg";
import ProgressSVG from "assets/undraw_setup_wizard.svg";
import CoffeeTimeSVG from "assets/undraw_hang_out.svg";
import AirSupportSVG from "assets/undraw_air_support.svg";
import CoworkersSVG from "assets/undraw_co_workers.svg";
import TeamSVG from "assets/undraw_team.svg";
import CoffeeTime2SVG from "assets/undraw_getting_coffee.svg";
import StatsSVG from "assets/undraw_charts.svg";
import CollabSVG from "assets/undraw_work_time.svg";
import JourneySVG from "assets/undraw_adventure_map.svg";
import AddActionSVG from "assets/undraw_messages.svg";
import TeamworkMakesTheDreamworkSVG from "assets/undraw_engineering_team.svg";
import MessagePromptSVG from "assets/undraw_going_up.svg";
import NewHireJourneyProgressSVG from "assets/undraw_in_progress.svg";
import PendingNewHireJourneyProgressSVG from "assets/undraw_time_management.svg";
import IntegrationsSVG from "assets/undraw_timeline.svg";
import LaunchingSVG from "assets/undraw_launching.svg";
import SpaceSVG from "assets/undraw_outer_space.svg";
import ControlPanelSVG from "assets/undraw_control_panel.svg";
import PowerfulSVG from "assets/undraw_powerful.svg";
import SeasonChangeSVG from "assets/undraw_season_change.svg";
import CelebrationSVG from "assets/undraw_celebration.svg";
import PeopleSVG from "assets/undraw_people.svg";
import ExcitingNewsSVG from "assets/undraw_exciting_news.svg";
import NoTasks from "assets/undraw_our_solution.svg";
import NoEmails from "assets/undraw_book_reading.svg";
import NoChatMessages from "assets/undraw_blank_canvas.svg";
import EmptyState from "./EmptyState";

interface MessageIconType {
  message: string;
  svg: string;
}

interface RandomEmptyStateProps {
  messageIcons: MessageIconType[];
  imageProps?: object;
  textProps?: object;
}

function RandomEmptyState({ messageIcons, imageProps = {}, textProps = {} }: RandomEmptyStateProps) {
  const randomIndex = Math.floor(Math.random() * messageIcons.length);
  const selectedResponse = messageIcons[randomIndex];

  return (
    <EmptyState message={selectedResponse.message} textProps={textProps}>
      <Image mx={{ base: 6 }} maxWidth="320px" maxHeight="320px" src={selectedResponse.svg} {...imageProps} />
    </EmptyState>
  );
}

interface EmptyStateProps {
  imageProps?: object;
  textProps?: object;
}

export function RandomEmptyTaskState() {
  const messageIcons = [
    { message: "No outstanding tasks here! Congrats!\n\nTake the time to go meditate?", svg: MindfulSVG },
    {
      message: "Your tasks are done! 🎉\n\nMaybe time to grab a coffee and connect with a new hire?",
      svg: CoffeeTimeSVG,
    },
    { message: "No open tasks! So much progress.", svg: ProgressSVG },
    {
      message: "No outstanding tasks!\n\nMaybe drop in on a new hire or manager and check-in on how it's going? 🤔",
      svg: AirSupportSVG,
    },
    { message: "No outstanding tasks!\n\nMore time to connect with coworkers! 🤗", svg: CoworkersSVG },
  ];

  return <RandomEmptyState messageIcons={messageIcons} />;
}

export function RandomEmptyPendingNewHiresState() {
  const messageIcons = [
    {
      message:
        "No pending New Hires! Time to relax!\n\nOr maybe check in with new hires that have been at the company for a little while now!",
      svg: CelebrationSVG,
    },
    {
      message: "No Pending New Hires! 🎉\n\nMay be a great moment to look at onboarding feedback surveys?",
      svg: PeopleSVG,
    },
    {
      message:
        "No Pending New Hires!\n\nCheck in with Buddies to see how their experience supporting New Hires has been? 🤔",
      svg: ExcitingNewsSVG,
    },
  ];

  return <RandomEmptyState messageIcons={messageIcons} />;
}

export function RandomEmptyRecentNewHiresState() {
  const messageIcons = [
    {
      message:
        "No recent New Hires! Time to relax!\n\nOr maybe check in with new hires that have been at the company for a little while now!",
      svg: CelebrationSVG,
    },
    {
      message: "No Recent New Hires! 🎉\n\nMay be a great moment to look at onboarding feedback surveys?",
      svg: PeopleSVG,
    },
    {
      message:
        "No Recent New Hires!\n\nCheck in with Buddies or Managers to see how their experience supporting New Hires has been? 🤔",
      svg: ExcitingNewsSVG,
    },
  ];

  return <RandomEmptyState messageIcons={messageIcons} />;
}

export function EmptyTaskStateForNewHireJourney() {
  const messageIcons = [
    {
      message: "No tasks for this New Hire yet!\n\nAdd a path to their journey and the tasks will appear here!",
      svg: JourneySVG,
    },
  ];

  return <RandomEmptyState messageIcons={messageIcons} />;
}

export function EmptyAutomationStateForNewHireJourney() {
  const messageIcons = [
    {
      message:
        "No automations for this new hire, YET!\n\nAdd a path to their journey and the automations will show up here",
      svg: NewHireJourneyProgressSVG,
    },
  ];

  return <RandomEmptyState messageIcons={messageIcons} />;
}

export function RandomEmptyIssueState() {
  const messageIcons = [
    {
      message: "Move along, no issues here.\n\nMaybe use this time to grab a coffee with a new hire?",
      svg: CoffeeTime2SVG,
    },
    {
      message: "You are swell. And have no issues to address!\n\n Perfect time to review some new hire feedback?",
      svg: StatsSVG,
    },
    {
      message:
        "Congrats! No issues to resolve.\n\nGentle reminder to ask recent new hires if they have any questions 😊",
      svg: CollabSVG,
    },
    {
      message:
        "Issues Inbox 0. Congrats!\n\nDon't forget to check in with the support network\n\nManagers, teammates, buddies, they need support too!",
      svg: TeamSVG,
    },
  ];

  return <RandomEmptyState messageIcons={messageIcons} />;
}

export function NoPathsCreatedYetState() {
  const messageIcons = [
    {
      message: "You currently have no paths!\n\nCreate a new one from scratch or use a template to get started! 🌱",
      svg: JourneySVG,
    },
  ];

  return <RandomEmptyState messageIcons={messageIcons} />;
}

export function NoPathsAssignedYetState({ imageProps = {}, textProps = {} }: EmptyStateProps) {
  const messageIcons = [
    {
      message:
        "No paths assigned!\n\nMake sure to assign some to this new hire so you get the full power of automations! ⚡",
      svg: JourneySVG,
    },
  ];

  return <RandomEmptyState imageProps={imageProps} messageIcons={messageIcons} textProps={textProps} />;
}

export function EmptyNewHireJourneyState() {
  const messageIcons = [
    {
      message: "You currently have no new hires!\n\nAdd your first to see the power of the automations 🚀",
      svg: NewHireJourneyProgressSVG,
    },
  ];

  return <RandomEmptyState messageIcons={messageIcons} />;
}

export function EmptyPendingNewHireJourneyState() {
  const messageIcons = [
    {
      message: "You currently have no pending new hires!\n\nNothing to do but wait 🤗",
      svg: PendingNewHireJourneyProgressSVG,
    },
  ];

  return <RandomEmptyState messageIcons={messageIcons} />;
}

export function EmptyIntegrationState() {
  const messageIcons = [
    {
      message: "You have no integrations connected!\n\nWe recommend connecting slack and your ATS! 🚀",
      svg: IntegrationsSVG,
    },
  ];

  return <RandomEmptyState messageIcons={messageIcons} />;
}

export function RandomEmptyActionState() {
  const messageIcons = [
    { message: "No actions in this path!\n\nAdd your first one! 🚀", svg: AddActionSVG },
    {
      message:
        "This path has no actions. Add one!\n\nMaybe a 'Send Message Prompt'? 🤔\n\nThese are one-click, forwardardable messages.",
      svg: MessagePromptSVG,
    },
    {
      message:
        "No actions in this path (yet)!\n\nGo ahead and add your first\n\nIt'll help everyone in collaborate more consistently! 🙏",
      svg: TeamworkMakesTheDreamworkSVG,
    },
  ];

  return <RandomEmptyState messageIcons={messageIcons} />;
}

export function RandomEmptyIssueActionsState() {
  const messageIcons = [
    { message: "No actions left! 🚀", svg: AddActionSVG },
    {
      message:
        "No more actions impacted by this issue.\n\nMaybe take a break and grab some coffee? 🤔\n\n",
      svg: MessagePromptSVG,
    },
    {
      message:
        "No more unresolved actions for this issue!\n\nGreat job! 🙏",
      svg: TeamworkMakesTheDreamworkSVG,
    },
  ];

  return <RandomEmptyState messageIcons={messageIcons} />;
}

export function RandomEmptyActivityFeedState() {
  const messageIcons = [
    { message: "No activities for this time period! 🌙", svg: LaunchingSVG },
    { message: "No activities for this time period! ⚡", svg: SpaceSVG },
    { message: "No activities for this time period! 🌟", svg: ControlPanelSVG },
    { message: "No activities for this time period! 💫", svg: PowerfulSVG },
    { message: "No activities for this time period! ⏳", svg: SeasonChangeSVG },
  ];

  return <RandomEmptyState messageIcons={messageIcons} />;
}

export function RandomEmptyEmailsStepState() {
  const messageIcons = [
    {
      message: "No emails!\n\nTo add emails, add a path that has emails! 📧",
      svg: NoEmails,
    },
  ];

  return <RandomEmptyState messageIcons={messageIcons} imageProps={{ maxWidth: "160px", maxHeight: "160px" }} />;
}

export function RandomEmptyChatMessagesStepState() {
  const messageIcons = [
    {
      message: "No chat messages!\n\nTo add chat messages, add a path that has chat messages! 💬",
      svg: NoChatMessages,
    },
  ];

  return <RandomEmptyState messageIcons={messageIcons} imageProps={{ maxWidth: "160px", maxHeight: "160px" }} />;
}

export function RandomEmptyTasksStepState() {
  const messageIcons = [
    {
      message: "No tasks!\n\nTo add tasks, add a path that has tasks! 📝",
      svg: NoTasks,
    },
  ];

  return <RandomEmptyState messageIcons={messageIcons} imageProps={{ maxWidth: "160px", maxHeight: "160px" }} />;
}
