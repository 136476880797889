/* eslint-disable max-len */
import { Flex } from "@chakra-ui/react";
import { FieldDef, Issue, IssueContextQueryResult } from "models/issue";
import { userQueryArgs, useUserDetailQuery } from "features/User/hooks";
import { CheckSlackConnection } from "features/User/Components/Slack";
import { RedirectToSearchUsersButton } from "features/Issues/Components/Button/SlackResolutionButtons";
import { OnlyIssue, ResolutionInfo } from "models/resolutions";
import { UserUpdateFormValues } from "components/Forms/UserUpdateForm";
import { User } from "models/user";
import UserFormFields from "features/Issues/Components/UserFormFields";
import { useMemo } from "react";

export function missingSlackUserIssueType(_: Issue) {
  return "Missing Slack Account";
}

export function expandedMissingSlackUserIssueType(issue: Issue, issueContext: IssueContextQueryResult) {
  if (issueContext?.user?.fullName) {
    return `Missing ${issueContext?.user?.fullName}'s Slack`;
  }
  return missingSlackUserIssueType(issue);
}

export function useMissingMessageServiceUserValue({ issue }: OnlyIssue) {
  const { data: user } = useUserDetailQuery(issue?.context?.userId, { enabled: !!issue?.context?.userId });
  return user?.messageServiceId;
}

export function missingMessageServiceUserValue(issueContext: IssueContextQueryResult) {
  return issueContext?.user?.messageServiceId;
}

export function IssueModalInputFields({ issue }: { issue: Issue }) {
  const { data: user } = useUserDetailQuery(issue?.context?.userId, {
    enabled: !!issue?.context?.userId,
    staleTime: 0,
  });
  const fields: FieldDef<UserUpdateFormValues>[] = useMemo(() => (
    [{ name: "workEmail", label: `${user?.fullName ? `${user?.fullName} ` : ""}Work Email` }]
  ), [user?.fullName]);
  return (
    <Flex direction="column" gap="4">
      {user && (
        <UserFormFields
          user={user}
          fieldsDefs={fields}
          issue={issue}
        />
      )}
      <RedirectToSearchUsersButton />
      <CheckSlackConnection userId={issue?.context?.userId} />
    </Flex>
  );
}

export function missingMessageServiceUserResolution(issue: Issue, issueContext: IssueContextQueryResult): ResolutionInfo {
  const { user } = issueContext || {};
  return ({
    issueType: "missing_message_service_user",
    tagText: missingSlackUserIssueType(issue),
    title: user?.fullName ? `Cannot find ${user?.fullName} in Slack` : "Cannot find user in Slack",
    additionalContext: `We use the Camino 'Work Email' to sync with Slack. We cannot find ${user?.firstName || "the user"}'s Slack account. Ensure the Slack email matches Camino's 'Work Email'.`,
    inputComponent: IssueModalInputFields,
  });
}

export function missingMessageServiceUserQueryArgs(issue: Issue) {
  const { userId } = issue?.context || {};
  return {
    ...userQueryArgs(userId),
    select: (user: User) => user?.messageServiceId,
  };
}

export function missingMessageServiceUserContextQueries(issue: Issue) {
  const { userId } = issue?.context || {};
  return [userQueryArgs(userId)];
}
