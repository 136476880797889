import FieldByType from "features/PendingNewHire/Form/FieldByType";
import { UseFormReturn } from "react-hook-form";
import { camelCase } from "lodash";
import { FormFieldBase } from "components/Form/typings";
import { FIELD_CONFIG, UserUpdateFormValues } from "./formDefinition";

interface DynamicUserFieldProps extends FormFieldBase {
  form: UseFormReturn<any>;
  fieldName: keyof UserUpdateFormValues;
  customLabel?: string;
}

export default function DynamicUserField({
  form,
  fieldName,
  customLabel,
  ...rest
}: DynamicUserFieldProps) {
  if (!fieldName) {
    return null;
  }
  const camelFieldName = camelCase(fieldName) as keyof UserUpdateFormValues;
  const field = FIELD_CONFIG[camelFieldName];
  const label = customLabel || field?.label;
  if (!field) {
    console.error(`Field ${camelFieldName} not found`);
    return null;
  }
  return (
    <FieldByType
      form={form}
      key={camelFieldName}
      {...field}
      label={label}
      {...rest}
    />
  );
}
