/* eslint-disable max-len */
import { Icon, IconProps, useColorModeValue } from "@chakra-ui/react";

function Collaborate({ ...props }: IconProps) {
  const color = useColorModeValue("notBlack.50", "white");
  return (
    <Icon
      viewBox="0 0 16 16"
      fill="none"
      color={color}
      {...props}
    >
      <path
        d="M3 10.5v-.5H2v.5a3.5 3.5 0 0 0 3.5 3.5h1.5v-1H5.5a2.5 2.5 0 1 1-2.5-2.5m9-5v.5h1v-.5a3.5 3.5 0 0 0-3.5-3.5h-1.5v1h1.5a2.5 2.5 0 1 1 2.5 2.5m-6.5 0H2.5a1.5 1.5 0 0 0-1.5 1.5v1h1v-1a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v1h1v-1a1.5 1.5 0 0 0-1.5-1.5m-1.5-.5a2 2 0 1 0-2-2 2 2 0 0 0 2 2m0-3a1 1 0 1 1-1 1 1 1 0 0 1 1-1m9.5 10.5h-3a1.5 1.5 0 0 0-1.5 1.5v1h1v-1a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v1h1v-1a1.5 1.5 0 0 0-1.5-1.5m-3.5-2.5a2 2 0 1 0 2-2 2 2 0 0 0-2 2m3 0a1 1 0 1 1-1-1 1 1 0 0 1 1 1"
        fill="currentColor"
      />
    </Icon>
  );
}
export default Collaborate;
