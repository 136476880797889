import { PropsWithChildren, forwardRef, useCallback, useState } from "react";
import {
  Button, HStack, Icon, As, ButtonProps, Text, Textarea, useToast,
} from "@chakra-ui/react";
import PopoverFormWrapper from "components/Popover/PopoverFormWrapper";
import { useMutation } from "@tanstack/react-query";
import apiClient from "services/ApiClient";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { PiShootingStar } from "react-icons/pi";

interface NavButtonProps extends ButtonProps {
  icon: As;
  label: string;
  subtle?: boolean;
  expanded?: boolean;
}

const SidebarTriggerButton = forwardRef<HTMLButtonElement, PropsWithChildren<any>>((props: NavButtonProps, ref) => {
  const { icon, label, subtle, expanded, ...buttonProps } = props;
  return (
    <Button ref={ref} size="xs" width="100%" variant="tertiary" justifyContent="start" {...buttonProps}>
      <HStack spacing="3">
        <Icon as={icon} boxSize="6" color="fg.subtle" />
        {expanded ? (
          <Text color={subtle ? "gray.400" : undefined}>{label}</Text>
        ) : null}
      </HStack>
    </Button>
  );
});

export default function MakeAWishButton({ expanded }: { expanded: boolean }) {
  const [wishText, setWishText] = useState("");
  const toast = useToast({
    status: "success",
  });
  const { mutate } = useMutation({
    mutationFn: (data: any) => apiClient.post<{ id: string }>(API_ROUTES.feedback, {
      feedback: { feedbackType: "Make a Wish", feedback: data },
    }),
    onSuccess: async (resp, data) => {
      setWishText("");
      toast({
        title: "Wish successfully submitted! We'll do our best to make it come true.",
      });
    },
    onError: (mutateError) => {
      console.log("mutateError", mutateError);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    },
  });

  const handleSubmit = useCallback(() => {
    if (wishText.trim()) {
      mutate(wishText);
    } else {
      toast({
        title: "Error",
        description: "Please enter your wish before submitting.",
        status: "error",
      });
    }
  }, [wishText, mutate, toast]);

  return (
    <PopoverFormWrapper
      onSubmit={handleSubmit}
      buttonText="Send"
      triggerButton={<SidebarTriggerButton subtle icon={PiShootingStar} label="Make a wish" expanded={expanded} />}
      placement="right-start"
      footerProps={{ backgroundColor: "bg.subtle" }}
      onClose={() => setWishText("")}
    >
      <Textarea
        value={wishText}
        onChange={(e) => setWishText(e.target.value)}
        placeholder="Wish for anything! Help us make the app better..."
        rows={3}
        border="none"
        resize="none"
        _focus={{ border: "none", boxShadow: "none" }}
      />
    </PopoverFormWrapper>
  );
}
