/* eslint-disable max-len */
import { Icon, IconProps, useColorModeValue } from "@chakra-ui/react";

function BaselinePeopleOutline({ ...props }: IconProps) {
  const color = useColorModeValue("notBlack.50", "white");
  return (
    <Icon
      viewBox="0 0 12 12"
      fill="none"
      color={color}
      {...props}
    >
      <path
        d="M8.25 7C7.65 7 6.715 7.17 6 7.5C5.285 7.165 4.35 7 3.75 7C2.665 7 0.5 7.54 0.5 8.625V10H11.5V8.625C11.5 7.54 9.335 7 8.25 7ZM6.25 9.25H1.25V8.625C1.25 8.355 2.53 7.75 3.75 7.75C4.97 7.75 6.25 8.355 6.25 8.625V9.25ZM10.75 9.25H7V8.625C7 8.395 6.9 8.195 6.74 8.015C7.18 7.865 7.72 7.75 8.25 7.75C9.47 7.75 10.75 8.355 10.75 8.625V9.25ZM3.75 6.5C4.715 6.5 5.5 5.715 5.5 4.75C5.5 3.785 4.715 3 3.75 3C2.785 3 2 3.785 2 4.75C2 5.715 2.785 6.5 3.75 6.5ZM3.75 3.75C4.3 3.75 4.75 4.2 4.75 4.75C4.75 5.3 4.3 5.75 3.75 5.75C3.2 5.75 2.75 5.3 2.75 4.75C2.75 4.2 3.2 3.75 3.75 3.75ZM8.25 6.5C9.215 6.5 10 5.715 10 4.75C10 3.785 9.215 3 8.25 3C7.285 3 6.5 3.785 6.5 4.75C6.5 5.715 7.285 6.5 8.25 6.5ZM8.25 3.75C8.8 3.75 9.25 4.2 9.25 4.75C9.25 5.3 8.8 5.75 8.25 5.75C7.7 5.75 7.25 5.3 7.25 4.75C7.25 4.2 7.7 3.75 8.25 3.75Z"
        fill="currentColor"
      />
    </Icon>
  );
}
export default BaselinePeopleOutline;
