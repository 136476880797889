/* eslint-disable import/prefer-default-export */
import { EmailRecipient } from "models/automation";
import { titleize } from "helpers/string";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { BasicUser } from "models/user";
import NameTag from "components/Tag/NameTag";

function getEmailRecipient(emailRecipient: EmailRecipient, size = "md" as "sm" | "md") {
  switch (emailRecipient?.recipient?.targetType) {
    case "User":
      return (
        <NameTag name={emailRecipient?.customEmail ?? emailRecipient?.recipient?.target?.fulName} size={size} />
      );
    case "OnboardingJourneyNetworkRelation":
      return <NameTag name={titleize(emailRecipient?.recipient?.target?.name)} size={size} />;
    default:
      return undefined;
  }
}

export function actionRecipients(action: AssignedAction, size = "md" as "sm" | "md"): JSX.Element[] {
  const recipients: JSX.Element[] = [];
  if (action?.workflowAction?.actionable?.relationTarget) {
    recipients.push(<NameTag name={action?.targetable?.fullName || titleize(action?.workflowAction?.actionable?.relationTarget)} size={size} />);
  } else if (action?.workflowAction?.actionable.specificTargetable) {
    const record = action?.workflowAction?.actionable.specificTargetable?.fullName
      ? <NameTag name={action?.workflowAction?.actionable.specificTargetable as BasicUser} size={size} />
      : <NameTag name={`#${action?.workflowAction?.actionable.specificTargetable?.name}`} size={size} />;
    recipients.push(record);
  }

  action?.workflowAction?.actionable?.additionalRecipients?.forEach((recipient: EmailRecipient) => {
    const recipientTag = getEmailRecipient(recipient);
    if (recipientTag) {
      recipients.push(recipientTag);
    }
  });

  return recipients;
}
