import { useCallback, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "services/ApiClient";
import { camelCase, pick } from "lodash";
import useAutoSave from "components/FormElements/useAutoSave";
import { apiCurrentCompanyPath } from "helpers/url";
import { Company } from "models/company";
import { useDeepCompareMemoize } from "use-deep-compare-effect";
import { CompanyUpdateFormValues, companyUpdateSchema, values } from "./formDefinition";
import { companyKeys, setNewCompanyInQueryClient } from "../hooks";

interface useCompanyAutoSaveFormProps {
  company: Company | undefined;
  fields: Array<keyof CompanyUpdateFormValues>;
  onSuccess?: () => void;
  onClose?: () => void;
}

export default function useCompanyAutoSaveForm({
  company,
  fields,
  onSuccess = () => {},
  onClose = () => {},
}: useCompanyAutoSaveFormProps) {
  const camelFields = useMemo(() => fields.map((field) => camelCase(field)), [fields]);
  const queryClient = useQueryClient();
  const toast = useToast({
    status: "success",
  });
  const onUpdateCompanySuccess = useCallback((resp: Company) => {
    setNewCompanyInQueryClient(queryClient, resp);
    onSuccess?.();
  }, [onSuccess, queryClient]);

  const defaultValues = useMemo(() => {
    if (!company) return {};
    return pick(values(company), camelFields);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useDeepCompareMemoize([company, camelFields]));

  const form = useForm({
    mode: "onBlur",
    resolver: yupResolver(companyUpdateSchema),
    values: defaultValues,
  });
  useAutoSave({
    form,
    submitUrl: apiCurrentCompanyPath(),
    onSuccess: onUpdateCompanySuccess,
  });
  const { formState: { dirtyFields } } = form;

  /* HandleOnSubmit */
  const { mutate: onSubmit, isPending: submitIsLoading } = useMutation({
    mutationFn: (data: CompanyUpdateFormValues) => {
      const updateData = Object.fromEntries(
        Object.entries(data).filter(([key]) => dirtyFields[key as keyof CompanyUpdateFormValues] === true),
      );
      return apiClient.put<Company>(apiCurrentCompanyPath(), updateData);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: companyKeys.current() });
      onSuccess();
      onClose();
      toast({
        title: "Success!",
      });
    },
    onError: (mutateError) => {
      console.log("UserUpdateForm Error", mutateError);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    },
  });
  return {
    form,
    isReady: !!company,
    onSubmit,
    submitIsLoading,
  };
}
