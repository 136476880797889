import { HStack, Stack, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object as yupObject, string as yupString } from "yup";
import { API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { AssignedPath } from "models/automation/scheduledWorkflow";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import { NewHireJourney } from "models/newHire";
import GenericAsyncSelectField from "components/FormElements/Fields/SelectFields/GenericAsyncSelectField";
import { assignedPathKeys } from "features/AssignedPath/hooks";
import { apiNewHireListPath } from "helpers/url";

const ASSIGNED_NEW_HIRE_FIELD = {
  type: "SELECT",
  required: true,
  label: "New Hire to Assign Path",
  name: "userId",
  helperText: "User to assign the path to.",
};

const createScheduledWorkflowFormSchema = yupObject({
  [ASSIGNED_NEW_HIRE_FIELD.name]: yupString().label(ASSIGNED_NEW_HIRE_FIELD.label).required(),
});

interface AssignPathFormProps {
  workflowId: string;
  onClose: () => void;
  updatePeopleTeamContactId: (value: string) => void;
}

export default function AssignPathForm({ workflowId, onClose }: AssignPathFormProps) {
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit: handleOnNewHireSubmit,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(createScheduledWorkflowFormSchema),
  });
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const queryClient = useQueryClient();
  const [newHireCacheKey] = useState("");
  const [otherCacheKey, updateOtherCacheKey] = useState("");

  const onChangeNewHireCallback = (journey: NewHireJourney | undefined) => {
    if (otherCacheKey !== journey?.user?.id) updateOtherCacheKey(journey?.user?.id || "");
  };

  /* HandleOnSubmit */
  const onAddNewHireSubmit = async (data: any) => {
    try {
      const {
        userId,
        peopleTeamContactId: peopleTeamId,
        managerId,
        buddyId,
        offerSignDate,
        startDate,
        offerExtensionDate,
      } = data;
      const postData = {
        scheduledWorkflow: { workflowId },
        onboarding_journey: {
          userId,
          peopleTeamContactId: peopleTeamId,
          managerId,
          buddyId,
          offerExtensionDate,
          offerSignDate,
          startDate,
        },
      };
      setIsLoading(true);
      const res = await apiClient.post<AssignedPath>(API_ROUTES.assignedPaths.base, postData);
      setIsLoading(false);

      queryClient.invalidateQueries({ queryKey: assignedPathKeys.lists() });
      toast({
        title: `${res.onboardingJourney.user.fullName} Successfuly Assigned Path`,
        status: "success",
      });
      onClose();
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    }
  };

  return (
    <>
      <Stack alignItems="start">
        <Stack width="60%" alignItems="start">
          <GenericAsyncSelectField
            fetchPath={apiNewHireListPath()}
            extractValue={(v: NewHireJourney) => v.user.id}
            embedOption={(v) => ({
              value: v.user.id,
              label: `${v.user.fullName} (${v.user.workEmail || "No Email"})`,
              rawValue: v,
            })}
            onChangeCallback={onChangeNewHireCallback}
            cacheKey={newHireCacheKey}
            control={control}
            {...ASSIGNED_NEW_HIRE_FIELD}
            errors={errors}
          />
        </Stack>
      </Stack>
      <HStack mt={12} justify="end">
        <ProceedCancelButtons
          handleSubmit={handleOnNewHireSubmit(onAddNewHireSubmit)}
          onClose={onClose}
          proceedText="Assign"
          proceedDisabled={!isDirty}
          isLoading={isLoading}
        />
      </HStack>
    </>
  );
}
