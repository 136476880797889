import { IssueType, Issue, IssueContextQueryResult } from "models/issue";
import { expandedMissingAttributeIssueType, missingAttributeIssueType } from "./definitions/MissingAttribute";
import { expandedMissingEmailIssueType, missingEmailIssueType } from "./definitions/MissingEmail";
import { missingMessageServiceIssueType } from "./definitions/MissingMessageService";
import { expandedMissingSlackUserIssueType, missingSlackUserIssueType } from "./definitions/MissingMessageServiceUser";
import { referenceEventOverlapIssueType } from "./definitions/ReferenceEventOverlap";
import { triggerAtInPastResolutionIssueType } from "./definitions/TriggerAtInPast";
import { unassignedRoleIssueType } from "./definitions/UnassignedRole";
import { missingChannelIssueType } from "./definitions/MissingChannel";

export const ISSUE_TYPE_MAP: Record<IssueType, (issue: Issue) => string> = {
  missing_channel: missingChannelIssueType,
  missing_attribute: missingAttributeIssueType,
  missing_email: missingEmailIssueType,
  missing_message_service: missingMessageServiceIssueType,
  missing_message_service_user: missingSlackUserIssueType,
  reference_event_overlap: referenceEventOverlapIssueType,
  trigger_at_in_past: triggerAtInPastResolutionIssueType,
  unassigned_role: unassignedRoleIssueType,
};

export default function resolveIssueType(issue: Issue): string | undefined {
  if (!issue) {
    return undefined;
  }
  const issueType = ISSUE_TYPE_MAP[issue.issueType];
  if (!issueType) {
    throw new Error(`Unknown issue type: ${issue?.issueType} for issue ${issue?.id}`);
  }
  return issueType(issue);
}

export const EXPANDED_ISSUE_TYPE_MAP: Partial<Record<IssueType, (issue: Issue, issueContext: IssueContextQueryResult) => string>> = {
  missing_email: expandedMissingEmailIssueType,
  missing_message_service_user: expandedMissingSlackUserIssueType,
  missing_attribute: expandedMissingAttributeIssueType,
};

export function expandedIssueTypeResolver(issue: Issue): ((issue: Issue, issueContext: IssueContextQueryResult) => string) | undefined {
  if (!issue) {
    return undefined;
  }
  return EXPANDED_ISSUE_TYPE_MAP[issue.issueType];
}
