import { Button, ButtonProps, Icon } from "@chakra-ui/react";
import { ElementType } from "react";

interface GreyStatusButtonProps extends ButtonProps {
  icon: ElementType;
  children: string;
  iconProps?: object;
}

export default function GreyStatusButton({ icon, iconProps = {}, children, ...rest }: GreyStatusButtonProps) {
  return (
    <Button
      leftIcon={icon ? <Icon as={icon} boxSize="3" {...iconProps} /> : undefined}
      variant="outline"
      colorScheme="skippedGrey"
      color="skippedGrey.500"
      fontWeight="bold"
      border="1.25px solid"
      fontSize="sm"
      py={0}
      h="32px"
      w="142px"
      cursor="auto"
      sx={{
        "span:first-of-type": {
          marginInlineEnd: ".25rem",
        },
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}
