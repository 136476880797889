import { useQueryClient } from "@tanstack/react-query";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import { GrayTableButton, PrimaryTableButton } from "components/Button/TableButton";
import { assignedActionKeys, useAssignedActionBatchForceSend, useForceSendAssignedAction } from "features/AssignedAction/hooks";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { ButtonProps } from "@chakra-ui/react";
import RedOutlineButton from "components/Button/RedOutlineButton";
import React from "react";
import { Issue } from "models/issue";
import TooltipWrapper from "components/TooltipWrapper";
import { issueKeys } from "features/Issues/hooks";

const CONFIRM_SEND_NOW = {
  header: "Confirm Send Now",
  body: "This cannot be undone! Be sure you want to send this actions now.",
  confirmButtonText: "Send Now",
};

interface SendNowButtonProps extends ButtonProps {
  action: AssignedAction;
  onSuccess?: (resp: any) => void;
}

export function SendNowButtonWrapper({ action, onSuccess, children }: SendNowButtonProps) {
  const queryClient = useQueryClient();

  const { mutate: performRequest, isReady } = useForceSendAssignedAction({
    action,
    onSuccess: (resp: any) => {
      queryClient.invalidateQueries({ queryKey: issueKeys.lists() });
      queryClient.invalidateQueries({ queryKey: issueKeys.details() });
      queryClient.invalidateQueries({ queryKey: assignedActionKeys.detail(action.id) });
      onSuccess?.(resp);
    },
  });

  const isDisabled = !isReady;
  const { isDisabled: childIsDisabled } = (children as JSX.Element).props;

  return (
    <TooltipWrapper
      condition={childIsDisabled || isDisabled}
      tooltipText="This action cannot be sent until you've resolved all other issues."
    >
      <ButtonWithConfirm
        handleConfirm={() => performRequest()}
        intent="confirmation"
        {...CONFIRM_SEND_NOW}
      >
        {React.cloneElement(
          children as JSX.Element,
          { isDisabled: childIsDisabled || isDisabled },
        )}
      </ButtonWithConfirm>
    </TooltipWrapper>
  );
}

export function GraySendNowButton({ action, onSuccess, ...rest }: SendNowButtonProps) {
  return (
    <SendNowButtonWrapper action={action} onSuccess={onSuccess}>
      <GrayTableButton {...rest}>Send Now</GrayTableButton>
    </SendNowButtonWrapper>
  );
}

export function PrimarySendNowButton({ action, onSuccess, ...rest }: SendNowButtonProps) {
  return (
    <SendNowButtonWrapper action={action} onSuccess={onSuccess}>
      <PrimaryTableButton {...rest}>Send Now</PrimaryTableButton>
    </SendNowButtonWrapper>
  );
}

interface SendAllActionsNowButtonProps {
  issue: Issue;
}

export function SendAllActionsNowButton({ issue }: SendAllActionsNowButtonProps) {
  const { scheduledWorkflowActionIds: ids } = issue;
  const {
    mutate,
    isPending,
  } = useAssignedActionBatchForceSend();
  return (
    <ButtonWithConfirm
      {...CONFIRM_SEND_NOW}
      handleConfirm={() => mutate(ids)}
    >
      <RedOutlineButton
        isLoading={isPending}
      >
        Send All Now
      </RedOutlineButton>
    </ButtonWithConfirm>
  );
}
