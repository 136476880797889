/* eslint-disable max-len */
import { Icon, IconProps, useColorModeValue } from "@chakra-ui/react";

function SlackIcon({ ...props }: IconProps) {
  const color = useColorModeValue("notBlack.50", "white");
  return (
    <Icon
      viewBox="0 0 12 12"
      fill="none"
      color={color}
      {...props}
    >
      <path
        d="M3 7.5a1 1 90 0 1-1 1a1 1 90 0 1-1-1a1 1 90 0 1 1-1h1zm.5 0a1 1 90 0 1 1-1a1 1 90 0 1 1 1v2.5a1 1 90 0 1-1 1a1 1 90 0 1-1-1zm1-4a1 1 90 0 1-1-1a1 1 90 0 1 1-1a1 1 90 0 1 1 1v1zm0 .5a1 1 90 0 1 1 1a1 1 90 0 1-1 1H2a1 1 90 0 1-1-1a1 1 90 0 1 1-1zm4 1a1 1 90 0 1 1-1a1 1 90 0 1 1 1a1 1 90 0 1-1 1h-1zm-.5 0a1 1 90 0 1-1 1a1 1 90 0 1-1-1V2.5a1 1 90 0 1 1-1a1 1 90 0 1 1 1zm-1 4a1 1 90 0 1 1 1a1 1 90 0 1-1 1a1 1 90 0 1-1-1v-1zm0-.5a1 1 90 0 1-1-1a1 1 90 0 1 1-1h2.5a1 1 90 0 1 1 1a1 1 90 0 1-1 1z"
        fill="currentColor"
      />
    </Icon>
  );
}
export default SlackIcon;
