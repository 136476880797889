import { HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import Clock from "components/Icon/Iconify/Clock";
import { dateTo12hrTimeOfDayWithTZInZone } from "helpers/time";
import { longAttributeStyles } from "../styles";

export default function SendAtInTZAttribute({ action }: { action: AssignedAction }) {
  return (
    <HorizontalGreyBlackField
      icon={Clock}
      label="Local Send Time"
      iconProps={{ mt: "4.5px" }}
      labelContainerProps={longAttributeStyles.labelContainerProps}
    >
      {dateTo12hrTimeOfDayWithTZInZone(action?.trigger?.data?.datetime, action?.timezone || "America/Los_Angeles")}
    </HorizontalGreyBlackField>
  );
}
