import { useQuery, useQueryClient } from "@tanstack/react-query";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { apiStagePathById } from "helpers/url";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import apiClient from "services/ApiClient";

const stagesKeys = {
  all: ["stages"] as const,
  lists: () => [...stagesKeys.all, "list"] as const,
  list: (filters: string) => [...stagesKeys.lists(), { filters }] as const,
  details: () => [...stagesKeys.all, "detail"] as const,
  detail: (id: string) => [...stagesKeys.details(), id] as const,
};

export const useStageDetailQuery = (id: string, options = {}) => useQuery<AssignedAction>({
  queryKey: stagesKeys.detail(id),
  queryFn: async () => apiClient.get(apiStagePathById(id)),
  staleTime: Infinity,
  ...options,
});

const stageQueryArguments = (queryString?: string) => {
  const query = queryString ? `?${queryString}` : "";
  return {
    queryKey: queryString ? stagesKeys.list(queryString) : stagesKeys.lists(),
    queryFn: async () => apiClient.get(`${API_ROUTES.stages.base}${query}`),
    staleTime: Infinity,
    placeholderData: [],
  };
};

export const useStageListQuery = (queryString?: string, options = {}) => useQuery({
  ...stageQueryArguments(queryString),
  ...options,
});

// This does not preload unless you use <Suspense> react components
// https://tanstack.com/query/latest/docs/framework/react/guides/prefetching#prefetch-in-components
export const usePreloadStageListQuery = (queryString: string, options = {}) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery({ ...stageQueryArguments(queryString), ...options });
};
