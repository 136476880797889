import {
  Box,
  Container,
  Flex,
  HStack,
  IconButton,
  useBreakpointValue,
  VStack,
  Button,
  Spacer,
  Text,
  Heading,
  BoxProps,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import AvatarDropdown from "components/AvatarDropdown";
import useCaminoStore from "hooks/useCaminoStore";
import { API_ROUTES, SHARED_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { User } from "models/user";
import { useQueryClient } from "@tanstack/react-query";
import Breadcrumbs, { BreadcrumbInfo, BreadCrumbNotCurrentPage } from "components/Breadcrumbs";
import useNavbarStore from "hooks/useNavbar";
import ROLE from "models/role";
import NewHireButtonWithModal from "features/NewHireJourney/NewHireForm/NewHireButtonWithModal";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";

function ImpersonatingBanner() {
  const setValue = useCaminoStore((state) => state.setValue);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleClick = async () => {
    const { company, ...user } = await apiClient.delete<User>(API_ROUTES.admin.users.impersonations.base);
    queryClient.clear();
    setValue({
      currentUser: user,
      currentCompany: company,
      currentRole: user.permission[0],
      isImpersonating: false,
    });
    navigate(SHARED_ROUTES.home);
  };

  return (
    <HStack
      bg="sortaOrange.500"
      color="text.warning"
      fontSize="sm"
      fontWeight="bold"
      px="2"
      py="1"
      rounded="md"
      textTransform="uppercase"
      width="100%"
    >
      <Text>Impersonating User</Text>
      <Spacer />
      <Button onClick={handleClick}>Stop Impersonating</Button>
    </HStack>
  );
}

interface BreadcrumbTitleProps {
  pageTitle: string | null;
  breadcrumbs: Array<BreadcrumbInfo | BreadCrumbNotCurrentPage>;
}

function BreadcrumbTitle({ pageTitle, breadcrumbs }: BreadcrumbTitleProps) {
  const headingColor = useColorModeValue("notBlack.600", "var(--chakra-colors-whiteAlpha-900)");
  return (
    <VStack alignItems="start" py="2">
      {pageTitle ? (
        <Heading color={headingColor} fontSize="xl" size="xs">
          {pageTitle}
        </Heading>
      ) : (
        <Heading color={headingColor} fontSize="xl" size="xs">
          {" "}
        </Heading>
      )}
      {breadcrumbs?.length ? (
        <Breadcrumbs mt={-1} breadcrumbInfos={breadcrumbs} includeHome />
      ) : (
        <Breadcrumbs mt={-1} breadcrumbInfos={[]} />
      )}
    </VStack>
  );
}

interface BackTitleProps {
  backLocationPath: string | null;
  backLocationName: string | null;
}

function BackTitle({ backLocationName, backLocationPath }: BackTitleProps) {
  const headingColor = useColorModeValue("notBlack.600", "var(--chakra-colors-whiteAlpha-900)");
  return (
    <Flex py="2" align="center" gap="3">
      {backLocationPath && (
        <Flex align="center" height="100%">
          <ReactRouterLink to={backLocationPath}>
            <Flex align="center">
              <Icon as={AiOutlineLeft} boxSize="6" />
            </Flex>
          </ReactRouterLink>
        </Flex>
      )}
      {backLocationName ? (
        <Heading color={headingColor} fontSize="xl" size="xs">
          {backLocationName}
        </Heading>
      ) : (
        <Heading color={headingColor} fontSize="xl" size="xs">
          {" "}
        </Heading>
      )}
    </Flex>
  );
}

function Navbar({ ...rest }: BoxProps) {
  const [user, isImpersonating] = useCaminoStore((state) => [state.currentUser, state.isImpersonating]);
  const isDesktop = useBreakpointValue({
    base: true,
    sm: false,
    md: false,
    lg: true,
  });
  const [breadcrumbs, pageTitle, backLocationPath, backLocationName] = useNavbarStore(
    (state) => [state.breadcrumbs, state.pageTitle, state.backLocationPath, state.backLocationName],
  );
  const bgColor = useColorModeValue("creamBg.opacity10", "gray.500");

  return (
    <Box as="section" pb={{ base: "4", md: "6" }} {...rest}>
      <Box as="nav" width={{ base: "full" }} bg={bgColor}>
        <Container py={{ base: "1", lg: "2" }} ml="0">
          {isImpersonating && <ImpersonatingBanner />}
          {isDesktop ? (
            <HStack spacing="10" justify="space-between" alignItems="center">
              { breadcrumbs?.length ? (
                <BreadcrumbTitle breadcrumbs={breadcrumbs} pageTitle={pageTitle} />
              ) : (
                <BackTitle backLocationName={backLocationName} backLocationPath={backLocationPath} />
              )}
              <Flex justify="end" align="center" flex="1">
                {user && (
                  <VStack spacing="2">
                    <HStack spacing="6">
                      {user.permission && user.permission.includes(ROLE.PEOPLE_TEAM) && <NewHireButtonWithModal />}
                      <AvatarDropdown />
                    </HStack>
                  </VStack>
                )}
              </Flex>
            </HStack>
          ) : (
            <HStack spacing="10" justify="space-between">
              <IconButton variant="tertiary" icon={<FiMenu fontSize="1.25rem" />} aria-label="Open Menu" />
            </HStack>
          )}
        </Container>
      </Box>
    </Box>
  );
}

export default Navbar;
