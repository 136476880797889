import { QueryClient, queryOptions, useQuery } from "@tanstack/react-query";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";
import { apiIssuePathById, apiIssuesPathByActionId } from "helpers/url";
import { createQueryString, QueryParams } from "helpers/queryString";
import { Issue, IssueContextQueryResult, TIMING_ISSUE_TYPES } from "models/issue";
import { assignedActionKeys } from "features/AssignedAction/hooks";
import { useCurrentCompanyQuery } from "features/Company/hooks";
import { newHireJourneyKeys, useNewHireJourneyDetailQuery, useNewHiresListQuery } from "features/NewHireJourney/hooks";
import { useUserDetailQuery } from "features/User/hooks";
import { uniq } from "lodash";
import { useMemo } from "react";
import { NewHireJourney, NewHireJourneyId } from "models/newHire";
import { AssignedAction } from "models/automation/scheduledWorkflow";

export const issueKeys = {
  all: ["issues"] as const,
  lists: () => [...issueKeys.all, "list"] as const,
  list: (filters: string) => [...issueKeys.lists(), ...filters] as const,
  details: () => [...issueKeys.all, "detail"] as const,
  detail: (id: string) => [...issueKeys.details(), id] as const,
  byActions: () => [...issueKeys.all, "action"] as const,
  byActionId: (actionId: string) => [...issueKeys.byActions(), actionId] as const,
  count: () => [...issueKeys.all, "count"] as const,
  issueActions: () => [...issueKeys.all, "issueActions"] as const,
  issueActionsById: (issueId: string) => [...issueKeys.issueActions(), issueId] as const,
  newHiresWithIssues: () => [...issueKeys.all, "newHiresWithIssues"] as const,
};

export function clearIssueQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries({ queryKey: issueKeys.lists() });
  queryClient.invalidateQueries({ queryKey: issueKeys.details() });
  queryClient.invalidateQueries({ queryKey: issueKeys.byActions() });
  queryClient.invalidateQueries({ queryKey: assignedActionKeys.details() });
  queryClient.invalidateQueries({ queryKey: assignedActionKeys.lists() });
  queryClient.invalidateQueries({ queryKey: newHireJourneyKeys.details() });
}

export const useIssuesByActionIdQuery = (actionId: string, options = {}) => useQuery<Issue[]>({
  queryKey: issueKeys.byActionId(actionId),
  queryFn: async () => apiClient.get(apiIssuesPathByActionId(actionId)),
  staleTime: 1000 * 60 * 3, // 3 minutes
  ...options,
});

export const useIssueCountQuery = (enabled: boolean = true) => useQuery<{ count: number }>({
  queryKey: issueKeys.count(),
  queryFn: async () => apiClient.get(API_ROUTES.issues.count),
  enabled,
});

export const useIssueDetailQuery = (id: string, options = {}) => useQuery<Issue>({
  queryKey: issueKeys.detail(id),
  queryFn: async () => apiClient.get(apiIssuePathById(id)),
  staleTime: 1000 * 60 * 3, // 3 minutes
  ...options,
});

export const useIssueListQuery = (queryString: string = "", options = {}) => useQuery<Issue[]>({
  queryKey: queryString ? issueKeys.list(queryString) : issueKeys.lists(),
  queryFn: async () => apiClient.get(`${generatePath(API_ROUTES.issues.base)}${queryString?.length ? `?${queryString}` : ""}`),
  placeholderData: [],
  staleTime: 1000 * 60 * 3, // 3 minutes
  ...options,
});

export function useIssueListQueryWithFilters({
  pagination,
  sortBy,
  filters,
  searchText,
  additionalQueries,
  options = {},
}: QueryParams & { options?: object }) {
  const queryString = createQueryString({
    pagination,
    sortBy,
    searchText,
    filters,
    additionalQueries,
  });
  return useIssueListQuery(queryString, options);
}

export const useIssuesByNewHireIdQuery = (newHireId: NewHireJourneyId = "", options = {}) => {
  const mergeEnabled = options?.enabled !== undefined ? options.enabled && !!newHireId : !!newHireId;
  const queryString = createQueryString({ filters: [{ fieldName: "issues.onboardingJourneyId", value: newHireId, operator: "=" }] });
  return useIssueListQuery(queryString, { ...options, enabled: mergeEnabled });
};

export function invalidateIssueQueries(queryClient: QueryClient, issue: Issue) {
  const queryString = createQueryString({ filters: [{ fieldName: "issues.onboardingJourneyId", value: issue?.newHireJourneyId, operator: "=" }] });
  queryClient.invalidateQueries({ queryKey: issueKeys.list(queryString) });
  queryClient.invalidateQueries({ queryKey: newHireJourneyKeys.detail(issue?.newHireJourneyId) });
  queryClient.invalidateQueries({ queryKey: issueKeys.detail(issue?.newHireJourneyId) });
  queryClient.invalidateQueries({ queryKey: newHireJourneyKeys.withActiveIssuesList() });
}

function issueActionsOptions(issue: Issue, options = {}) {
  const actionIds = issue?.scheduledWorkflowActionIds || [];
  const queryString = createQueryString({
    pagination: {
      pageIndex: 0,
      pageSize: 100,
    },
    filters: [
      {
        fieldName: "id",
        value: actionIds,
        operator: "in",
      },
      {
        fieldName: "status",
        value: ["processed", "error", "skipped", "pending"],
        operator: "in",
      },
    ],
  });
  const enabled = options?.enabled !== undefined ? options.enabled && !!issue : !!issue;
  return queryOptions<AssignedAction[]>({
    queryKey: issueKeys.issueActionsById(issue?.id),
    queryFn: async () => apiClient.get(`${generatePath(API_ROUTES.assignedActions.base)}${queryString?.length ? `?${queryString}` : ""}`),
    staleTime: 1000 * 60 * 3, // 3 minutes
    ...options,
    enabled,
  });
}

export function useIssueActionsQuery(issue: Issue, options = {}) {
  return useQuery<AssignedAction[]>(issueActionsOptions(issue, options));
}

type UseIssueContextOptions = {
  includeActions?: boolean;
  enabled?: boolean;
};

export function useIssueContextQuery(issue: Issue, options: UseIssueContextOptions = {
  includeActions: false, enabled: true,
}): IssueContextQueryResult | Record<string, never> {
  const includeActions = options?.includeActions === undefined ? false : options?.includeActions;
  const enabled = (options?.enabled === undefined ? true : options?.enabled) && !!issue;

  const userQueryEnabled = enabled && !!issue?.context.userId;
  const newHireQueryEnabled = enabled && !!issue?.newHireJourneyId;
  const companyQueryEnabled = enabled && (issue?.context?.roleName === "company" || issue?.issueType === "missing_message_service");
  const issueActionsQueryEnabled = enabled && includeActions && !!issue && TIMING_ISSUE_TYPES.includes(issue?.issueType || "") && !!issue?.scheduledWorkflowActionIds?.length;

  const { data: actions = [], isLoading: actionsIsLoading, isFetched: actionsFetched } = useIssueActionsQuery(issue as Issue, { enabled: issueActionsQueryEnabled });
  const { data: user, isLoading: userIsLoading, isFetched: userFetched } = useUserDetailQuery(issue?.context.userId as string, { enabled: userQueryEnabled });
  const { data: newHire, isLoading: newHireIsLoading, isFetched: newHireFetched } = useNewHireJourneyDetailQuery(issue?.newHireJourneyId, { enabled: newHireQueryEnabled });
  const { data: company, isLoading: companyIsLoading, isFetched: companyFetched } = useCurrentCompanyQuery({ enabled: companyQueryEnabled });

  const isLoading = (userQueryEnabled && userIsLoading)
    || (newHireQueryEnabled && newHireIsLoading)
    || (companyQueryEnabled && companyIsLoading)
    || (issueActionsQueryEnabled && actionsIsLoading);

  const isFetched = (!userQueryEnabled || userFetched)
    && (!newHireQueryEnabled || newHireFetched)
    && (!companyQueryEnabled || companyFetched)
    && (!issueActionsQueryEnabled || actionsFetched);

  return issue ? { user, newHire, company, issue, actions, isLoading, isFetched } : {} as Record<string, never>;
}

export function updateIssueActionList({ queryClient, issueId, action }: { issueId: string, action: AssignedAction, queryClient: QueryClient }): void {
  queryClient.setQueryData<Issue[]>(issueKeys.issueActionsById(issueId), (prevActions) => {
    if (!prevActions) return prevActions;

    return prevActions.map((oldAction) => {
      if (oldAction.id !== action?.id) return oldAction;

      return {
        ...oldAction,
        ...action,
      };
    });
  });
}

export function useNewHiresWithIssuesListQuery(options = {}) {
  return useQuery<NewHireJourney[]>({
    queryKey: issueKeys.newHiresWithIssues(),
    queryFn: async () => apiClient.get(API_ROUTES.newHireJourneys.withActiveIssues.base),
    staleTime: 1000 * 60 * 3, // 3 minutes
    ...options,
  });
}
