/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import { Icon, IconProps, useColorModeValue } from "@chakra-ui/react";

function OutineLocation({ ...props }: IconProps) {
  const color = useColorModeValue("notBlack.50", "white");
  return (
    <Icon
      viewBox="0 0 12 12"
      fill="none"
      color={color}
      {...props}
    >
      <path
        d="M 6 1 C 4.065 1 2.5 2.565 2.5 4.5 c 0 2.625 3.5 6.5 3.5 6.5 s 3.5 -3.875 3.5 -6.5 c 0 -1.935 -1.565 -3.5 -3.5 -3.5 M 3.5 4.5 c 0 -1.38 1.12 -2.5 2.5 -2.5 s 2.5 1.12 2.5 2.5 c 0 1.44 -1.44 3.595 -2.5 4.94 C 4.96 8.105 3.5 5.925 3.5 4.5"
        fill="currentColor"
      />
      <circle cx="6" cy="4.5" r="1.25" fill="#959393" />
    </Icon>
  );
}
export default OutineLocation;
