import { createColumnHelper } from "@tanstack/react-table";
import { basicDate } from "helpers/time";
import { BaseAssignedAction } from "models/automation";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { InternalLink } from "components/Link";
import { assignedActionPathById } from "helpers/url";
import ActionTypeTag from "components/Tag/ActionTypeTag";
import ControlledDataTable, { ControlledDataTableProps } from "components/Table/ControlledDataTable";
import { combinedActionDateTime } from "helpers/assignedAction";
import { Issue } from "models/issue";
import { RandomEmptyIssueActionsState } from "components/EmptyComponents/RandomEmptyState";
import { Flex } from "@chakra-ui/react";
import { BlockedOrReadyTag } from "features/Issues/Components/NumberOfIssuesTag";
import { useIssueActionsQuery } from "features/Issues/hooks";
import AssignedActionSideDrawer from "components/AssignedActionSideDrawer";
import useAssignedActionSideDrawer from "components/AssignedActionSideDrawer/hooks";
import { CombinedActionId } from "models/automation/combinedAction";
import ActionRowResolutionButtons from "features/Issues/Components/ActionRowResolutionButtons";
import { DisplayRecipients } from "./Components";

const columnHelper = createColumnHelper<BaseAssignedAction | AssignedAction>();

const createColumnDefs = (
  createExpandClickHandlerByCombinedId: (combinedId: CombinedActionId) => (e: React.MouseEvent<HTMLElement>) => void,
  issueId: string,
) => [
  columnHelper.accessor((row) => row, {
    id: "actionType",
    header: "Type",
    cell: (row) => (
      <ActionTypeTag actionType={row.getValue()?.actionType} />
    ),
    maxSize: 32,
    minSize: 32,
    size: 32,
    meta: {
      sx: {
        paddingLeft: 6,
        paddingRight: 4,
      },
    },
  }),
  columnHelper.accessor((row) => row, {
    id: "name",
    header: "Name",
    cell: (row) => (
      <InternalLink
        to={assignedActionPathById(row.getValue()?.id)}
        textDecoration={row.getValue()?.status === "skipped" ? "line-through" : "none"}
        newTab
      >
        {row.getValue()?.name}
      </InternalLink>
    ),
    maxSize: 300,
    minSize: 200,
    size: 300,
    meta: {
      sx: {
        paddingLeft: 0,
      },
    },
  }),
  columnHelper.accessor((row) => row, {
    id: "sendDate",
    header: "Send Date",
    cell: (row) => basicDate(combinedActionDateTime(row.getValue())),
    minSize: 119,
    maxSize: 119,
    sortingFn: (rowA, rowB) => {
      const dateA = combinedActionDateTime(rowA.original)
        ? new Date(combinedActionDateTime(rowA.original))
        : null;
      const dateB = combinedActionDateTime(rowB.original)
        ? new Date(combinedActionDateTime(rowB.original))
        : null;

      if (dateA === null && dateB === null) return 0;
      if (dateA === null) return -1;
      if (dateB === null) return 1;

      return dateA - dateB;
    },
  }),
  columnHelper.accessor((row) => row, {
    id: "target",
    header: "Recipient",
    cell: (row) => <DisplayRecipients action={row.getValue()} />,
    minSize: 140,
    maxSize: 140,
  }),
  columnHelper.accessor((row) => row, {
    id: "issuesCount",
    header: "# Issues",
    cell: (row) => (
      <Flex width="fit-content" justify="flex-start">
        <BlockedOrReadyTag issuesCount={row.getValue()?.issues?.length || 0} action={row.getValue()} />
      </Flex>
    ),
    minSize: 100,
    maxSize: 100,
    meta: { headerHStackProps: { justifyContent: "center" } },
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row, {
    id: "moreOptions",
    header: "",
    cell: (row) => (
      <ActionRowResolutionButtons
        createExpandClickHandlerByCombinedId={createExpandClickHandlerByCombinedId}
        action={row.getValue()}
        issueId={issueId}
      />
    ),
  }),
  // columnHelper.accessor((row) => row, {
  //   id: "threeDots",
  //   header: "",
  //   cell: (row) => (
  //     <ActionVerticalMenu assignedAction={row.getValue() as AssignedAction} />
  //   ),
  // }),
];

interface SingleIssueActionsTableProps {
  issue: Issue;
  tableDefaultSort?: { id: string; desc: boolean };
}

export default function SingleIssueActionsTable({
  issue,
  tableDefaultSort = { id: "sendDate", desc: false },
}: SingleIssueActionsTableProps) {
  const { data, isLoading } = useIssueActionsQuery(issue);
  const { isOpen, onClose, selectedActionId, createExpandClickHandlerByCombinedId } = useAssignedActionSideDrawer();

  const tableProps: ControlledDataTableProps<BaseAssignedAction | AssignedAction> = {
    data,
    columns: createColumnDefs(createExpandClickHandlerByCombinedId, issue?.id),
    initialPagination: { pageSize: 20, pageIndex: 0 },
    defaultSort: [tableDefaultSort],
    textWrap: true,
    emptyStateComponent: !issue && !isLoading ? <RandomEmptyIssueActionsState /> : null,
    isLoading,
  };

  return (
    <>
      <ControlledDataTable
        useCardWrapper={false}
        sx={{ width: "100%" }}
        thProps={{ background: "transparent", paddingTop: 8 }}
        {...tableProps}
      />
      <AssignedActionSideDrawer isOpen={isOpen} onClose={onClose} resourceId={selectedActionId} />
    </>
  );
}
