import { useParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import EmployeeCard from "components/Card/EmployeeCard";
import { User } from "models/user";
import {
  Modal, ModalBody, ModalContent, useDisclosure,
} from "@chakra-ui/react";
import ProfileForm from "components/Forms/ProfileForm";
import { useNavbar } from "hooks/useNavbar";
import { useEffect } from "react";
import { useUserDetailQuery } from "features/User/hooks";

export default function UserPage() {
  const { id } = useParams<{ id: string }>();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { setPageTitle } = useNavbar();

  const { data: employee } = useUserDetailQuery(id as string);

  useEffect(() => {
    if (employee) {
      setPageTitle(`${employee?.firstName}'s Profile`);
    }
  }, [employee, setPageTitle]);

  return (
    <AppPage>
      <EmployeeCard employee={employee} editClickedHandler={onOpen} />
      <Modal size="5xl" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalBody>
            <ProfileForm user={employee as unknown as User} onSave={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </AppPage>
  );
}
