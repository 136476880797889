/* eslint-disable no-nested-ternary */
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Text,
  Flex,
  HStack,
  Heading,
} from "@chakra-ui/react";
import { CombinedActionId } from "models/automation/combinedAction";
import { useCombinedActionDetailQuery } from "features/CombinedAction/hooks";
import CombinedActionStatusTag from "components/Tag/CombinedActionStatusTag";
import ActionTypeTag from "components/Tag/ActionTypeTag";
import { isTask } from "features/Task/helpers";
import { fromCombinedActionId } from "features/CombinedAction/helpers";
import ViewCombinedActionButton from "features/CombinedAction/ViewCombinedActionButton";
import { BuddyInfo, ManagerInfo, NewHireInfo } from "features/NewHireJourney/Components/NewHireInfo";
import { useNewHireJourneyDetailQuery } from "features/NewHireJourney/hooks";
import EmailDrawerForm from "./EmailDrawerForm";
import ChatMessageDrawerForm from "./ChatMessageDrawerForm";
import TaskDrawerForm from "./TaskDrawerForm";
import ReminderDrawerForm from "./ReminderDrawerForm";

function ErrorText() {
  return <Text>Error</Text>;
}

type ActionType = "task" | "email" | "chat" | "task_reminder";

function getActionForm(actionType?: ActionType) {
  switch (actionType) {
    case "email":
      return { title: "Email", form: EmailDrawerForm };
    case "task":
      return { title: "Task", form: TaskDrawerForm };
    case "chat":
      return { title: "Chat Message", form: ChatMessageDrawerForm };
    case "task_reminder":
      return { title: "Reminder", form: ReminderDrawerForm };
    default:
      return { title: "Error", form: ErrorText };
  }
}

interface AssignedActionSideDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  resourceId: CombinedActionId;
}

export default function AssignedActionSideDrawer({ isOpen, onClose, resourceId }: AssignedActionSideDrawerProps) {
  const { data: combinedAction } = useCombinedActionDetailQuery(resourceId, { enabled: !!resourceId });
  const { actionType = undefined } = fromCombinedActionId(resourceId) || {};
  const { form: ActionForm } = getActionForm(actionType);
  const { data: newHire } = useNewHireJourneyDetailQuery(combinedAction?.newHireJourneyId, { enabled: !!combinedAction?.newHireJourneyId });

  if (!resourceId) return null;

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton mt="10px" />
        <DrawerHeader paddingBottom="4" fontSize="md" fontWeight="bold" borderBottomWidth="1px">
          <Flex direction="column" align="flex-start" gap="2">
            <HStack w="100%" align="center" justify="space-between" pr="50px">
              <Heading
                fontSize="md"
                fontWeight="700"
                lineHeight="19.2px"
              >
                {combinedAction ? (isTask(combinedAction) ? combinedAction?.title : combinedAction?.name) : "Loading"}
              </Heading>
              <ViewCombinedActionButton combinedActionId={combinedAction?.prefixId}>
                View Full Info
              </ViewCombinedActionButton>
            </HStack>
            <Flex gap="2" align="center">
              <ActionTypeTag actionType={actionType} />
              <Flex width="fit-content" my="1">
                <CombinedActionStatusTag action={combinedAction} />
              </Flex>
            </Flex>
            <Flex
              gap="4"
              display="inline-flex"
              width="100%"
            >
              <NewHireInfo newHire={newHire} />
              <ManagerInfo newHire={newHire} />
              <BuddyInfo newHire={newHire} />
            </Flex>
          </Flex>
        </DrawerHeader>

        <DrawerBody>
          {!combinedAction ? (
            <Text>Loading...</Text>
          ) : (
            <ActionForm onClose={onClose} combinedAction={combinedAction} />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
