import FullPage from "layouts/FullPage";
import { PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import {
  Box,
  Center,
  Divider,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useMemo, useRef } from "react";
import { useNavbar } from "hooks/useNavbar";
import { useParams } from "react-router-dom";
import { useIssueDetailQuery, useIssuesByNewHireIdQuery } from "features/Issues/hooks";
import { useNewHireJourneyDetailQuery } from "features/NewHireJourney/hooks";
import NewHireSupportTeam from "features/NewHireJourney/Components/NewHireSupportTeam";
import QueryError from "components/EmptyComponents/QueryError";
import NewHireNameAndTitle from "features/NewHireJourney/Components/NewHireNameAndTitle";
import IssueCard from "features/Issues/Components/IssueCard";
import { NewHireJourney } from "models/newHire";
import { Text14 } from "components/Text";
import { Issue, TIMING_ISSUE_TYPES } from "models/issue";

function IssueNumberText({
  issueNumber,
  issueCount,
  newHire,
}: { newHire: NewHireJourney, issueCount: number, issueNumber: number }) {
  const color = useColorModeValue("issueGrey.700", "white");
  return (
    <Text14 color={color}>
      {`Resolve `}
      <Text as="span" fontWeight="bold">
        {newHire?.user?.fullName}
      </Text>
      {`'s Issues ${issueNumber} of ${issueCount}`}
    </Text14>
  );
}

const sortWithInitialIdFirst = (
  issues: Issue[],
  initialId: string | undefined,
): Issue[] => {
  if (!initialId) {
    return issues; // Return the original issues if no initialId is provided
  }
  return issues.slice().sort((a, b) => {
    if (TIMING_ISSUE_TYPES.includes(a.issueType)) return 1; // Move timing-related items to the end
    if (TIMING_ISSUE_TYPES.includes(b.issueType)) return -1;

    if (initialId !== undefined && a.id === initialId) return -1; // Move initialId to the front
    if (initialId !== undefined && b.id === initialId) return 1;

    return 0; // Maintain relative order otherwise
  });
};

export default function IssuePage() {
  const { id } = useParams<{ id: string }>();

  const { data: issue, error, isError } = useIssueDetailQuery(id as string, { enabled: !!id });
  const newHireId = useMemo(() => issue?.newHireJourneyId, [issue?.newHireJourneyId]);
  const { data: newHire } = useNewHireJourneyDetailQuery(newHireId, {
    enabled: !!newHireId,
  });

  // Store the initial issue ID and track the newHireJourneyId it was set for
  const initialIssueIdRef = useRef<string | null>(null);
  const initialNewHireJourneyIdRef = useRef<string | null>(null);

  useEffect(() => {
    if (
      issue?.newHireJourneyId // Ensure newHireJourneyId is available
      && (!initialNewHireJourneyIdRef.current || initialNewHireJourneyIdRef.current !== issue.newHireJourneyId) // Update only if different
    ) {
      initialNewHireJourneyIdRef.current = issue.newHireJourneyId; // Update tracking for newHireJourneyId
      initialIssueIdRef.current = id as string; // Update the initial issue ID
    }
  }, [id, issue?.newHireJourneyId]);

  const initialIssueId = initialIssueIdRef.current || id; // Use ref if set, fallback to current id

  const { data: issues } = useIssuesByNewHireIdQuery(issue?.newHireJourneyId, {
    select: (innerIssues: Issue[]) => sortWithInitialIdFirst(innerIssues, initialIssueId),
  });
  const issueCount = issues?.length || 1;
  const issueNumber = (issues?.findIndex((i) => i.id === issue?.id) || 0) + 1;

  if (!issue && isError) {
    return (<QueryError queryError={error} resourceName="Issue" id={id as string} />);
  }

  return (
    <FullPage>
      <Box h="100%" px="10" py="5">
        <Flex gap="4" direction="column" w="fit-content">
          <NewHireNameAndTitle newHire={newHire} />
          <NewHireSupportTeam newHire={newHire} />
        </Flex>
        <Divider my="8" />
        <Center>
          {issue && (
            <Flex direction="column" gap="4">
              {newHire && issueNumber > 0 && <IssueNumberText newHire={newHire} issueCount={issueCount} issueNumber={issueNumber} />}
              <IssueCard issue={issue} issues={issues || []} includeNext />
            </Flex>
          )}
        </Center>
      </Box>
    </FullPage>
  );
}
