import { Textarea, useTheme } from "@chakra-ui/react";
import { TextInputFieldProps } from "components/Form/typings";
import FormField from "./FormField";

export default function TextAreaField({
  name,
  label,
  register,
  helperText,
  errors,
  required,
  variant,
  formFieldProps = {},
}: TextInputFieldProps) {
  const theme = useTheme();
  // Accessing default variant from the theme
  const formVariant = variant || theme.components.Form.defaultProps.variant;
  return (
    <FormField
      name={name}
      label={label}
      errors={errors}
      helperText={helperText}
      required={required}
      variant={formVariant}
      {...formFieldProps}
    >
      <Textarea size="sm" {...register(name)} {...(["floating", "flushedFloating"].includes(formVariant) ? { placeholder: " " } : {})} />
    </FormField>
  );
}
