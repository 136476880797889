import { generatePath, useParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import { SHARED_ROUTES } from "definitions/constants/routeConstants";
import { useNavbar } from "hooks/useNavbar";
import { useEffect } from "react";
import AssignedTaskCard from "features/Task/AssignedTaskCard";
import { useTaskDetailQuery } from "features/Task/hooks";
import { newHireJourneyPathById } from "helpers/url";

export default function TaskPage() {
  const { id, newHireJourneyId } = useParams<{ id: string; newHireJourneyId: string }>();

  const { data, isLoading } = useTaskDetailQuery(id || "");

  const { setBackLocationPath, setBackLocationName, setPageTitle } = useNavbar();

  useEffect(() => {
    setPageTitle("My Tasks");
    if (data && !isLoading) {
      if (newHireJourneyId) {
        // setBackLocationPath(newHireJourneyPathById(newHireJourneyId, "tasks"));
        // setBackLocationName("New Hire Page");
      } else {
        // setBackLocationPath(generatePath(SHARED_ROUTES.tasks.index));
        // setBackLocationName("Tasks");
      }
    }
    return () => {
      setPageTitle(null);
      setBackLocationName(null);
      setBackLocationPath(null);
    };
  }, [newHireJourneyId, data, isLoading, setBackLocationPath, setPageTitle, setBackLocationName]);

  return (
    <AppPage>
      <AssignedTaskCard task={data} isLoading={isLoading} />
    </AppPage>
  );
}
