import {
  Box,
} from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { useCallback } from "react";
import { BaseAssignedAction } from "models/automation";
import DatePickerField from "components/FormElements/Fields/DatePickerField";
import { buildISODate, convertToLocalDate } from "helpers/time";
import moment from "moment";
import { DatePickerFieldProps } from "components/Form/typings";
import { TRIGGER_DATE_FIELD } from "./fields";
import { SharedSchema } from "./schema";

export const triggerValues = (action: BaseAssignedAction): Partial<SharedSchema> => ({
  trigger: action?.trigger,
  triggerDate: action?.trigger?.type === "date_time" ? convertToLocalDate(action?.trigger?.data?.datetime, action?.timezone || "UTC") : undefined,
});

interface TriggerDateProps extends DatePickerFieldProps {
  assignedActionId: string;
  form: UseFormReturn<SharedSchema>;
  includeIcon?: boolean;
  containerProps: object;
}

export default function TriggerDate({ form, includeIcon, containerProps = {}, ...rest }: TriggerDateProps) {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = form;

  const handleDateChange = useCallback(
    (newDate: Date | undefined) => {
      if (newDate) {
        const [time, timezone] = getValues(["timeOfDay", "timezone"]);
        const newIsoDate = buildISODate(newDate, time || "09:00", timezone || "UTC");
        setValue("trigger.data.datetime", new Date(newIsoDate), { shouldDirty: true });
        setValue("triggerDate", new Date(newDate), { shouldDirty: true });
      }
    },
    [getValues, setValue],
  );

  return (
    <Box width="fit-content" {...containerProps}>
      <DatePickerField
        {...TRIGGER_DATE_FIELD}
        minDate={moment().subtract(1, "days").toDate()}
        errors={errors}
        control={control}
        onDateChange={(date: Date | undefined) => {
          handleDateChange(date);
        }}
        includeIcon={includeIcon}
        {...rest}
      />
    </Box>
  );
}
