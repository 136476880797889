import { Tag, TagLabel, Avatar, Skeleton, TagProps } from "@chakra-ui/react";

export interface NameTagProps extends TagProps {
  name: string | undefined | null;
  imageUrl?: string | undefined;
  tagLabelProps?: object;
  size?: "sm" | "md";
}

export function SkeletonTag({ size }: { size?: "sm" | "md" }) {
  const { tagSizeProps } = sizeMap(size || "md");
  return (
    <Skeleton borderRadius="full" width="80px" height={tagSizeProps.height || "28px"} />
  );
}

function sizeMap(size: "sm" | "md") {
  switch (size) {
    case "md":
      return {
        tagSizeProps: {
          height: "28px",
          minHeight: "26px",
          borderRadius: "lg",
          px: "3",
        },
        tagLabelSizeProps: {
          fontSize: "sm",
        },
      };
    default:
      return {
        tagSizeProps: {
          height: "18px",
          minHeight: "16px",
          px: "2",
        },
        tagLabelSizeProps: {
          fontSize: "xs",
        },
      };
  }
}

export default function NameTag({ name, imageUrl, tagLabelProps = {}, size = "md", ...props }: NameTagProps) {
  if (!name) {
    return <SkeletonTag />;
  }
  const { tagSizeProps, tagLabelSizeProps } = sizeMap(size);
  return (
    <Tag size="lg" colorScheme="gray" maxWidth="none" {...tagSizeProps} {...props}>
      {imageUrl && (
        <Avatar
          src={imageUrl}
          size="xs"
          name={name}
          ml={-1}
          mr={2}
        />
      )}
      <TagLabel
        fontWeight="normal"
        {...tagLabelSizeProps}
        {...tagLabelProps}
      >
        {name}
      </TagLabel>
    </Tag>
  );
}

