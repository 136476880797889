import { Box, Button, HStack } from "@chakra-ui/react";
import LinkButton from "components/Button/LinkButton";
import { PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import { issuePathById, issuesPath } from "helpers/url";
import useGoBack from "hooks/useGoBack";
import { Issue } from "models/issue";

export default function NextPreviousButton({ issueId, issues }: { issueId: string, issues: Issue[] }) {
  const goBack = useGoBack(issuesPath());
  if (!issues) {
    return null;
  }
  const currentIssueIndex = issues?.findIndex((issue) => issue.id === issueId);
  const nextIssue = issues?.[currentIssueIndex! + 1];
  return (
    <HStack mt="4" mx="8" justify="space-between" alignItems="flex-end" w="100%">
      {currentIssueIndex === 0 ? (
        <Box />
      ) : (
        <Box>
          <Button variant="link" onClick={goBack}>
            Back
          </Button>
        </Box>
      )}
      <Box>
        {!nextIssue ? (
          <LinkButton variant="link" to={PEOPLE_TEAM_ROUTES.issues.base}>Finish</LinkButton>
        ) : (
          <LinkButton variant="link" to={issuePathById(nextIssue?.id)}>Next</LinkButton>
        )}
      </Box>
    </HStack>
  );
}
