/* eslint-disable react/jsx-no-useless-fragment */
import { PropsWithChildren, useCallback, useEffect } from "react";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { FieldValues, useForm } from "react-hook-form";
import DatePickerField from "components/FormElements/Fields/DatePickerField";
import { useUpdateAssignedAction } from "../hooks";

interface UpdateTriggerAtFormProps extends PropsWithChildren {
  action: AssignedAction;
  onClose?: () => void;
  onSuccess?: () => void;
  onFailure?: () => void;
  label?: string;
  helperText?: string;
  minDate?: Date;
  initialValue?: Date;
  defaultIsOpen?: boolean;
  usePortal?: boolean;
  preserveTime?: boolean;
  isDisabled?: boolean;
}

export default function UpdateTriggerAtForm({
  action,
  onClose,
  label = "",
  minDate,
  onSuccess,
  onFailure,
  isDisabled = false,
  usePortal = true,
  children,
  ...rest
}: UpdateTriggerAtFormProps) {
  const { mutate } = useUpdateAssignedAction({ action });
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<FieldValues>({
    mode: "onChange",
    defaultValues: action && action?.trigger?.data?.datetime
      ? { "trigger.data.datetime": action?.trigger?.data?.datetime }
      : {},
  });

  const onSubmit = useCallback((values: any) => {
    mutate(values, {
      onSuccess: () => {
        onSuccess?.();
        onClose?.();
      },
      onError: () => {
        onFailure?.();
      },
    });
  }, [mutate, onClose, onFailure, onSuccess]);

  useEffect(() => {
    const subscription = watch(() => {
      handleSubmit(onSubmit)();
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, onSubmit, watch]);

  return (
    <DatePickerField
      name="trigger.data.datetime"
      control={control}
      label={label}
      errors={errors}
      disabled={isDisabled}
      minDate={minDate}
      overrideHelperSpace
      usePortal={usePortal}
      preserveTime
      {...rest}
    >
      {children}
    </DatePickerField>
  );
}
