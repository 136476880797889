/* eslint-disable no-nested-ternary */
import {
  As, Badge, Box, Button, ButtonProps, HStack, Icon, Link, Text, Tooltip, useColorModeValue,
} from "@chakra-ui/react";
import RoundBadge from "components/Badge/RoundBadge";
import { isActiveSidebar } from "helpers/sidebar";
import { GrWorkshop } from "react-icons/gr";
import { useLocation, Link as RouterLink } from "react-router-dom";

interface NavButtonProps extends ButtonProps {
  icon: As;
  label: string;
  route?: string;
  subtle?: boolean;
  notifications?: number;
  notify?: boolean;
  expanded?: boolean;
}

export default function SidebarNavButton({
  route,
  icon,
  label,
  subtle = false,
  notifications = undefined,
  notify = undefined,
  expanded = true,
  ...buttonProps
}: NavButtonProps) {
  const { pathname } = useLocation();
  const isActive = isActiveSidebar(pathname, route);
  const activeBgColor = useColorModeValue("gray.100", "gray.700");
  return (
    <Link
      as={RouterLink}
      to={route || "#"}
      style={{ textDecoration: "none" }}
      _hover={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Tooltip label={label} isDisabled={expanded} placement="right">
        <Button
          width="100%"
          variant="tertiary"
          justifyContent="start"
          size="xs"
          bg={isActive ? activeBgColor : undefined}
          {...(expanded && notifications
            ? {
              rightIcon: (
                <Badge variant="solid" colorScheme={notifications < 5 ? "green" : "red"} borderRadius="8px" size="xs">
                  {notifications}
                </Badge>
              ),
            }
            : expanded && notify ? (
              {
                rightIcon: (
                  <Box alignSelf="stretch" ml="1">
                    <RoundBadge variant="solid" alignSelf="center" colorScheme="red" />
                  </Box>
                ),
              }
            ) : {})}
          {...buttonProps}
        >
          <HStack gap={expanded ? "3" : "0"} transition="gap 0.6s ease-in-out">
            {[GrWorkshop].includes(icon) ? (
              <Icon as={icon} boxSize="5" sx={{ path: { stroke: "fg.subtle" } }} />
            ) : (
              <Icon as={icon} boxSize="5" color="fg.subtle" />
            )}
            <Text
              color={subtle ? "gray.400" : undefined}
              maxWidth={expanded ? "150px" : "0px"}
              overflow="hidden"
              whiteSpace="nowrap"
              opacity={expanded ? 1 : 0}
              transition="opacity 0.6s ease-in-out, max-width 0.6s ease-in-out"
            >
              {label}
            </Text>
          </HStack>
        </Button>
      </Tooltip>
    </Link>
  );
}
