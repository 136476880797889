import { AssignedAction } from "models/automation/scheduledWorkflow";
import EmailPageInfo from "./Email/EmailPageInfo";
import ChatPageInfo from "./Chat/ChatPageInfo";
import TaskPageInfo from "./Task/TaskPageInfo";

export default function AssignedActionPageInfo({ action }: { action: AssignedAction}) {
  switch (action?.actionType) {
    case "email":
      return <EmailPageInfo action={action} />;
    case "chat":
      return <ChatPageInfo action={action} />;
    case "task":
    case "task_notifier":
    case "task_reminder":
      return <TaskPageInfo action={action} />;
    default:
      return null;
  }
}
