import { PersonalizationTokenRoleName } from "models/personalizationToken";

/* eslint-disable import/prefer-default-export */
export const USER_ROLE_NAMES: PersonalizationTokenRoleName[] = [
  "recipient",
  "assignee",
  "new_hire",
  "manager",
  "buddy",
  "people_team_contact",
];
