/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import {
  Box, Flex, Image, Stack, Divider, Text,
  useColorMode,
  Icon,
  Collapse,
} from "@chakra-ui/react";
import { useLocation, Link } from "react-router-dom";
import { Fragment, PropsWithChildren, useEffect, useState } from "react";
import sideBarItemsByRole from "definitions/sidebarDefinitions";
import ROLE from "models/role";
import { roleFromPath, roleInOrder } from "utils/role";
import Logo from "assets/Logo.svg";
import DarkModeLogo from "assets/CaminoDarkModeLogo.svg";
import LogoMini from "assets/logo-mini.svg";
import DarkModeLogoMini from "assets/logo-dark-mini.svg";
import useCaminoStore from "hooks/useCaminoStore";
import useFeatureFlagStore from "hooks/useFeatureFlagStore";
import { ADMIN_ROUTES, API_ROUTES, SHARED_ROUTES } from "definitions/constants/routeConstants";
import { BiCog } from "react-icons/bi";
import { MdAdminPanelSettings, MdOutlineDoubleArrow } from "react-icons/md";
import apiClient from "services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { removeLastPath } from "helpers/url";
import { pendingNewHireKeys } from "features/PendingNewHire/hooks";
import { taskKeys } from "features/Task/hooks";
import { useIssueCountQuery } from "features/Issues/hooks";
import useSidebarState from "hooks/useSidebarState";
import SidebarNavButton from "./SidebarNavButton";
import SidebarSubNavButton from "./SidebarSubNavButton";
import MakeAWishButton from "./MakeAWishButton";

interface SidebarHeaderSectionProps extends PropsWithChildren {
  header?: string;
  expanded?: boolean;
}

function SidebarSection({ children, expanded = true, header = undefined }: SidebarHeaderSectionProps) {
  return (
    <Stack>
      <Collapse in={expanded} transition={{ enter: { duration: ".6" }, exit: { duration: ".6" } }}>
        {header && (
          <Text textStyle="sm" color="fg.subtle" fontWeight="medium">
            {header}
          </Text>
        )}
      </Collapse>
      <Stack spacing="1">{children}</Stack>
    </Stack>
  );
}

export default function Sidebar() {
  const location = useLocation();
  const [currentRole, user, setCurrentRole] = useCaminoStore((state) => [
    state.currentRole,
    state.currentUser,
    (arg0: ROLE) => state.setValue({ currentRole: arg0 }),
  ]);
  const { colorMode } = useColorMode();
  const { isExpanded, toggleSidebar } = useSidebarState();

  const [showSettings] = useFeatureFlagStore((state) => [state.settings]);
  // TODO - THIS IS TEMP - We should fix once we have a better idea of 'currentRole' and it being atomic / valid / up to date
  const computedRole = roleFromPath(location.pathname) || currentRole || roleInOrder(user);

  const { data: issuesCount } = useIssueCountQuery(!!computedRole && computedRole === ROLE.PEOPLE_TEAM)

  const { data: pendingNewHiresCount } = useQuery<{ count: number }>({
    queryKey: pendingNewHireKeys.count(),
    queryFn: async () => apiClient.get(API_ROUTES.newHireJourneys.pending.count),
    enabled: !!computedRole && computedRole === ROLE.PEOPLE_TEAM,
  });

  const { data: tasksCount } = useQuery<{ count: number }>({
    queryKey: taskKeys.count(),
    queryFn: async () => apiClient.get(API_ROUTES.tasks.count),
  });

  const notificationCounts: { [key: string]: number | undefined } = {
    Issues: issuesCount?.count,
    "Pending New Hires": pendingNewHiresCount?.count,
    "My Tasks": tasksCount?.count,
  };

  useEffect(() => {
    if (computedRole !== currentRole) setCurrentRole(computedRole);
  }, [computedRole, currentRole, setCurrentRole]);

  if (!computedRole || !user) {
    console.log(`ERROR: SIDEBAR WAS UNABLE TO FIND USER/ROLE`);
    return null;
  }

  const sidebarItems = sideBarItemsByRole(currentRole || ROLE.BASIC);

  return (
    <Flex as="section" minH="100vh" bg="bg.canvas" borderRight="1px" borderRightColor="gray.200">
      <Flex
        flex="1"
        bg="bg.surface"
        boxShadow="sm"
        maxW={{ base: "full", sm: "xs" }}
        pb={{ base: "6", sm: "8" }}
        pl="4"
        pr="2"
        pt="1"
      >
        <Stack justify="space-between" spacing="1" width="full">
          <Stack spacing="0" shouldWrapChildren>
            <Flex
              mb="1"
              h="20px"
              w="100%"
              align="center"
              position="relative"
            >
              <Icon
                as={MdOutlineDoubleArrow}
                boxSize="4"
                color="fg.muted"
                onClick={toggleSidebar}
                cursor="pointer"
                transition="transform 0.6s ease-in-out"
                transform={isExpanded ? "translateX(160px) rotateY(180deg)" : "translateX(0)"}
              />
            </Flex>
            <Flex
              justifyContent="center"
              alignItems="center"
              maxWidth={isExpanded ? "160px" : "40px"}
              transition="max-width 0.6s ease-in-out"
              mb="1"
            >
              <Link to={SHARED_ROUTES.home}>
                <Image
                  maxWidth={isExpanded ? "160px" : "40px"}
                  maxHeight="30px"
                  alt="Logo"
                  src={colorMode === "dark" ? (isExpanded ? DarkModeLogo : DarkModeLogoMini) : (isExpanded ? Logo : LogoMini)}
                  transition="max-width 0.6s ease-in-out, opacity 0.3s ease-in-out"
                />
              </Link>
            </Flex>
            <Box>
              {sidebarItems.map(({ header, items, id }) => (
                <Fragment key={`fragment-${id}`}>
                  <Divider key={`divider-${id}`} my="12px" />
                  <SidebarSection expanded={isExpanded} key={`section-${id}`} header={header}>
                    {null}
                    {(items || []).map(({
                      name, route, icon, role, subItems,
                    }) => (
                      <Fragment key={`section-fragment-${id}-${name}`}>
                        <SidebarNavButton
                          route={route}
                          key={`navbutton-${id}-${role}-${name}`}
                          label={name}
                          icon={icon}
                          expanded={isExpanded}
                          {...(["Pending New Hires", "My Tasks"].includes(name)
                            ? { notifications: notificationCounts[name] }
                            : name === "Issues" ? (
                              { notify: (notificationCounts[name] || 0) > 0 }
                            ) : {})}
                        />
                        {location.pathname.includes(removeLastPath(route)) && subItems ? (
                          <>
                            {(subItems || []).map(({ name: subName, route: subRoute }) => (
                              <SidebarSubNavButton
                                route={subRoute}
                                key={`${name}-${subName}`}
                                label={subName}
                              />
                            ))}
                          </>
                        ) : null}
                      </Fragment>
                    ))}
                  </SidebarSection>
                </Fragment>
              ))}
            </Box>
          </Stack>
          {user.companyId === process.env.CAMINO_ID && (
            <Stack spacing="1">
              <SidebarNavButton
                subtle
                route={ADMIN_ROUTES.hub}
                icon={MdAdminPanelSettings}
                label="Admin"
                expanded={isExpanded}
              />
            </Stack>
          )}
          {showSettings && (
            <Stack spacing="1">
              <SidebarNavButton
                subtle
                route={SHARED_ROUTES.settings}
                icon={BiCog}
                label="Settings"
                expanded={isExpanded}
              />
            </Stack>
          )}
          <Stack gap="1">
            <MakeAWishButton expanded={isExpanded} />
          </Stack>
        </Stack>
      </Flex>
    </Flex>
  );
}
