import { useQueries } from "@tanstack/react-query";
import { useIssueContextQuery, useIssuesByActionIdQuery } from "features/Issues/hooks";
import { AssignedActionId } from "models/automation";
import { Issue, IssueContextQueryResult, TIMING_ISSUE_TYPES, IssueType } from "models/issue";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { excludeTimingIssues, timingIssueActionCompleted } from "../helpers";
import issueValueQueryArgs from "./issueValueQueryArgs";
import { missingAttributeValue } from "./definitions/MissingAttribute";
import { missingEmailValue } from "./definitions/MissingEmail";
import { missingMessageServiceValue } from "./definitions/MissingMessageService";
import { missingMessageServiceUserValue } from "./definitions/MissingMessageServiceUser";
import { unassignedRoleValue } from "./definitions/UnassignedRole";
import { missingChannelValue } from "./definitions/MissingChannel";

type NonTimingConversionIssueArgs = (input: IssueContextQueryResult) => any;
type TimingConversionIssueArgs = (action: AssignedAction) => any;

export const ACTION_ISSUE_VALUE_MAP: Record<IssueType, NonTimingConversionIssueArgs | TimingConversionIssueArgs> = {
  missing_channel: missingChannelValue,
  missing_attribute: missingAttributeValue,
  missing_email: missingEmailValue,
  missing_message_service: missingMessageServiceValue,
  missing_message_service_user: missingMessageServiceUserValue,
  unassigned_role: unassignedRoleValue,
  reference_event_overlap: timingIssueActionCompleted,
  trigger_at_in_past: timingIssueActionCompleted,
};

type ActionIssueArgs = {
  issue: Issue;
  action: AssignedAction;
};

export function useActionIssueValue({
  issue,
  action,
}: ActionIssueArgs): string | boolean | Record<string, never> | null | undefined {
  const issueContextResult = useIssueContextQuery(issue, { enabled: !!issue && !!action });
  if (issueContextResult?.issue) {
    const resolveIssueValue = ACTION_ISSUE_VALUE_MAP[issueContextResult?.issue?.issueType] || (() => undefined);
    if (TIMING_ISSUE_TYPES.includes(issueContextResult?.issue?.issueType)) {
      (resolveIssueValue as TimingConversionIssueArgs)(action);
    }
    return (resolveIssueValue as NonTimingConversionIssueArgs)(issueContextResult as IssueContextQueryResult);
  }
  return undefined;
}

export function useActionNonTimingIssues(actionId: AssignedActionId | undefined) {
  const { data: issues, isSuccess } = useIssuesByActionIdQuery(actionId as string, { enabled: !!actionId });
  const nonTimingIssues = excludeTimingIssues(issues);
  return {
    isSuccess,
    queries: useQueries({
      queries: (nonTimingIssues ?? []).map((nonTimingIssue) => issueValueQueryArgs(nonTimingIssue)),
    }),
  };
}

export function useIsActionReadyToSend(actionId: AssignedActionId | undefined) {
  const { queries: allNonActionValues, isSuccess } = useActionNonTimingIssues(actionId);
  if (!isSuccess) {
    return undefined;
  }
  if (allNonActionValues.some((query) => query.isLoading)) {
    return undefined;
  }
  if (allNonActionValues.some((query) => query.isError)) {
    return undefined;
  }
  if (allNonActionValues.some((query) => !query.data)) {
    return false;
  }
  return true;
}

export function useNonTimingIssueCountByActionId(actionId: AssignedActionId | undefined) {
  const { queries: allNonActionValues, isSuccess } = useActionNonTimingIssues(actionId);

  if (!isSuccess) {
    return { count: undefined, isLoading: true, isError: false };
  }
  if (allNonActionValues.some((query) => query.isLoading)) {
    return { count: undefined, isLoading: true, isError: false };
  }
  if (allNonActionValues.some((query) => query.isError)) {
    return { count: 0, isLoading: false, isError: true };
  }
  const count = allNonActionValues.filter((query) => !query.data).length;
  return { count, isLoading: false, isError: false };
}
