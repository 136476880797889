/* eslint-disable no-nested-ternary */
import { Tag, TagProps } from "@chakra-ui/react";
import { combinedActionStatus, combinedActionStatusInfo, isImmediateCombinedAction } from "helpers/assignedAction";
import { CombinedAction } from "models/automation/combinedAction";
import { AssignedActionStatusType } from "models/automation";
import { scheduledActionStatusConverter } from "helpers/scheduledWorkflowConverters";
import { toActionStatusStyles } from "./Status/styles";

interface SendDateWithImmediateTagProps extends TagProps {
  action: CombinedAction;
}

export default function CombinedActionStatusTag({ action, ...rest }: SendDateWithImmediateTagProps) {
  if (!action) {
    return null;
  }
  const status = combinedActionStatus(action);
  const text = scheduledActionStatusConverter(status as AssignedActionStatusType);
  const styles = toActionStatusStyles(status as AssignedActionStatusType);
  return (
    <Tag
      borderRadius="md"
      boxShadow="none"
      {...styles}
      {...rest}
    >
      {text}
    </Tag>
  );
}

export function MiniCombinedActionCardStatusTag({ action, ...rest }: SendDateWithImmediateTagProps) {
  if (!action) {
    return null;
  }
  const actionStatus = combinedActionStatusInfo(action);
  let { text } = actionStatus;
  // Only returns Sent, Error, Skipped, Immediate
  if (!text) {
    return null;
  }
  let status = combinedActionStatus(action);
  console.log("STATUS", status);
  if (status && !["skipped", "processed", "error"].includes(status) && isImmediateCombinedAction(action)) {
    status = "immediate";
    text = "Immedate";
  }
  console.log("nextSTATUS", status);
  const styles = toActionStatusStyles(status as AssignedActionStatusType);
  return (
    <Tag
      borderRadius="md"
      boxShadow="none"
      {...styles}
      {...rest}
    >
      {text}
    </Tag>
  );
}
