import { useColorModeValue, Flex, Icon, Skeleton, FlexProps, IconProps, TextProps } from "@chakra-ui/react";
import CaminoCalendar from "components/Icon/CaminoCalendar";
import GlobalTimezone from "components/Icon/Iconify/GlobalTimezone";
import LinkedInIcon from "components/Icon/Iconify/LinkedInIcon";
import OutlineEmail from "components/Icon/Iconify/OutlineEmail";
import OutineLocation from "components/Icon/Iconify/OutlineLocation";
import { Text12 } from "components/Text";
import { isInPast, dateToAbbrevMonthYear } from "helpers/time";
import useCopyToClipboardWIthToast from "hooks/useCopyToClipboardWIthToast";
import { NewHireJourney } from "models/newHire";

interface InlineAttributeProps extends FlexProps {
  icon: any;
  isLoading?: boolean;
  iconProps?: IconProps;
  textProps?: TextProps;
}

function InlineAttribute({ icon, children, isLoading = false, iconProps = {}, textProps = {}, ...props }: InlineAttributeProps) {
  const textColor = useColorModeValue("notBlack.DEFAULT", "var(--chakra-colors-whiteAlpha-900)");
  return (
    <Flex gap="1" align="center" {...props}>
      <Icon as={icon} boxSize={3} color={textColor} {...iconProps} />
      {isLoading ? (
        <Skeleton height="12px" width="100px" />
      ) : (
        <Text12
          color={textColor}
          {...textProps}
        >
          {children}
        </Text12>
      )}
    </Flex>
  );
}

interface NewHireAttributeProps extends FlexProps {
  newHire: NewHireJourney | undefined;
}

export function StartDateAttribute({ newHire, ...props }: NewHireAttributeProps) {
  return (
    <InlineAttribute icon={CaminoCalendar} isLoading={!newHire} {...props}>
      {isInPast(newHire?.startDate) ? `Started ${dateToAbbrevMonthYear(newHire?.startDate)}` : `Starting ${dateToAbbrevMonthYear(newHire?.startDate)}`}
    </InlineAttribute>
  );
}

export function WorkEmailAttribute({ newHire, ...props }: NewHireAttributeProps) {
  const workEmail = newHire?.user?.workEmail as string;
  const { onCopy } = useCopyToClipboardWIthToast(workEmail);
  return (
    <InlineAttribute textProps={workEmail ? { onClick: onCopy, cursor: "pointer" } : {}} icon={OutlineEmail} isLoading={!newHire} {...props}>
      {workEmail || "No Work Email"}
    </InlineAttribute>
  );
}

export function PersonalEmailAttribute({ newHire, ...props }: NewHireAttributeProps) {
  const personalEmail = newHire?.user?.personalEmail as string;
  const { onCopy } = useCopyToClipboardWIthToast(personalEmail);
  return (
    <InlineAttribute textProps={personalEmail ? { onClick: onCopy, cursor: "pointer" } : {}} icon={OutlineEmail} isLoading={!newHire} {...props}>
      {personalEmail || "No Personal Email"}
    </InlineAttribute>
  );
}

export function LocationAttribute({ newHire, ...props }: NewHireAttributeProps) {
  return (
    <InlineAttribute icon={OutineLocation} isLoading={!newHire} {...props}>
      {newHire?.user?.location || "No Location"}
    </InlineAttribute>
  );
}

export function TimezoneAttribute({ newHire, ...props }: NewHireAttributeProps) {
  return (
    <InlineAttribute icon={GlobalTimezone} isLoading={!newHire} {...props}>
      {newHire?.user?.timezone || "No Timezone"}
    </InlineAttribute>
  );
}

export function LinkedInAttribute({ newHire, ...props }: NewHireAttributeProps) {
  const linkedinUrl = newHire?.user?.linkedinUrl as string;
  const { onCopy } = useCopyToClipboardWIthToast(linkedinUrl);
  return (
    <InlineAttribute textProps={linkedinUrl ? { onClick: onCopy, cursor: "pointer" } : {}} icon={LinkedInIcon} isLoading={!newHire} {...props}>
      {linkedinUrl || "No LinkedIn"}
    </InlineAttribute>
  );
}
