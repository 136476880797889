import {
  Card, CardBody, Stack,
} from "@chakra-ui/react";
import NewEmployeeButtonWithModal from "components/ModalForm/NewEmployeeButtonWithModal";
import SearchBar from "components/SearchBar";
import WorkforceTable from "components/Table/WorkforceTable";
import { useNavbar } from "hooks/useNavbar";
import FullPage from "layouts/FullPage";
import { useEffect, useState } from "react";

export default function WorkforcePage() {
  const [searchText, setSearchText] = useState("");
  const { setBackLocationPath, setBackLocationName, setPageTitle } = useNavbar();

  useEffect(() => {
    setPageTitle("Workforce");
    // setBackLocationName("Home");
    // setBackLocationPath("/");
    return () => {
      setPageTitle(null);
      setBackLocationName(null);
      setBackLocationPath(null);
    };
  }, [setBackLocationName, setBackLocationPath, setPageTitle]);

  return (
    <FullPage>
      <Card p="4">
        <CardBody>
          <Stack direction="column" w="100%" alignItems="flex-start" gap="12">
            <NewEmployeeButtonWithModal />
            <SearchBar minWidth="500" name="tasksSearch" onInput={setSearchText} my="4" />
          </Stack>
          <WorkforceTable searchText={searchText} />
        </CardBody>
      </Card>
    </FullPage>
  );
}
