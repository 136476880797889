import { HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { actionPath } from "helpers/url";
import Template from "components/Icon/Iconify/Template";
import { longAttributeStyles } from "../styles";

export default function FromTemplateAttribute({ action }: { action: AssignedAction }) {
  return (
    <HorizontalGreyBlackField
      icon={Template}
      label="Template"
      labelContainerProps={longAttributeStyles.labelContainerProps}
    >
      <Text as="span" fontWeight="medium">
        <Link
          as={RouterLink}
          to={{
            pathname: actionPath(action?.workflowAction),
          }}
        >
          {action.workflowAction.actionable.name}
        </Link>
      </Text>
    </HorizontalGreyBlackField>
  );
}
