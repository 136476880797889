import { Button, ButtonProps, useColorModeValue } from "@chakra-ui/react";

export default function CancelButton({ children, ...rest }: ButtonProps) {
  const color = useColorModeValue("inherit", "gray.50");
  return (
    <Button
      variant="outline"
      color={color}
      colorScheme="gray"
      {...rest}
    >
      {children || "Cancel"}
    </Button>
  );
}
