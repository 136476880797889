import {
  Card, CardBody, Flex, Heading, useColorModeValue, Image, calc,
} from "@chakra-ui/react";
import { IntegrationPartner } from "definitions/constants";
import { NewIncomingWebhook } from "models/integration/incomingWebhook";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import { PropsWithChildren } from "react";
import { AvailableIntegrationType } from "models/integration";
import { useModal } from "hooks/useModal";
import Modal from "components/Modal";
import { useAddIntegration, useAvailableIntegrationListQuery } from "features/Integrations/hooks";
import IntegrationSection from "./IntegrationSection";
import APIKeyCredentialsForm from "./APIKeyCredentialsForm";

type ConfirmationCopy = Record<IntegrationPartner, ConfirmationInfo>;

interface ConfirmationInfo {
  header: string;
  body: string;
  confirmButtonText: string;
  intent: "confirmation";
}

const CONFIRMATION_COPY: ConfirmationCopy = {
  greenhouse: {
    header: "Connect Greenhouse",
    body: "Credentials will be created that you will need to manually add to Greenhouse.",
    confirmButtonText: "Confirm",
    intent: "confirmation",
  },
  lever: {
    header: "Connect Lever",
    body: "You will be re-directed to Lever to give Camino approval to integrate.",
    confirmButtonText: "Confirm",
    intent: "confirmation",
  },
  slack: {
    header: "Confirm Connect",
    body: "You will be re-directed to Slack to give Camino approval to integrate.",
    confirmButtonText: "Confirm",
    intent: "confirmation",
  },
  ashby: {
    header: "Create Credientials in Ashby",
    body: "You will be re-directed to a Ashby to create an API key. Return here to enter your key.",
    confirmButtonText: "Confirm",
    intent: "confirmation",
  },
};

function AdditionalIntegration({
  integration,
  children,
  ...rest
}: PropsWithChildren<{ integration: AvailableIntegrationType }>) {
  const bgColor = useColorModeValue("inherit", "gray.600");
  return (
    <Card width={calc("25%").subtract("12px").toString()} minW="250px" bg={bgColor} {...rest}>
      <CardBody height="100%" display="flex" alignItems="center" flexDirection="column" justifyContent="space-between">
        <Image src={integration.logoUrl} alt={integration.partner} w="50%" maxW="50%" maxH="50%" m={2} mb={6} />
        {children}
      </CardBody>
    </Card>
  );
}

interface IntegrationsProps {
  setNewWebhookCredentials: (webhook: NewIncomingWebhook) => void;
}

export default function AdditionalIntegrations({ setNewWebhookCredentials }: IntegrationsProps) {
  const ashbyCredsModal = useModal({ id: "ashbyCredentials", size: "lg", header: { title: "Ashby Credentials" } });

  const { data: additionalIntegrations, isLoading } = useAvailableIntegrationListQuery({
    placeholderData: [],
  });

  const { performRequest: createIntegration } = useAddIntegration(
    (response, _, toast) => {
      let toastMessage = "Integration Successfully Connected.";
      if (response.partner === "greenhouse") {
        setNewWebhookCredentials(response);
        toastMessage = "Integration Credentials Created.";
      } else if (response.partner === "ashby") {
        ashbyCredsModal.onClose();
      }
      toast({ title: toastMessage });
    },
  );

  const onConfirm = (integration: AvailableIntegrationType) => {
    switch (integration.partner) {
      case "lever":
      case "slack":
        window.open(integration.url, "_blank", "noopener,noreferrer");
        break;
      case "greenhouse":
        createIntegration({
          integration: {
            partner: integration.partner,
            connectionType: "webhook",
          },
        });
        break;
      case "ashby":
        ashbyCredsModal.onOpen();
        window.open(integration.url, "_blank", "noopener,noreferrer");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <IntegrationSection title="Additional" isLoaded={!isLoading}>
        <Flex direction="row" gap="4" wrap="wrap" width="100%">
          {additionalIntegrations?.length ? (
            additionalIntegrations.map((integration: AvailableIntegrationType) => (
              <AdditionalIntegration key={integration.partner} integration={integration}>
                <ButtonWithConfirm
                  buttonText="Connect"
                  {...CONFIRMATION_COPY[integration.partner]}
                  handleConfirm={() => onConfirm(integration)}
                />
              </AdditionalIntegration>
            ))
          ) : (
            <Heading size="xs" color="fg.muted">
              {isLoading ? "Loading" : "No available integrations"}
            </Heading>
          )}
        </Flex>
      </IntegrationSection>
      <Modal modal={ashbyCredsModal}>
        <APIKeyCredentialsForm
          onSubmit={(data) => createIntegration({
            integration: {
              partner: "ashby",
              connectionType: "api_key",
              ...data,
            },
          })}
        />
      </Modal>
    </>
  );
}
