import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { useAssignedActionBatchQuery } from "features/AssignedAction/hooks";
import { NewHireFormValues } from "features/PendingNewHire/Form/definition";
import { pendingNewHireKeys } from "features/PendingNewHire/hooks";
import { useTaskBatchQuery } from "features/Task/hooks";
import { toCombinedActionId } from "features/CombinedAction/helpers";
import { apiAssignedPathsPathByNewHireId, apiNewHireJourneyPathById, apiNewHireListPath } from "helpers/url";
import { pick, uniq } from "lodash";
import { ExtendedNewHireJourney } from "models";
import { NewHireJourney } from "models/newHire";
import { User } from "models/user";
import { useCallback, useMemo } from "react";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";
import { useToast } from "@chakra-ui/react";

export const newHireJourneyKeys = {
  all: ["newHireJourneys"] as const,
  lists: () => [...newHireJourneyKeys.all, "list"] as const,
  list: (filters: string) => [...newHireJourneyKeys.lists(), { filters }] as const,
  withActiveIssuesList: () => [...newHireJourneyKeys.all, "withActiveIssuesList"] as const,
  details: () => [...newHireJourneyKeys.all, "detail"] as const,
  detail: (id: string) => [...newHireJourneyKeys.details(), id] as const,
};

interface QueryOptions {
  enabled?: boolean;
  [key: string]: any;
}

export const newHireJourneyQueryArgs = (id: string | undefined, options: QueryOptions = {}) => ({
  queryKey: id ? newHireJourneyKeys.detail(id) : [],
  queryFn: id ? async () => apiClient.get(apiNewHireJourneyPathById(id)) : async () => undefined,
  staleTime: 1000 * 60 * 3, // 3 minutes
  ...options,
  enabled: options?.enabled ? options.enabled && !!id : !!id,
});

export const useNewHireJourneyDetailQuery = (id: string | undefined, options: QueryOptions = {}) => useQuery<ExtendedNewHireJourney>({
  ...newHireJourneyQueryArgs(id, options),
});

export const usePreLoadNewHireAssociations = (newHireJourney: ExtendedNewHireJourney, _options: QueryOptions = {}) => {
  const assignedActionIds = useMemo(
    () => uniq((newHireJourney?.assignedPaths || [])
      .flatMap((path) => path.actions)
      .filter((action) => action.actionType === "email" || action.actionType === "chat")
      .map((action) => toCombinedActionId(action))),
    [newHireJourney?.assignedPaths],
  );
  const taskIds = useMemo(
    () => uniq((newHireJourney?.tasks || []).map((task) => toCombinedActionId(task))),
    [newHireJourney?.tasks],
  );
  useTaskBatchQuery(taskIds, { enabled: !!taskIds.length });
  useAssignedActionBatchQuery(assignedActionIds, { enabled: !!assignedActionIds.length });
};

export const usePreloadNewHireJourneyDetailQuery = (id: string | undefined, options: QueryOptions = {}) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery({ ...newHireJourneyQueryArgs(id), ...options });
};

export const useNewHiresListQuery = (queryString: string, options: QueryOptions = {}) => useQuery({
  queryKey: queryString ? newHireJourneyKeys.list(queryString) : newHireJourneyKeys.lists(),
  queryFn: async () => apiClient.get(apiNewHireListPath(queryString)),
  staleTime: 1000 * 60 * 3, // 3 minutes
  ...options,
});

type UserKeys = keyof User;

export const USER_FIELDS: UserKeys[] = [
  "firstName",
  "lastName",
  "preferredName",
  "title",
  "workEmail",
  "personalEmail",
  "pronouns",
  "timezone",
  "location",
  "rawLocation",
  "googleLocationId",
  "country",
  "department",
  "bio",
  "linkedinUrl",
  "managerId",
  "phoneCountryCode",
  "phoneNumber",
  "employmentType",
];

type NewHireJourneyKeys = keyof ExtendedNewHireJourney | "pending";

export const NEW_HIRE_JOURNEY_FIELDS: NewHireJourneyKeys[] = [
  "buddyId",
  "peopleTeamContactId",
  "startDate",
  "onboardingPlanUrl",
  "teamWikiUrl",
  "teamGoalsUrl",
  "pending",
  "shippingTrackingNumber",
  "shippingTrackingUrl",
  "shippingCarrier",
  "internalOnboardingChecklistUrl",
  "hrisOnboardingUrl",
  "workflowsStatus",
];

export function useUpdateNewHireJourney(id: string) {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation({
    mutationFn: (data: Partial<NewHireFormValues & { workflowsStatus: "enabled" | "disabled" }>) => {
      const user = pick(data, USER_FIELDS);
      const onboardingJourney = pick(data, NEW_HIRE_JOURNEY_FIELDS);
      const workflows = data?.workflowIds ? { workflowIds: data.workflowIds } : {};
      return apiClient.put<NewHireJourney>(generatePath(API_ROUTES.newHireJourneys.show, { id: id || "" }), {
        ...(Object.keys(user).length ? { user } : {}),
        ...(Object.keys(onboardingJourney).length ? { onboardingJourney } : {}),
        ...(Object.keys(workflows).length ? { workflows } : {}),
      });
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: pendingNewHireKeys.count() });
      queryClient.setQueryData(newHireJourneyKeys.detail(res.id), (oldData: NewHireJourney | undefined) => {
        if (!oldData) {
          return res;
        }
        return {
          ...oldData,
          ...res,
        };
      });
    },
    onError: (e) => {
      console.log(e);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    },
  });
}

export function useAddPathsToNewHireJourney(id: string, onClose?: () => void) {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation({
    mutationFn: (data: Partial<NewHireFormValues>) => {
      const workflows = data?.workflowIds ? { workflowIds: data.workflowIds } : {};
      return apiClient.put<NewHireJourney>(apiAssignedPathsPathByNewHireId(id), {
        ...(Object.keys(workflows).length ? { workflows } : {}),
      });
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: newHireJourneyKeys.detail(res.id) });
      toast({
        title: `Successfully Added`,
        status: "success",
      });
      onClose?.();
    },
    onError: (e) => {
      console.error(e);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    },
  });
}

export function useUpdateNewHireJourneyStatus(newHireJourneyId: string) {
  const toast = useToast();
  const mutation = useUpdateNewHireJourney(newHireJourneyId);
  const { mutate, ...rest } = mutation;

  const toggle = useCallback((workflowsStatus: "enabled" | "disabled" | "completed") => {
    const newStatus = workflowsStatus === "enabled" ? "disabled" : "enabled";
    mutate({ workflowsStatus: newStatus }, {
      onSuccess: (res) => {
        toast({ title: `Automations ${res?.workflowsStatus === "disabled" ? "paused" : "running"}` });
      },
    });
  }, [mutate, toast]);

  return { ...rest, toggle };
}
