/* eslint-disable import/prefer-default-export */
import { theme as baseTheme } from "@chakra-ui/react";
import { floatingNoValueBorder, FLUSHED_ACTIVE_BOTTOM_BORDER, FLUSHED_BORDER_BASE, FLUSHED_ERROR_BOTTOM_BORDER } from "definitions/constants/styling";

const FLOATING_LABEL_BASE_TOP = 7;
const SELECT_VALUE_PADDING_TOP = 18;
const TEXT_CURSOR_MARGIN_TOP = SELECT_VALUE_PADDING_TOP;
const INPUT_HEIGHT = "3.2rem";
const TRANSLATE_Y_VALUE = 16;
const TRANSLATE_Y_PX = `${TRANSLATE_Y_VALUE}px`;
const TRANSLATE_Y_W_ADJUST_PX = `${TRANSLATE_Y_VALUE - 1}px`;
const ICON_PADDING_LEFT = 20;
const ICON_WIDTH = 20;
const INPUT_VALUE_PADDING_LEFT_WITH_ICON = 60;
const LABEL_PADDING_LEFT_WITH_ICON = 64;
// Need to calculate this as the DateTimePicker button does not allow for us to say padding left
// is 60px (unlike the input)
const CALCULATED_PADDING_LEFT = INPUT_VALUE_PADDING_LEFT_WITH_ICON - ICON_PADDING_LEFT - ICON_WIDTH;

const TRANSFORM_VALUE = `scale(0.75) translateY(-${TRANSLATE_Y_PX})`;
const TRANSFORM_VALUE_IMPORTANT = `scale(0.75) translateY(-${TRANSLATE_Y_W_ADJUST_PX}) !important`;

export const floatingLabelTransformImportant = {
  transform: `${TRANSFORM_VALUE} !important`,
};

export const floatingLabelTransform = {
  transform: TRANSFORM_VALUE,
};

export const inputValueWithIconPaddingLeft = {
  paddingLeft: `${INPUT_VALUE_PADDING_LEFT_WITH_ICON}px`,
};

export const labelWithIconPaddingLeft = {
  paddingLeft: `${LABEL_PADDING_LEFT_WITH_ICON}px`,
};

export const floatingLabelBase = {
  top: `${FLOATING_LABEL_BASE_TOP}px`,
  color: "gray.400",
  fontSize: "md",
  left: 2,
  zIndex: 2,
  position: "absolute",
  pointerEvents: "none",
  mr: 3,
  pr: 2,
  my: 2,
  transformOrigin: "left top",
};

export const floatingLabelWithIcon = {
  ...floatingLabelBase,
  ...labelWithIconPaddingLeft,
  top: "5px",
};

// Input Styles
export const floatingInputBase = {
  height: INPUT_HEIGHT,
  paddingTop: "14px",
  textIndent: "8px",
};

export const inputWithIcon = {
  ...floatingInputBase,
  ...inputValueWithIconPaddingLeft,
};

// REACT SELECT

const sharedReactSelectWithValueSelector = ".chakra-react-select--has-value "
  + ".chakra-react-select__control:not(.chakra-react-select__control--is-focused):not("
  + ".chakra-react-select__control--menu-is-open):not(:hover)";

const REACT_SELECT_STYLES = {
  ".chakra-react-select--has-value ~ .floating-label": floatingLabelTransform,

  ".chakra-react-select__value-container": {
    height: "100%",
  },

  // BORDER STYLES
  ".chakra-react-select__control": {
    ...FLUSHED_BORDER_BASE,
  },
  ".chakra-react-select__control:hover:not(.chakra-react-select__control--is-focused)": {
    ...FLUSHED_BORDER_BASE,
  },
  ".chakra-react-select__control--is-focused, .chakra-react-select__control.chakra-react-select__control--is-focused:hover":
    {
      ...FLUSHED_ACTIVE_BOTTOM_BORDER,
    },
  [sharedReactSelectWithValueSelector]: {
    ...FLUSHED_BORDER_BASE,
  },
};

export const REACT_SELECT_SINGLE_STYLES = {
  ".chakra-react-select__single-value": {
    paddingLeft: 2,
    height: "100%",
    paddingTop: `${SELECT_VALUE_PADDING_TOP}px`,
  },
  ".chakra-input-container:has(.chakra-react-select-container-single-select) + .chakra-form__label.floating-label": {
    top: `${FLOATING_LABEL_BASE_TOP}px`,
  },
  ".chakra-react-select-container-single-select + .chakra-form__label.floating-label": {
    top: `${FLOATING_LABEL_BASE_TOP}px`,
  },
  ".timezone .chakra-react-select__single-value": {
    paddingTop: `${SELECT_VALUE_PADDING_TOP - 2}px`,
  },
  ".chakra-react-select-container-single-select, .chakra-react-select__single-value": {
    maxHeight: `${INPUT_HEIGHT}`,
  },
  ".chakra-react-select-container-single-select .chakra-react-select__input-container": {
    minHeight: `${INPUT_HEIGHT}`,
  },
  ".chakra-react-select-container-single-select:has(.chakra-react-select__contol--is-focused) ~ label": {
    transform: "none",
  },

  // hidden input
  ".chakra-react-select-container-single-select input.chakra-react-select__input": {
    height: "20px",
    marginTop: `${TEXT_CURSOR_MARGIN_TOP}px !important`,
    marginLeft: 2,
  },

  // BORDER STYLES
  ".chakra-react-select-container-single-select .chakra-react-select__control": {
    height: INPUT_HEIGHT,
    ...FLUSHED_BORDER_BASE,
    _hover: {
      ...FLUSHED_ACTIVE_BOTTOM_BORDER,
      background: "transparent",
    },
    _active: FLUSHED_ACTIVE_BOTTOM_BORDER,
    _focusVisible: {
      ...FLUSHED_ACTIVE_BOTTOM_BORDER,
      boxShadow: "none",
    },
  },
};

export const REACT_SELECT_MULTI_STYLES = {
  ".chakra-react-select__value-container.chakra-react-select__value-container--is-multi": {
    paddingInlineStart: 0,
    minHeight: `${INPUT_HEIGHT}`,
  },
  [".chakra-react-select__value-container.chakra-react-select__value-container--is-multi.chakra-react-select__value-container--has-value, "
  + ".chakra-react-select__control--is-focused .chakra-react-select__value-container.chakra-react-select__value-container--is-multi"]:
    {
      paddingTop: "22px",
    },
};

// INPUT

export const CHAKRA_INPUT_ICON_STYLES = {
  ".chakra-input__left-element, .chakra-input__right-element": {
    paddingLeft: `${ICON_PADDING_LEFT}px`,
    alignSelf: "center",
    height: "100%",
    ".chakra-icon": {
      transform: "scale(1.5)",
    },
    ".left-box-icon": {
      transform: "scale(1.5)",
    },
  },
  ".chakra-input__left-element + .chakra-input": inputWithIcon,
};

// BUTTON

const FORM_VARIANT_STYLES = {
  background: "transparent",
  fontWeight: "normal",
  ...FLUSHED_BORDER_BASE,
  _hover: {
    background: "transparent",
  },
  _active: FLUSHED_ACTIVE_BOTTOM_BORDER,
  _focusVisible: {
    ...FLUSHED_ACTIVE_BOTTOM_BORDER,
    boxShadow: "none",
  },
}

const FORM_BUTTON_STYLES = {
  ".chakra-button.has-value ~ .chakra-form__label": floatingLabelTransform,
  ".chakra-button.floating-button[aria-expanded='true']": {
    ...FLUSHED_ACTIVE_BOTTOM_BORDER,
  },
  ".chakra-button.floating-button": {
    background: "transparent",
    ...FLUSHED_BORDER_BASE,
    _hover: {
      background: "transparent",
    },
    _active: FLUSHED_ACTIVE_BOTTOM_BORDER,
    _focusVisible: {
      ...FLUSHED_ACTIVE_BOTTOM_BORDER,
      boxShadow: "none",
    },
  },
  ".chakra-button.has-value": {
    paddingInlineStart: "0",
    paddingLeft: "0 !important",
  },
  ".chakra-button.floating-button.has-error:not([aria-expanded='true'])": {
    ...FLUSHED_ERROR_BOTTOM_BORDER,
  },
};

const TRIX_STYLES = {
  ".trix-editor": {
    paddingTop: "20px",
    paddingLeft: "15px",
    borderRadius: "md",
    ...floatingNoValueBorder,
    borderWidth: "1.5px",
  },
  ".trix-container ~ label.has-value": {
    transform: TRANSFORM_VALUE_IMPORTANT,
  },
  ".trix-container ~ label": {
    paddingLeft: "17px",
  },
};

export const floatingForm = (props: any) => ({
  field: {
    _focusWithin: {
      borderBottomColor: "var(--chakra-colors-brand-500)",
      boxShadow: "0px 1px 0px 0px var(--chakra-colors-brand-500)",
    },
  },
  container: {
    // Floating label styles
    _focusWithin: {
      ".floating-label": floatingLabelTransform,
      ".chakra-input__group:has(.chakra-input__left-element) ~ .floating-label": floatingLabelTransformImportant,
    },

    ".floating-label": floatingLabelBase,

    "input:not(:placeholder-shown) + .floating-label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
      floatingLabelTransform,
    ".chakra-input__group > input:not(:placeholder-shown) ~ label": floatingLabelTransform,
    ".chakra-input__group:has(input:not(:placeholder-shown)) ~ label": floatingLabelTransform,
    ".chakra-input__group:has(.chakra-input__left-element):has(input:not(:placeholder-shown)) ~ .floating-label":
      floatingLabelTransformImportant,
    ".chakra-input__group:has(.chakra-input__left-element) > input:not(:placeholder-shown) ~ .floating-label": {
      transform: TRANSFORM_VALUE_IMPORTANT,
    },
    "input[type='time']:not([value='']) ~ label": floatingLabelTransform,

    // Button Styles
    ...FORM_BUTTON_STYLES,

    // Input styles
    ...CHAKRA_INPUT_ICON_STYLES,
    "input:not(.chakra-react-select__input)": floatingInputBase,
    ".timezone.chakra-react-select-container-single-select input.chakra-react-select__input": {
      marginTop: `${TEXT_CURSOR_MARGIN_TOP - 2}px !important`,
    },

    // React Select styles
    ...REACT_SELECT_STYLES,
    ...REACT_SELECT_SINGLE_STYLES,
    ...REACT_SELECT_MULTI_STYLES,
    ".chakra-react-select__indicator": {
      transform: "translateY(1px)",
    },

    ".chakra-input__group:has(.chakra-input__left-element) ~ .floating-label": floatingLabelWithIcon,

    ...TRIX_STYLES,
  },
});

export const flushedFloatingButton = {
  height: `${INPUT_HEIGHT} !important`,
  paddingTop: "18px !important",
  paddingLeft: `${ICON_PADDING_LEFT}px !important`,
  width: "100%",

  // Icon styles
  ".chakra-button__icon": {
    transform: "translateY(-50%) !important",
    marginRight: `${CALCULATED_PADDING_LEFT}px`,
  },

  // Label styles with icons
  "&:has(.chakra-button__left-icon) ~ label": labelWithIconPaddingLeft,
  ".chakra-input__group:has(.chakra-input__left-element) ~ label": labelWithIconPaddingLeft,

  // Input styles with icons
  ".chakra-input__left-element + .chakra-input": inputValueWithIconPaddingLeft,

  ...FORM_BUTTON_STYLES,
  ...FORM_VARIANT_STYLES,
};

export const flushedFloating = (props) => {
  const baseFlushed = baseTheme.components.Input?.variants?.flushed?.(props) || {};

  return {
    ...baseFlushed,
    position: "relative",
    ...floatingForm(props),

    field: {
      ...(baseFlushed?.field || {}),
      ...floatingForm(props).field, // Extend field styles
    },

    // Ensure placeholder disappears when typing
    _placeholder: { color: "transparent" },
  };
};
