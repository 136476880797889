import { Button, ButtonProps } from "@chakra-ui/react";

export default function RedOutlineButton({ children, ...rest }: ButtonProps) {
  return (
    <Button
      variant="outline"
      colorScheme="caminoNewErrorRed"
      color="caminoNewErrorRed.DEFAULT"
      borderColor="var(--chakra-colors-caminoNewErrorRed-opacity50)"
      border="1.25px solid"
      py={0}
      h="32px"
      _disabled={{
        cursor: "not-allowed",
        color: "skippedGrey.DEFAULT",
        borderColor: "skippedGrey.DEFAULT",
        colorScheme: "skippedGrey",
        pointerEvents: "all",
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}
