/* eslint-disable import/prefer-default-export */

import { AssignedActionType, BaseAssignedAction } from "models/automation";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { BaseTask } from "models/task";
import { CombinedAction } from "models/automation/combinedAction";
import { isTask } from "../features/Task/helpers";
import { isDependencyTrigger } from "./action";
import { relativeDate } from "./time";
import { titleize } from "./string";

export function sortDateTime<T>(
  resources: T[],
  dateAccessor: (resource: T) => Date | null | undefined,
  direction: "asc" | "desc" = "asc",
): T[] {
  return resources.sort((a: T, b: T) => {
    const dateA = dateAccessor(a);
    const dateB = dateAccessor(b);

    // For ascending order: place undefined/null dates at the end
    if (direction === "asc") {
      if (dateA == null && dateB == null) return 0; // Both are null/undefined, keep original order
      if (dateA == null) return 1; // Move a to the end
      if (dateB == null) return -1; // Move b to the end
    }

    // For descending order: place undefined/null dates at the beginning
    if (direction === "desc") {
      if (dateA == null && dateB == null) return 0; // Both are null/undefined, keep original order
      if (dateA == null) return -1; // Move a to the beginning
      if (dateB == null) return 1; // Move b to the beginning
    }

    // Convert date strings to Date objects if needed
    const parsedDateA = typeof dateA === "string" ? new Date(dateA) : dateA;
    const parsedDateB = typeof dateB === "string" ? new Date(dateB) : dateB;

    // Ensure parsedDateA and parsedDateB are numbers before comparison
    const numericDateA = parsedDateA instanceof Date ? parsedDateA.getTime() : Number(parsedDateA);
    const numericDateB = parsedDateB instanceof Date ? parsedDateB.getTime() : Number(parsedDateB);

    // Determine the sort order based on the direction
    const comparison = numericDateA - numericDateB;
    return direction === "asc" ? comparison : -comparison;
  });
}

export function sortAssignedActionsByTriggerDate(
  assignedActions: AssignedAction[],
  direction: "asc" | "desc" = "asc",
): AssignedAction[] {
  return sortDateTime(assignedActions, (action) => action?.trigger?.data?.datetime, direction);
}

export function sortAssignedActionsAndTaskByTriggerDate(
  assignedActions: Array<BaseAssignedAction | BaseTask>,
  direction: "asc" | "desc" = "asc",
): Array<BaseAssignedAction | BaseTask> {
  return sortDateTime(
    assignedActions,
    (action) => action?.trigger?.data?.datetime || action?.taskNotifier?.trigger?.data?.datetime,
    direction,
  );
}

export function isImmediateAssignedAction(assignedAction: AssignedAction | BaseAssignedAction | undefined): boolean {
  return (
    assignedAction?.workflowAction?.actionable?.trigger?.data?.referenceEvent === "immediate"
    && assignedAction?.workflowAction?.actionable?.trigger?.data?.offset === 0
    && assignedAction?.trigger?.data?.customized === false
  );
}

export function isDependencyAssignedAction(assignedAction: AssignedAction | BaseAssignedAction | undefined): boolean {
  return isDependencyTrigger(assignedAction?.workflowAction?.actionable?.trigger);
}

export function combinedActionDateTime(action: CombinedAction) {
  const assignedAction = isTask(action) ? action.taskNotifier : action;
  return assignedAction?.trigger?.data?.datetime;
}

export function isImmediateCombinedAction(action: CombinedAction) {
  const assignedAction = isTask(action) ? action.taskNotifier : action;
  return isImmediateAssignedAction(assignedAction);
}

export function combinedActionStatus(action: CombinedAction) {
  const assignedAction = isTask(action) ? action.taskNotifier : action;
  return assignedAction?.status;
}

export function combinedActionStatusInfo(action: CombinedAction) {
  const status = combinedActionStatus(action);
  if (status === "processed") {
    return { colorScheme: "dateGreen", text: "Sent", statusLabel: "Sent At" };
  } else if (status === "error") {
    return { colorScheme: "error", text: "Error", statusLabel: "Errored At" };
  } else if (status === "skipped") {
    return { colorScheme: "helperText", text: "Skipped", statusLabel: "Skipped At" };
  } else if (isImmediateCombinedAction(action)) {
    return { colorScheme: "dateBlue", text: "Immediate", statusLabel: "Scheduled At" };
  }
  return { colorScheme: null, text: null, statusLabel: "Scheduled At" };
}

export function combinedActionDateInfo(combinedAction: CombinedAction, includeImmediate = false) {
  const status = combinedActionStatus(combinedAction);
  const assignedAction = isTask(combinedAction) ? combinedAction.taskNotifier : combinedAction;
  const datetime = assignedAction?.trigger?.data?.datetime;
  if (status === "processed") {
    return { label: "Sent", value: relativeDate(assignedAction?.statusUpdatedAt) };
  } else if (status === "skipped") {
    return { label: "Skipped", value: relativeDate(assignedAction?.statusUpdatedAt) };
  } else if (includeImmediate && isImmediateAssignedAction(assignedAction)) {
    return { label: "Scheduled", value: "After Kickoff" };
  } else if (datetime) {
    return { label: "Scheduled", value: relativeDate(datetime) };
  }
  return { label: "Scheduled", value: "TBD" };
}

export function toAssignedActionType(actionType: AssignedActionType) {
  if (actionType === "task_reminder") {
    return "Reminder";
  } else if (actionType === "task_notifier") {
    return "Task";
  }
  return titleize(actionType);
}
