import {
  Button, Input, InputGroup, InputRightElement, useTheme,
} from "@chakra-ui/react";
import { TextInputFieldWithSetValueButtonProps } from "components/Form/typings";
import FormField from "../FormField";

export default function TextInputFieldWithSetValueButton({
  name,
  label,
  register,
  placeholder,
  helperText,
  errors,
  required,
  type,
  textTransform,
  defaultValue,
  setValue,
  buttonText,
  variant,
  formFieldProps = {},
  ...props
}: TextInputFieldWithSetValueButtonProps) {
  const theme = useTheme();
  // Accessing default variant from the theme
  const formVariant = variant || theme.components.Form.defaultProps.variant;
  return (
    <FormField
      name={name}
      label={label}
      errors={errors}
      helperText={helperText}
      required={required}
      variant={formVariant}
      {...formFieldProps}
    >
      <InputGroup>
        <Input
          flex="1"
          textTransform={textTransform}
          type={type}
          placeholder={["floating", "flushedFloating"].includes(formVariant) ? " " : placeholder}
          colorScheme="brand"
          {...register(name)}
          {...props}
        />
        <InputRightElement width="fit-content" mx="4">
          <Button
            size="sm"
            colorScheme="gray"
            onClick={() => setValue(name, defaultValue, {
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true,
            })}
          >
            {buttonText}
          </Button>
        </InputRightElement>
      </InputGroup>
    </FormField>
  );
}
