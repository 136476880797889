import { Issue, IssueContextQueryResult } from "models/issue";
import { ResolutionInfo } from "models/resolutions";
import { TimingIssueModalInputFields } from "./TimingResolution";

export function triggerAtInPastResolutionIssueType(issue: Issue) {
  return "Past Send Date";
}

export function triggerAtInPastResolution(_1: Issue, _2: IssueContextQueryResult): ResolutionInfo {
  return ({
    issueType: "trigger_at_in_past",
    tagText: "Action(s) not sent",
    title: "Unsent action(s) scheduled in the past",
    additionalContext: "These actions are past their scheduled date and have not been sent. Please review and skip, send or reschedule them.",
    inputComponent: TimingIssueModalInputFields,
  });
}
