/* eslint-disable max-len */
import { Icon, IconProps, useColorModeValue } from "@chakra-ui/react";

function RoundPersonOutline({ ...props }: IconProps) {
  const color = useColorModeValue("notBlack.50", "white");
  return (
    <Icon
      viewBox="0 0 12 12"
      fill="none"
      color={color}
      {...props}
    >
      <path
        d="M6 2.95C6.13789 2.95 6.27443 2.97716 6.40182 3.02993C6.52921 3.08269 6.64496 3.16004 6.74246 3.25754C6.83996 3.35504 6.91731 3.47079 6.97007 3.59818C7.02284 3.72557 7.05 3.86211 7.05 4C7.05 4.13789 7.02284 4.27443 6.97007 4.40182C6.91731 4.52921 6.83996 4.64496 6.74246 4.74246C6.64496 4.83996 6.52921 4.91731 6.40182 4.97007C6.27443 5.02284 6.13789 5.05 6 5.05C5.72152 5.05 5.45445 4.93938 5.25754 4.74246C5.06062 4.54555 4.95 4.27848 4.95 4C4.95 3.72152 5.06062 3.45445 5.25754 3.25754C5.45445 3.06062 5.72152 2.95 6 2.95ZM6 7.45C7.485 7.45 9.05 8.18 9.05 8.5V9.05H2.95V8.5C2.95 8.18 4.515 7.45 6 7.45ZM6 2C4.895 2 4 2.895 4 4C4 5.105 4.895 6 6 6C7.105 6 8 5.105 8 4C8 2.895 7.105 2 6 2ZM6 6.5C4.665 6.5 2 7.17 2 8.5V9.5C2 9.775 2.225 10 2.5 10H9.5C9.775 10 10 9.775 10 9.5V8.5C10 7.17 7.335 6.5 6 6.5Z"
        fill="currentColor"
      />
    </Icon>
  );
}
export default RoundPersonOutline;
