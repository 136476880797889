import LoadingCard from "components/Card/LoadingCard";
import ProfileForm from "components/Forms/ProfileForm";
import { useUserDetailQuery } from "features/User/hooks";
import useCaminoStore from "hooks/useCaminoStore";
import { useNavbar } from "hooks/useNavbar";
import AppPage from "layouts/AppPage";
import { useEffect } from "react";

export default function ProfilePage() {
  const id = useCaminoStore((state) => state.currentUser?.id);
  const { setPageTitle } = useNavbar();

  const { data: user } = useUserDetailQuery(id || "");

  useEffect(() => {
    if (user) {
      setPageTitle("My Profile");
    }
  }, [user, setPageTitle]);

  return <AppPage>{user ? <ProfileForm user={user} /> : <LoadingCard />}</AppPage>;
}
