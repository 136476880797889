/* eslint-disable no-nested-ternary */
import {
  MenuItem,
  Menu,
  MenuButton,
  Button,
  MenuList,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import { VThreeDotMenu } from "components/Menu/ThreeDotMenu";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { actionRecipients } from "./helpers";
import { Text12 } from "components/Text";

interface ActionVerticalMenuProps {
  assignedAction: AssignedAction;
}

export function ActionVerticalMenu({ assignedAction }: ActionVerticalMenuProps) {
  const { onClose, onOpen, isOpen } = useDisclosure();
  return (
    <Menu>
      {() => (
        <>
          <MenuButton as={Button}>
            <VThreeDotMenu inPortal />
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={onOpen}
            >
              Custom Date
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
}

export function DisplayRecipients({ action }: { action: AssignedAction }) {
  const recipients = actionRecipients(action, "sm");
  const recipientLength = recipients.length;
  if (recipientLength === 0) {
    return null;
  }
  const firstRecipient = recipients.shift();
  const addOn = recipientLength > 1 ? <Text12>{` +${recipientLength - 1}`}</Text12> : "";
  return (
    <Flex align="center" gap="1">
      {firstRecipient}
      {addOn}
    </Flex>
  );
}
