import { Button, ButtonGroup, Flex } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import ActionRichTextAreaField from "components/FormElements/Fields/ActionRichTextAreaField";
import {
  forwardRef, useCallback, useEffect, useImperativeHandle, useState,
} from "react";
import RecipientsContainer from "components/Forms/PathActions/Email/RecipientsContainer";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { EmailRecipient } from "models/automation";
import { yupResolver } from "@hookform/resolvers/yup";
import { dirtyValues } from "helpers/form";
import { useQueryClient } from "@tanstack/react-query";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import { newHireJourneyKeys } from "features/NewHireJourney/hooks";
import { assignedActionTargetText } from "helpers/workflowActionableConverters";
import { GreyBlackLabel, HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";
import { isEqual } from "lodash";
import CalendarMonth from "components/Icon/Iconify/CalendarMonth";
import Clock from "components/Icon/Iconify/Clock";
import StarOutline from "components/Icon/Iconify/StartOutline";
import { ListIssuesByAction } from "features/Issues/Components/ListIssuesInfo";
import NameTag from "components/Tag/NameTag";
import { Schema, schema } from "./schema";
import { BODY_FIELD, SUBJECT_FIELD } from "./fields";
import useSubmitUpdateForm from "../Shared/useSubmitUpdateForm";
import { TriggerExplanation } from "../Shared/DisplayInfo";
import { triggerValues } from "../Shared/TriggerDateTime";
import TriggerTimeModalButton from "../Shared/TriggerTimeModalButton";
import TriggerDate from "../Shared/TriggerDate";

const ALWAYS_DIRTY_FIELDS = ["additionalRecipients", "ccRecipients", "bccRecipients"];

function isEmailAction(action: AssignedAction): action is AssignedAction & { actionType: "email" } {
  return action.actionType === "email";
}

const values = (action: AssignedAction): Schema => {
  if (!isEmailAction(action)) {
    throw new Error("Action is not of type email");
  }

  return {
    actionType: "email",
    content: {
      body: action?.content?.bodyTrix ?? action?.content?.body,
      subject: action?.content?.subject,
    },
    name: action?.name,
    additionalRecipients: action?.additionalRecipients as EmailRecipient[],
    ccRecipients: action?.ccRecipients as EmailRecipient[],
    bccRecipients: action?.bccRecipients as EmailRecipient[],
    ...triggerValues(action),
    trigger: action?.trigger,
  };
};

function normalizeRecipients(assignedAction: AssignedAction) {
  if (!assignedAction) {
    return null;
  }
  return ({
    additionalRecipients: (assignedAction?.additionalRecipients || []).sort(),
    ccRecipients: (assignedAction?.ccRecipients || []).sort(),
    bccRecipients: (assignedAction?.bccRecipients || []).sort(),
  });
}

function hasChanged(action: AssignedAction, pulledDirtyValues: Schema) {
  if (!isEqual(normalizeRecipients(action), normalizeRecipients(pulledDirtyValues))) {
    return true;
  }
  const { additionalRecipients, ccRecipients, bccRecipients, actionType, ...rest } = pulledDirtyValues;
  return !(Object.keys(rest || {}).length === 0);
}

interface UpdateEmailFormProps {
  assignedAction: AssignedAction;
  onSuccess: () => void;
  setIsSubmitLoading: (isLoading: boolean) => void;
  setIsFormDirty: (isLoading: boolean) => void;
}

const UpdateEmailForm = forwardRef(
  ({ assignedAction, onSuccess, setIsSubmitLoading, setIsFormDirty }: UpdateEmailFormProps, ref) => {
    const queryClient = useQueryClient();
    const form = useForm<Schema>({
      mode: "onBlur",
      resolver: yupResolver<Schema>(schema, { stripUnknown: true }),
      defaultValues: values(assignedAction),
    });
    const {
      control,
      formState: { errors, dirtyFields, isDirty },
      handleSubmit,
      register,
      watch,
      reset,
    } = form;
    const { mutate: onSubmitForm, isPending: onSubmitLoading } = useSubmitUpdateForm({
      assignedAction,
      onSubmitSuccess: onSuccess,
    });

    useEffect(() => {
      setIsFormDirty(isDirty);
    }, [isDirty, setIsFormDirty]);

    useEffect(() => {
      setIsSubmitLoading(onSubmitLoading);
    }, [onSubmitLoading, setIsSubmitLoading]);

    const handleOnSubmit = useCallback(
      (onSuccessCallback: () => void) => {
        handleSubmit((data) => {
          // handleSubmit built in - if the form is invalid, this function will not be called.
          // Because of this, if we pull "updateData" and its an empty object (no dirty fields),
          // we do not need to call the onSubmit function.
          const updateData = dirtyValues(data, dirtyFields, ALWAYS_DIRTY_FIELDS);
          if (!hasChanged(assignedAction, updateData)) {
            onSuccess?.();
            onSuccessCallback?.();
            return;
          }
          updateData.actionType = data.actionType;
          onSubmitForm(updateData, {
            onSuccess: () => {
              queryClient.invalidateQueries({ queryKey: newHireJourneyKeys.detail(assignedAction?.scheduledWorkflow?.onboardingJourneyId) });
              reset({}, { keepValues: true });
              onSuccess?.();
              onSuccessCallback?.();
            },
            onError: (error) => {
              console.log("error", error);
            },
          });
        })();
      },
      [handleSubmit, dirtyFields, assignedAction, onSubmitForm, onSuccess, queryClient, reset],
    );

    // Expose form methods to parent via ref
    useImperativeHandle(ref, () => ({
      handleSubmit: (onSuccessCallback: () => void) => handleOnSubmit(onSuccessCallback),
    }));

    const [additionalRecipients, ccRecipients, bccRecipients] = watch([
      "additionalRecipients",
      "ccRecipients",
      "bccRecipients",
    ]);

    const [showAdditionalRecipients, setShowAdditionalRecipients] = useState(!!additionalRecipients?.length);
    const [showCc, setShowCc] = useState(!!ccRecipients?.length);
    const [showBcc, setShowBcc] = useState(!!bccRecipients?.length);

    useEffect(() => {
      if (additionalRecipients?.length) {
        setShowAdditionalRecipients(true);
      }
    }, [additionalRecipients, setShowAdditionalRecipients]);

    useEffect(() => {
      if (ccRecipients?.length) {
        setShowCc(true);
      }
    }, [ccRecipients, setShowCc]);

    useEffect(() => {
      if (bccRecipients?.length) {
        setShowBcc(true);
      }
    }, [bccRecipients, setShowBcc]);

    return (
      <>
        <ListIssuesByAction actionId={assignedAction?.id} align="flex-start" />
        <Flex mt="2" mb="6" gap="4" direction="column" align="start" w="100%">
          <HorizontalGreyBlackField icon={StarOutline} label="To" labelContainerProps={{ width: "75px" }}>
            <NameTag name={assignedActionTargetText(assignedAction)} />
          </HorizontalGreyBlackField>
          {assignedAction?.trigger?.type === "date_time" ? (
            <>
              <TriggerDate
                form={form}
                formFieldProps={{ sideLayout: true, labelProps: { display: "flex", alignItems: "flex-end", minWidth: "75px" } }}
                label={<GreyBlackLabel icon={CalendarMonth} label="Send Date" />}
                includeIcon={false}
                triggerButtonProps={{
                  padding: "0",
                  height: "32px !important",
                  paddingTop: "0 !important",
                  fontSize: "14px",
                }}
                containerProps={{ height: "32px" }}
              />
              <HorizontalGreyBlackField
                icon={Clock}
                label="Sending At"
                iconProps={{ alignSelf: "flex-end" }}
                labelContainerProps={{ minWidth: "75px" }}
              >
                <TriggerTimeModalButton form={form} assignedActionId={assignedAction?.id || ""} />
              </HorizontalGreyBlackField>
            </>
          ) : (
            <TriggerExplanation assignedAction={assignedAction} />
          )}
          {!(showAdditionalRecipients && showCc && showBcc) && (
            <ButtonGroup colorScheme="gray" variant="outline" isAttached mb={4}>
              {!showAdditionalRecipients && (
                <Button onClick={() => setShowAdditionalRecipients((s) => !s)}>+ Recipients</Button>
              )}
              {!showCc && <Button onClick={() => setShowCc((s) => !s)}>Cc</Button>}
              {!showBcc && <Button onClick={() => setShowBcc((s) => !s)}>Bcc</Button>}
            </ButtonGroup>
          )}
          {showAdditionalRecipients && (
            <RecipientsContainer label="Additional Recipients" name="additionalRecipients" control={control} />
          )}
          {showCc && <RecipientsContainer label="Cc" name="ccRecipients" control={control} />}
          {showBcc && <RecipientsContainer label="Bcc" name="bccRecipients" control={control} />}
        </Flex>
        <TextInputField {...SUBJECT_FIELD} errors={errors} register={register} />
        <ActionRichTextAreaField
          actionType="email"
          targetType={assignedAction?.workflowAction?.actionable?.targetType}
          {...BODY_FIELD}
          errors={errors}
          control={control}
        />
      </>
    );
  },
);

export default UpdateEmailForm;
