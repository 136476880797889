import { OptionType } from "components/Form/Select";
import { FieldInfo } from "components/Form/typings";
import DatePickerField from "components/FormElements/Fields/DatePickerField";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import CreateableSingleSelectField from "components/FormElements/Fields/SelectFields/CreateableSingleSelectField";
import LocationSelectField from "components/FormElements/Fields/SelectFields/LocationSelectField";
import SingleSelectField from "components/FormElements/Fields/SelectFields/SingleSelectField";
import TimezoneSelectField from "components/FormElements/Fields/SelectFields/TimezoneSelectField";
import UserSelectField from "components/FormElements/Fields/SelectFields/UserSelectField";
import { AutoFormFieldType } from "models/autoForm";
import { FieldErrors, FieldValues, UseFormReturn, UseFormSetValue } from "react-hook-form";

// Define the props that will be passed to the field mapper
interface FieldProps {
  name: string;
  label: string;
  register?: any; // Assuming you're using `register` from React Hook Form, type this properly based on your form lib
  control?: any; // Control from React Hook Form for more complex inputs
  errors?: FieldErrors; // Errors from React Hook Form
  optionsUrl?: string;
  options?: OptionType;
  setValue?: UseFormSetValue<any>;
}

// Map each type to the corresponding component
const fieldMapper: Record<AutoFormFieldType, (props: FieldProps) => JSX.Element> = {
  text: ({
    name, label, register, control, errors, setValue, ...rest
  }) => (
    <TextInputField name={name} label={label} register={register} errors={errors} {...rest} />
  ),
  email: ({
    name, label, register, control, errors, setValue, ...rest
  }) => (
    <TextInputField name={name} label={label} register={register} errors={errors} {...rest} />
  ),
  date: ({
    name, label, control, errors, register, setValue, ...rest
  }) => (
    <DatePickerField name={name} label={label} control={control} errors={errors} {...rest} />
  ),
  userSelect: ({
    name, label, control, register, errors, setValue, ...rest
  }) => (
    <UserSelectField name={name} label={label} control={control} errors={errors} {...rest} />
  ),
  createableSingleSelect: ({
    name, label, control, errors, register, optionsUrl, setValue, ...rest
  }) => (
    <CreateableSingleSelectField
      name={name}
      label={label}
      control={control}
      errors={errors}
      resourceUrl={optionsUrl as string}
      {...rest}
    />
  ),
  singleSelect: ({
    name, label, control, errors, options, setValue, ...rest
  }) => (
    <SingleSelectField name={name} label={label} control={control} errors={errors} options={options} {...rest} />
  ),
  timezoneSelect: ({
    name, label, control, errors, options, setValue, ...rest
  }) => (
    <TimezoneSelectField name={name} label={label} control={control} errors={errors} {...rest} />
  ),
  locationSelect: ({
    name, label, control, errors, options, setValue, ...rest
  }) => (
    <LocationSelectField name={name} label={label} control={control} errors={errors} setValue={setValue} {...rest} />
  ),
};

interface FieldByTypeProps extends FieldInfo {
  name: string;
  label: string;
  type: AutoFormFieldType;
  form: UseFormReturn<FieldValues>;
}

export default function FieldByType({
  name,
  label,
  type,
  form,
  ...rest
}: FieldByTypeProps) {
  const {
    register,
    control,
    formState: { errors },
    setValue,
  } = form;
  const FieldComponent = fieldMapper[type];
  if (FieldComponent) {
    return FieldComponent({
      name,
      label,
      register,
      control,
      errors,
      setValue,
      ...rest,
    });
  }
  return null;
}
