import { useDisclosure } from "@chakra-ui/react";
import { toCombinedActionId } from "features/CombinedAction/helpers";
import { CombinedAction, CombinedActionId } from "models/automation/combinedAction";
import { useCallback, useState } from "react";

export default function useAssignedActionSideDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedActionId, setSelectedActionId] = useState<CombinedActionId | "">("");

  const handleOnClose = useCallback(
    () => {
      setSelectedActionId("");
      onClose();
    },
    [onClose],
  );

  const handleOpenDrawer = useCallback(
    (action: CombinedAction) => {
      setSelectedActionId(toCombinedActionId(action));
      onOpen();
    },
    [onOpen],
  );

  // this wraps an action and then returns a clickable handler function
  const createExpandClickHandlerByCombinedAction = useCallback(
    (action: CombinedAction) => (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      handleOpenDrawer(action);
    },
    [handleOpenDrawer],
  );

  const handleOpenDrawerById = useCallback(
    (combinedId: CombinedActionId) => {
      setSelectedActionId(combinedId);
      onOpen();
    },
    [onOpen],
  );

  // this wraps an action and then returns a clickable handler function
  const createExpandClickHandlerByCombinedId = useCallback(
    (combinedId: CombinedActionId) => (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      handleOpenDrawerById(combinedId);
    },
    [handleOpenDrawerById],
  );

  return {
    isOpen,
    onClose: handleOnClose,
    selectedActionId,
    createExpandClickHandlerByCombinedAction,
    createExpandClickHandlerByCombinedId,
  };
}
