/* eslint-disable max-len */
import { Icon, IconProps } from "@chakra-ui/react";

export default function Flower(props: IconProps) {
  return (
    <Icon
      viewBox="0 0 24 15"
      fill="none"
      {...props}
    >
      <path
        d="M23.6742 2.78381C23.6742 2.58638 23.6525 2.39388 23.6101 2.20829C23.3277 0.94768 22.1372 0 20.7117 0C19.076 0 17.7493 1.2458 17.7493 2.78282C17.7493 1.2458 16.4225 0 14.7868 0C13.2389 0 11.9684 1.1155 11.8361 2.53801C11.7039 1.1155 10.4344 0 8.88549 0C7.24975 0 5.923 1.2458 5.923 2.78282C5.92498 1.24679 4.59822 0.000987167 2.96249 0.000987167C1.45015 0.000987167 0.204344 1.06515 0.023692 2.43929C0.0088845 2.55281 0 2.66732 0 2.78381C0 2.82527 0.000987167 2.86772 0.0029615 2.90918C0.000987167 2.99803 0 3.08588 0 3.17473C0 9.70582 5.29418 15 11.8253 15C18.3564 15 23.6505 9.70582 23.6505 3.17473C23.6505 3.16288 23.6505 3.15005 23.6505 3.1382C23.6663 3.02172 23.6752 2.90424 23.6752 2.78381H23.6742Z"
        fill="#DBF3DA"
      />
    </Icon>
  );
}

{/* <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
