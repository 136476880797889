/* eslint-disable max-len */
import { Icon, IconProps, useColorModeValue } from "@chakra-ui/react";

export default function LinkedInIcon({ ...props }: IconProps) {
  const color = useColorModeValue("notBlack.50", "white");
  return (
    <Icon
      viewBox="0 0 12 12"
      fill="none"
      color={color}
      {...props}
    >
      <path
        d="M 10.224 10.2258 h -1.7772 v -2.784 c 0 -0.6642 -0.0138 -1.5186 -0.9264 -1.5186 c -0.9264 0 -1.068 0.7224 -1.068 1.4694 v 2.8332 H 4.6758 V 4.5 h 1.7064 v 0.78 h 0.0234 c 0.2382 -0.45 0.8184 -0.924 1.6848 -0.924 c 1.8006 0 2.1336 1.1844 2.1336 2.727 z M 2.6682 3.7164 c -0.5724 0 -1.032 -0.4626 -1.032 -1.032 s 0.4602 -1.032 1.032 -1.032 a 1.032 1.032 90 0 1 0 2.064 m 0.8904 6.51 h -1.782 V 4.5 h 1.782 z M 11.1132 0 H 0.8856 C 0.396 0 0 0.387 0 0.864 v 10.272 C 0 11.613 0.396 12 0.8856 12 h 10.2252 c 0.489 0 0.8892 -0.3864 0.8892 -0.864 V 0.864 C 12 0.3876 11.5998 0 11.1108 0 z"
        fill="currentColor"
      />
    </Icon>
  );
}
