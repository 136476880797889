import { Tag, TagProps } from "@chakra-ui/react";
import { scheduledActionStatusConverter } from "helpers/scheduledWorkflowConverters";
import { statusDateTime } from "helpers/status";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { PropsWithChildren } from "react";
import { AssignedActionStatusType } from "models/automation";
import { toActionStatusStyles } from "./styles";

interface ActionStatusProps extends TagProps {
  action: AssignedAction;
}

function ActionStatus({ status, children, ...rest }: { status: AssignedActionStatusType } & PropsWithChildren) {
  return (
    <Tag size="sm" height="24px" fontSize="xs" {...toActionStatusStyles(status)} {...rest}>
      {children}
    </Tag>
  );
}

export function ActionStatusTag({ action, ...rest }: ActionStatusProps) {
  if (!action) {
    return null;
  }
  return (
    <ActionStatus status={action?.status} {...rest}>
      {scheduledActionStatusConverter(action?.status)}
    </ActionStatus>
  );
}

export function ActionStatusTimeTag({ action, ...rest }: ActionStatusProps) {
  if (!action || ["pending", "awaiting_info"].includes(action.status)) {
    return null;
  }
  let statusAt = "";
  if (["processed", "error", "skipped"].includes(action.status)) {
    statusAt = ` ${statusDateTime(action.statusUpdatedAt)}`;
  }
  return (
    <ActionStatus status={action?.status} {...rest}>
      {`${scheduledActionStatusConverter(action?.status)}${statusAt}`}
    </ActionStatus>
  );
}
