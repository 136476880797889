import { useCallback, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { User } from "models/user";
import { camelCase, isEmpty, omit, pick } from "lodash";
import { setNewUserInQueryClient } from "features/User/hooks";
import useAutoSave from "components/FormElements/useAutoSave";
import { apiUserPathById } from "helpers/url";
import { useDeepCompareMemoize } from "use-deep-compare-effect";
import { UserUpdateFormValues, userUpdateSchema, values } from "./formDefinition";

function transformData(data: UserUpdateFormValues) {
  // will only pick fields that have a value
  const onboardingJourney = pick(data, [
    "startDate",
    "peopleTeamContactId",
    "buddyId",
    "onboardingPlanUrl",
    "teamWikiUrl",
    "teamGoalsUrl",
    "shippingTrackingNumber",
    "shippingTrackingUrl",
    "shippingCarrier",
    "internalOnboardingChecklistUrl",
    "hrisOnboardingUrl",
  ]);

  const user = omit(data, [
    "startDate",
    "peopleTeamContactId",
    "buddyId",
    "onboardingPlanUrl",
    "teamWikiUrl",
    "teamGoalsUrl",
    "shippingTrackingNumber",
    "shippingTrackingUrl",
    "shippingCarrier",
    "internalOnboardingChecklistUrl",
    "hrisOnboardingUrl",
  ]);

  return {
    ...(isEmpty(user) ? {} : { user }),
    ...(isEmpty(onboardingJourney) ? {} : { onboardingJourney }),
  };
}

interface useUserAutoSaveFormProps {
  user: User | undefined;
  fields: Array<keyof UserUpdateFormValues>;
  onSuccess?: () => void;
  onClose?: () => void;
}

export default function useUserAutoSaveForm({
  user,
  fields,
  onSuccess = () => {},
  onClose = () => {},
}: useUserAutoSaveFormProps) {
  const camelFields = useMemo(() => fields.map((field) => camelCase(field)), [fields]);
  const queryClient = useQueryClient();
  const onUpdateUserSuccess = useCallback((resp: User) => {
    setNewUserInQueryClient(queryClient, resp);
    onSuccess?.();
  }, [onSuccess, queryClient]);

  const defaultValues = useMemo(() => {
    if (!user) return {};
    return pick(values(user), camelFields);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useDeepCompareMemoize([user, camelFields]));

  const form = useForm({
    mode: "onBlur",
    resolver: yupResolver(userUpdateSchema),
    values: defaultValues,
  });

  const { isSubmitting, isSuccess, isError } = useAutoSave({
    form,
    submitUrl: apiUserPathById(user?.id || ""),
    onSuccess: onUpdateUserSuccess,
    transformData,
  });
  return {
    form,
    isReady: !!user,
    isSubmitting,
    isSuccess,
    isError,
  };
}
