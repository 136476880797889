import {
  useClipboard,
  useToast,
  Flex,
  IconButton,
  useColorModeValue,
  theme,
  Card,
  CardBody,
  Text,
  Tooltip,
  FlexProps,
} from "@chakra-ui/react";
import { MdContentCopy } from "react-icons/md";

interface CopyableValueProps extends FlexProps {
  copyableValue: string;
  displayValue?: string;
  textProps?: object;
}

export function CopyableValue({
  displayValue, copyableValue, textProps, ...rest
}: CopyableValueProps) {
  const { onCopy } = useClipboard(copyableValue);
  const toast = useToast({
    status: "success",
    duration: 2000,
  });

  const handleCopy = () => {
    onCopy();
    toast({
      title: `Copied!`,
      description: `Value ${copyableValue} has been copied to your clipboard.`,
    });
  };

  return (
    <Flex align="center" justify="space-between" gap="6" {...rest}>
      <Tooltip label={copyableValue} aria-label={`Value ${copyableValue}`} placement="top">
        <Text isTruncated color="fg.muted" {...textProps}>
          {displayValue || copyableValue}
        </Text>
      </Tooltip>
      <Tooltip label="Copy to clipboard" aria-label={`Copy ${copyableValue} to clipboard`} placement="top">
        <IconButton
          size="xs"
          aria-label={`Copy Value ${copyableValue}`}
          onClick={handleCopy}
          icon={<MdContentCopy />}
        />
      </Tooltip>
    </Flex>
  );
}

CopyableValue.defaultProps = {
  textProps: {},
  displayValue: null,
};

interface CopyableFieldProps {
  fieldName: string;
  fieldValue: string;
  includeFieldName?: boolean;
}

export function CopyableField({ fieldName, fieldValue, includeFieldName }: CopyableFieldProps) {
  const cardBg = useColorModeValue(theme.colors.gray[100], theme.colors.gray[600]);
  const { onCopy } = useClipboard(fieldValue);
  const toast = useToast({
    status: "success",
    duration: 2000,
  });

  const handleCopy = () => {
    onCopy();
    toast({
      title: `${fieldName} copied.`,
      description: `The ${fieldName} has been copied to your clipboard.`,
    });
  };

  return (
    <Flex direction="column">
      {includeFieldName && (
        <Text color="fg.muted" fontWeight="700">
          {fieldName}
        </Text>
      )}
      <Card bg={cardBg} mt="1">
        <CardBody fontWeight="bold" pl="6" pr="3" p="1">
          <Flex align="center" justify="space-between" gap="6">
            <Text color="fg.muted">{fieldValue}</Text>
            <IconButton aria-label={`Copy ${fieldName}`} onClick={handleCopy} icon={<MdContentCopy />} />
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}

CopyableField.defaultProps = {
  includeFieldName: false,
};
