import { UUID } from "crypto";
import { PersonalizationTokenRoleName } from "./personalizationToken";
import { User } from "./user";
import { Company } from "./company";
import { NewHireJourney } from "./newHire";
import { AssignedAction } from "./automation/scheduledWorkflow";

export type IssueType =
| "missing_channel"
| "missing_message_service_user"
| "missing_message_service"
| "missing_email"
| "unassigned_role"
| "missing_attribute"
| "reference_event_overlap"
| "trigger_at_in_past";

export const TIMING_ISSUE_TYPES = [
  "reference_event_overlap",
  "trigger_at_in_past",
];

export type TimingIssueType = typeof TIMING_ISSUE_TYPES[number];

export type NonTimingIssueType = Exclude<IssueType, TimingIssueType>;

export type IssueContext = {
  roleName: PersonalizationTokenRoleName;
  attribute: string;
  emailType: string;
  userId: string;
};

export type Issue = {
  id: string;
  issueType: IssueType; // TODO: Replace with enum or union type
  newHireName: string;
  newHireJourneyId: string;
  scheduledWorkflowActionIds: UUID[];
  context: IssueContext;
  createdAt? : Date;
};

export type IssueContextQueryResult = {
  issue: Issue;
  company?: Company;
  user?: User;
  newHire?: NewHireJourney;
  actions?: AssignedAction[];
  isLoading: boolean;
  isFetched: boolean;
} | Record<string, never>;

export type FieldDef<T> = {
  name: keyof T
  label?: string;
};
