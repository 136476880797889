/* eslint-disable max-len */
import { Icon, IconProps } from "@chakra-ui/react";

export default function DownTriangle(props: IconProps) {
  return (
    <Icon
      viewBox="0 0 14 14"
      fill="none"
      {...props}
    >
      <path
        d="M4.08366 5.83333L7.00033 8.75L9.91699 5.83333"
        stroke="#4097EB"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
