/* eslint-disable no-nested-ternary */
import { Box, Card, CardBody, CardProps, Flex, FlexProps, HStack, Skeleton, Stack, StackProps } from "@chakra-ui/react";
import { Heading16 } from "components/Heading";
import IssueStatusTag from "components/Tag/Status/IssueStatusTag";
import { Issue } from "models/issue";
import InfoExpander from "components/InfoExpander";
import { Text14 } from "components/Text";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { useIssueValue } from "../Resolutions/useIssueValue";
import NextIssueButton from "./NextPreviousButton";
import { issueResolution } from "../Resolutions/issueResolution";
import { useIssueContextQuery } from "../hooks";
import { isTimingIssue } from "../helpers";
import { useActionIssueValue } from "../Resolutions/useActionIssueValue";
import { IndividualActionTimingIssueModalInputFields } from "../Resolutions/definitions/TimingResolution";
import resolveIssueType from "../Resolutions/resolveIssueType";

export function IssueCardSkeleton({ ...rest }: StackProps) {
  return (
    <Stack padding={4} spacing={1} w="340px" {...rest}>
      <Skeleton height="40px" />
      <Skeleton height="40px" />
    </Stack>
  );
}

interface IssueCardHeaderProps extends FlexProps {
  title: string;
  additionalContext?: React.ReactNode;
  status?: Issue["status"];
}

function IssueCardHeader({ title, additionalContext, status, ...rest }: IssueCardHeaderProps) {
  return (
    <Flex direction="column" {...rest}>
      <HStack justify="space-between">
        <Heading16>{title}</Heading16>
        {status && (
          <IssueStatusTag status={status} />
        )}
      </HStack>
      <Box my="2">
        <InfoExpander buttonText="More Info">
          {additionalContext ?? "Loading..."}
        </InfoExpander>
      </Box>
    </Flex>
  );
}

export function ActionIssueResolutionContent({
  issue,
  action,
  skeletonProps = {},
}: { issue: Issue, action: AssignedAction, skeletonProps?: object }) {
  const value = useActionIssueValue({ issue, action });
  const issueContext = useIssueContextQuery(issue);
  const { title, additionalContext, inputComponent: InputComponent } = issueResolution(issue, issueContext);
  return (
    <>
      <IssueCardHeader
        title={title}
        {...(!isTimingIssue(issue) ? { status: value ? "resolved" : "error" } : {})}
        additionalContext={additionalContext}
      />
      {issueContext?.isLoading ? (
        <IssueCardSkeleton {...skeletonProps} w="100%" />
      ) : isTimingIssue(issue) ? (
        <IndividualActionTimingIssueModalInputFields issue={issue} action={action} />
      ) : (
        <InputComponent issue={issue} />
      )}
    </>
  );
}

export function IssueResolutionContent({
  issue,
  issues = [],
  includeNext = false,
  skeletonProps = {},
}: { issue: Issue, issues?: Issue[], includeNext?: boolean, skeletonProps?: object }) {
  const { data: value, isLoading } = useIssueValue(issue);
  const issueContext = useIssueContextQuery(issue);
  const { title, additionalContext, inputComponent: InputComponent } = issueResolution(issue, issueContext);
  return (
    <>
      <IssueCardHeader
        title={issueContext.isFetched ? title : resolveIssueType(issue) as string}
        {...(!isTimingIssue(issue) ? { status: value ? "resolved" : "error" } : {})}
        additionalContext={additionalContext}
      />
      {isLoading || issueContext?.isLoading ? (
        <IssueCardSkeleton {...skeletonProps} />
      ) : isTimingIssue(issue) && value ? (
        <Text14>No Actions to Resolve</Text14>
      ) : (
        <InputComponent issue={issue} />
      )}
      {includeNext && (
        <Flex justify="space-between">
          <NextIssueButton issueId={issue?.id} issues={issues} />
        </Flex>
      )}
    </>
  );
}

export default function IssueCard({
  issue,
  issues = [],
  includeNext = false,
  ...rest
}: { issue: Issue, issues?: Issue[], includeNext?: boolean } & CardProps) {
  if (!issue || !issues) {
    return <IssueCardSkeleton />;
  }
  return (
    <Card width="fit-content" {...rest}>
      <CardBody>
        <IssueResolutionContent issue={issue} issues={issues} includeNext={includeNext} />
      </CardBody>
    </Card>
  );
}
