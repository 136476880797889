import { Box, Button, ButtonProps, Icon, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal, useColorModeValue } from "@chakra-ui/react";
import DownTriangle from "components/Icon/Iconify/DownTriangle";
import InfoOutlineRounded from "components/Icon/Iconify/InfoOutlineRounded";
import { Text12 } from "components/Text";

interface InfoExpanderProps extends ButtonProps {
  buttonText: string;
  includePortal?: boolean;
}

export default function InfoExpander({ buttonText, children, includePortal = false, ...rest }: InfoExpanderProps) {
  const bgColor = useColorModeValue("backgroundBlue.light", "backgroundBlue.dark");

  const popoverContent = (
    <PopoverContent bg={bgColor} borderColor={bgColor} boxShadow="none">
      <PopoverBody>
        <Box borderRadius="10px">
          {typeof children === "string" ? (
            <Text12 lineHeight="20px">{children}</Text12>
          ) : (
            children
          )}
        </Box>
      </PopoverBody>
    </PopoverContent>
  );
  return (
    <Popover gutter={2} isLazy>
      <PopoverTrigger>
        <Button
          variant="unstyled"
          color="linkBlue.DEFAULT"
          height="20px"
          display="inline-flex"
          fontSize="12px"
          fontWeight="400"
          rightIcon={<Icon style={{ marginRight: "4px" }} boxSize="14px" as={DownTriangle} />}
          leftIcon={<Icon style={{ marginLeft: "4px" }} boxSize="14px" as={InfoOutlineRounded} />}
          {...rest}
        >
          {buttonText}
        </Button>
      </PopoverTrigger>
      {includePortal ? <Portal>{popoverContent}</Portal> : popoverContent}
    </Popover>
  );
}
