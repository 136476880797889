import {
  Button, Input, InputGroup, InputRightElement, useTheme,
} from "@chakra-ui/react";
import { TextInputFieldProps } from "components/Form/typings";
import { useState } from "react";
import FormField from "../FormField";

export default function SecureInputField({
  name,
  label,
  register,
  placeholder,
  helperText,
  errors,
  required,
  textTransform,
  defaultValue: _,
  variant,
  formFieldProps = {},
  ...props
}: TextInputFieldProps) {
  const theme = useTheme();
  // Accessing default variant from the theme
  const formVariant = variant || theme.components.Form.defaultProps.variant;
  const [show, setShow] = useState(false);

  return (
    <FormField
      name={name}
      label={label}
      errors={errors}
      helperText={helperText}
      required={required}
      variant={formVariant}
      {...formFieldProps}
    >
      <InputGroup size="md">
        <Input
          pr="4.5rem"
          type={show ? "text" : "password"}
          placeholder={["floating", "flushedFloating"].includes(formVariant) ? " " : (placeholder || "Enter password")}
          colorScheme="brand"
          {...register(name)}
          {...props}
        />
        <InputRightElement width="4.5rem">
          <Button colorScheme="gray" h="1.75rem" size="sm" pr="2" onClick={() => setShow((s) => !s)}>
            {show ? "Hide" : "Show"}
          </Button>
        </InputRightElement>
      </InputGroup>
    </FormField>
  );
}
