import GreyBlackField, { GreyBlackFieldProps, HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";
import CaminoPeopleTeamIcon from "components/Icon/CaminoPeopleTeamIcon";
import BaselinePeopleOutline from "components/Icon/Iconify/BaselinePeopleOutline";
import OutlinePersonPin from "components/Icon/Iconify/OutlinePersonPin";
import RoundPersonOutline from "components/Icon/Iconify/RoundPersonOutline";
import { BuddyTag, ManagerTag, NewHireTag, PeopleTeamContactTag } from "components/Tag/UserTag/RoleTag";
import { NewHireJourney } from "models/newHire";

interface NewHireInfoProps extends Omit<GreyBlackFieldProps, "label"> {
  newHire: NewHireJourney;
  includeIcon?: boolean;
  includeLink?: boolean;
}

export function NewHireInfo({ newHire, includeIcon = false, includeLink = true, ...rest }: NewHireInfoProps) {
  return (
    <GreyBlackField
      label="New Hire"
      icon={includeIcon ? RoundPersonOutline : null}
      gap="3px"
      {...rest}
    >
      <NewHireTag newHire={newHire} />
    </GreyBlackField>
  );
}

export function HorizontalNewHireInfo({ newHire, includeIcon = false, includeLink = true, ...rest }: NewHireInfoProps) {
  return (
    <HorizontalGreyBlackField
      label="New Hire"
      icon={includeIcon ? RoundPersonOutline : null}
      {...rest}
    >
      <NewHireTag newHire={newHire} />
    </HorizontalGreyBlackField>
  );
}

export function ManagerInfo({ newHire, includeIcon = false, includeLink = true, ...rest }: NewHireInfoProps) {
  return (
    <GreyBlackField
      label="Manager"
      icon={includeIcon ? OutlinePersonPin : null}
      gap="3px"
      {...rest}
    >
      <ManagerTag newHire={newHire} />
    </GreyBlackField>
  );
}

export function HorizontalManagerInfo({ newHire, includeIcon = false, includeLink = true, ...rest }: NewHireInfoProps) {
  return (
    <HorizontalGreyBlackField
      label="Manager"
      icon={includeIcon ? OutlinePersonPin : null}
      {...rest}
    >
      <ManagerTag newHire={newHire} />
    </HorizontalGreyBlackField>
  );
}

export function PeopleTeamContactInfo({ newHire, includeIcon = false, includeLink = true, ...rest }: NewHireInfoProps) {
  return (
    <GreyBlackField
      label="People Team Contact"
      icon={includeIcon ? CaminoPeopleTeamIcon : null}
      gap="3px"
      {...rest}
    >
      <PeopleTeamContactTag newHire={newHire} />
    </GreyBlackField>
  );
}

export function HorizontalPeopleTeamContactInfo({ newHire, includeIcon = false, includeLink = true, ...rest }: NewHireInfoProps) {
  return (
    <HorizontalGreyBlackField
      label="People Team Contact"
      icon={includeIcon ? CaminoPeopleTeamIcon : null}
      {...rest}
    >
      <PeopleTeamContactTag newHire={newHire} />
    </HorizontalGreyBlackField>
  );
}

export function BuddyInfo({ newHire, includeIcon = false, includeLink = true, ...rest }: NewHireInfoProps) {
  return (
    <GreyBlackField
      label="Buddy"
      icon={includeIcon ? BaselinePeopleOutline : null}
      gap="3px"
      {...rest}
    >
      <BuddyTag newHire={newHire} />
    </GreyBlackField>
  );
}

export function HorizontalBuddyInfo({ newHire, includeIcon = false, includeLink = true, ...rest }: NewHireInfoProps) {
  return (
    <HorizontalGreyBlackField
      label="Buddy"
      icon={includeIcon ? BaselinePeopleOutline : null}
      {...rest}
    >
      <BuddyTag newHire={newHire} />
    </HorizontalGreyBlackField>
  );
}
