import * as yup from "yup";
import { sharedSchema } from "../Shared/schema";
import { MESSAGE_FIELD } from "./fields";

const content = yup.object({
  message: yup.string().required().label(MESSAGE_FIELD.label),
});

export const schema = sharedSchema.concat(
  yup.object({
    content,
  }),
);

export type Schema = yup.InferType<typeof schema>;
