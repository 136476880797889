import { Flex } from "@chakra-ui/react";
import { HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { TriggerExplanation } from "components/Forms/AssignedActions/Shared/DisplayInfo";
import { EmailRecipient } from "models/automation";
import RecipientTag from "components/Forms/PathActions/Email/RecipientTag";
import { emailTagId } from "features/AssignedAction/helpers";
import { longAttributeStyles } from "../styles";
import { EmailRecipientAttribute } from "../Attribute/RecipientAttribute";
import SendAtAttribute from "../Attribute/SendAtAttribute";
import SendDateAttribute from "../Attribute/SendDateAttribute";
import TemplateActionAttribute from "../Attribute/FromTemplateAttribute";

export default function EmailOverview({ action }: { action: AssignedAction }) {
  return (
    <Flex mt="2" mb="6" gap="4" direction="column" align="start" w="100%">
      <EmailRecipientAttribute action={action} />
      {action?.ccRecipients?.length ? (
        <HorizontalGreyBlackField label="cc" labelContainerProps={longAttributeStyles.labelContainerProps}>
          {action?.ccRecipients?.map((recipient: EmailRecipient) => (
            <RecipientTag key={emailTagId(recipient)} recipient={recipient} disabled />
          ))}
        </HorizontalGreyBlackField>
      ) : null}
      {action?.bccRecipients?.length ? (
        <HorizontalGreyBlackField label="bcc" labelContainerProps={longAttributeStyles.labelContainerProps}>
          {action?.bccRecipients?.map((recipient: EmailRecipient) => (
            <RecipientTag key={emailTagId(recipient)} recipient={recipient} disabled />
          ))}
        </HorizontalGreyBlackField>
      ) : null}
      {action?.trigger?.type === "date_time" ? (
        <>
          <SendDateAttribute action={action} />
          <SendAtAttribute action={action} />
        </>
      ) : (
        <TriggerExplanation assignedAction={action} />
      )}
      <TemplateActionAttribute action={action} />
    </Flex>
  );
}
