import { Heading } from "@chakra-ui/react";
import NotificationsForm from "features/Notifiers/NotifierForm";
import { useNavbar } from "hooks/useNavbar";
import AppPage from "layouts/AppPage";
import InnerSidebar from "layouts/InnerSidebar";
import { useEffect } from "react";

export default function NotifiersPage() {
  const { setBackLocationPath, setBackLocationName, setPageTitle } = useNavbar();

  useEffect(() => {
    setPageTitle("Notifications");
    // setBackLocationName("Home");
    // setBackLocationPath("/");
    return () => {
      setPageTitle(null);
      setBackLocationName(null);
      setBackLocationPath(null);
    };
  }, [setBackLocationName, setBackLocationPath, setPageTitle]);

  return (
    <AppPage>
      <InnerSidebar>
        <Heading mb="8" size="sm" color="fg.subtle">
          Notifications
        </Heading>
        <NotificationsForm />
      </InnerSidebar>
    </AppPage>
  );
}
