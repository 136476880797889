import { Issue, IssueContextQueryResult } from "models/issue";
import { userQueryArgs } from "features/User/hooks";
import { User } from "models/user";
import { OnlyIssue, ResolutionInfo } from "models/resolutions";
import { attributeValue, toEmailFieldName } from "features/Issues/helpers";
import { get } from "lodash";
import { Flex } from "@chakra-ui/react";
import { Text12 } from "components/Text";

export function missingChannelIssueType(issue: Issue) {
  return `Cannot Find Slack Channel`;
}

export function expandedMissingEmailIssueType(issue: Issue, issueContext: IssueContextQueryResult) {
  return missingChannelIssueType(issue);
}

export function missingChannelValue(issueContext: IssueContextQueryResult) {
  const attribute = toEmailFieldName(issueContext?.issue?.context?.emailType);
  return get(issueContext?.user, attribute);
}

export function useMissingEmailValue({ issue }: OnlyIssue) {
  return "PLACEHOLDER";
}

export function IssueModalInputFields({ issue }: { issue: Issue }) {
  return (
    <Flex direction="column" gap="2">
      <Text12>Placeholder</Text12>
    </Flex>
  );
}

export function missingChannelResolution(issue: Issue, issueContext: IssueContextQueryResult): ResolutionInfo {
  return ({
    issueType: "missing_channel",
    tagText: `Cannot Find Slack Channel`,
    title: `Cannot find Slack Channel {PLACEHOLDER}`,
    // eslint-disable-next-line max-len
    additionalContext: `The Slack channel {PLACEHOLDER}, which we want to deliver a message to, cannot be found.`,
    inputComponent: IssueModalInputFields,
  });
}

export function missingChannelQueryArgs(issue: Issue) {
  const { emailType, userId } = issue?.context || {};
  const attribute = toEmailFieldName(emailType);
  return {
    ...userQueryArgs(userId),
    // No rolename is provided for missing email, so we pass recipient bc it
    // defaults to looking for the userId user's info (and the userId is the person whose email is missing)
    select: (user: User) => attributeValue({ user, roleName: "recipient", attribute }),
  };
}

export function missingChannelContextQueries(issue: Issue) {
  const { userId } = issue?.context || {};
  return [userQueryArgs(userId)];
}
