/* eslint-disable max-len */
import { Icon, IconProps } from "@chakra-ui/react";

function BaselineErrorOutline({ color = "#E92E4D", ...props }: IconProps) {
  return (
    <Icon
      viewBox="0 0 14 14"
      fill="none"
      color={color}
      {...props}
    >
      <path
        d="M6.41699 8.75033H7.58366V9.91699H6.41699V8.75033ZM6.41699 4.08366H7.58366V7.58366H6.41699V4.08366ZM6.99449 1.16699C3.77449 1.16699 1.16699 3.78033 1.16699 7.00033C1.16699 10.2203 3.77449 12.8337 6.99449 12.8337C10.2203 12.8337 12.8337 10.2203 12.8337 7.00033C12.8337 3.78033 10.2203 1.16699 6.99449 1.16699ZM7.00033 11.667C4.42199 11.667 2.33366 9.57866 2.33366 7.00033C2.33366 4.42199 4.42199 2.33366 7.00033 2.33366C9.57866 2.33366 11.667 4.42199 11.667 7.00033C11.667 9.57866 9.57866 11.667 7.00033 11.667Z"
        fill="currentColor"
      />
    </Icon>
  );
}
export default BaselineErrorOutline;
