/* eslint-disable max-len */
import { Icon, IconProps } from "@chakra-ui/react";

export default function InfoOutlineRounded({ color = "#4097EB", ...props }: IconProps) {
  return (
    <Icon
      viewBox="0 0 14 14"
      fill="none"
      color={color}
      {...props}
    >
      <path
        d="M7.00033 9.91602C7.1656 9.91602 7.30424 9.86001 7.41624 9.74802C7.52824 9.63601 7.58405 9.49757 7.58366 9.33268V6.99935C7.58366 6.83407 7.52766 6.69563 7.41566 6.58402C7.30366 6.4724 7.16521 6.4164 7.00033 6.41602C6.83544 6.41563 6.69699 6.47163 6.58499 6.58402C6.47299 6.6964 6.41699 6.83485 6.41699 6.99935V9.33268C6.41699 9.49796 6.47299 9.6366 6.58499 9.7486C6.69699 9.8606 6.83544 9.9164 7.00033 9.91602ZM7.00033 5.24935C7.1656 5.24935 7.30424 5.19335 7.41624 5.08135C7.52824 4.96935 7.58405 4.8309 7.58366 4.66602C7.58327 4.50113 7.52727 4.36268 7.41566 4.25068C7.30405 4.13868 7.1656 4.08268 7.00033 4.08268C6.83505 4.08268 6.6966 4.13868 6.58499 4.25068C6.47338 4.36268 6.41738 4.50113 6.41699 4.66602C6.4166 4.8309 6.4726 4.96954 6.58499 5.08193C6.69738 5.19432 6.83583 5.25013 7.00033 5.24935ZM7.00033 12.8327C6.19338 12.8327 5.43505 12.6795 4.72533 12.373C4.0156 12.0666 3.39824 11.651 2.87324 11.1264C2.34824 10.6018 1.93272 9.98446 1.62666 9.27435C1.3206 8.56424 1.16738 7.8059 1.16699 6.99935C1.1666 6.19279 1.31983 5.43446 1.62666 4.72435C1.93349 4.01424 2.34902 3.39688 2.87324 2.87227C3.39747 2.34765 4.01483 1.93213 4.72533 1.62568C5.43583 1.31924 6.19416 1.16602 7.00033 1.16602C7.80649 1.16602 8.56483 1.31924 9.27533 1.62568C9.98583 1.93213 10.6032 2.34765 11.1274 2.87227C11.6516 3.39688 12.0674 4.01424 12.3746 4.72435C12.6818 5.43446 12.8348 6.19279 12.8337 6.99935C12.8325 7.8059 12.6793 8.56424 12.374 9.27435C12.0687 9.98446 11.6532 10.6018 11.1274 11.1264C10.6016 11.651 9.98427 12.0668 9.27533 12.3736C8.56638 12.6804 7.80805 12.8335 7.00033 12.8327ZM7.00033 11.666C8.3031 11.666 9.40658 11.2139 10.3107 10.3098C11.2149 9.4056 11.667 8.30213 11.667 6.99935C11.667 5.69657 11.2149 4.5931 10.3107 3.68893C9.40658 2.78477 8.3031 2.33268 7.00033 2.33268C5.69755 2.33268 4.59408 2.78477 3.68991 3.68893C2.78574 4.5931 2.33366 5.69657 2.33366 6.99935C2.33366 8.30213 2.78574 9.4056 3.68991 10.3098C4.59408 11.2139 5.69755 11.666 7.00033 11.666Z"
        fill="currentColor"
      />
    </Icon>
  );
}
