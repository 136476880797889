/* eslint-disable no-nested-ternary */
import { Box } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import { useEffect } from "react";
import { useNavbar } from "hooks/useNavbar";
import { useAssignedActionDetailQuery } from "features/AssignedAction/hooks";
import { retry3TimesPolicy } from "helpers/query";
import { displayActionType } from "components/Forms/AssignedActions/Shared/helpers";
import { newHireJourneyPathById } from "helpers/url";
import AssignedActionPageInfo from "features/AssignedAction/Components/AssignedActionPageInfo";
import QueryError from "components/EmptyComponents/QueryError";

export default function AssignedActionPage() {
  const { id } = useParams<{ id: string }>();
  const { setBackLocationPath, setPageTitle, setBackLocationName } = useNavbar();

  const {
    data,
    isLoading,
    error: queryError,
    isError,
  } = useAssignedActionDetailQuery(id || "", {
    retry: (failureCount: number, error: any) => retry3TimesPolicy(failureCount, error),
    enabled: !!id,
  });

  useEffect(() => {
    if (data && !isLoading) {
      setPageTitle(`${displayActionType(data.actionType)} Overview`);
      // setBackLocationName(`Schedule`);
    }
  }, [data, isLoading, setBackLocationName, setBackLocationPath, setPageTitle]);

  if (!data && isError) {
    return (<QueryError queryError={queryError} resourceName="Assigned Action" id={id as string} />);
  }

  return (
    <AppPage>
      <Box>{data && <AssignedActionPageInfo action={data} />}</Box>
    </AppPage>
  );
}
