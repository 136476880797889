import { useTheme, Box, IconButton, Flex } from "@chakra-ui/react";
import { chakraComponents } from "chakra-react-select";
import { GenericAsyncMultiSelect } from "components/Form/Select/GenericAsyncMultiSelect";
import { SelectFieldProps } from "components/Form/typings";
import { Path } from "models/automation";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { titleize } from "helpers/string";
import { CloseIcon } from "@chakra-ui/icons";
import FormField from "../FormField";

function CustomMenu({ children, ...props }: any) {
  return (
    <chakraComponents.Menu {...props}>
      <Box position="relative">
        <Flex position="absolute" top={2} right={2} zIndex={1}>
          <IconButton
            aria-label="Close menu"
            icon={<CloseIcon />}
            size="sm"
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation();
              props.selectProps.onMenuClose();
            }}
          />
        </Flex>
        {children}
      </Box>
    </chakraComponents.Menu>
  );
}

interface PathsSelectFieldWithConfirmProps extends SelectFieldProps<Path> {
  onSubmit: () => void;
  isLoading: boolean;
  closeMenuOnSelect?: boolean;
}

export default function PathsSelectFieldWithConfirm({
  name,
  label,
  helperText,
  control,
  errors,
  required,
  variant,
  onChangeCallback,
  isLoading,
  closeMenuOnSelect = false,
  onSubmit,
  formFieldProps = {},
  ...props
}: PathsSelectFieldWithConfirmProps) {
  const theme = useTheme();
  const formVariant = variant || theme.components.Form.defaultProps.variant;

  return (
    <FormField
      name={name}
      label={label}
      errors={errors}
      helperText={helperText}
      required={required}
      variant={formVariant}
      {...formFieldProps}
    >
      <GenericAsyncMultiSelect
        control={control}
        name={name}
        fetchPath={API_ROUTES.paths.base}
        extractValue={(v: Path) => v.id}
        embedOption={(v: Path) => ({
          value: v.id,
          label: titleize(v.name),
          rawValue: v,
        })}
        onChangeCallback={onChangeCallback}
        includeDropdownIndicator
        closeMenuOnSelect={closeMenuOnSelect}
        isClearable={false}
        variant={formVariant}
        placeholder={null}
        containerStyles={{ width: "100%" }}
        components={{
          Menu: CustomMenu,
        }}
        onBlur={onSubmit}
        isLoading={isLoading}
        {...props}
      />
    </FormField>
  );
}

PathsSelectFieldWithConfirm.defaultProps = {
  closeMenuOnSelect: false,
};
