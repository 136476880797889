import { TagProps } from "@chakra-ui/react";
import OutlineTag from "./OutlineTag";

export function RedTag({ children, ...rest }: TagProps) {
  return (
    <OutlineTag
      color="error.DEFAULT"
      borderColor="var(--chakra-colors-caminoNewErrorRed-DEFAULT)"
      bg="caminoNewErrorRed.opacity10"
      border="none"
      {...rest}
    >
      {children}
    </OutlineTag>
  );
}

export function GrayTag({ children, ...rest }: TagProps) {
  return (
    <OutlineTag
      color="tableUnderlineBorder.DEFAULT"
      borderColor="var(--chakra-colors-tableUnderlineBorder-DEFAULT)"
      bg="rgba(217, 217, 217, 0.05)"
      {...rest}
    >
      {children}
    </OutlineTag>
  );
}

export function BrandTag({ children, ...rest }: TagProps) {
  return (
    <OutlineTag
      color="brand.DEFAULT"
      borderColor="var(--chakra-colors-brand-DEFAULT)"
      bg="brand.50"
      {...rest}
    >
      {children}
    </OutlineTag>
  );
}
