import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import SendMessageForm from "components/ModalForm/SendMessageForm";
import React, { PropsWithChildren, useCallback } from "react";

interface SendMessageButtonWrapperProps extends PropsWithChildren {
  targetId: string;
  targetType: string;
  defaultMessage: string;
  onSuccess?: () => void;
}

export default function SendMessageButtonWrapper({
  targetId,
  defaultMessage,
  targetType,
  onSuccess,
  children,
  ...rest
}: SendMessageButtonWrapperProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSuccess = useCallback(() => {
    onClose();
    onSuccess?.();
  }, [onClose, onSuccess]);

  return (
    <>
      {React.cloneElement(children as JSX.Element, { onClick: onOpen })}

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="2xl" {...rest}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send Slack Message</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SendMessageForm
              onClose={onClose}
              targetId={targetId}
              targetType={targetType}
              draftMessage={defaultMessage}
              onSuccess={handleSuccess}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

SendMessageButtonWrapper.defaultProps = {
  onSuccess: () => null,
};
