import { PiBackspace } from "react-icons/pi";
import { AssignedActionType } from "models/automation";
import { toAssignedActionType } from "helpers/assignedAction";
import GreyStatusButton from "./GreyStatusButton";

export default function SkippedStatus({ actionType }: { actionType: AssignedActionType }) {
  return (
    <GreyStatusButton icon={PiBackspace} iconProps={{ sx: { transform: "scaleX(-1)" } }}>
      {`${toAssignedActionType(actionType)} Skipped`}
    </GreyStatusButton>
  );
}
