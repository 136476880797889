import { Tag } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { titleize } from "helpers/string";
import { toIssueStatusStyles } from "./styles";

export default function IssueStatusTag({ status, children, ...rest }: { status: "resolved" | "error" } & PropsWithChildren) {
  return (
    <Tag size="md" fontSize="xs" {...toIssueStatusStyles(status)} {...rest}>
      {titleize(status)}
    </Tag>
  );
}
