/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import { Icon, IconProps, useColorModeValue } from "@chakra-ui/react";

function OutlineSubtitles({ ...props }: IconProps) {
  const color = useColorModeValue("notBlack.50", "white");
  return (
    <Icon
      viewBox="0 0 12 12"
      fill="none"
      color={color}
      {...props}
    >
    <path
      d="M10 2H2C1.45 2 1 2.45 1 3V9C1 9.55 1.45 10 2 10H10C10.55 10 11 9.55 11 9V3C11 2.45 10.55 2 10 2ZM10 9H2V3H10V9ZM3 5H4V6H3V5ZM3 7H7V8H3V7ZM8 7H9V8H8V7ZM5 5H9V6H5V5Z"
      fill="currentColor"
    />
    </Icon>
  );
}
export default OutlineSubtitles;
