/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import { Icon, IconProps, useColorModeValue } from "@chakra-ui/react";

function OutlineEmail({ ...props }: IconProps) {
  const color = useColorModeValue("notBlack.50", "white");
  return (
    <Icon
      viewBox="0 0 12 12"
      fill="none"
      color={color}
      {...props}
    >
    <path
      d="M11 3c0-0.55-0.45-1-1-1H2c-0.55 0-1 0.45-1 1v6c0 0.55 0.45 1 1 1h8c0.55 0 1-0.45 1-1zm-1 0l-4 2.5-4-2.5zm0 6H2V4l4 2.5L10 4z"
      fill="currentColor"
    />
    </Icon>
  );
}
export default OutlineEmail;
